import { BusinessContextGridRowProps } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/BusinessContextGridRow';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

import { IconName } from '@ac/web-components';

export const mapReportToBusinessContextGridRowProps = (
  report: BusinessContextDataReport
): BusinessContextGridRowProps[] => {
  const context = report.currentGlobalContext;

  return [
    {
      nameKey: 'USER',
      icon: IconName.profile,
      current: context?.user && {
        id: context.user.details.id,
        displayName: context.user.details.userName,
      },
      store: report.cache.user,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
    {
      nameKey: 'SYSTEM',
      icon: IconName.profileSettings,
      current: context?.system && {
        id: context.user.details.id,
        displayName: 'SYSTEM',
      },
      store: report.cache.system,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
    {
      nameKey: 'CUSTOMER',
      icon: IconName.configCustomer,
      current: context?.customer && {
        id: context.customer.details.id,
        displayName: context.customer.details.name,
      },
      store: report.cache.customer,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
    {
      nameKey: 'CENTRAL_RESERVATION_OFFICE',
      icon: IconName.cro,
      current: context?.centralReservationOffice && {
        id: context.centralReservationOffice.office.id,
        displayName: context.centralReservationOffice.office.name,
      },
      store: report.cache.centralReservationOffice,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
    {
      nameKey: 'PROPERTY',
      icon: IconName.configProperty,
      current: context?.property && {
        id: context.property.details.id,
        displayName: context.property.details.name,
      },
      store: report.cache.property,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
    {
      nameKey: 'PROFILE_CENTER',
      icon: IconName.profileDetails,
      current: context?.profileCenters && {
        id: context.profileCenters.details.id,
        displayName: context.profileCenters.details.code,
      },
      store: report.cache.profileCenters,
      entitiesLimit: report.cache.entitiesLimitPerStore,
    },
  ];
};
