import { ConfirmationModalPresenterContextProvider } from 'frontend-container/components/modals/confirmationModal/presenter/Provider';

import {
  createProvider,
  PropsWithChildren,
  ProviderComposer,
} from '@ac/react-infrastructure';

const providers = [
  createProvider({ component: ConfirmationModalPresenterContextProvider }),
];

export const RefreshModalPresenterProviders = (
  props: PropsWithChildren
): JSX.Element => (
  <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
);
