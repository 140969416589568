import { PresenterResultType } from 'frontend-container/publicApi';

export const handleUnsavedChangesRedirect = async (
  discardUnsavedChanges: boolean,
  link: string,
  callback: (link: string) => void
): Promise<void> => {
  if (
    discardUnsavedChanges ||
    !window.ACP?.container?.hasUnsavedChanges?.(link)
  ) {
    callback(link);
  } else {
    const modal = window.ACP?.container?.modals?.unsavedChanges;
    const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

    if (!isUnsavedChangesModalVisible) {
      const result = await modal?.show();

      if (result?.type === PresenterResultType.Confirmed) {
        callback(link);
      }
    }
  }
};
