export enum InternetSpeedTestStatus {
  Finished = 'Measured',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  Error = 'Error',
}

interface InternetSpeedTestFinishedState {
  valueInMbps: number;
  date: Date;
  status: InternetSpeedTestStatus.Finished;
}

export interface InternetSpeedTestError {
  detailedMessage: string;
}

interface InternetSpeedTestErrorState {
  error: InternetSpeedTestError;
  status: InternetSpeedTestStatus.Error;
}

interface InternetSpeedTestInProgressState {
  status: InternetSpeedTestStatus.InProgress;
}

interface InternetSpeedTestNotStartedState {
  status: InternetSpeedTestStatus.NotStarted;
}

export type InternetSpeedTestState =
  | InternetSpeedTestFinishedState
  | InternetSpeedTestErrorState
  | InternetSpeedTestInProgressState
  | InternetSpeedTestNotStartedState;
