import { isInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const inventoryAppName = 'frontend-inventory';
export const registerInventory = (): void => {
  registerApplication(
    inventoryAppName,
    () => loadReactApp(acConfig.frontendUrls.inventory, inventoryAppName),
    (location: Location) => isInventoryModule(location.pathname)
  );
};
