import { getConfigurationEntityDescription } from 'frontend-container/utils/getConfigurationEntityDescription';

import { GenericEntity } from '@ac/library-api';

export const mapWorkstationsToOptions = (
  workstations: GenericEntity[]
): Array<{ name: string; value: string }> =>
  workstations.map((workstation) => ({
    name: `${workstation?.code} - ${getConfigurationEntityDescription(
      workstation.descriptionMap
    )}`,
    value: workstation.id,
  }));
