import { microservicesVersionsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/manifest';
import { MicroservicesVersionsList } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/MicroservicesVersionsList';
import { useMicroservicesVersionsSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/presenter/context';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import { CollapsiblePanelTitleSize } from '@ac/web-components';

const sectionConfig: PageSectionConfiguration = {
  header: {
    toggleContent: {
      allowToggle: true,
    },
    textSize: CollapsiblePanelTitleSize.auto,
  },
};

export const MicroservicesVersionsSection = (): JSX.Element | null => {
  const presenter = useMicroservicesVersionsSectionPresenterContext();

  return (
    <StandardPageSection
      manifest={microservicesVersionsSectionManifest}
      presenter={presenter}
      config={sectionConfig}
    >
      <MicroservicesVersionsList />
    </StandardPageSection>
  );
};
