import { isCacheDataUpToDate } from 'frontend-container/shared/businessContext/provider/cache/invalidation';
import {
  BUSINESS_CONTEXT_DB_KEYS_PROVIDERS,
  BusinessContextDbStoreKey,
} from 'frontend-container/shared/businessContext/provider/dbConfig';

import {
  BusinessContextUnitIdentifier,
  CentralReservationOfficeContextData,
  CustomerContextData,
  PropertyConfigurationAggregate,
  PropertyContextData,
  SystemContextData,
  UserContextData,
} from '@ac/library-api';
import { ProfileCentersContextData } from '@ac/library-api/dist/businessContext/profileCenters/types';
import { LoginService } from '@ac/library-utils/dist/services';
import {
  DbEntity,
  IndexedDBOpenedStoreManager,
  ITransaction,
} from '@ac/library-utils/dist/storage/database';

export type CachedItemInfo<TData> = {
  entity: DbEntity<TData>;
  isUpToDate: boolean;
};

export type DataFindingResult<TData> = {
  cacheResult: CachedItemInfo<TData> | undefined;
};

export interface CacheReadResult {
  user?: DataFindingResult<UserContextData>;
  system?: DataFindingResult<SystemContextData>;
  customer?: DataFindingResult<CustomerContextData>;
  centralReservationOffice?: DataFindingResult<CentralReservationOfficeContextData>;
  property?: DataFindingResult<PropertyContextData>;
  profileCenters?: DataFindingResult<ProfileCentersContextData>;
}

export interface GetCacheReportParams {
  transaction: ITransaction;
  maxEntitiesToFetch: number;
}

export interface BusinessContextCacheReader {
  readDataFromCache(
    transaction: ITransaction,
    unit: BusinessContextUnitIdentifier
  ): Promise<CacheReadResult>;
}

export class BusinessContextIndexedDBCacheReader
  implements BusinessContextCacheReader
{
  async readDataFromCache(
    transaction: ITransaction,
    unit: BusinessContextUnitIdentifier
  ): Promise<CacheReadResult> {
    const user = await this.findEntityInDbStoreByKey<UserContextData>(
      transaction,
      BusinessContextDbStoreKey.User,
      LoginService.authData()?.userId
    );

    const system = await this.findSystemContextData(transaction);

    const customer = await this.findEntityInDbStoreByKey<CustomerContextData>(
      transaction,
      BusinessContextDbStoreKey.Customer,
      unit.tenantId
    );

    const centralReservationOffice =
      await this.findEntityInDbStoreByKey<CentralReservationOfficeContextData>(
        transaction,
        BusinessContextDbStoreKey.CentralReservationOffice,
        unit.centralReservationOfficeId
      );

    const property = await this.findEntityInDbStoreByKey<PropertyContextData>(
      transaction,
      BusinessContextDbStoreKey.Property,
      unit.propertyId,
      (context) => ({
        ...context,
        configurationEntities: new PropertyConfigurationAggregate({
          propertyId: context.details.id,
          regionCode: context.details.regionCode,
          initialAggregate: context.configurationEntities.entities,
        }),
      })
    );

    const profileCenters =
      await this.findEntityInDbStoreByKey<ProfileCentersContextData>(
        transaction,
        BusinessContextDbStoreKey.ProfileCenters,
        unit.profileCenterId
      );

    return {
      user,
      system,
      customer,
      centralReservationOffice,
      property,
      profileCenters,
    };
  }

  private async findSystemContextData(
    transaction: ITransaction
  ): Promise<DataFindingResult<SystemContextData> | undefined> {
    if (!LoginService.isSuperUser()) {
      return;
    }

    return await this.findEntityInDbStoreByKey<SystemContextData>(
      transaction,
      BusinessContextDbStoreKey.System,
      BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.system()
    );
  }

  private async findEntityInDbStoreByKey<TData>(
    transaction: ITransaction,
    storeName: string,
    entityId: string | undefined,
    cachedEntityCustomCreator?: (input: TData) => TData
  ): Promise<DataFindingResult<TData> | undefined> {
    if (!entityId) {
      return;
    }

    const store = new IndexedDBOpenedStoreManager<TData>({
      transaction,
      storeName,
    });

    const cachedValue = await store.getEntityById(entityId);

    if (!cachedValue) {
      return {
        cacheResult: undefined,
      };
    }

    const entity: DbEntity<TData> = cachedEntityCustomCreator
      ? {
          ...cachedValue,
          value: cachedEntityCustomCreator(cachedValue.value),
        }
      : cachedValue;

    return {
      cacheResult: {
        entity,
        isUpToDate: isCacheDataUpToDate(cachedValue.updatedAt),
      },
    };
  }
}
