import { getIsMenuElementItemActive } from 'frontend-container/components/Menu/components/ContextSelectButton/getIsMenuElementItemActive';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { SafeFloatingWrapper } from 'frontend-container/components/Menu/components/SafeFloatingWrapper/SafeFloatingWrapper';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { FlexGap } from '@ac/web-components';

interface Props {
  element: MenuElement;
  activeItem?: string;
  handleOnSubItemClick: (
    link: string,
    menuElementItems?: MenuElementItem[] | undefined,
    onBeforeRedirectCallback?: () => void
  ) => Promise<void>;
  onSubItemMouseOver: () => void;
}

export const BreadcrumbsButtonModuleContent = ({
  element,
  activeItem,
  handleOnSubItemClick,
  onSubItemMouseOver,
}: Props): JSX.Element => (
  <SafeFloatingWrapper
    key={element.id}
    nested
    placement="right-start"
    floatingContent={
      <div className="menu-subitems-container menu-subitems-container-dynamic">
        {element.items?.map((item) => (
          <SubItem
            item={item}
            key={item.link}
            active={getIsMenuElementItemActive(item)}
            onClick={handleOnSubItemClick}
          />
        ))}
      </div>
    }
  >
    <div>
      <SubItem
        item={{
          link: element.items.at(0)?.link ?? '',
          translation: element.translation,
        }}
        id={element.id}
        showActionArrow={element.items.length > 0}
        onMouseOver={onSubItemMouseOver}
        spacing={FlexGap.sm}
        key={element.id}
        active={activeItem === element.id}
        onClick={handleOnSubItemClick}
      />
    </div>
  </SafeFloatingWrapper>
);
