import { useMemo } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import { useBrowserDateTime } from 'frontend-container/components/Menu/components/CroContext/useBrowserDateTime';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  formatDate,
  getShortDateFormat,
  getTimeFormat,
  ShortDateFormats,
  TimeFormats,
} from 'frontend-container/utils/dateUtils';

export const useProfileCentersDateTime: DateTimeHook = () => {
  const { date } = useBrowserDateTime();

  const customerDetails = getCustomerContextData()?.details;
  const shortDateFormat = getShortDateFormat(
    customerDetails,
    ShortDateFormats.YYYYMMDD
  );
  const timeFormat = getTimeFormat(customerDetails, TimeFormats.H24);

  const stringDate = useMemo(
    () => formatDate(date, shortDateFormat.toUpperCase()),
    [date, shortDateFormat]
  );

  const stringTime = useMemo(
    () => formatDate(date, timeFormat),
    [date, timeFormat]
  );

  return {
    date: stringDate,
    time: stringTime,
    dateFormat: shortDateFormat,
    timeFormat,
  };
};
