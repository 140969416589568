import { isCashieringModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const cashieringAppName = 'frontend-cashiering';
export const registerCashiering = (): void => {
  registerApplication(
    cashieringAppName,
    () => loadReactApp(acConfig.frontendUrls.cashiering, cashieringAppName),
    (location: Location) => isCashieringModule(location.pathname)
  );
};
