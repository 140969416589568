import { isReservationsModule } from 'frontend-container/components/Menu/utils/modules/reservations';
import { loadEmberApp } from 'frontend-container/utils/externalResources/ember';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const reservationsAppName = 'frontend-reservations';
export const registerReservations = (): void => {
  registerApplication(
    reservationsAppName,
    () => loadEmberApp(acConfig.frontendUrls.reservations, reservationsAppName),
    (location: Location) => isReservationsModule(location.pathname)
  );
};
