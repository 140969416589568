import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';
import { MicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

export type MaintenancePanelMode = 'basic' | 'fullReadonly' | 'full';

export interface MaintenancePanelState {
  environment?: EnvironmentDetails;
  microservicesVersions?: MicroservicesVersions;
  contextReport?: BusinessContextDataReport;
  isLoadingInProgress: boolean;
  mode: MaintenancePanelMode;
}
export const getDefaultMaintenancePanelState = ({
  mode,
}: Partial<MaintenancePanelState>): MaintenancePanelState => ({
  isLoadingInProgress: false,
  mode: mode ?? 'basic',
});
