import { MaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';
import { MaintenancePanelState } from 'frontend-container/components/panels/maintenancePanel/store/state';
import { useCreateMaintenancePanelStore } from 'frontend-container/components/panels/maintenancePanel/store/store';

import { PropsWithChildren } from '@ac/react-infrastructure';

type Props = Partial<MaintenancePanelState>;

export const MaintenancePanelContextProvider = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const store = useCreateMaintenancePanelStore(props);

  return (
    <MaintenancePanelContext.Provider value={store}>
      {props.children}
    </MaintenancePanelContext.Provider>
  );
};
