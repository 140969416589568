import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { findRegionData } from 'frontend-container/utils/region/findRegion';

import { ModalTheme } from '@ac/web-components';

interface MultiRegionErrorModalProps {
  onClose: () => void;
  onConfirm: () => void;
  globalRegionCode: string;
  title?: string;
  message?: string;
}

export const MultiRegionErrorModal: FunctionComponent<
  MultiRegionErrorModalProps
> = ({
  onClose,
  onConfirm,
  globalRegionCode,
  title,
  message,
}: MultiRegionErrorModalProps) => {
  const { t } = useTranslation();
  const globalRegion = findRegionData(globalRegionCode);

  return (
    <ac-modal
      theme={ModalTheme.alertTheme}
      modalTitle={title || t('MULTI_REGION.ERROR_MODAL.TITLE')}
      confirmButton={{
        content: {
          text: t('COMMON.BUTTONS.CONFIRM'),
        },
        onClick: onConfirm,
      }}
      cancelButton={{
        content: {
          text: t('COMMON.BUTTONS.CANCEL'),
        },
        onClick: onClose,
      }}
    >
      {message ||
        t('MULTI_REGION.ERROR_MODAL.CONTENT', {
          globalRegionCode,
          globalRegionDomain: globalRegion?.domain,
        })}
    </ac-modal>
  );
};
