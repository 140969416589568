import { useEffect } from 'react';
import { ContainerWorkstation } from 'frontend-container/publicApi';

export const useWorkstationInitialization = <
  WorkstationKey extends keyof ContainerWorkstation
>(
  workstationKey: WorkstationKey,
  callback: ContainerWorkstation[WorkstationKey]
): void => {
  useEffect(() => {
    if (window?.ACP?.container) {
      window.ACP.container.workstation[workstationKey] = callback;
    }

    return (): void => {
      if (window?.ACP?.container) {
        delete window.ACP.container.workstation[workstationKey];
      }
    };
  }, [workstationKey, callback]);
};
