import { ConfirmationModalPresenterContext } from 'frontend-container/components/modals/confirmationModal/presenter/context';
import { ConfirmationModalInput } from 'frontend-container/components/modals/confirmationModal/presenter/input';

import {
  PropsWithChildren,
  useModalPresenterStore,
} from '@ac/react-infrastructure';

export const ConfirmationModalPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useModalPresenterStore<ConfirmationModalInput, void>();

  return (
    <ConfirmationModalPresenterContext.Provider value={store}>
      {props.children}
    </ConfirmationModalPresenterContext.Provider>
  );
};
