import { MaintenancePanelContent } from 'frontend-container/components/panels/maintenancePanel/features/MaintenancePanelContent';
import { useMaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';
import { MaintenancePanelProviders } from 'frontend-container/components/panels/maintenancePanel/Providers';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const MaintenancePanel = (): JSX.Element | null => {
  const presenter = useMaintenancePanelPresenterContext();

  return presenter.state.shouldBeRendered ? (
    <MaintenancePanelProviders config={acConfig}>
      <MaintenancePanelContent presenter={presenter} />
    </MaintenancePanelProviders>
  ) : null;
};
