import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

export const mapBusinessContextDataReportToJsonContent = (
  contextReport: BusinessContextDataReport
): object => {
  const current = contextReport.currentGlobalContext;

  return {
    global: {
      user: current?.user.details,
      customer: current?.customer?.details,
      system: current?.system ? 'yes' : 'no',
      centralReservationOffice: current?.centralReservationOffice?.office,
      property: current?.property?.details,
      profileCenters: current?.profileCenters?.details,
    },
    cache: contextReport.cache,
  };
};
