import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const isNotificationsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.notifications ?? '');
};

export const isNotificationSubscriptions = (
  pathname: string = window.location.pathname
): boolean => {
  return isNotificationsModule() && pathname.includes('/subscription');
};

export const isNotificationsDashboard = (
  pathname: string = window.location.pathname
): boolean => {
  return isNotificationsModule() && pathname.includes('/dashboard');
};

export const isNotificationsTenantContextDashboard = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    isNotificationsModule() &&
    (pathname.includes('/dashboard/tenant') ||
      pathname.includes('/dashboard/administrator/tenant'))
  );
};

export const isNotificationSubscriptionsRoute = (
  pathname: string = window.location.pathname
): boolean => {
  return isNotificationsModule() && pathname.includes('/subscription');
};

export const isNotificationBellVisible = (): boolean =>
  !isNotificationsDashboard() && !isCentralReservationOfficeModule();

export default {
  isNotificationsModule,
  isNotificationSubscriptions,
  isNotificationsDashboard,
  isNotificationBellVisible,
  isNotificationSubscriptionsRoute,
};
