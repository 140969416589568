import { useState } from 'react';
import { useConfirmationModalPresenterContext } from 'frontend-container/components/modals/confirmationModal/presenter/context';
import { ConfirmationModalType } from 'frontend-container/components/modals/confirmationModal/presenter/input';
import {
  isBusinessContextCacheEnabled,
  setBusinessContextCacheEnabled,
} from 'frontend-container/shared/businessContext/provider/isBusinessContextCacheEnabled';
import { BusinessContextProviderConsoleLogger } from 'frontend-container/shared/businessContext/provider/logger/consoleLogger';

import { ModalResultType } from '@ac/react-infrastructure';

interface CommandResult {
  isCacheEnabledInStorage: boolean;
  isCacheEnabled: boolean;
  isConsoleLoggerEnabled: boolean;
  setIsCacheEnabled(value: boolean): void;
  setIsConsoleLoggerEnabled(value: boolean): void;
  saveChanges(): Promise<void>;
}

export const useBusinessContextFeatureConfiguration = (): CommandResult => {
  const presenter = useConfirmationModalPresenterContext();
  const isCacheEnabledInStorage = isBusinessContextCacheEnabled();

  const [isCacheEnabled, setIsCacheEnabled] = useState<boolean>(
    isCacheEnabledInStorage
  );
  const [isConsoleLoggerEnabled, setIsConsoleLoggerEnabled] = useState<boolean>(
    BusinessContextProviderConsoleLogger.isFeatureEnabled()
  );

  const saveChanges = async (): Promise<void> => {
    const result = await presenter.show({
      type: ConfirmationModalType.PageReloadAfterBusinessContextConfigChange,
    });

    if (result.resultType !== ModalResultType.Confirmed) {
      return;
    }

    setBusinessContextCacheEnabled(isCacheEnabled);
    BusinessContextProviderConsoleLogger.setFeatureEnabled(
      isConsoleLoggerEnabled
    );
    window.location.reload();
  };

  return {
    isCacheEnabledInStorage,
    isCacheEnabled,
    isConsoleLoggerEnabled,
    setIsCacheEnabled,
    setIsConsoleLoggerEnabled,
    saveChanges,
  };
};
