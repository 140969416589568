import { useSyncExternalStore } from 'react';
import { layoutDirectionUpdatedEventCreator } from 'frontend-container/publicApi/events';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { LayoutDirection } from '@ac/web-components';

type LayoutDirectionOutput = {
  direction: LayoutDirection;
  isLtr: boolean;
  isRtl: boolean;
};

export const useLayoutDirection = (): LayoutDirectionOutput => {
  const direction = useSyncExternalStore(subscribe, getLayoutDirection);

  const isRtl = direction === LayoutDirection.rtl;
  const isLtr = direction === LayoutDirection.ltr;

  return { isLtr, isRtl, direction };
};

const subscribe = (onChange: () => void): (() => void) => {
  const eventBus = getCurrentGlobalEventBus();
  const unsubscribe = eventBus.subscribe(
    layoutDirectionUpdatedEventCreator,
    onChange
  );

  return unsubscribe;
};

const getLayoutDirection = (): LayoutDirection => {
  const layoutDirection = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--layout-direction');

  if (layoutDirection === 'rtl') {
    return LayoutDirection.rtl;
  }

  return LayoutDirection.ltr;
};
