import { isRateManagerModule } from 'frontend-container/components/Menu/utils/modules/rateManager';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const rateManagerAppName = 'frontend-rate-manager';
export const registerRateManager = (): void => {
  registerApplication(
    rateManagerAppName,
    () => loadReactApp(acConfig.frontendUrls.rateManager, rateManagerAppName),
    (location: Location) => isRateManagerModule(location.pathname)
  );
};
