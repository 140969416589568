import { useState } from 'react';
import { ChangePasswordModal } from 'frontend-container/components/Menu/components/ChangePasswordModal/ChangePasswordModal';
import { MainMenuInfotip } from 'frontend-container/components/Menu/components/MainMenuInfotip/MainMenuInfotip';
import { UserInfotipBody } from 'frontend-container/components/Menu/components/User/components/UserInfotipBody';
import { UserInfotipHeader } from 'frontend-container/components/Menu/components/User/components/UserInfotipHeader';
import { UserTrigger } from 'frontend-container/components/Menu/components/User/components/UserTrigger';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { UserPreferencesModal } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/UserPreferencesModal';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

const menuId = 'menu-logged-user';

export const User = (): JSX.Element => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isUserPreferencesModalOpen, setIsUserPreferencesModalOpen] =
    useState(false);
  const { userDetails, department, jobTitle } =
    userService.getFullCurrentUser() ?? {};

  const togglePasswordModal = (): void => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
  };

  const toggleUserPreferencesModal = (): void => {
    setIsUserPreferencesModalOpen(!isUserPreferencesModalOpen);
  };

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <>
      <MainMenuInfotip
        componentId={menuId}
        additionalContainerClass={
          isMenuV2Enabled ? '' : 'ac-margin-inline-end-md'
        }
        triggeringComponent={
          <UserTrigger
            firstName={userDetails.personalData?.firstName}
            lastName={userDetails.personalData?.lastName}
          />
        }
        headerComponent={
          <UserInfotipHeader
            firstName={userDetails.personalData?.firstName}
            lastName={userDetails.personalData?.lastName}
            email={userDetails.email}
            department={department}
            jobTitle={jobTitle}
          />
        }
        bodyComponent={
          <UserInfotipBody
            togglePasswordModal={togglePasswordModal}
            toggleUserPreferencesModal={toggleUserPreferencesModal}
          />
        }
      />
      {isPasswordModalOpen && (
        <ChangePasswordModal
          onClose={togglePasswordModal}
          onConfirm={togglePasswordModal}
        />
      )}
      {isUserPreferencesModalOpen && (
        <UserPreferencesModal onClose={toggleUserPreferencesModal} />
      )}
    </>
  );
};
