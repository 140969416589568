import React from 'react';
import {
  Error as ErrorComponent,
  ERROR_PAGE_QUERY_SELECTOR,
} from 'frontend-container/components/Errors/Error';
import { isError403 } from 'frontend-container/components/Errors/Error403';
import { isError404 } from 'frontend-container/components/Errors/Error404';
import {
  isError503,
  pathname503,
} from 'frontend-container/components/Errors/Error503';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { logError } from 'frontend-container/utils/logger/logger';
import { checkActivityFunctions } from 'single-spa';

import { uuidv4 } from '@ac/library-utils/dist/utils';
import { Errors, LoaderSize } from '@ac/web-components';

const LOADER_ID = 'loading-modules-loader';

enum AppStatus {
  MOUNTED = 'MOUNTED',
  LOAD_ERROR = 'LOAD_ERROR',
}

type AppNames =
  | 'frontend-reports'
  | 'frontend-changelog'
  | 'frontend-configuration'
  | 'frontend-accounts-receivable'
  | 'frontend-reservations'
  | 'frontend-integrations'
  | 'frontend-notifications'
  | 'frontend-profiles'
  | 'frontend-cashiering'
  | 'frontend-housekeeping'
  | 'frontend-rate-manager'
  | 'frontend-task-management'
  | 'frontend-meetings-and-events'
  | 'frontend-travel-agent-commissions'
  | 'frontend-workflows'
  | 'error-404'
  | 'error-403'
  | 'error-503'
  | 'login-no-permissions'
  | 'login-permissions-in-progress';

type AppsByStatuses = {
  [key in AppStatus]: AppNames;
};

type SingleSpaEvent = Event & {
  totalAppChanges: number;
  detail: { appsByNewStatus: AppsByStatuses };
};

export const renderLoaderComponent = (): void => {
  const rootElement = document.getElementById('root');
  const element = document.createElement('ac-loader-covering');
  element.loaderSize = LoaderSize.md;
  element.id = LOADER_ID;
  rootElement?.insertBefore(element, rootElement?.firstChild);
};

export const removeLoader = (): void => {
  const rootElement = document.getElementById('root');
  if (!rootElement) {
    return;
  }
  const child = [...rootElement.children].find(({ id }) => id === LOADER_ID);
  if (!child) {
    return;
  }
  rootElement.removeChild(child);
};

export const beforeAppChange = (event: SingleSpaEvent): void => {
  const rootElement = document.getElementById('root');
  const isErrorPathname = isError403() || isError404() || isError503();

  if (
    !isErrorPathname &&
    rootElement?.querySelector(ERROR_PAGE_QUERY_SELECTOR)
  ) {
    removeReactRoot(ROUTER_OUTLET_SELECTOR);
  }
  const appsThatShouldBeActive = checkActivityFunctions(window.location);
  if (
    appsThatShouldBeActive.length &&
    appsThatShouldBeActive.some((app) =>
      event.detail.appsByNewStatus.MOUNTED.includes(app)
    )
  ) {
    renderLoaderComponent();
  }
};

export const appChange = (event: SingleSpaEvent): void => {
  removeLoader();
  if (event.detail.appsByNewStatus.LOAD_ERROR?.length) {
    const appsThatShouldBeActive = checkActivityFunctions(window.location);
    if (
      appsThatShouldBeActive.length &&
      appsThatShouldBeActive.some((app) =>
        event.detail.appsByNewStatus.LOAD_ERROR.includes(app)
      )
    ) {
      /**
       * render outside single-spa, because we need to keep page url un-touched
       * when there is some general error(.e.g network or compile error) so users
       * and devs can use browser refresh button.
       * this is necessary for users whom used to hit refresh button without reading
       * and during development it will be annoying if redirect to /500 on compile
       * error. this is a case when developing ember and hard reloading page with
       * compile error.
       */
      const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
      root.render(<ErrorComponent errorType={Errors.error500} />);
    }
  }
};

export const handleErrorLoadingModule = (
  error: unknown,
  appName: string
): void => {
  const getErrorMessage = (): string => {
    if (error instanceof SyntaxError) {
      return error.message;
    }

    return error instanceof TypeError ? error.toString() : String(error);
  };

  const id = uuidv4();
  logError(
    `Error while loading external module ${appName}: ${getErrorMessage()}. ID=${id}`
  );
  navigateToPage(`${pathname503}?id=${encodeURIComponent(id)}`);
};

export const handleEmptyModuleUrl = (appName: string): void => {
  const id = uuidv4();
  const log = `${id} error loading external module: ${appName}, module url is empty`;
  logError(log);
  navigateToPage(`${pathname503}?id=${encodeURIComponent(id)}`);
};

/**
 * Global mount is used for businessContext initialization
 * It's important for all modules to have it already during their mount.
 */
let globalMount: ModuleLifecycyle = () => {
  throw new Error('frontend-container: custom globalMount is not defined');
};

type ModuleLifecycyle = (props: { name: string }) => Promise<void>;

export const beforeEveryAppMount = (bootstrap: ModuleLifecycyle): void => {
  globalMount = bootstrap;
};

export const injectGlobalMount = <
  TModule extends { mount: ModuleLifecycyle | ModuleLifecycyle[] }
>(
  module: TModule
): TModule => ({
  ...module,
  mount: [
    globalMount,
    ...(Array.isArray(module.mount) ? module.mount : [module.mount]),
  ],
});
