import { useState } from 'react';
import {
  InternetSpeedTestError,
  InternetSpeedTestState,
  InternetSpeedTestStatus,
} from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';

export interface InternetSpeedTestStore {
  state: InternetSpeedTestState;
  startMeasurement(): void;
  saveMeasurement(measurementInMbps: number): void;
  finishMeasurementWithError(error: InternetSpeedTestError): void;
}

export const useInternetSpeedTestStore = (): InternetSpeedTestStore => {
  const [state, setState] = useState<InternetSpeedTestState>({
    status: InternetSpeedTestStatus.NotStarted,
  });

  return {
    startMeasurement: (): void => {
      setState({
        status: InternetSpeedTestStatus.InProgress,
      });
    },
    saveMeasurement: (measurementInMbps: number): void => {
      setState({
        status: InternetSpeedTestStatus.Finished,
        valueInMbps: measurementInMbps,
        date: new Date(),
      });
    },
    finishMeasurementWithError: (error: InternetSpeedTestError): void => {
      setState({
        status: InternetSpeedTestStatus.Error,
        error,
      });
    },
    state,
  };
};
