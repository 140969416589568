import { useCallback, useEffect, useReducer, useState } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import {
  getFormattedBusinessDate,
  getFormattedTime,
} from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate/service';
import { useInterval } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate/useInterval';
import { usePropertyBusinessDateChangedNotification } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate/usePropertyBusinessDateChangedNotification';
import {
  propertyBusinessDateUpdatedEventCreator,
  propertyLocalDateTimeUpdatedEventCreator,
} from 'frontend-container/publicApi';
import { updatePropertyBusinessDate } from 'frontend-container/shared/businessContext/mutators';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

export const useBusinessDateTime: DateTimeHook = () => {
  const [time, setTime] = useState<string>();
  const [, rerender] = useReducer((i: number): number => i + 1, 0);
  const formattedBusinessDate = getFormattedBusinessDate();

  const refreshBusinessDate = useCallback(
    async (businessDate?: string): Promise<void> => {
      const hasNewBusinessDate = await updatePropertyBusinessDate(businessDate);
      if (!hasNewBusinessDate) {
        return;
      }

      const eventBus = getCurrentGlobalEventBus();
      const formattedNextBusinessDate = getFormattedBusinessDate();
      eventBus.dispatch(
        propertyBusinessDateUpdatedEventCreator({
          businessDate: formattedNextBusinessDate.date,
        })
      );
      rerender();
    },
    []
  );

  usePropertyBusinessDateChangedNotification(refreshBusinessDate, 300_000);

  useInterval(async () => {
    const eventBus = getCurrentGlobalEventBus();
    const formattedTime = await getFormattedTime();

    setTime(formattedTime.time);
    eventBus.dispatch(
      propertyLocalDateTimeUpdatedEventCreator({
        localPropertyDateTime:
          formattedTime.currentDateTime.localPropertyDateTime,
      })
    );
  }, 60_000);

  useEffect(() => {
    void refreshBusinessDate();
    (
      window.ACP?.container ?? { refreshBusinessDate: undefined }
    ).refreshBusinessDate = (): void => void refreshBusinessDate();
  }, [refreshBusinessDate]);

  return {
    date: formattedBusinessDate.date,
    dateFormat: formattedBusinessDate.dateFormat,
    time,
    timeFormat: undefined,
  };
};
