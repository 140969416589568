import {
  AcMediaSm,
  MENU_PINNED_KEY,
  OpenCloseValues,
  PinnedHelpContainerClass,
  SpContentWidthSmName,
  SpContextHelpWidthName,
} from 'frontend-container/components/ContextHelp/constants';

import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';
import { viewportResizedEventCreator } from '@ac/react-infrastructure';

export const pinContextHelp = (eventBus: IEventBus): void => {
  document.documentElement.style.setProperty(
    `${SpContextHelpWidthName}`,
    `var(${SpContentWidthSmName})`
  );
  document.body.classList.add(PinnedHelpContainerClass);
  eventBus.dispatch(viewportResizedEventCreator());
};

export const pinContextHelpOnUserAction = (eventBus: IEventBus): void => {
  pinContextHelp(eventBus);
  sessionStorage.setItem(MENU_PINNED_KEY, OpenCloseValues.Open);
};

export const unpinContextHelp = (eventBus: IEventBus): void => {
  document.documentElement.style.setProperty(
    `${SpContextHelpWidthName}`,
    '0px'
  );
  document.body.classList.remove(PinnedHelpContainerClass);
  eventBus.dispatch(viewportResizedEventCreator());
};

export const unpinContextHelpOnUserAction = (eventBus: IEventBus): void => {
  unpinContextHelp(eventBus);
  sessionStorage.setItem(MENU_PINNED_KEY, OpenCloseValues.Closed);
};

export const checkIsPinAvailable = (): boolean => {
  const panelWidth =
    parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue(SpContentWidthSmName)
        ?.substring(-2),
      10
    ) || 0;

  return window.innerWidth - panelWidth >= AcMediaSm;
};

export const checkIsPinnedByUser = (): boolean =>
  sessionStorage.getItem(MENU_PINNED_KEY) === OpenCloseValues.Open ||
  sessionStorage.getItem(MENU_PINNED_KEY) === null;

export const checkIsAlreadyPinned = (): boolean =>
  document.body.classList.contains(PinnedHelpContainerClass);
