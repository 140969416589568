import { useMeasureInternetSpeedCommand } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/commands/measureInternetSpeed';
import { InternetQualityIndicator } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/features/qualityIndicator/InternetQualityIndicator';
import { InternetSpeedValue } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/features/value/InternetSpeedValue';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { InternetSpeedTestStatus } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';
import { useInternetSpeedTranslation } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/translation';

import { DateUtils } from '@ac/library-utils/dist/utils';
import {
  Color,
  CssUnit,
  FlexDirection,
  IconName,
  TextListSlot,
} from '@ac/web-components';

export const InternetSpeedTestSectionContent = (): JSX.Element => {
  const { t } = useInternetSpeedTranslation();
  const measureInternetSpeed = useMeasureInternetSpeedCommand();
  const { state } = useInternetSpeedTestContext();

  return (
    <div>
      <ac-flex class="ac-padding-vertical-lg" direction={FlexDirection.column}>
        <ac-box>
          <ac-button
            onClick={measureInternetSpeed}
            loading={state.status === InternetSpeedTestStatus.InProgress}
          >
            <ac-button-content
              text={
                state.status === InternetSpeedTestStatus.NotStarted
                  ? t('START_TEST')
                  : t('TEST_AGAIN')
              }
            />
          </ac-button>
        </ac-box>
        <InternetSpeedTestResult />
      </ac-flex>
    </div>
  );
};

const InternetSpeedTestResult = (): JSX.Element | null => {
  const { state } = useInternetSpeedTestContext();
  const { t } = useInternetSpeedTranslation();

  if (
    state.status === InternetSpeedTestStatus.NotStarted ||
    state.status === InternetSpeedTestStatus.InProgress
  ) {
    return null;
  }

  if (state.status === InternetSpeedTestStatus.Error) {
    return (
      <ac-validation-message
        class="ac-spacing-top-sm"
        iconColor={Color.alert}
        iconName={IconName.error}
        text={t('RESULT.ERROR_INFORMATION')}
      />
    );
  }

  return (
    <ac-text-list
      labelColumnSize={{ unit: CssUnit.px, value: 80 }}
      class="ac-spacing-top-sm"
    >
      <ac-text-list-item label={t('RESULT.RESULT')}>
        <div slot={TextListSlot.value}>
          <InternetQualityIndicator />
        </div>
      </ac-text-list-item>

      <ac-text-list-item label={t('RESULT.SPEED')}>
        <div slot={TextListSlot.value}>
          <InternetSpeedValue />
        </div>
      </ac-text-list-item>

      <ac-text-list-item
        label={t('RESULT.TESTED_AT')}
        value={DateUtils.serialization.formatDateTimeForDisplay(state.date)}
      />
    </ac-text-list>
  );
};
