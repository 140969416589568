import { isIntegrationsModule } from 'frontend-container/components/Menu/utils/modules/integrations';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const integrationsAppName = 'frontend-integrations';
export const registerIntegrations = (): void => {
  if (acConfig.frontendUrls.integrations) {
    registerApplication(
      integrationsAppName,
      () =>
        loadReactApp(acConfig.frontendUrls.integrations, integrationsAppName),
      (location: Location) => isIntegrationsModule(location.pathname)
    );
  }
};
