import { useTranslation } from 'react-i18next';
import {
  AcLibrariesGroup,
  FeModule,
} from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';

import { Orientation } from '@ac/web-components';

interface Props {
  module: FeModule;
  libraries: AcLibrariesGroup[];
}

export const FrontendModuleInfotipContent = (
  props: Props
): JSX.Element | null => {
  const { t } = useTranslation();

  const { module: feModule, libraries } = props;

  return (
    <div className="ac-spacing-lg">
      <ac-text-group
        label={t('MAINTENANCE_PANEL.MICROSERVICES.INFO_TITLE')}
        orientation={Orientation.vertical}
        class="ac-spacing-bottom-sm"
      >
        {`${t('MAINTENANCE_PANEL.MICROSERVICES.BUILD_DATE')}: ${
          feModule.buildDate
        }`}
      </ac-text-group>
      {libraries.map((library) => (
        <ac-paragraph
          key={`${feModule.name}-${library.name}`}
          class="fe-library-version"
        >
          <ac-text-group
            label={library.name}
            orientation={Orientation.vertical}
          >
            {library.libraries &&
              library.libraries.length > 0 &&
              library.libraries.map((libraryVersion) => (
                <ac-paragraph
                  key={`${feModule.name}-${library.name}-${libraryVersion.name}`}
                >
                  <ac-text-group label={libraryVersion.name}>
                    {libraryVersion.version}
                  </ac-text-group>
                </ac-paragraph>
              ))}
          </ac-text-group>
        </ac-paragraph>
      ))}
    </div>
  );
};
