import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'frontend-container/components/modals/confirmationModal/GenericConfirmationModal';
import { useSetUpMaintenancePanel } from 'frontend-container/components/panels/maintenancePanel/commands/setUp';
import { maintenancePanelConfiguration } from 'frontend-container/components/panels/maintenancePanel/features/config';
import { useCopyToClipboard } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/copyToClipboard';
import { useDownloadJson } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/downloadJson';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { InternetSpeedTestStatus } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';
import { MaintenancePanelSections } from 'frontend-container/components/panels/maintenancePanel/features/MaintenancePanelSections';
import { MaintenancePanelPresenterStore } from 'frontend-container/components/panels/maintenancePanel/presenter/context';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { navigateToPageEventCreator } from 'frontend-container/shared/navigation/events';

import {
  DefaultSidePanel,
  DefaultSidePanelConfig,
} from '@ac/react-infrastructure';
import { ButtonPattern, FlexDirection, IconName } from '@ac/web-components';

interface Props {
  presenter: MaintenancePanelPresenterStore;
}

export const MaintenancePanelContent = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useMaintenancePanelContext();
  const { state: internetSpeedTest } = useInternetSpeedTestContext();
  const setUp = useSetUpMaintenancePanel();
  const copyToClipboard = useCopyToClipboard();
  const downloadJson = useDownloadJson();
  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  const areExportButtonsDisabled =
    !state.environment ||
    !state.microservicesVersions ||
    internetSpeedTest.status === InternetSpeedTestStatus.InProgress;
  const isExportDataLoading = state.isLoadingInProgress;

  useEffect(() => {
    setUp();

    const unsubscribe = eventBus.subscribe(navigateToPageEventCreator, () =>
      props.presenter.hide()
    );

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config: DefaultSidePanelConfig = {
    container: maintenancePanelConfiguration,
    header: {
      text: t('MAINTENANCE_PANEL.TITLE'),
    },
    body: (
      <ac-flex
        direction={FlexDirection.column}
        class="ac-spacing-horizontal-md"
      >
        {state.isLoadingInProgress && <ac-loader-covering />}
        <MaintenancePanelSections />
      </ac-flex>
    ),
    footer: (
      <ac-flex class="ac-gap-sm">
        <ac-button
          onClick={copyToClipboard}
          disabled={areExportButtonsDisabled}
          loading={isExportDataLoading}
          pattern={ButtonPattern.tertiary}
        >
          <ac-button-content
            icon={IconName.duplicate}
            text={t('MAINTENANCE_PANEL.COPY_TO_CLIPBOARD')}
          />
        </ac-button>
        <ac-button
          onClick={downloadJson}
          disabled={areExportButtonsDisabled}
          loading={isExportDataLoading}
          pattern={ButtonPattern.tertiary}
        >
          <ac-button-content
            icon={IconName.download}
            text={t('MAINTENANCE_PANEL.DOWNLOAD_FILE')}
          />
        </ac-button>
      </ac-flex>
    ),
  };

  return (
    <>
      <DefaultSidePanel presenter={props.presenter} config={config} />;
      <ConfirmationModal />
    </>
  );
};
