import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadKeySteps } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/readKeySteps';
import { DataLoadedStep } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/steps/DataLoadedStep';
import { LoadingDataStep } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/steps/LoadingDataStep';
import { ReadingFailedStep } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/steps/ReadingFailedStep';
import { WorkstationSelectionStep } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/steps/WorkstationSelectionStep';
import { readKeyData } from 'frontend-container/components/Menu/components/Workstation/service';
import { WorkstationTestSelector } from 'frontend-container/components/Menu/components/Workstation/testSelectors';
import { getSelectOptions } from 'frontend-container/components/Menu/components/Workstation/utils';
import i18n from 'i18next';

import { GenericEntity, ReadKeyDto } from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';

import { ReadKeyModalTestSelectors } from './testSelectors';

interface ReadKeyModalProps {
  onCloseModal: () => void;
  workstations: GenericEntity[];
  preselectedWorkstation?: AcSelectOption;
}

export const ReadKeyModal = ({
  workstations,
  preselectedWorkstation,
  onCloseModal,
}: ReadKeyModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [keyData, setKeyData] = useState<ReadKeyDto | undefined>(undefined);
  const [readKeyStep, setReadKeyStep] = useState<ReadKeySteps>(
    ReadKeySteps.workstationSelection
  );
  const [selectedWorkstation, setSelectedWorkstation] = useState<
    AcSelectOption | undefined
  >(preselectedWorkstation);

  const onSelectWorkstationHandler = (workstation: AcSelectOption): void => {
    setSelectedWorkstation(workstation);
  };

  const onReadDataHandler = useCallback(async (): Promise<void> => {
    try {
      const workstationDetails = workstations.find(
        (workstation) => workstation.id === selectedWorkstation?.value
      );

      if (!workstationDetails) {
        return;
      }

      setReadKeyStep(ReadKeySteps.loadingData);
      const data = await readKeyData({
        workstation: workstationDetails.code,
      });

      if (data.result === 'fail') {
        setReadKeyStep(ReadKeySteps.error);

        return;
      }

      setKeyData(data);
      setReadKeyStep(ReadKeySteps.dataLoaded);
    } catch (e) {
      setReadKeyStep(ReadKeySteps.error);
    }
  }, [selectedWorkstation?.value, workstations]);

  const modalButtons = useMemo(() => {
    if (readKeyStep === ReadKeySteps.workstationSelection) {
      return {
        confirmButton: {
          onClick: onReadDataHandler,
          ['data-test-selector']: ReadKeyModalTestSelectors.ReadBtn,
          content: {
            text: t('MENU.WORKSTATION.READ_KEY.READ'),
          },
          disabled: !selectedWorkstation,
        },
        cancelButton: {
          onClick: onCloseModal,
          content: {
            text: t('MENU.MODAL.COMMON.CANCEL'),
          },
        },
      };
    }

    if (readKeyStep === ReadKeySteps.error) {
      return {
        confirmButton: {
          onClick: onReadDataHandler,
          content: {
            text: t('MENU.MODAL.COMMON.TRY_AGAIN'),
          },
        },
        cancelButton: {
          onClick: onCloseModal,
          content: {
            text: t('MENU.MODAL.COMMON.CLOSE'),
          },
        },
      };
    }

    if (readKeyStep === ReadKeySteps.dataLoaded) {
      return {
        cancelButton: {
          onClick: onCloseModal,
          content: {
            text: t('MENU.MODAL.COMMON.CLOSE'),
          },
        },
      };
    }

    return {};
  }, [onCloseModal, onReadDataHandler, readKeyStep, selectedWorkstation, t]);

  const workstationOptions = useMemo(
    () => getSelectOptions(workstations, i18n.language, true),
    [workstations]
  );

  return (
    <ac-modal
      {...modalButtons}
      data-test-selector={WorkstationTestSelector.ReadKeyModal}
      modalTitle={t('MENU.WORKSTATION.READ_KEY.TITLE')}
    >
      <div>
        {readKeyStep === ReadKeySteps.workstationSelection && (
          <WorkstationSelectionStep
            onSelectWorkstation={onSelectWorkstationHandler}
            workstations={workstationOptions}
            selectedWorkstation={selectedWorkstation}
          />
        )}

        {readKeyStep === ReadKeySteps.loadingData && <LoadingDataStep />}

        {readKeyStep === ReadKeySteps.error && <ReadingFailedStep />}

        {readKeyStep === ReadKeySteps.dataLoaded && keyData && (
          <DataLoadedStep data={keyData} />
        )}
      </div>
    </ac-modal>
  );
};
