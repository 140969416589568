import { breadcrumbsUnitItemId } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import {
  CroContext,
  isCroContextVisible,
} from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { ProfileCentersContext } from 'frontend-container/components/Menu/components/ProfileCentersContext/ProfileCentersContext';
import {
  isPropertyContextVisible,
  PropertyContext,
} from 'frontend-container/components/Menu/components/PropertyContext';
import { isNotificationsTenantContextDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';

export const BreadcrumbsButtonContextContent = (): JSX.Element | null => {
  if (isPropertyContextVisible() && !isNotificationsTenantContextDashboard()) {
    return (
      <PropertyContext
        triggerId={`#${breadcrumbsUnitItemId}`}
        isContextSwitchHidden
      />
    );
  }

  if (isCroContextVisible() && !isNotificationsTenantContextDashboard()) {
    return (
      <CroContext
        triggerId={`#${breadcrumbsUnitItemId}`}
        isContextSwitchHidden
      />
    );
  }

  if (isProfileCentersContextVisible()) {
    return (
      <ProfileCentersContext
        triggerId={`#${breadcrumbsUnitItemId}`}
        isContextSwitchHidden
      />
    );
  }

  return null;
};
