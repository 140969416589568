import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getPropertyUnits,
  getTenantUnit,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { pathnamePrestepNoPermissions } from 'frontend-container/components/Prestep/AccessDenied/NoPermissions/config';
import { pathnamePrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress/config';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { LoginService } from '@ac/library-utils/dist/services';

export const getShouldRedirectToAccessDenied = (): boolean => {
  const isSystemUser = LoginService.isSuperUser();

  if (isSystemUser) {
    return false;
  }

  const isAssignedToTenant = Boolean(getTenantUnit());
  const isAssignedToAnyProperty = Boolean(getPropertyUnits().length);
  const isAssignedToAnyCro = Boolean(getCroItems().length);

  if (isAssignedToAnyProperty || isAssignedToAnyCro) {
    return false;
  }

  if (!isAssignedToTenant) {
    return true;
  }

  const tenantPermissionIds =
    getCustomerContextData()?.permissions.permissionIds ?? [];
  const hasAnyTenantPermissions = Boolean(tenantPermissionIds.length);

  return !hasAnyTenantPermissions;
};

export const getAccessDeniedRedirectPath = (): string => {
  const userDetails = userService.getCurrentUserDetails();

  if (userDetails.externalId) {
    return pathnamePrestepPermissionsInProgress;
  }

  return pathnamePrestepNoPermissions;
};
