import { createContext } from 'react';
import { ContextsContextStore } from 'frontend-container/components/Menu/store/store';

import { useDefinedContext } from '@ac/react-infrastructure/dist/features/featureSchema';

export const ContextsContext = createContext<ContextsContextStore | undefined>(
  undefined
);

export const useContextsContext = (): ContextsContextStore =>
  useDefinedContext(ContextsContext);
