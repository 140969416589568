import { PropsWithChildren } from 'react';

import { DividerSpacing, TextSize, TextWeight } from '@ac/web-components';

interface PropTypes {
  sectionTitle?: string;
  hideDivider?: boolean;
  hide?: boolean;
}

export const UserPreferencesFormSection = ({
  sectionTitle,
  children,
  hideDivider,
  hide,
}: PropsWithChildren<PropTypes>): JSX.Element | null =>
  !hide ? (
    <ac-box>
      <ac-box class="ac-spacing-vertical-sm">
        {sectionTitle && (
          <ac-text weight={TextWeight.semibold} size={TextSize.sm}>
            {sectionTitle}
          </ac-text>
        )}
      </ac-box>

      <ac-box sizeSm={9} class="ac-padding-right-sm">
        {children}
      </ac-box>

      {!hideDivider && <ac-divider spacing={DividerSpacing.none} />}
    </ac-box>
  ) : null;
