import { isNotificationsModule } from 'frontend-container/components/Menu/utils/modules/notifications';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const notificationsAppName = 'frontend-notifications';
export const registerNotifications = (): void => {
  if (acConfig.frontendUrls.notifications) {
    registerApplication(
      notificationsAppName,
      () =>
        loadReactApp(acConfig.frontendUrls.notifications, notificationsAppName),
      (location: Location) => isNotificationsModule(location.pathname)
    );
  }
};
