export const profileCentersPath = '/profiles/global-view/';

export const profileCentersLandingPagePathname = '/landing-page/profile-center';

export const isProfileCentersModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(profileCentersPath);
};

export const isProfileCentersModuleLanding = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(profileCentersLandingPagePathname);
};
