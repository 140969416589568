import { MenuPosition } from 'frontend-container/components/Menu/menuPosition';

import { EffectiveSettingDetails } from '@ac/library-api';

export const mapSettingsArrayToSettingsObject = (
  settings: EffectiveSettingDetails[]
): Record<string, boolean | MenuPosition[] | unknown> => {
  const settingsObject: Record<string, unknown> = {};

  for (const setting of settings) {
    const settingValueObjectValue = (setting.value as { value: unknown }).value;
    const settingFeatureObjectValue = (setting.value as { feature: boolean })
      .feature;

    const value = settingFeatureObjectValue ?? settingValueObjectValue;

    settingsObject[setting.code ?? ''] = value;
  }

  return settingsObject;
};
