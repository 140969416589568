import { useCallback, useRef, useState } from 'react';
import { isScrollNeeded } from 'frontend-container/components/Menu/utils/menuScroll/scrollReport';

type ScrollUpdater = {
  attachScrollObserverToRef: (ref?: HTMLElement | null) => void;
  getIsScrollNeeded: () => void;
};

export const useScrollUpdater = (): ScrollUpdater => {
  const [isMenuScrollVisible, setIsMenuScrollVisible] = useState(false);

  const scrollObserver = useRef(
    new MutationObserver(() => {
      setIsMenuScrollVisible(isScrollNeeded());
    })
  ).current;

  const attachScrollObserverToRef = useCallback(
    (ref?: HTMLElement | null): void => {
      if (!ref) {
        scrollObserver.disconnect();

        return;
      }

      scrollObserver.disconnect();
      scrollObserver.observe(ref, { childList: true, subtree: true });
    },
    [scrollObserver]
  );

  const getIsScrollNeeded = useCallback((): boolean => {
    return isMenuScrollVisible;
  }, [isMenuScrollVisible]);

  return {
    attachScrollObserverToRef,
    getIsScrollNeeded,
  };
};
