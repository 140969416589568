import { useEffect } from 'react';
import { ConfirmationModal } from 'frontend-container/components/modals/confirmationModal/GenericConfirmationModal';
import { useSetupRefreshModal } from 'frontend-container/components/modals/refreshModal/commands/setupRefreshModal';

import './RefreshModal.scss';

export const RefreshModalContent = (): JSX.Element | null => {
  const setup = useSetupRefreshModal();

  useEffect(() => {
    const { cleanup } = setup();

    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConfirmationModal />
      {/* forcing pre-download of ac-modal bundle */}
      <ac-modal cssScope="refresh-modal-hidden-modal" />
    </>
  );
};
