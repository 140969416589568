import { createContext } from 'react';
import { MaintenancePanelFeatureTogglesStore } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const MaintenancePanelFeatureTogglesContext = createContext<
  MaintenancePanelFeatureTogglesStore | undefined
>(undefined);

export const useMaintenancePanelFeatureTogglesContext =
  (): MaintenancePanelFeatureTogglesStore =>
    useDefinedContext(MaintenancePanelFeatureTogglesContext);
