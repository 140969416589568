import { BusinessContextContainer } from 'frontend-container/publicApi';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { setUpWebSocketsApi } from 'frontend-container/utils/setUpWebSocketsApi';

import {
  BusinessContextUnitIdentifier,
  globalBusinessContextUpdatedEventCreator,
  SepModuleBusinessContextData,
} from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

export const selectUnitInBusinessContext = async (
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> => {
  const contextContainer = window.ACP?.businessContext;
  if (!contextContainer) {
    throw new Error(
      'The context container is not defined. Please use setInitialBusinessContextForUnit() firstly.'
    );
  }

  const currentBusinessContextData = getBusinessContextData();
  const newBusinessContextData =
    await businessContextProvider.getBusinessContext(unit);

  if (currentBusinessContextData !== newBusinessContextData) {
    saveNewBusinessContext(unit, contextContainer, newBusinessContextData);

    setUpWebSocketsApi();
  }

  return newBusinessContextData;
};

const saveNewBusinessContext = async (
  unit: BusinessContextUnitIdentifier | undefined,
  contextContainer: BusinessContextContainer,
  context: SepModuleBusinessContextData
): Promise<void> => {
  if (unit?.propertyId) {
    SessionService.setPropertyId(unit.propertyId);
  }
  if (unit?.centralReservationOfficeId) {
    SessionService.setCentralReservationOfficeId(
      unit.centralReservationOfficeId
    );
  }
  if (unit?.profileCenterId) {
    SessionService.setProfileCenterId(unit.profileCenterId);
  }

  contextContainer.context = context;

  getCurrentGlobalEventBus().dispatch(
    globalBusinessContextUpdatedEventCreator(context)
  );
};
