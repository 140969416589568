import { ConfirmationModalContent } from 'frontend-container/components/modals/confirmationModal/GenericConfirmationModalContent';
import { useConfirmationModalPresenterContext } from 'frontend-container/components/modals/confirmationModal/presenter/context';

export const ConfirmationModal = (): JSX.Element | null => {
  const presenter = useConfirmationModalPresenterContext();

  return presenter.state.isVisible ? (
    <ConfirmationModalContent presenter={presenter} />
  ) : null;
};
