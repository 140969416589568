import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { breadcrumbsUnitItemId } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { AlignItems, Placement, Size } from '@ac/web-components';

import './Breadcrumbs.scss';

interface Props {
  unitCode?: string;
  menuItemName?: string;
  pageTitle?: string;
  isUnitBreadcrumbVisible?: boolean;
  isContextButtonVisible?: boolean;
  subItems?: MenuElementItem[];
  menuItems?: MenuElement[];
  selectedItemTranslation: string;
}

export const BreadcrumbsMobileView = ({
  unitCode,
  menuItemName,
  isUnitBreadcrumbVisible,
  subItems,
  menuItems,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const [isHoverMenuVisible, setHoverMenuVisible] = useState(false);

  const breadcrumbsButtonId = 'mobile-breadcrumbs-wrapper';

  const hideHoverMenu = (): void => setHoverMenuVisible(false);
  const showHoverMenu = (): void => setHoverMenuVisible(true);

  if (!isUnitBreadcrumbVisible && !menuItemName) {
    return null;
  }

  return (
    <>
      <BreadcrumbsButton
        content={t('COMMON.ELLIPSIS')}
        id={breadcrumbsButtonId}
        onMouseEnter={showHoverMenu}
        onMouseLeave={hideHoverMenu}
        transparent
      />
      <ac-position-wrapper
        target={`#${breadcrumbsButtonId}`}
        attachTo={`#${breadcrumbsButtonId}`}
        contentHidden={!isHoverMenuVisible}
        placement={Placement.bottomStart}
      >
        <ac-flex
          class="breadcrumbs-hover-menu"
          alignItems={AlignItems.center}
          onMouseEnter={showHoverMenu}
          onMouseLeave={hideHoverMenu}
        >
          {isUnitBreadcrumbVisible && (
            <BreadcrumbsButton
              content={unitCode}
              iconSize={Size.xs}
              showIcon={false}
              type={BreadcrumbsButtonType.context}
              id={breadcrumbsUnitItemId}
            />
          )}
          {menuItemName && (
            <BreadcrumbsButton
              content={menuItemName}
              iconSize={Size.xs}
              showIcon={isUnitBreadcrumbVisible}
              type={BreadcrumbsButtonType.module}
              menuItems={menuItems}
              subItems={subItems}
              id="breadcrumbs-module-button"
            />
          )}
        </ac-flex>
      </ac-position-wrapper>
    </>
  );
};
