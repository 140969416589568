import { fetchUserEnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/userEnvironmentDetails';
import { getMicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/commands/getMicroservicesVersions';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';
import { MaintenancePanelStore } from 'frontend-container/components/panels/maintenancePanel/store/store';
import { businessContextReportCreator } from 'frontend-container/shared/businessContext/provider/providerInstance';

type CommandResult = () => Promise<void>;

export const useSetUpMaintenancePanel = (): CommandResult => {
  const store = useMaintenancePanelContext();

  return async () => {
    try {
      store.setLoading(true);

      const fetchingEnvironmentDetailsPromise =
        fetchUserEnvironmentDetails().then((environmentDetails) => {
          store.setEnvironmentDetails(environmentDetails);
        });

      await Promise.all([
        fetchAndStoreMicroservicesVersions(store),
        fetchAndStoreBusinessContextReport(store),
        fetchingEnvironmentDetailsPromise,
      ]);
    } finally {
      store.setLoading(false);
    }
  };
};

const fetchAndStoreMicroservicesVersions = async (
  store: MaintenancePanelStore
): Promise<void> => {
  const microservicesVersions = await getMicroservicesVersions();

  store.setMicroservicesVersions(microservicesVersions);
};

const fetchAndStoreBusinessContextReport = async (
  store: MaintenancePanelStore
): Promise<void> => {
  if (store.state.mode === 'basic') {
    return;
  }

  const businessContextReport =
    await businessContextReportCreator.getContextStoreReport({
      maxEntitiesToFetch: 25,
    });

  store.setBusinessContextReport(businessContextReport);
};
