import {
  BaseApi,
  LoggerService,
  Metrics,
  MetricsFactory,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { assertIsDefined } from '@ac/library-utils/dist/utils/assertIsDefined';

let isMetricsCreationTriggered = false;

export const initializeMetricsOnce = (logger: Promise<LoggerService>): void => {
  if (isMetricsCreationTriggered || !LoginService.isLoggedIn()) {
    return;
  }

  const eventBus = window.ACP?.communication?.eventBus;

  assertIsDefined(
    eventBus,
    "window.ACP.communication.eventBus is not defined - incorrect call (or order of calls) to 'initializeMetricsOnce'"
  );

  const metricsPromise = new MetricsFactory().createMetrics({
    logger,
    eventBus,
  });

  assertIsDefined(
    window.ACP,
    "window.ACP is not defined - incorrect call (or order of calls) to 'initializeMetricsOnce'"
  );
  window.ACP.developerTools.metrics = metricsPromise;

  metricsPromise.then(initializeMetrics);

  isMetricsCreationTriggered = true;
};

const initializeMetrics = ({ tracking }: Metrics): void => {
  BaseApi.addPreRequestMiddlewares(tracking.getApiMiddlewares());
  tracking.startTracking();
};
