import { acConfig } from '@ac/library-utils/dist/declarations';

export const isHousekeepingModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.housekeeping ?? '');
};

export default {
  isHousekeepingModule,
};
