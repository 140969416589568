/* eslint-disable @typescript-eslint/unbound-method */
import {
  FeatureToggleInfo,
  FeatureTogglesSortValue,
} from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

import { StringComparer } from '@ac/library-utils/dist/utils';
import { DatagridSortDirection } from '@ac/web-components';

export const sortItems = (
  filteredItems: FeatureToggleInfo[],
  sort: FeatureTogglesSortValue
): FeatureToggleInfo[] => {
  const comparer =
    sort.direction === DatagridSortDirection.ascending
      ? StringComparer.ascending
      : StringComparer.descending;

  return filteredItems.sort((first, second) =>
    comparer(first[sort.name] as string, second[sort.name] as string)
  );
};
