import { useCallback, useReducer, useState } from 'react';
import { Context } from 'frontend-container/components/Menu/components/Context/context';
import {
  ButtonContextOption,
  ContextOption,
  ContextSelectButtonOptions,
} from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';

export interface ContextsContextStore {
  version: number;
  tabsOptions: ButtonContextOption[];
  allContextLists: Map<string, Context[] | ButtonContextOption>;
  currentButtonOption: ButtonContextOption | undefined;
  setContextsContext: (
    selectButtonOptions: ContextSelectButtonOptions | undefined
  ) => void;
}

export const useCreateContextsContextStore = (): ContextsContextStore => {
  const [tabsOptions, setTabsOptions] = useState<ButtonContextOption[]>([]);
  const [allContextLists, setAllContextList] = useState<
    Map<string, Context[] | ButtonContextOption>
  >(new Map());
  const [currentButtonOption, setCurrentButtonOption] = useState<
    ButtonContextOption | undefined
  >(undefined);

  const [version, incrementVersion] = useReducer(
    (currentVersion: number) => currentVersion + 1,
    0
  );

  const setContextsContext = useCallback(
    (selectButtonOptions: ContextSelectButtonOptions | undefined) => {
      if (selectButtonOptions?.currentButtonOption === currentButtonOption) {
        return;
      }
      incrementVersion();
      const tabSelectOptions = selectButtonOptions?.buttonSelectOptions
        ? selectButtonOptions?.buttonSelectOptions
        : [];
      setTabsOptions(tabSelectOptions);

      const availableListsPerContexts = new Map();

      tabSelectOptions.forEach((tab) => {
        if (tab.id === ContextOption.configuration) {
          availableListsPerContexts.set(`${tab.id}`, tab.getContext(tab));
        } else {
          availableListsPerContexts.set(`${tab.id}`, tab.getContext());
        }
      });

      setAllContextList(availableListsPerContexts);

      setCurrentButtonOption(selectButtonOptions?.currentButtonOption);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    version,
    tabsOptions,
    allContextLists,
    currentButtonOption,
    setContextsContext,
  };
};
