import { createContext } from 'react';
import { MaintenancePanelStore } from 'frontend-container/components/panels/maintenancePanel/store/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const MaintenancePanelContext = createContext<
  MaintenancePanelStore | undefined
>(undefined);

export const useMaintenancePanelContext = (): MaintenancePanelStore =>
  useDefinedContext(MaintenancePanelContext);
