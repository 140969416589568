import { acConfig } from '@ac/library-utils/dist/declarations';

export const isCashieringModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.cashiering ?? '');
};

export const isCashieringBillingModule = (
  pathname: string = window.location.pathname
): boolean => {
  return Boolean(pathname?.match(/billing\/*/));
};

export default {
  isCashieringModule,
  isCashieringBillingModule,
};
