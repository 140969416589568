import { createCommunicationEventCreator } from '@ac/library-utils/dist/communication/event-bus';

export interface CashierStatusOpenedDetails {
  cashierNumber: number;
  cashierFullName: string;
}

export const cashierStatusOpenedEventCreator =
  createCommunicationEventCreator<CashierStatusOpenedDetails>(
    'container_cashierStatusOpened'
  );
