import { TFunction } from 'i18next';

import { DatagridConfiguration, IconName } from '@ac/web-components';

const getText = (t: TFunction, key: string): string =>
  t(`MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.${key}`);

export const getContextGridConfig = (t: TFunction): DatagridConfiguration[] => [
  {
    size: '210px',
  },
  {
    icon: IconName.webAccount,
    text: getText(t, 'CURRENT_DATA'),
    size: '250px',
  },
  {
    icon: IconName.save,
    text: getText(t, 'CACHE'),
  },
  {
    size: '40px',
  },
];
