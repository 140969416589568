import { EnvironmentDetailsList } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/EnvironmentDetailsList';
import { environmentDetailsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/manifest';
import { useEnvironmentDetailsSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/presenter/context';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import { CollapsiblePanelTitleSize } from '@ac/web-components';

const sectionConfig: PageSectionConfiguration = {
  header: {
    toggleContent: {
      allowToggle: false,
    },
    textSize: CollapsiblePanelTitleSize.auto,
  },
};

export const EnvironmentDetailsSection = (): JSX.Element | null => {
  const presenter = useEnvironmentDetailsSectionPresenterContext();

  return (
    <StandardPageSection
      manifest={environmentDetailsSectionManifest}
      presenter={presenter}
      config={sectionConfig}
    >
      <EnvironmentDetailsList />
    </StandardPageSection>
  );
};
