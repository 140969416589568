import { mapShortDateFormat, TimeFormat } from '@ac/library-api';
import {
  API_DATE_FORMAT,
  DateUtils,
  DISPLAY_TIME_FORMAT,
  DISPLAY_TIME_FORMAT_12_HOURS,
} from '@ac/library-utils/dist/utils';

export const TimeFormats = {
  H24: DISPLAY_TIME_FORMAT,
  H12: DISPLAY_TIME_FORMAT_12_HOURS,
} as const;

export const ShortDateFormats = {
  YYYYMMDD: API_DATE_FORMAT,
} as const;

export const formatDate = (
  date: string | Date,
  outputFormat: string,
  inputFormat?: string
): string => {
  return DateUtils.serialization.formatDateTimeForDisplay(
    date,
    inputFormat,
    outputFormat
  );
};

const getTimeFormatFromCode = (timeFormatCode: string): string => {
  return timeFormatCode.includes('H24') ? TimeFormats.H24 : TimeFormats.H12;
};

type WithTimeFormatCode = { timeFormatCode: TimeFormat };

export const getTimeFormat = ((
  value: WithTimeFormatCode | undefined,
  fallback?: string
) => {
  if (value) {
    return getTimeFormatFromCode(value.timeFormatCode);
  }

  return fallback;
}) as {
  (value: WithTimeFormatCode): string;
  (value: WithTimeFormatCode | undefined): string | undefined;
  (value: WithTimeFormatCode | undefined, fallback: string): string;
};

type WithShortDateFormat = { shortDateFormat: string | undefined };

export const getShortDateFormat = ((
  value: WithShortDateFormat | undefined,
  fallback?: string
) => {
  if (value?.shortDateFormat) {
    return mapShortDateFormat(value.shortDateFormat);
  }

  return fallback;
}) as {
  (value: { shortDateFormat: string }): string;
  (value: { shortDateFormat?: string } | undefined): string | undefined;
  (value: { shortDateFormat?: string } | undefined, fallback: string): string;
};
