import { getBrowserGenericVersion } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/browserGenericVersion';
import { BrowserDetails } from 'frontend-container/components/panels/maintenancePanel/types/browserDetails';

const getBrowserName = (detectedBrowserName: string): string => {
  if (detectedBrowserName.toLowerCase().startsWith('edg')) {
    return 'Edge';
  }

  if (detectedBrowserName.toLowerCase().startsWith('opr')) {
    return 'Opera';
  }

  return detectedBrowserName;
};

export const getBrowserDetails = (
  userAgent: string = window.navigator.userAgent
): BrowserDetails => {
  const matchedBrowserDetails =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  const matchedEngineBrowserName = matchedBrowserDetails[1];
  const formattedBrowserName =
    matchedEngineBrowserName === 'MSIE' ||
    matchedEngineBrowserName === 'trident'
      ? `${matchedEngineBrowserName} `
      : `${matchedEngineBrowserName}/`;

  const genericVersion = getBrowserGenericVersion(
    formattedBrowserName,
    userAgent
  );
  const matchedBrowserVersion = matchedBrowserDetails[2];

  let browserName = matchedEngineBrowserName ?? navigator.appName;
  let browserVersion =
    genericVersion ?? matchedBrowserVersion ?? navigator.appVersion;

  const matchedVersion = userAgent.match(/version\/([\d.]+)/i);

  if (matchedVersion !== null) {
    browserVersion = matchedVersion[1];
  }

  if (matchedEngineBrowserName === 'Chrome') {
    const matchedBrowser = userAgent.match(/\b(OPR|Edg)[a-zA-Z]*\/([\d.]+)/);
    if (matchedBrowser !== null) {
      browserName = getBrowserName(matchedBrowser[1]);
      browserVersion = matchedBrowser[2] ? matchedBrowser[2] : browserVersion;
    }
  }

  const majorBrowserVersion =
    browserVersion.match(/^[^.]*/)?.[0] ?? browserVersion;

  return {
    name: browserName,
    version: majorBrowserVersion,
  };
};
