import { PropsWithChildren } from 'react';

import { AlignItems, JustifyContent } from '@ac/web-components';

export const Banner = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <ac-flex
      class="bg-warning"
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
      grow={true}
    >
      {children}
    </ac-flex>
  );
};
