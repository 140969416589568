import { getStaticConfiguration } from 'frontend-container/shared/mainHeaders';

import {
  BaseApi,
  Config,
  getErrorLoggerMiddleware,
  getRequestTimeLoggerMiddleware,
  LoggerService,
} from '@ac/library-api';
import { acConfig, ModuleNames } from '@ac/library-utils/dist/declarations';

export const initializeBaseApi = (): void => {
  BaseApi.host = acConfig.apiUrl;
  BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
    Promise.resolve(getStaticConfiguration());
  BaseApi.moduleName = ModuleNames.CONTAINER;
};

export const initializeBaseApiErrorLogger = (logger: LoggerService): void => {
  BaseApi.clearRequestMiddlewares();
  BaseApi.addRequestMiddlewares([
    getErrorLoggerMiddleware(logger.warning),
    getRequestTimeLoggerMiddleware(logger.debug),
  ]);
};
