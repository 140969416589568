import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContextById } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToRegion } from 'frontend-container/utils/region/redirectToRegion';

export const redirectToContextRegion = (
  contextType: ContextType,
  contextId: string,
  customPathname?: string
): boolean => {
  const context =
    contextType === ContextType.PROPERTY
      ? getPropertyContextById(contextId)
      : getCroContextById(contextId);

  if (!context?.regionCode) {
    return false;
  }

  const regionData = findRegionData(context.regionCode);

  return redirectToRegion(
    regionData?.code ?? '',
    regionData?.domain ?? '',
    context,
    customPathname
  );
};
