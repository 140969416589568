const STORAGE_SETTING_KEY = 'aboveCloud.isBusinessContextCacheEnabled';

export const isBusinessContextCacheEnabled = (): boolean =>
  localStorage.getItem(STORAGE_SETTING_KEY) === 'true';

export const setBusinessContextCacheEnabled = (
  shouldCacheBeEnabled: boolean
): void =>
  localStorage.setItem(
    STORAGE_SETTING_KEY,
    JSON.stringify(shouldCacheBeEnabled)
  );
