import { MicroservicesVersionsSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/presenter/context';

import {
  PageSectionPresenterInitialAppearance,
  PropsWithChildren,
  usePageSectionPresenterStore,
} from '@ac/react-infrastructure';

interface Params {
  initialAppearance: PageSectionPresenterInitialAppearance;
}

export const MicroservicesVersionsSectionPresenterContextProvider = (
  props: PropsWithChildren<Params>
): JSX.Element => {
  const store = usePageSectionPresenterStore(props.initialAppearance);

  return (
    <MicroservicesVersionsSectionPresenterContext.Provider value={store}>
      {props.children}
    </MicroservicesVersionsSectionPresenterContext.Provider>
  );
};
