import { useEffect, useState } from 'react';
import {
  ConfigurationResponse,
  loadConfiguration,
} from 'frontend-container/components/AutomaticLogout/data/loadConfiguration';

import { LoginService } from '@ac/library-utils/dist/services';

export interface ConfigurationState {
  idleTimeoutInSeconds: number;
  warningTimeInSecondsBeforeIdleTimeout: number;
}

export const useConfigurationFromApi = (): ConfigurationState | null => {
  const [configuration, setConfiguration] = useState<ConfigurationState | null>(
    null
  );

  useEffect(() => {
    const configurationForUser = LoginService.isSuperUser()
      ? getDefaultConfigurationForSuperUser()
      : loadConfiguration();

    const { idleTimeoutInMinutes, warningTimeInSecondsBeforeIdleTimeout } =
      configurationForUser;

    if (!idleTimeoutInMinutes) {
      return;
    }

    // x7FFFFFFF is max value of minutes that can be passed to setTimeout
    // in order not to use bigger value we agreed to 30000 as max allowed minutes
    const idleTimeoutInSeconds = Math.min(30000, idleTimeoutInMinutes) * 60;

    setConfiguration({
      idleTimeoutInSeconds,
      warningTimeInSecondsBeforeIdleTimeout:
        getValidatedWarningTimeInSecondsBeforeIdleTimeout(
          idleTimeoutInSeconds,
          warningTimeInSecondsBeforeIdleTimeout
        ),
    });
  }, []);

  return configuration;
};

const getDefaultConfigurationForSuperUser = (): ConfigurationResponse => ({
  idleTimeoutInMinutes: 10,
  warningTimeInSecondsBeforeIdleTimeout: 60,
});

const getValidatedWarningTimeInSecondsBeforeIdleTimeout = (
  idleTimeoutInSeconds: number,
  warningTimeInSecondsBeforeIdleTimeout: number | undefined
): number => {
  if (!warningTimeInSecondsBeforeIdleTimeout) {
    return 0;
  }

  const minimumTimeInSecondsForUserInteraction = 30;
  const maximumWarningTimeInSecondsBeforeIdleTimeout =
    idleTimeoutInSeconds - minimumTimeInSecondsForUserInteraction;
  if (
    warningTimeInSecondsBeforeIdleTimeout >
    maximumWarningTimeInSecondsBeforeIdleTimeout
  ) {
    return maximumWarningTimeInSecondsBeforeIdleTimeout;
  }

  return warningTimeInSecondsBeforeIdleTimeout;
};
