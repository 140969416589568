import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

export const baseConfigurationPath = '/configurationv2';

export const isConfigurationv2Module = (
  pathname: string = window.location.pathname
): boolean => {
  const isNewConfigurationPath = pathname?.startsWith(baseConfigurationPath);

  return isNewConfigurationPath;
};

export const isConfigurationv2PropertyContextModule = (
  pathname: string = window.location.pathname
): boolean => {
  const isConfigurationV2Module = isConfigurationv2Module(pathname);

  return (
    isConfigurationV2Module &&
    pathname?.startsWith(
      `${acConfig.newFrontendUrls?.configurationv2}/property`
    )
  );
};

export const isConfigurationv2ResourceManagementModule = (
  pathname: string = window.location.pathname
): boolean => {
  const isConfigurationV2Module = isConfigurationv2Module(pathname);

  return (
    isConfigurationV2Module &&
    pathname?.startsWith(
      `${acConfig.newFrontendUrls?.configurationv2}/resource-management`
    )
  );
};

export const isConfigurationv2InPropertyContext = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    isConfigurationv2ResourceManagementModule(pathname) ||
    isConfigurationv2PropertyContextModule(pathname)
  );
};

export const isConfigurationv2ModuleForSuperAdmin = (): boolean => {
  return (
    isConfigurationv2Module(window.location.pathname ?? '') &&
    Boolean(LoginService.isSuperUser())
  );
};

export const isConfigurationv2TenantContextModule = (
  pathname: string = window.location.pathname
): boolean =>
  isConfigurationv2Module(pathname) &&
  !isConfigurationv2InPropertyContext(pathname);

export default {
  isConfigurationv2Module,
  isConfigurationv2PropertyContextModule,
  isConfigurationv2InPropertyContext,
  isConfigurationv2TenantContextModule,
  isConfigurationv2ModuleForSuperAdmin,
};
