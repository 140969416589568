import { getInternetSpeedTestTranslationKey } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/translation';

import { PageSectionManifest } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

export const internetSpeedTestSectionManifest: PageSectionManifest = {
  id: 'maintenance-panel-internet-speed-section',
  icon: IconName.www,
  title: getInternetSpeedTestTranslationKey('TITLE'),
};
