import { Context } from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContextById } from 'frontend-container/components/Menu/components/PropertyContext/service';
import {
  NavigateToCro,
  NavigateToOptions,
  NavigateToProperty,
  Router,
} from 'frontend-container/publicApi/types';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  contextTypeRegionChangeQueryParamMapper,
  getOriginForNewRegion,
} from 'frontend-container/utils/region/getUrlForNewRegion';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  SEPFrontendUrlKey,
  SEPInternalFrontendUrlKeyType,
} from '@ac/library-utils/dist/declarations/config';
import { isEmpty, trim } from '@ac/library-utils/dist/utils';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

const createRoute = (
  route: string,
  { subroute, searchParams }: NavigateToOptions = {}
): string => {
  if (!isEmpty(searchParams?.toString())) {
    return trim`${route}${subroute}?${searchParams}`;
  }

  return trim`${route}${subroute}`;
};

const createRegionalRoute = (
  route: string,
  context: Context,
  { subroute, searchParams }: NavigateToOptions = {}
): string => {
  const region = findRegionData(context.regionCode ?? '');
  const origin = getOriginForNewRegion(
    region?.code ?? '',
    region?.domain ?? ''
  );
  const path = createRoute(route, { subroute, searchParams });

  return trim`${origin}${path}`;
};

const getContextSearchParamTuple = (context: Context): [string, string] => [
  contextTypeRegionChangeQueryParamMapper(context),
  context.id,
];

const getModulePath = (module: SEPInternalFrontendUrlKeyType): string =>
  acConfig.newFrontendUrls[module];

type NavigationOptions = {
  replace?: boolean;
  reload?: boolean;
  newTab?: boolean;
};

const navigate = (
  route: string,
  { newTab, reload, replace }: NavigationOptions
): void => {
  if (newTab) {
    window.open(route, '_blank');

    return;
  }
  if (reload) {
    if (replace) {
      window.location.replace(route);
    } else {
      window.location.href = route;
    }
  } else {
    if (replace) {
      history.replaceState({}, '', route);
    } else {
      history.pushState({}, '', route);
    }
  }
};

const navigateTo = (
  relativePath: string,
  {
    searchParams,
    newTab = false,
    reload = false,
    replace = false,
  }: NavigationOptions & NavigateToOptions = {}
): void => {
  const route = createRoute(relativePath, { searchParams });

  navigate(route, {
    newTab,
    reload,
    replace,
  });
};

const navigateToModule = (
  module: SEPInternalFrontendUrlKeyType,
  {
    subroute,
    searchParams,
    newTab = false,
    replace = false,
    reload = false,
  }: NavigationOptions & NavigateToOptions = {}
): void => {
  const route = createRoute(getModulePath(module), {
    subroute,
    searchParams,
  });
  navigate(route, {
    replace,
    reload,
    newTab,
  });
};

const navigateToProperty: NavigateToProperty = async (
  propertyId,
  module,
  { subroute, searchParams, newTab } = {}
) => {
  const propertyContext = getPropertyContextById(propertyId);

  if (!propertyContext) {
    throw new Error(
      `Failed to navigate to property. Property context "${propertyId}" not found`
    );
  }

  setSessionContext(propertyContext, newTab);

  const searchParamsWithContext = new URLSearchParams(searchParams);
  searchParamsWithContext.append(
    ...getContextSearchParamTuple(propertyContext)
  );

  if (propertyContext.regionCode === getCurrentRegionCode()) {
    return navigateToModule(module, {
      subroute,
      newTab,
      reload: true,
      replace: true,
      searchParams: newTab ? searchParamsWithContext : searchParams,
    });
  }

  const regionalRoute = createRegionalRoute(
    getModulePath(module),
    propertyContext,
    {
      subroute,
      searchParams: searchParamsWithContext,
    }
  );

  navigate(regionalRoute, {
    newTab,
    replace: true,
    reload: true,
  });
};

const navigateToCro: NavigateToCro = async (
  croId,
  { subroute, searchParams, newTab } = {}
) => {
  const croContext = getCroContextById(croId);

  if (!croContext) {
    throw new Error(
      `Failed to navigate to CRO. CRO context "${croId}" not found`
    );
  }

  setSessionContext(croContext, newTab);

  const searchParamsWithContext = new URLSearchParams(searchParams);
  searchParamsWithContext.append(...getContextSearchParamTuple(croContext));

  if (croContext.regionCode === getCurrentRegionCode()) {
    return navigateToModule(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE, {
      subroute,
      newTab,
      reload: true,
      replace: true,
      searchParams: newTab ? searchParamsWithContext : searchParams,
    });
  }

  const regionalRoute = createRegionalRoute(
    getModulePath(SEPFrontendUrlKey.CENTRAL_RESERVATION_OFFICE),
    croContext,
    {
      subroute,
      searchParams: searchParamsWithContext,
    }
  );

  navigate(regionalRoute, {
    newTab,
    reload: true,
    replace: true,
  });
};

export const createRouter = (): Router => ({
  navigateTo,
  navigateToModule,
  navigateToProperty,
  navigateToCro,
});
