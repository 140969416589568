import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { loadBlazorApp } from 'frontend-container/utils/externalResources/blazor/load';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const registerWorkflows = (): void => {
  registerApplication(
    'frontend-workflows',
    async () => loadBlazorApp(acConfig.frontendUrls.workflows),
    (location: Location) => isWorkflowsModule(location.pathname)
  );
};
