import { AcPropertyIdHeaderName } from 'frontend-container/shared/mainHeaders';

import {
  BaseApi,
  buildHostForRegionCode,
  Config,
  ConfigBuilder,
  CUSTOM_CN1_REGION_CODE,
  getCN1HeaderForReadWriteMode,
  LibraryApiRequestHeaders,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { isValidIpAddress } from '@ac/library-utils/dist/utils';
import {
  getCurrentRegionCode,
  getGlobalRegionCode,
  RegionsCodes,
} from '@ac/library-utils/dist/utils/multi-region';

type GetApiConfigForConfigurationRequestsOptions = {
  headers?: LibraryApiRequestHeaders;
};

export const getApiConfigForConfigurationRequests = (
  options: GetApiConfigForConfigurationRequestsOptions = {}
): Config => {
  return new ConfigBuilder()
    .setHost(getHostForConfigurationRequests())
    .setHeaders({
      ...options.headers,
      ...getCN1HeaderForReadWriteMode(true),
      [AcPropertyIdHeaderName]: null,
    })
    .getConfig();
};

const getHostForConfigurationRequests = (): string => {
  const currentRegionCode = getCurrentRegionCode();
  const cn1Code = (CUSTOM_CN1_REGION_CODE ?? RegionsCodes.China).toLowerCase();
  if (
    currentRegionCode === cn1Code ||
    window.location.hostname.includes('localhost') ||
    isValidIpAddress(window.location.hostname)
  ) {
    return BaseApi.host;
  }

  const globalCode = getGlobalRegionCode();
  const region = acConfig.regions.find(
    (item) => item.code.toLowerCase() === globalCode
  );

  return buildHostForRegionCode(globalCode, region?.domain ?? '');
};
