import { useEffect } from 'react';
import { SystemDefaultLandingPage } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import { PrestepAccessDenied } from 'frontend-container/components/Prestep/AccessDenied/AccessDenied';
import i18n from 'frontend-container/i18n';
import { NotificationsNames } from 'frontend-container/shared/webSockets/types';

import {
  useTenantWebSocketClientsContext,
  withTranslationsReady,
} from '@ac/react-infrastructure';

const PrestepPermissionsInProgress = (): JSX.Element => {
  const {
    state: { webSockets },
  } = useTenantWebSocketClientsContext();

  useEffect(() => {
    const channelPromise = webSockets.user.subscribe(
      { notificationType: NotificationsNames.UserPermissionsChanged },
      () =>
        (window.location.href = `${window.location.origin}${SystemDefaultLandingPage.pathname}`)
    );

    return (): void => {
      channelPromise.then((channel) => channel.unsubscribe());
    };
  }, [webSockets]);

  return (
    <PrestepAccessDenied
      message={i18n.t('LOGIN_LOGOUT.LOGIN_PERMISSIONS_IN_PROGRESS.MESSAGE')}
    />
  );
};

const PrestepPermissionsInProgressWithTranslationsReady = withTranslationsReady(
  PrestepPermissionsInProgress
);

export { PrestepPermissionsInProgressWithTranslationsReady as PrestepPermissionsInProgress };
