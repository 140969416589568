import { Branding } from 'frontend-container/shared/branding/loadBranding';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

export type LoginBranding = Pick<
  Branding,
  | 'loginBackgroundUrl'
  | 'loginLogoUrl'
  | 'loginBackgroundId'
  | 'loginLogoId'
  | 'loginFaviconData'
>;

export const LOGIN_BRANDING_KEY = 'aboveCloud.loginBranding';

export const saveLoginBrandingToStorage = (
  loginBranding: LoginBranding
): void => {
  LocalStorage.set(LOGIN_BRANDING_KEY, loginBranding);
};

export const removeLoginBrandingFromStorage = (): void => {
  LocalStorage.remove(LOGIN_BRANDING_KEY);
};

export const getLoginBrandingFromStorage = (): LoginBranding | null =>
  LocalStorage.getParsed<LoginBranding>(LOGIN_BRANDING_KEY);

export const updateLoginBrandingStorage = (
  branding: Partial<LoginBranding>
): void => {
  const localStorageBranding = getLoginBrandingFromStorage();

  return saveLoginBrandingToStorage({
    ...localStorageBranding,
    ...branding,
  });
};
