import { isConfigurationEnabled } from 'frontend-container/components/Menu/components/ConfigurationContext/service';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import { isNotificationsTenantContextDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';

export const getActiveContextId = (): string => {
  if (isPropertyContextVisible() && !isNotificationsTenantContextDashboard())
    return ContextOption.property;

  if (isCroContextVisible() && !isNotificationsTenantContextDashboard())
    return ContextOption.cro;

  if (isProfileCentersContextVisible()) return ContextOption.profileCenter;

  if (
    isConfigurationEnabled() &&
    !isPropertyContextVisible() &&
    !isCroContextVisible() &&
    !isProfileCentersContextVisible()
  )
    return ContextOption.configuration;

  return '';
};
