import { Context } from 'frontend-container/components/Menu/components/Context';
import { getUrlForNewRegion } from 'frontend-container/utils/region/getUrlForNewRegion';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const redirectToRegion = (
  code: string,
  domain: string,
  context: Context,
  customPathname?: string
): boolean => {
  const currentRegionCode = getCurrentRegionCode();
  if (currentRegionCode && context?.regionCode !== currentRegionCode) {
    window.location.replace(
      getUrlForNewRegion(code, domain, context, customPathname)
    );

    return true;
  }

  return false;
};
