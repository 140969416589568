export const getBrowserGenericVersion = (
  name: string,
  userAgent: string
): string => {
  const matchStringPattern = `.*${name}([0-9_|.]*).*`;
  const newMatchRegex = new RegExp(matchStringPattern, 'i');
  const version = userAgent.match(newMatchRegex);

  return version ? version[1] : '';
};
