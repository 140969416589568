import { MaintenancePanelFeatureTogglesContext } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/context';
import { useCreateMaintenancePanelFeatureTogglesStore } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/store';

import { PropsWithChildren } from '@ac/react-infrastructure';

export const MaintenancePanelFeatureTogglesContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateMaintenancePanelFeatureTogglesStore();

  return (
    <MaintenancePanelFeatureTogglesContext.Provider value={store}>
      {props.children}
    </MaintenancePanelFeatureTogglesContext.Provider>
  );
};
