import {
  GenericEntity,
  GenericOverrideEntity,
  GenericTypeEntity,
} from '@ac/library-api';
import { AcSelectOption } from '@ac/web-components';

export const getSelectOptions = (
  entities: Array<GenericTypeEntity | GenericOverrideEntity | GenericEntity>,
  language: string,
  withCode?: boolean
): AcSelectOption[] =>
  entities.map(({ id, code, descriptionMap }) => {
    const codeName = withCode && code ? `${code} — ` : '';
    const name = `${codeName}${descriptionMap[language]}`;

    return {
      id,
      name,
      value: id,
      other: !withCode ? name : code,
    };
  });
