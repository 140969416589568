export const preventMoreThenOneOverrideOfEventListenersFunctions = (): void => {
  const originalAddEventListener = window.addEventListener;
  Object.defineProperty(window, 'addEventListener', {
    get: (): typeof window.addEventListener => {
      return originalAddEventListener;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set: () => {},
  });
  const originalRemoveEventListener = window.removeEventListener;
  Object.defineProperty(window, 'removeEventListener', {
    get: (): typeof window.addEventListener => {
      return originalRemoveEventListener;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set: () => {},
  });
};
