import { configurationAppName } from 'frontend-container/config/configuration';
import { reservationsAppName } from 'frontend-container/config/reservations';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const isEmberAppByPathname = (pathname: string): boolean => {
  return (
    pathname.startsWith(acConfig.newFrontendUrls.reservations ?? '') ||
    pathname.startsWith(acConfig.newFrontendUrls.configuration ?? '')
  );
};

export const isEmberAppByAppName = (appName: string): boolean => {
  return appName === reservationsAppName || appName === configurationAppName;
};
