import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { getMainApplicationMenu } from 'frontend-container/components/Menu/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { CONTAINER_ROOT_SELECTOR } from 'frontend-container/shared/constants';

import {
  ButtonPattern,
  ButtonTheme,
  Placement,
  TargetValueObject,
} from '@ac/web-components';

import './Item.scss';

interface Props {
  selectedRoute: string | undefined;
  isSelected: boolean;
  menuElement: MenuElement;
  setSelectedRoute: (setSelectedRouteId: string, route: string) => void;
  class?: string;
}

export const Item = (props: Props): JSX.Element => {
  const [isElementActive, setIsElementActive] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;
  const { t } = useTranslation();

  const handleOnMouseOverMenuItem = (): void => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setIsElementActive(true);
  };

  const handleOnHideMenuItem = (): void => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => setIsElementActive(false), 50);
  };

  const redirectToLink = (link: string): Promise<void> =>
    handleMenuItemRedirect(link, props.menuElement.items, () => {
      setIsElementActive(false);
      props.setSelectedRoute(props.menuElement.id, link);
    });

  const handleClickMenuSubItem = async (
    link: string,
    discardUnsavedChanges: boolean = false
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      discardUnsavedChanges,
      link,
      redirectToLink
    );
  };

  const handleOnClickMenuSubItem = async (link: string): Promise<void> =>
    await handleClickMenuSubItem(link);

  const menuElements = getMainApplicationMenu();
  const shortcutsWindows = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutWindows)
  );
  const shortcutsMac = menuElements.flatMap((element: MenuElement) =>
    element.items.filter((item) => item.keyboardShortcutMac)
  );
  const isMac = navigator.appVersion.indexOf('Mac') !== -1;
  const keyboardShortcuts = isMac
    ? shortcutsMac.map((item: MenuElementItem) => item.keyboardShortcutMac)
    : shortcutsWindows.map(
        (item: MenuElementItem) => item.keyboardShortcutWindows
      );
  useHotkeys(
    keyboardShortcuts.join(),
    (handler: {
      altKey: boolean;
      ctrlKey: boolean;
      metaKey: boolean;
      keyCode: number;
    }) => {
      const shortcuts = isMac ? shortcutsMac : shortcutsWindows;

      const foundMenuItem = shortcuts.find((item: MenuElementItem) => {
        const { altKey, ctrlKey, metaKey, keyCode } = handler;
        const isTheSameKeyCode = keyCode === item.keyCode;

        return altKey && (isMac ? metaKey : ctrlKey) && isTheSameKeyCode;
      });
      if (foundMenuItem) {
        handleClickMenuSubItem(foundMenuItem.link);
      }
    }
  );

  return (
    <>
      <div
        id={props.menuElement.id}
        className={`container-menu-item ${props.class || ''}`}
      >
        {props.isSelected && <div className="menu-status-bar" />}
        <ac-button
          class="container-menu-button"
          data-test-selector={props.menuElement.id}
          pattern={ButtonPattern.tertiary}
          theme={ButtonTheme.light}
          selected={isElementActive}
          onMouseOver={handleOnMouseOverMenuItem}
          onMouseLeave={handleOnHideMenuItem}
        >
          <ac-button-content
            text={t(props.menuElement.translation)}
            icon={props.menuElement.icon}
          />
        </ac-button>
        <ac-position-wrapper
          dynamicClass="menu-subitems-wrapper layout-direction"
          attachTo={CONTAINER_ROOT_SELECTOR}
          isBoundaryContainerApplied={false}
          contentHidden={!isElementActive}
          target={`#${props.menuElement.id}`}
          targetValue={TargetValueObject.mainMenu}
          placement={Placement.bottomStart}
          offset={undefined}
          onMouseOver={handleOnMouseOverMenuItem}
          onMouseLeave={handleOnHideMenuItem}
        >
          <div className="menu-subitems-container">
            {props.menuElement.items.map((item, index) => (
              <SubItem
                key={index}
                active={props.selectedRoute === item.link}
                item={item}
                onClick={handleOnClickMenuSubItem}
              />
            ))}
          </div>
        </ac-position-wrapper>
      </div>
    </>
  );
};
