import React from 'react';
import { Logout } from 'frontend-container/components/LoginLogout/Logout';
import { defaultDocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { clearStorageData } from 'frontend-container/components/Menu/components/User/logout';
import { applySessionDataFromUrl } from 'frontend-container/components/SessionData/applySessionDataFromUrl';
import { mapFrontendUrlsToNewFrontendUrls } from 'frontend-container/config';
import i18n, { initI18nTranslations } from 'frontend-container/i18n';
import { startContainerApp } from 'frontend-container/initContainerApp';
import { initWebComponents } from 'frontend-container/initWebComponents';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { initActivityTracker } from 'frontend-container/utils/activityTracker/activityTrackerInitializer';
import { initializeBaseApi } from 'frontend-container/utils/api/initialization';
import { createReactRoot } from 'frontend-container/utils/createReactRoot';
import { defineObjectOnWindow } from 'frontend-container/utils/definedCustomObjectOnWindow';
import { hasProperty } from 'frontend-container/utils/hasProperty';
import { initServicesWithLogger } from 'frontend-container/utils/logger/initServicesWithLogger';
import { initLogger } from 'frontend-container/utils/logger/logger';
import {
  initLoginService,
  postHashToParentWindow,
} from 'frontend-container/utils/loginService/loginServiceInitializers';
import { setupWhitelistingCatcher } from 'frontend-container/utils/setupWhitelistingCatcher';
import { initBrowserZoomWarning } from 'frontend-container/utils/warnBrowserZoom';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionDataHost } from '@ac/library-utils/dist/services';
import { isLocalhost, updateDocumentTitle } from '@ac/library-utils/dist/utils';
import { LoaderSize } from '@ac/web-components';

// systemjs should be loaded once for whole application.
// If loaded more than once - then errors with loading modules/components
// can occur.
import 'systemjs/dist/system';
import 'systemjs/dist/extras/amd';
import 'systemjs/dist/extras/named-register';

const startApp = async (): Promise<void> => {
  initBrowserZoomWarning();

  if (redirectFromLoginIfNeeded()) {
    return;
  }

  if (!acConfigWasLoadedAndReloadPageIfNot()) {
    return;
  }

  if (window.frameElement && LoginService.hasAvailableAuthorisationDetails()) {
    return postHashToParentWindow();
  }

  initApplicationStyles();
  setupSessionData();
  setupDefaultPageTitle();
  setupWhitelistingCatcher();
  defineObjectOnWindow();
  initWebComponents();
  initI18nTranslations();

  const isLogoutPage = window.location.pathname.startsWith('/logout');
  if (isLogoutPage) {
    return renderLogout();
  }

  renderLoader();
  await clearStorageOn403AndRetryIfOperationFails(
    initLoggerWithoutLogin,
    handleLoggerError
  );

  initLoginService(async (newRoute) => {
    initializeBaseApi();
    await clearStorageOn403AndRetryIfOperationFails(
      initLoggerWithLogin,
      handleLoggerError
    );

    // temporarily disable activity-tracker for super user due to task ACFC-1759
    if (!LoginService.isSuperUser()) {
      initActivityTracker();
    }

    await startContainerApp(newRoute);
  });
};

const redirectFromLoginIfNeeded = (): boolean => {
  const { pathname } = window.location;
  const { referrer } = window.document;
  const plainReferrerOrigin = referrer.replace('/login', '');
  const isLoginUrl = pathname === '/login';
  const isLoginCorrectLocation = isLoginUrl && !isLocalhost();
  const isAlreadyLoggingIn =
    LoginService.hasAvailableAuthorisationDetails() ||
    Boolean(window.frameElement);

  if (
    isLoginCorrectLocation &&
    !isAlreadyLoggingIn &&
    acConfig.containerFrontendUrl !== plainReferrerOrigin
  ) {
    window.location.href = `${
      acConfig.containerFrontendUrl
    }${mapFrontendUrlsToNewFrontendUrls(plainReferrerOrigin)}`;

    return true;
  }

  return false;
};

const acConfigWasLoadedAndReloadPageIfNot = (): boolean => {
  const RETRIES_KEY = 'aboveCloud.reloadRetries';

  if (!acConfig.identityOauthUrl) {
    const retries = parseInt(sessionStorage.getItem(RETRIES_KEY) ?? '0', 10);
    if (retries > 10) {
      sessionStorage.removeItem(RETRIES_KEY);

      const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
      root.render(<>{i18n.t('ERRORS_PAGES.NETWORK_ERROR')}</>);

      return false;
    }

    sessionStorage.setItem(RETRIES_KEY, `${retries + 1}`);
    window.location.reload();

    return false;
  }

  return true;
};

const setupSessionData = (): void => {
  SessionDataHost.setupSessionData(() => {
    applySessionDataFromUrl();
  });
};

const setupDefaultPageTitle = (): void => {
  updateDocumentTitle(defaultDocumentTitle);
};

const initApplicationStyles = (): void => {
  import('./utils/initStyles').then(({ initStyles }) => initStyles());
};

const renderLogout = (): void => {
  const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
  root.render(<Logout />);
};

const renderLoader = (): void => {
  const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
  root.render(<ac-loader-covering loaderSize={LoaderSize.md} />);
};

const initLoggerWithoutLogin = async (): Promise<void> => {
  const logger = initLogger();
  await initServicesWithLogger(logger);
  const initializedLogger = await logger;
  initializedLogger.information('Logger initialized for none logged in user');
};

const initLoggerWithLogin = async (): Promise<void> => {
  const logger = initLogger();
  initServicesWithLogger(logger);
  const initializedLogger = await logger;
  initializedLogger.information('Application started');
};

const handleLoggerError = (error: unknown): void => {
  // Logger is not that critical to stop the entire app, we tried to initialize it 2 times already.
  console.error('Logger initialization failed', error); // eslint-disable-line no-console
};

const clearStorageOn403AndRetryIfOperationFails = async (
  operation: () => Promise<void>,
  errorCallback: (error: unknown) => void,
  shouldRetry = true
): Promise<void> => {
  try {
    await operation();
  } catch (error: unknown) {
    // 403 here may be because of leftovers from a previous session with other tenant
    if (hasProperty(error, 'status')) {
      const errorStatus = String(error.status);
      if (errorStatus === '403') {
        clearStorageData();
      }
    }

    if (shouldRetry) {
      return clearStorageOn403AndRetryIfOperationFails(
        operation,
        errorCallback,
        false
      );
    }

    errorCallback(error);
  }
};

startApp();
