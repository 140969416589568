import { useEffect, useMemo, useRef, useState } from 'react';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getIsMenuComponentAllowed } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Breadcrumbs } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import { ContextManager } from 'frontend-container/components/Menu/components/Context/ContextManager';
import {
  ContextSelectButtonOptions,
  useContextMenuItems,
} from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { defaultDocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { Logo } from 'frontend-container/components/Menu/components/Logo';
import { useBrandingLogo } from 'frontend-container/components/Menu/components/Logo/useBrandingLogo';
import { MenuContent } from 'frontend-container/components/Menu/components/MenuContent/MenuContent';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import { TrainingBanner } from 'frontend-container/components/Menu/components/TrainingBanner/TrainingBanner';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { User } from 'frontend-container/components/Menu/components/User/User';
import { UserDocumentationButton } from 'frontend-container/components/Menu/components/UserDocumentation/UserDocumentation';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedChangelogMenu,
  getAllowedConfigurationMenu,
  getAllowedIntegrationsMenuElementsForSystemUser,
  getAllowedMainApplicationMenu,
  getAllowedProfileCenterMenu,
  getAllowedWorkflowsMenu,
  getFullMenu,
} from 'frontend-container/components/Menu/configuration';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { getSelectedMenuItem } from 'frontend-container/components/Menu/menuItems';
import {
  isBusinessContextDataGoingToChange,
  THEME_DARK_KEY,
} from 'frontend-container/components/Menu/service';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { useOverrideMenuHeight } from 'frontend-container/components/Menu/useOverrideMenuHeight';
import { useTrainingFeature } from 'frontend-container/components/Menu/useTrainingFeature';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isModuleWithoutMainMenuEntry } from 'frontend-container/components/Menu/utils/isModuleWithoutMainMenuEntry';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import {
  adjustScrollToCurrentMenuItem,
  getNextScrollIndex,
} from 'frontend-container/components/Menu/utils/menuScroll/adjustScrollToCurrentMenuItem';
import { setCurrentIndex } from 'frontend-container/components/Menu/utils/menuScroll/currentIndex';
import { getScrollReport } from 'frontend-container/components/Menu/utils/menuScroll/scrollReport';
import { scrollToMenuItem } from 'frontend-container/components/Menu/utils/menuScroll/scrollToSelectedMenuItem';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { isChangelogModule } from 'frontend-container/components/Menu/utils/modules/changelog';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2TenantContextModule } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { isIntegrationsModule } from 'frontend-container/components/Menu/utils/modules/integrations';
import {
  isNotificationsTenantContextDashboard,
  isNotificationSubscriptions,
} from 'frontend-container/components/Menu/utils/modules/notifications';
import { isProfileCentersModule } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { useMaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { emberPathNameAppNameMap } from 'frontend-container/config/emberPathnameAppNameMap';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { useLayoutDirection } from 'frontend-container/utils/hooks/useLayoutDirection';
import { isEmberAppByAppName } from 'frontend-container/utils/isEmberApp';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';
import { unloadApplication } from 'single-spa';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { useBreadcrumbsTitle } from '@ac/react-infrastructure';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  MenuBarSlot,
  MenuBarTheme,
} from '@ac/web-components';

import './Menu.scss';

let usedApp: string | undefined;
let resizeId: ReturnType<typeof setTimeout> | undefined;

export const Menu = (): JSX.Element => {
  const [menuItems, setMenuItems] = useState<MenuElement[]>([]);
  const [contextMenuItems, setContextMenuItems] =
    useState<ContextSelectButtonOptions>();

  const { setContextsContext } = useContextsContext();
  const { isRtl } = useLayoutDirection();
  const [scrollDownDisabled, setScrollDownDisabled] = useState(false);
  const [scrollUpDisabled, setScrollUpDisabled] = useState(false);
  const [, updateArrows] = useState(0);
  const locationRef = useRef<string>(location.pathname);
  const [selectedItem, setSelectedItem] = useState(() => {
    const [initialSelectedElement] = getSelectedMenuItem(menuItems);

    return initialSelectedElement?.id;
  });
  const [pageName, setPageName] = useState(defaultDocumentTitle);
  const [selectedRoute, setSelectedRoute] = useState(() => {
    const [, initialSelectedItem] = getSelectedMenuItem(menuItems);

    return initialSelectedItem ? initialSelectedItem.link : undefined;
  });

  const { getContextMenuItems } = useContextMenuItems();

  const isMenuV2EnabledCached = getIsMenuV2Enabled();
  const isNonProduction = useTrainingFeature();

  const fullMenuConfiguration = useMemo(() => getFullMenu(), []);
  const mainMenuLogo = useBrandingLogo();
  const breadcrumbsTitle = useBreadcrumbsTitle();
  const [currentMenuElement, currentMenuItem] = getSelectedMenuItem(menuItems);
  const isDarkMode = Boolean(LocalStorage.getParsed(THEME_DARK_KEY));

  useEffect(() => {
    void updateRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (breadcrumbsTitle) {
      setPageName(breadcrumbsTitle);
    }
  }, [breadcrumbsTitle]);

  useEffect(() => {
    updateMenuScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRtl, menuItems]);

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    window.addEventListener('popstate', updateRoute);
    window.addEventListener('resize', handleResize);
    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      () => updateRoute()
    );

    return (): void => {
      window.removeEventListener('popstate', updateRoute);
      window.removeEventListener('resize', handleResize);
      unsubscribeBusinessContextUpdatedEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  const handleResize = (): void => {
    if (resizeId) {
      clearTimeout(resizeId);
    }
    resizeId = setTimeout(updateMenuScroll, 150);
  };

  const updateMenuScroll = (): void => {
    const currentMenuElements = menuItems;
    const [element] = getSelectedMenuItem(currentMenuElements);
    const id = element?.id ?? selectedItem ?? '';
    if (id) {
      void adjustScrollToCurrentMenuItem(
        id,
        currentMenuElements,
        updateArrowStatus
      );
    }
  };

  const redirectWithoutMenuItemAccess = (
    allAccessConfiguration: AllAccessConfiguration,
    currentMenuElementSelected?: MenuElement
  ): boolean => {
    const isRouteNotFound =
      !currentMenuElementSelected &&
      allAccessConfiguration[allAccessConfiguration.currentSource].permissions
        .length &&
      !isModuleWithoutMainMenuEntry();

    if (isRouteNotFound) {
      navigateToPage(pathname403);

      return true;
    }

    const [selectedMenuElement, selectedMenuItem] = getSelectedMenuItem(
      fullMenuConfiguration
    );

    const isMenuItemNotAllowed =
      selectedMenuItem &&
      !getIsMenuComponentAllowed(selectedMenuItem, allAccessConfiguration);

    const isMenuElementNotAllowed =
      selectedMenuElement &&
      !isModuleWithoutMainMenuEntry() &&
      !getIsMenuComponentAllowed(selectedMenuElement, allAccessConfiguration);

    if (isMenuItemNotAllowed || isMenuElementNotAllowed) {
      navigateToPage(pathname403);

      return true;
    }

    return false;
  };

  const updateMenuItems = (
    allAccessConfiguration: AllAccessConfiguration
  ): MenuElement[] => {
    if (isErrorPage() && menuItems.length) {
      return menuItems;
    }

    let menuElements: MenuElement[];

    const isSystemUser = LoginService.isSuperUser();

    if (
      isConfigurationModule() ||
      isConfigurationv2TenantContextModule() ||
      isFloorPlanModule() ||
      isNotificationSubscriptions() ||
      isNotificationsTenantContextDashboard()
    ) {
      menuElements = getAllowedConfigurationMenu(allAccessConfiguration);
    } else if (!isSystemUser && isProfileCentersModule()) {
      menuElements = getAllowedProfileCenterMenu(allAccessConfiguration);
    } else if (isChangelogModule()) {
      menuElements = getAllowedChangelogMenu(allAccessConfiguration);
    } else if (!isSystemUser && isWorkflowsModule()) {
      menuElements = getAllowedWorkflowsMenu(allAccessConfiguration);
    } else if (!isSystemUser && isCentralReservationOfficeModule()) {
      menuElements = getAllowedCentralReservationOfficeMenu(
        allAccessConfiguration
      );
    } else if (isSystemUser && isIntegrationsModule()) {
      menuElements = getAllowedIntegrationsMenuElementsForSystemUser(
        allAccessConfiguration
      );
    } else {
      menuElements = getAllowedMainApplicationMenu(allAccessConfiguration);
    }
    setMenuItems(menuElements);

    return menuElements;
  };

  const unloadEmberApplication = async (): Promise<void> => {
    const scope = getAppScopeFromPathname();
    if (
      !!usedApp &&
      emberPathNameAppNameMap[scope] !== usedApp &&
      isEmberAppByAppName(usedApp)
    ) {
      await unloadApplication(usedApp ?? '');
    }
    usedApp = emberPathNameAppNameMap[scope];
  };

  const updateRoute = async (event?: Event): Promise<void> => {
    const isPopstateEvent = event && event.type === 'popstate';
    if (isPopstateEvent) {
      const newPathname = location.pathname;
      if (newPathname === locationRef.current) {
        return;
      }
      locationRef.current = newPathname;
    }

    await unloadEmberApplication();
    // Only modules which are editable in global region care for readOnly mode.
    // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
    // That's why we active readOnly mode for modules other then the ones editable in global region.
    if (isReadOnlyRequired()) {
      const newValue = getReadOnlyByUser() ?? true;
      setReadOnlyMode(newValue);
    }

    if (isBusinessContextDataGoingToChange()) {
      return;
    }

    const allAccessConfiguration = getAllAccessConfiguration();

    const menuElements: MenuElement[] = updateMenuItems(allAccessConfiguration);

    setContextMenuItems(getContextMenuItems(allAccessConfiguration));

    setContextsContext(getContextMenuItems(allAccessConfiguration));

    const [element, item] = getSelectedMenuItem(menuElements);

    const isRedirected = redirectWithoutMenuItemAccess(
      allAccessConfiguration,
      element
    );

    if (isRedirected) {
      return;
    }

    if (element) {
      handleSelectRoute(element?.id ?? '', item?.link);
    }
  };

  const handleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onButtonHandleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onScrollUp = (): void => {
    onScroll(false);
  };

  const onScrollDown = (): void => {
    onScroll(true);
  };

  const onScroll = (forward: boolean): void => {
    const elements = menuItems;
    const maxIndex = elements.length - 1;
    const nextIndex = getNextScrollIndex(forward, isRtl);
    const limitedIndex = Math.min(Math.max(0, nextIndex), maxIndex);
    const selectedMenuItemId = elements[limitedIndex]?.id;

    if (!selectedMenuItemId) {
      return;
    }

    const hasScrolled = scrollToMenuItem({
      id: selectedMenuItemId,
      center: false,
      enforce: true,
      callback: updateArrowStatus,
    });
    if (hasScrolled) {
      setCurrentIndex(limitedIndex);
    }
  };

  const updateArrowStatus = (scrollOptions: ScrollToOptions): void => {
    const { isArrowUpDisabled, isArrowDownDisabled, isScrollVisible } =
      getScrollReport(scrollOptions);
    if (isScrollVisible) {
      setScrollUpDisabled(isArrowUpDisabled);
      setScrollDownDisabled(isArrowDownDisabled);
    }

    // update only if it's necessary
    updateArrows(
      Number(isArrowUpDisabled) +
        Number(isArrowDownDisabled) * 10 +
        Number(isScrollVisible) * 100
    );
  };

  const { isWorkstationVisible } = useIsWorkstationVisible();

  const menuBarClasses = useMemo(() => {
    const classes = [
      'menu-bar',
      isPathnameWithoutProperty() ? 'without-property' : '',
    ];

    return classes.filter(Boolean).join(' ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems.length, window.location.pathname]);

  const maintenancePanel = useMaintenancePanelPresenterContext();

  const openMaintenancePanel = async (): Promise<void> => {
    if (!maintenancePanel.state.isVisible) {
      await maintenancePanel.show();
    }
  };

  useOverrideMenuHeight(isNonProduction, isMenuV2EnabledCached);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-flex class="layout-direction">
        {isMenuV2EnabledCached && (
          <ac-menu-bar
            onLogoDoubleClickCallback={openMaintenancePanel}
            customLogoSrc={mainMenuLogo}
            theme={isDarkMode ? MenuBarTheme.dark : MenuBarTheme.light}
          >
            <ac-flex
              slot={MenuBarSlot.pageName}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <BreadcrumbsButton
                id="menu-page-name"
                type={BreadcrumbsButtonType.items}
                menuItems={menuItems}
                subItems={currentMenuElement?.items}
                content={pageName || defaultDocumentTitle}
                showIcon={false}
                isMenuHeader
              />
            </ac-flex>
            <ac-flex alignItems={AlignItems.center} slot={MenuBarSlot.content}>
              <ContextManager />

              {isCashierVisible() && <Cashier />}
              {isWorkstationVisible && <WorkstationMenu />}
              {isBellVisible() && <NotificationBellContainer />}
              <UserDocumentationButton />
              {LoginService.isLoggedIn() &&
                userService.getCurrentUserDetails() && <User />}
            </ac-flex>
            <ac-flex
              slot={MenuBarSlot.breadcrumbs}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <Breadcrumbs
                currentModule={currentMenuElement}
                buttonSelectOptions={contextMenuItems?.buttonSelectOptions}
                currentButtonOption={contextMenuItems?.currentButtonOption}
                pageTitle={pageName}
                menuItems={menuItems}
                selectedItem={currentMenuItem}
              />
            </ac-flex>
          </ac-menu-bar>
        )}

        {!isMenuV2EnabledCached && (
          <>
            <Logo />
            <ac-box class="menu-bar-wrapper">
              <ac-flex
                alignItems={AlignItems.center}
                dynamicClass={menuBarClasses}
                justifyContent={
                  isError403IpWhitlelistPage()
                    ? JustifyContent.flexEnd
                    : JustifyContent.normal
                }
                direction={
                  isError403IpWhitlelistPage()
                    ? FlexDirection.row
                    : FlexDirection.column
                }
              >
                <MenuContent
                  isRtl={isRtl}
                  menuItems={menuItems}
                  onButtonHandleSelectRoute={onButtonHandleSelectRoute}
                  onScrollDown={onScrollDown}
                  onScrollUp={onScrollUp}
                  scrollDownDisabled={scrollDownDisabled}
                  scrollUpDisabled={scrollUpDisabled}
                  selectedItem={selectedItem}
                  selectedRoute={selectedRoute}
                  buttonSelectOptions={contextMenuItems?.buttonSelectOptions}
                  currentButtonContextOption={
                    contextMenuItems?.currentButtonOption
                  }
                />
              </ac-flex>
            </ac-box>
          </>
        )}
      </ac-flex>
      {isNonProduction && isMenuV2EnabledCached && (
        <div className="container-banner">
          <TrainingBanner />
        </div>
      )}
    </ac-flex>
  );
};
