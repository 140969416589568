import { userService } from 'frontend-container/components/Menu/components/User/service';
import { startListeningOnSessionEnd } from 'frontend-container/utils/loginService/checkSession';

import { LoginService } from '@ac/library-utils/dist/services';

const URL_TO_REDIRECT_KEY = '@ac/url to redirect';
const storeURLDuringLogout = (urlToRedirect: string): void => {
  sessionStorage.setItem(URL_TO_REDIRECT_KEY, urlToRedirect);
};
const popStoredURL = (): string | null => {
  const storedURL = sessionStorage.getItem(URL_TO_REDIRECT_KEY);
  sessionStorage.removeItem(URL_TO_REDIRECT_KEY);

  return storedURL;
};

const onSessionEndCallback = (): void => {
  storeURLDuringLogout(`${window.location.pathname}${window.location.search}`);
  userService.logout();
};

const onLogoutDoItOnAllTabs = (): void => {
  startListeningOnSessionEnd(
    LoginService.getSessionState() ?? '',
    onSessionEndCallback
  );
};

export { onLogoutDoItOnAllTabs, popStoredURL, storeURLDuringLogout };
