import { extractFileUrlsFromDynamicImports } from '@ac/library-utils/dist/utils/micro-frontends/loading/page';

export const getScriptUrl = (pageString: string): string => {
  const scriptUrls = extractFileUrlsFromDynamicImports(pageString);

  if (scriptUrls.length !== 1) {
    throw new Error(
      `Page should expose only one script. Found: ${scriptUrls.join(', ')}`
    );
  }

  return scriptUrls[0];
};
