import { FeatureToggleInfo } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

import {
  ConfigBuilder,
  PROPERTY_ID_HEADER,
  SystemMaintenanceFeatureToggleDto,
  TENANT_ID_HEADER,
} from '@ac/library-api';
import { FeatureTogglesApi } from '@ac/library-api/dist/api/v0/systemMaintenance';
import { removeDuplicates } from '@ac/library-utils/dist/utils';
import { StringComparer } from '@ac/library-utils/dist/utils/array/sort';
import { getBusinessContextContainer } from '@ac/react-infrastructure';

interface Unit {
  tenantId: string | null;
  propertyId: string | null;
}

interface AllUnitsFeatureToggles {
  system: SystemMaintenanceFeatureToggleDto[];
  tenant: SystemMaintenanceFeatureToggleDto[];
  property: SystemMaintenanceFeatureToggleDto[];
}

const getUniqueKey = (item: SystemMaintenanceFeatureToggleDto): string =>
  item.id ?? item.featureName;

const isFeatureEnabled = (
  collection: SystemMaintenanceFeatureToggleDto[],
  key: string
): boolean =>
  collection.find((item) => getUniqueKey(item) === key)?.enabled ?? false;

const fetchFeatureTogglesForUnit = async (
  unit: Unit
): Promise<SystemMaintenanceFeatureToggleDto[]> => {
  const config = new ConfigBuilder()
    .setHeaders({
      [TENANT_ID_HEADER]: unit.tenantId,
      [PROPERTY_ID_HEADER]: unit.propertyId,
    })
    .setSkipCache(true)
    .getConfig();

  const result = await FeatureTogglesApi.getEffectiveFeatureToggles({
    customConfig: config,
  });

  return result.data;
};

const fetchFeatureTogglesForAllUnits =
  async (): Promise<AllUnitsFeatureToggles> => {
    const context = getBusinessContextContainer()?.context;
    const tenantId = context?.customer?.details.id;
    const propertyId = context?.property?.details.id;

    const systemUnit: Unit = {
      tenantId: null,
      propertyId: null,
    };

    const tenantUnit: Unit | undefined = tenantId
      ? {
          tenantId,
          propertyId: null,
        }
      : undefined;

    const propertyUnit: Unit | undefined =
      tenantId && propertyId
        ? {
            tenantId,
            propertyId,
          }
        : undefined;

    const [system, tenant, property] = await Promise.all([
      fetchFeatureTogglesForUnit(systemUnit),
      tenantUnit ? fetchFeatureTogglesForUnit(tenantUnit) : [],
      propertyUnit ? fetchFeatureTogglesForUnit(propertyUnit) : [],
    ]);

    return {
      system,
      tenant,
      property,
    };
  };

export const fetchFeatureTogglesDefinitions = async (): Promise<
  FeatureToggleInfo[]
> => {
  const { system, tenant, property } = await fetchFeatureTogglesForAllUnits();

  const uniqueKeys = removeDuplicates(
    [...system, ...tenant, ...property].map((item) => ({
      key: getUniqueKey(item),
      featureName: item.featureName,
    })),
    'key'
  );

  return uniqueKeys
    .sort((first, second) =>
      StringComparer.ascending(first.featureName, second.featureName)
    )
    .map(({ key, featureName }) => {
      const isSystemEnabled = isFeatureEnabled(system, key);
      const isTenantEnabled = isFeatureEnabled(tenant, key);
      const isPropertyEnabled = isFeatureEnabled(property, key);

      return {
        featureName,
        isEnabled: isSystemEnabled || isTenantEnabled || isPropertyEnabled,
        availability: {
          system: isSystemEnabled,
          tenant: isTenantEnabled,
          property: isPropertyEnabled,
        },
      };
    });
};
