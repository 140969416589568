import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  currentIndex,
  setCurrentIndex,
} from 'frontend-container/components/Menu/utils/menuScroll/currentIndex';
import { isElementVisible } from 'frontend-container/components/Menu/utils/menuScroll/isElementVisible';
import { scrollToMenuItem } from 'frontend-container/components/Menu/utils/menuScroll/scrollToSelectedMenuItem';

import { repeatableCall } from '@ac/library-utils/dist/utils';

export const adjustScrollToCurrentMenuItem = async (
  id: string,
  menuElements: MenuElement[] = [],
  callback: (scrollOptions?: ScrollToOptions) => void
): Promise<void> => {
  await repeatableCall(
    () => new Promise((resolve) => resolve(true)),
    () => !!document.querySelector(`[data-test-selector=${id}].hydrated`),
    {
      intervalTime: 500,
      repeatCount: 50,
    }
  );

  const foundIndex: number = menuElements.findIndex((item) => item.id === id);
  const selectedMenuItemId = menuElements[foundIndex]?.id;

  if (!selectedMenuItemId) {
    return;
  }

  setCurrentIndex(foundIndex);
  scrollToMenuItem({
    id: selectedMenuItemId,
    enforce: false,
    center: true,
    callback,
  });
};

export const getNextScrollIndex = (
  forward: boolean,
  isRtl: boolean
): number => {
  const container = document.querySelector('.items-container');
  if (!container) {
    return currentIndex;
  }

  const menuItems = Array.prototype.slice.call(
    document.querySelectorAll('.container-menu-item'),
    0
  ) as Element[];
  if (menuItems.length === 0) {
    return currentIndex;
  }

  const containerRect = container.getBoundingClientRect();
  const availableSpace = containerRect.width;

  let nextIndex = currentIndex;

  // get the latest visible element
  for (
    let i = currentIndex;
    forward ? i < menuItems.length : i >= 0;
    forward ? i++ : i--
  ) {
    const elementRect = menuItems[i].getBoundingClientRect();
    if (i === currentIndex || isElementVisible(containerRect, elementRect)) {
      nextIndex = i;
    } else {
      break;
    }
  }

  // the latest element might be the new index
  if (isRtl && !forward) {
    return nextIndex - 1;
  } else if (!isRtl && forward) {
    return nextIndex + 1;
  }

  // find out how many elements fit the container
  for (
    let sum = 0, i = nextIndex;
    forward ? i < menuItems.length : i >= 0;
    forward ? i++ : i--
  ) {
    sum += menuItems[i].getBoundingClientRect().width;
    if (sum >= availableSpace) {
      break;
    }
    nextIndex = isRtl ? nextIndex + 1 : nextIndex - 1;
  }

  return nextIndex;
};
