import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';

export const getCurrentGlobalEventBus = (): IEventBus => {
  const eventBus = window.ACP?.communication?.eventBus;

  if (!eventBus) {
    throw new Error('Global ACP communication object has not been defined yet');
  }

  return eventBus;
};
