import { createContext } from 'react';

import {
  PageSectionPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export const InternetSpeedTestSectionPresenterContext = createContext<
  PageSectionPresenterStore | undefined
>(undefined);

export const useInterneSpeedSectionPresenterContext =
  (): PageSectionPresenterStore =>
    useDefinedContext(InternetSpeedTestSectionPresenterContext);
