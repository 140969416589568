import { useTranslation } from 'react-i18next';
import { FrontendModuleInfotipContent } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/FrontendModuleInfotipContent';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';

import { useSharedInfotipPresenter } from '@ac/react-infrastructure';
import {
  IconName,
  OpeningBehavior,
  Orientation,
  TargetValueObject,
  TextSize,
  TextWeight,
} from '@ac/web-components';

export const MicroservicesVersionsList = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { state } = useMaintenancePanelContext();
  const { createShowHandler } = useSharedInfotipPresenter(
    FrontendModuleInfotipContent,
    {
      targetValue: TargetValueObject.sidePanel,
    }
  );

  if (!state.microservicesVersions) {
    return null;
  }

  const {
    backendVersions,
    frontendVersions,
    frontendLibraryVersions,
    fiscalVersion,
  } = state.microservicesVersions;

  const orientation = Orientation.vertical;

  const fiscalVersionStatus = {
    hasNoProperty: !fiscalVersion,
    hasError: Boolean(fiscalVersion?.error),
    hasVersion: Boolean(fiscalVersion?.version),
    hasNoVersion:
      Boolean(fiscalVersion) &&
      !fiscalVersion?.error &&
      !fiscalVersion?.version,
  };

  return (
    <ac-flex class="ac-spacing-top-md">
      <ac-box>
        <ac-paragraph>
          <ac-text
            size={TextSize.lg}
            weight={TextWeight.semibold}
            class="ac-spacing-bottom-sm"
          >
            {t('MAINTENANCE_PANEL.MICROSERVICES.BACKEND_VERSIONS')}
          </ac-text>
        </ac-paragraph>
        {backendVersions.map(([name, version]) => (
          <ac-paragraph key={name} class="be-version">
            <ac-text-group
              label={name}
              orientation={orientation}
              class="break-word"
            >
              {version}
            </ac-text-group>
          </ac-paragraph>
        ))}
      </ac-box>
      <ac-box>
        <ac-box data-test-selector="frontend-versions">
          <ac-paragraph>
            <ac-text
              size={TextSize.lg}
              weight={TextWeight.semibold}
              class="ac-spacing-bottom-sm"
            >
              {t('MAINTENANCE_PANEL.MICROSERVICES.FRONTEND_VERSIONS')}
            </ac-text>
          </ac-paragraph>
          {frontendVersions.map((feModule) => (
            <ac-paragraph key={feModule.name} class="fe-version">
              <ac-text-group
                label={feModule.name}
                orientation={orientation}
                class="break-word"
              >
                {feModule.version}

                {frontendLibraryVersions[feModule.name]?.length && (
                  <ac-icon
                    icon={IconName.information}
                    id={`libraries-versions-icon-${feModule.name}`}
                    class="ac-spacing-left-sm pointer"
                    data-test-selector={`libraries-versions-icon-${feModule.name}`}
                    onMouseEnter={createShowHandler(
                      {
                        module: feModule,
                        libraries: frontendLibraryVersions[feModule.name],
                      },
                      { openingBehavior: OpeningBehavior.onMouseOver }
                    )}
                  />
                )}
              </ac-text-group>
            </ac-paragraph>
          ))}
        </ac-box>
        <ac-box data-test-selector="fiscal-versions">
          <ac-paragraph>
            <ac-text
              size={TextSize.lg}
              weight={TextWeight.semibold}
              class="ac-spacing-bottom-sm"
            >
              {t('MAINTENANCE_PANEL.MICROSERVICES.FISCAL_VERSIONS')}
            </ac-text>
          </ac-paragraph>
          <ac-paragraph class="fiscal-version">
            <ac-text-group
              label={t('MAINTENANCE_PANEL.MICROSERVICES.SFE_VERSION_LABEL')}
              orientation={orientation}
              class="break-word"
            >
              {fiscalVersionStatus.hasVersion &&
                t('MAINTENANCE_PANEL.MICROSERVICES.SFE_VERSION', {
                  propertyName: fiscalVersion?.propertyName,
                  version: fiscalVersion?.version,
                })}
              {fiscalVersionStatus.hasNoVersion &&
                t('MAINTENANCE_PANEL.MICROSERVICES.SFE_NO_VERSION', {
                  propertyName: fiscalVersion?.propertyName,
                })}
              {fiscalVersionStatus.hasError &&
                t('MAINTENANCE_PANEL.MICROSERVICES.SFE_ERROR', {
                  propertyName: fiscalVersion?.propertyName,
                })}
              {fiscalVersionStatus.hasNoProperty &&
                t('MAINTENANCE_PANEL.MICROSERVICES.SFE_NO_PROPERTY')}
            </ac-text-group>
          </ac-paragraph>
        </ac-box>
      </ac-box>
    </ac-flex>
  );
};
