import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2Module } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isPropertyDependentInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isMeetingsAndEventsRatesPathname } from 'frontend-container/components/Menu/utils/modules/meetingsAndEvents';
import { isRateManagerModule } from 'frontend-container/components/Menu/utils/modules/rateManager';

export const isModuleEditableInGlobalRegionOnly = (newLink?: string): boolean =>
  isConfigurationModule(newLink) ||
  isConfigurationv2Module(newLink) ||
  isRateManagerModule(newLink) ||
  isPropertyDependentInventoryModule(newLink) ||
  isMeetingsAndEventsRatesPathname(newLink);
