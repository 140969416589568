export type Resource<T = unknown> =
  | PromiseSettledResult<T>
  | { status: 'pending' }
  | { status: 'initial' };

export const getResourceValue = <T extends unknown[]>(
  resource: Resource<T>,
  fallbackValue: T
): T => {
  return resource.status === 'fulfilled' ? resource.value : fallbackValue;
};
