import {
  handleEmptyModuleUrl,
  handleErrorLoadingModule,
  injectGlobalMount,
} from 'frontend-container/utils/externalResources/shared';

import { loadEsmApp as loadEsmModule } from '@ac/library-utils/dist/utils/micro-frontends/loading/page/esm';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

export const loadEsmApp = async (
  appUrl: string,
  appName: string
): Promise<AppToRender<unknown>> => {
  if (!appUrl) {
    handleEmptyModuleUrl(appName);

    return {
      bootstrap: [],
      mount: [],
      unmount: [],
    };
  }

  try {
    const acModule = await loadEsmModule(appUrl);
    const exportedApp = await acModule.getIndexInContainer();

    return injectGlobalMount(exportedApp);
  } catch (error) {
    handleErrorLoadingModule(error as unknown, appName);
  }

  return {
    bootstrap: [],
    mount: [],
    unmount: [],
  };
};
