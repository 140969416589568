import { createContext } from 'react';

import {
  PanelPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export type MaintenancePanelPresenterStore = PanelPresenterStore<void, void>;

export const MaintenancePanelPresenterContext = createContext<
  MaintenancePanelPresenterStore | undefined
>(undefined);

export const useMaintenancePanelPresenterContext =
  (): MaintenancePanelPresenterStore =>
    useDefinedContext(MaintenancePanelPresenterContext);
