import {
  BusinessContextCacheReader,
  BusinessContextIndexedDBCacheReader,
} from 'frontend-container/shared/businessContext/provider/cache/reader';
import {
  BusinessContextCacheWriter,
  BusinessContextIndexedDBCacheWriter,
} from 'frontend-container/shared/businessContext/provider/cache/writer';

export interface BusinessContextCache {
  reader: BusinessContextCacheReader;
  writer: BusinessContextCacheWriter;
}

export class BusinessContextIndexedDBCache implements BusinessContextCache {
  public readonly reader: BusinessContextCacheReader;
  public readonly writer: BusinessContextCacheWriter;

  constructor() {
    this.reader = new BusinessContextIndexedDBCacheReader();
    this.writer = new BusinessContextIndexedDBCacheWriter();
  }
}
