import { EffectiveSettingDetails, GeneralSettingCode } from '@ac/library-api';

export const SUCCESS_VARIABLE_NAME = '--notification-time-success';
export const WARNING_VARIABLE_NAME = '--notification-time-warning';
export const ERROR_VARIABLE_NAME = '--notification-time-error';
const DEFAULT_SUCCESS_TIME = 3750;
const DEFAULT_WARNING_TIME = 5750;
const DEFAULT_ERROR_TIME = 5750;

export interface SettingValue {
  value: number;
}

const setGlobalNotificationTimeCSSVariable = (
  variableName: string,
  autoCloseTimeout: number
): void => {
  document.body.style.setProperty(
    variableName,
    `${autoCloseTimeout / 1000 + 0.75}s`
  );
};

export const setupToastNotificationsTimeouts = ({
  propertySettings,
  customerSettings,
}: {
  propertySettings: EffectiveSettingDetails[];
  customerSettings: EffectiveSettingDetails[];
}): void => {
  const successTimeout = findSettingValue({
    settingName: GeneralSettingCode.SuccessDisplayTime,
    propertySettings,
    customerSettings,
  });
  const warningTimeout = findSettingValue({
    settingName: GeneralSettingCode.WarningDisplayTime,
    propertySettings,
    customerSettings,
  });
  const errorTimeout = findSettingValue({
    settingName: GeneralSettingCode.ErrorDisplayTime,
    propertySettings,
    customerSettings,
  });
  setGlobalNotificationTimeCSSVariable(
    WARNING_VARIABLE_NAME,
    warningTimeout ?? DEFAULT_WARNING_TIME
  );
  setGlobalNotificationTimeCSSVariable(
    ERROR_VARIABLE_NAME,
    errorTimeout ?? DEFAULT_ERROR_TIME
  );
  setGlobalNotificationTimeCSSVariable(
    SUCCESS_VARIABLE_NAME,
    successTimeout ?? DEFAULT_SUCCESS_TIME
  );
};

const findSettingValue = ({
  settingName,
  propertySettings,
  customerSettings,
}: {
  settingName: string;
  propertySettings: EffectiveSettingDetails[];
  customerSettings: EffectiveSettingDetails[];
}): number | undefined => {
  const settingValueObject =
    propertySettings.find((item) => item.code === settingName) ??
    customerSettings.find((item) => item.code === settingName);

  const settingValue = settingValueObject?.value
    ? (settingValueObject as EffectiveSettingDetails<SettingValue>).value?.value
    : undefined;

  return settingValue ? settingValue * 1000 : undefined;
};
