import { Error as ErrorComponent } from 'frontend-container/components/Errors/Error';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { logger } from 'frontend-container/utils/logger/logger';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

import {
  BusinessContextUnitIdentifier,
  NoUnitAssignmentError,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { Errors } from '@ac/web-components';

export const setBusinessContextForApp = async (): Promise<void> => {
  if (isError403IpWhitlelistPage()) {
    return;
  }

  const requiredUnitId = getUnitForCurrentLocation();
  const { tenantId, propertyId, centralReservationOfficeId, profileCenterId } =
    requiredUnitId;

  const isIdMissing =
    !tenantId && !propertyId && !centralReservationOfficeId && !profileCenterId;

  if (isIdMissing && !LoginService.isSuperUser()) {
    navigateToPage(pathname403);

    return;
  }

  await selectUnitInBusinessContext(
    requiredUnitId as BusinessContextUnitIdentifier
  );
};

export const setSafeBusinessContextForApp = async (props: {
  name: string;
}): Promise<void> => {
  try {
    await setBusinessContextForApp();
  } catch (error) {
    removeReactRoot(ROUTER_OUTLET_SELECTOR);
    const root = createReactRoot(ROUTER_OUTLET_SELECTOR);

    const message: string =
      error instanceof Error ? error.message : String(error);
    const errorLog = `error on a business context setup for the ${props.name} module: ${message}`;
    const errorType =
      error instanceof NoUnitAssignmentError
        ? Errors.error403
        : Errors.error500;

    logger?.error(errorLog);

    root.render(<ErrorComponent errorType={errorType} />);
  }
};
