import ReactDOM from 'react-dom/client';
import { pathnamePrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress/config';
import { PrestepPermissionsInProgressContainer } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress/PermissionsInProgressContainer';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { createReactRoot } from 'frontend-container/utils/createReactRoot';
import { registerApplication } from 'single-spa';

export const isPrestepPermissionsInProgress = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(pathnamePrestepPermissionsInProgress);
};

const prestepPermissionsInProgressAppName = 'prestep-permissions-in-progress';

export const registerPrestepPermissionsInProgress = (): void => {
  let root: ReactDOM.Root | undefined;

  registerApplication(
    prestepPermissionsInProgressAppName,
    {
      bootstrap: () => Promise.resolve(),
      mount: async () => {
        root = createReactRoot(ROUTER_OUTLET_SELECTOR);
        root.render(<PrestepPermissionsInProgressContainer />);
      },
      unmount: async () => {
        root?.unmount();
      },
    },
    (location: Location) => isPrestepPermissionsInProgress(location.pathname)
  );
};
