import {
  FeatureToggleInfo,
  FeatureTogglesSortValue,
} from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

import { DatagridSortDirection } from '@ac/web-components';

export const revertSortValue = (
  current: FeatureTogglesSortValue,
  sortField: keyof FeatureToggleInfo
): FeatureTogglesSortValue => {
  if (current.name !== sortField) {
    return {
      name: sortField,
      direction: DatagridSortDirection.ascending,
    };
  }

  return {
    name: sortField,
    direction:
      current.direction === DatagridSortDirection.ascending
        ? DatagridSortDirection.descending
        : DatagridSortDirection.ascending,
  };
};
