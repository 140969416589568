import {
  AlignItems,
  ButtonPattern,
  ButtonTheme,
  DividerDirection,
  FlexDirection,
  IconName,
  JustifyContent,
  Size,
} from '@ac/web-components';

interface Props {
  onScrollUp: () => void;
  onScrollDown: () => void;
  scrollUpDisabled?: boolean;
  scrollDownDisabled?: boolean;
  isRtl: boolean;
}

export const Arrows = ({
  scrollUpDisabled,
  scrollDownDisabled,
  onScrollDown,
  onScrollUp,
  isRtl,
}: Props): JSX.Element => {
  let scrollUpHandler = onScrollUp;
  let scrollDownHandler = onScrollDown;

  if (isRtl) {
    [scrollUpHandler, scrollDownHandler] = [scrollDownHandler, scrollUpHandler];
  }

  return (
    <ac-flex
      alignItems={AlignItems.center}
      justifyContent={JustifyContent.center}
      direction={FlexDirection.row}
      class="force-direction-ltr ac-gap-s"
    >
      <ac-divider class="bg-gray2" direction={DividerDirection.vertical} />
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={scrollUpHandler}
        disabled={scrollUpDisabled}
      >
        <ac-icon icon={IconName.arrowLeft} size={Size.lg} />
      </ac-button>
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={scrollDownHandler}
        disabled={scrollDownDisabled}
      >
        <ac-icon icon={IconName.arrowRight} size={Size.lg} />
      </ac-button>
      <ac-divider class="bg-gray2" direction={DividerDirection.vertical} />
    </ac-flex>
  );
};
