import { notifyAboutBusinessContextChange } from 'frontend-container/shared/businessContext/events/notifyAboutChange';
import { formatError } from 'frontend-container/utils/errors/formatError';
import { logger } from 'frontend-container/utils/logger/logger';

import {
  BaseApi,
  CurrentDate,
  isPropertyBusinessContextData,
} from '@ac/library-api';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';

export const updatePropertyBusinessDate = async (
  newBusinessDate?: string
): Promise<boolean> => {
  const container = window.ACP?.businessContext;

  if (!container || !isPropertyBusinessContextData(container.context)) {
    throw new Error(
      'Cannot mutate business context without property context defined in the context container'
    );
  }

  const currentBusinessDate = container.context.property.businessDate;
  const canFetchBusinessDate = Boolean(currentBusinessDate); // draft property has no businessDate ACPR-92856
  const nextBusinessDate =
    newBusinessDate || (canFetchBusinessDate ? await fetchBusinessDate() : '');

  if (currentBusinessDate !== nextBusinessDate) {
    container.context.property.businessDate = nextBusinessDate;
    notifyAboutBusinessContextChange();

    return true;
  }

  return false;
};

const fetchBusinessDate = async (): Promise<string> => {
  BaseApi.clearCache(new RegExp(CurrentDateApi.ApiCacheKeyCurrentDate));
  try {
    const currentDate = (await CurrentDateApi.getCurrentDate()) as CurrentDate;

    return currentDate.businessDate;
  } catch (e) {
    logger?.error(formatError(e));

    return '';
  }
};
