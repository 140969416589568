import { acConfig } from '@ac/library-utils/dist/declarations';

export const isMeetingsAndEventsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.meetingsAndEvents ?? '');
};

export const isMeetingsAndEventsRatesPathname = (
  pathname: string = window.location.pathname
): boolean => {
  const paths = ['function-space-rate-manager', 'packages', 'package'];

  return paths.some((path) =>
    pathname?.startsWith(
      `${acConfig.newFrontendUrls.meetingsAndEvents ?? ''}/${path}`
    )
  );
};

export default {
  isMeetingsAndEventsModule,
};
