import { useTranslation } from 'react-i18next';
import { mapMaintenanceDataToJsonContentString } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/mapToJsonContent/mapMaintenanceDataToJsonContent';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';

import { DateUtils } from '@ac/library-utils/dist/utils';
import { useContainerToast } from '@ac/react-infrastructure';

type CommandResult = () => Promise<void>;

const executeJsonDownload = async (
  fileName: string,
  jsonContent: string
): Promise<void> => {
  const blob = new Blob([jsonContent], {
    type: 'application/json',
  });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  await new Promise((resolve) => setTimeout(resolve, 1000));

  document.body.removeChild(link);
};

export const useDownloadJson = (): CommandResult => {
  const {
    state: { environment, microservicesVersions, contextReport },
  } = useMaintenancePanelContext();
  const { state: internetSpeedTestState } = useInternetSpeedTestContext();
  const { t } = useTranslation();
  const { showSuccess, showError } = useContainerToast();

  return async () => {
    if (!environment || !microservicesVersions) {
      return showError(t('MAINTENANCE_PANEL.COPY_TO_CLIPBOARD_FAILED'));
    }

    const dateTime = DateUtils.serialization.format(
      new Date(),
      'DD-MM-YYYY hh:mm:ss'
    );

    const fileName = `${location.hostname} - ${dateTime}.json`;

    const content = mapMaintenanceDataToJsonContentString({
      environment,
      microservicesVersions,
      contextReport,
      dateTime,
      internetSpeedTest: internetSpeedTestState,
    });

    try {
      await executeJsonDownload(fileName, content);

      showSuccess(t('MAINTENANCE_PANEL.DOWNLOAD_FILE_SUCCESS', { fileName }));
    } catch {
      showError(t('MAINTENANCE_PANEL.COPY_TO_CLIPBOARD_FAILED'));
    }
  };
};
