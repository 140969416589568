import { acConfig } from '@ac/library-utils/dist/declarations';

export const defaultActivityReservationsPathName = '/activity-reservations';

export const isActivityReservationsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    !!acConfig.newFrontendUrls.activityReservations &&
    pathname?.startsWith(acConfig.newFrontendUrls.activityReservations)
  );
};

export default {
  isActivityReservationsModule,
};
