import React from 'react';
import { formatDate } from 'frontend-container/utils/dateUtils';

import { TextColor, TextSize } from '@ac/web-components';

interface Props {
  businessDate: string;
  color?: TextColor;
  dateFormat?: string;
  propertyTime: string;
}

export const BusinessDate = ({
  businessDate,
  color,
  dateFormat,
  propertyTime,
}: Props): JSX.Element => (
  <ac-text class="no-wrap" color={color} size={TextSize.sm}>
    {`${formatDate(businessDate, 'DD MMM', dateFormat)}`} {propertyTime}
  </ac-text>
);
