import { addRefreshTokenListener } from 'frontend-container/utils/loginService/loginServiceInitializers';

import { LoginService } from '@ac/library-utils/dist/services';

export const handleTokenExpiration = (
  callback: (isLoggedIn: boolean) => void,
  enforceAuthorizationTimeout: number
): (() => void) => {
  let removeRefreshTokenListener: (() => void) | undefined;
  let authorizeTimeoutId: ReturnType<typeof setTimeout> | undefined;

  const removeAuthListeners = (): void => {
    if (removeRefreshTokenListener) {
      removeRefreshTokenListener();
      removeRefreshTokenListener = undefined;
    }

    if (authorizeTimeoutId) {
      clearTimeout(authorizeTimeoutId);
      authorizeTimeoutId = undefined;
    }
  };

  const visibilityChangeHandler = (): void => {
    const isLoggedIn = LoginService.isLoggedIn();

    callback(isLoggedIn);
    removeAuthListeners();

    if (document.hidden || isLoggedIn) {
      return;
    }

    removeRefreshTokenListener = addRefreshTokenListener(() => {
      callback(true);
      removeAuthListeners();
    });

    authorizeTimeoutId = setTimeout(() => {
      LoginService.authorize(location.pathname, true);
    }, enforceAuthorizationTimeout);
  };

  document.addEventListener('visibilitychange', visibilityChangeHandler);

  return () => {
    document.removeEventListener('visibilitychange', visibilityChangeHandler);
    removeAuthListeners();
  };
};
