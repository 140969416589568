import { acConfig } from '@ac/library-utils/dist/declarations';

export const isItineraryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.itinerary ?? '');
};

export default {
  isItineraryModule,
};
