import { NotificationBell } from 'frontend-container/components/Menu/components/NotificationBell/NotificationBell';

import { TenantWebSocketClientsProvider } from '@ac/react-infrastructure';

export const NotificationBellContainer = (): JSX.Element => {
  return (
    <TenantWebSocketClientsProvider>
      <NotificationBell />
    </TenantWebSocketClientsProvider>
  );
};
