import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  ActivityReservationPropertySettingsKeys,
  activityReservationsCustomerPermissionKeys,
  ActivityReservationsCustomerSettingsKeys,
  activityReservationsPropertyPermissionKeys,
  FeatureToggleName,
  ItineraryCustomerSettingsKeys,
  itineraryPropertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const getActivityReservationsMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();
  const customerContextData = getCustomerContextData();

  const propertySettings = propertyContextData
    ? propertyContextData?.settings.effectiveSettingsDetails
    : [];
  const customerSettings = customerContextData
    ? customerContextData?.settings.effectiveSettings
    : [];

  const settings = [...propertySettings, ...customerSettings];

  const hasPrestep = (
    settings.find(
      ({ code }) =>
        code ===
        ActivityReservationPropertySettingsKeys.ActivityReservationsUsePrestep
    )?.value as { value: boolean }
  )?.value;

  return {
    translation: 'MENU.ACTIVITY_RESERVATIONS.TITLE',
    icon: IconName.spa,
    id: 'menu-activity-reservations',
    featureToggles: [FeatureToggleName.ActivityReservations],
    settings: [ActivityReservationsCustomerSettingsKeys.ActivityReservations],
    permissionsConfiguration: {
      permissions: [
        activityReservationsCustomerPermissionKeys.viewActivityReservationModule,
      ],
      permissionsConjunction: PermissionsConjunction.And,
    },
    items: [
      {
        permissionsConfiguration: {
          permissions: [
            activityReservationsCustomerPermissionKeys.viewActivitiesDashboard,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        link: `${acConfig.newFrontendUrls.activityReservations}/dashboard`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.DASHBOARD',
        code: 'ActivitiesDashboard',
        aliases: [
          `${acConfig.newFrontendUrls.activityReservations}/reservation`,
        ],
      },
      {
        permissionsConfiguration: {
          permissions: [
            activityReservationsPropertyPermissionKeys.viewActivitiesPlanner,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        code: 'ActivitiesPlanner',
        link: `${acConfig.newFrontendUrls.activityReservations}/planner`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.PLANNER',
      },
      {
        permissionsConfiguration: {
          permissions: [
            activityReservationsPropertyPermissionKeys.createAndEditActivityReservation,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/activity-reservation`
          : `${acConfig.newFrontendUrls.activityReservations}/reservation/new`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_RESERVATION',
        aliases: [
          `${acConfig.newFrontendUrls.activityReservations}/reservation/new`,
        ],
      },
      {
        permissionsConfiguration: {
          permissions: [
            activityReservationsPropertyPermissionKeys.createAndEditActivityReservation,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/activity-reservations`
          : `${acConfig.newFrontendUrls.activityReservations}/reservations/new`,
        translation:
          'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_MULTI_RESERVATION',
        aliases: [
          `${acConfig.newFrontendUrls.activityReservations}/reservations/new`,
        ],
      },
      {
        permissionsConfiguration: {
          permissions: [
            itineraryPropertyPermissionKeys.createEditRemoveItinerary,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [ItineraryCustomerSettingsKeys.Itinerary],
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/itinerary`
          : `${acConfig.newFrontendUrls.itinerary}/activity-itinerary/new`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_ITINERARY',
        featureToggles: [FeatureToggleName.ActivityReservationsItinerary],
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/activity-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.ACTIVITY_MANAGER',
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/package-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.PACKAGE_MANAGER',
        permissionsConfiguration: {
          permissions: [
            activityReservationsPropertyPermissionKeys.viewPackageManager,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        featureToggles: [FeatureToggleName.ActivityPackages],
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/rate-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.RATE_MANAGER',
        permissionsConfiguration: {
          permissions: [
            activityReservationsPropertyPermissionKeys.viewActivitiesRateManager,
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
    ],
  };
};
