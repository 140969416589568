import { BRANDING_PROPERTY_IDENTIFIER_COLOR } from 'frontend-container/components/Menu/constants';

import { SettingsPropertiesApi } from '@ac/library-api/dist/api/v0/configuration/settings';

type BrandingSettingValue = {
  isDefault: boolean;
  locked: boolean;
  value: {
    value: string;
  };
};

export const fetchPropertyBrandingColor = async (
  propertyId: string
): Promise<string | undefined> => {
  const result = (await SettingsPropertiesApi.getValue({
    pathParams: {
      propertyId,
      code: BRANDING_PROPERTY_IDENTIFIER_COLOR,
    },
  })) as BrandingSettingValue;

  if (result) {
    return result?.value?.value;
  }

  return;
};
