import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessContextGridExtendedRow } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/BusinessContextGridExtendedRow';
import { BusinessContextStoreReport } from 'frontend-container/shared/businessContext/provider/cache/report';

import { useSharedTooltipPresenter } from '@ac/react-infrastructure';
import {
  AlignItems,
  FlexDirection,
  IconName,
  TargetValueObject,
  TextColor,
  TextWeight,
} from '@ac/web-components';

interface CurrentContextElement {
  id: string;
  displayName: string | undefined;
}

export interface BusinessContextGridRowProps {
  icon: IconName;
  nameKey: string;
  current: CurrentContextElement | undefined;
  store: BusinessContextStoreReport;
  entitiesLimit: number;
}

export const BusinessContextGridRow = ({
  icon,
  nameKey,
  current,
  store,
  entitiesLimit,
}: BusinessContextGridRowProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { createShowHandler } = useSharedTooltipPresenter({
    targetValue: TargetValueObject.sidePanel,
  });

  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  return (
    <ac-datagrid-row>
      <ac-datagrid-cell>
        <ac-flex
          direction={FlexDirection.row}
          alignItems={AlignItems.center}
          class="ac-gap-md"
        >
          <ac-icon icon={icon} />
          <ac-text>
            {t(`MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.${nameKey}`)}
          </ac-text>
        </ac-flex>
      </ac-datagrid-cell>

      <ac-datagrid-cell>
        {current && (
          <ac-flex direction={FlexDirection.column} class="pointer">
            <ac-text
              color={TextColor.primaryDark}
              weight={TextWeight.semibold}
              id={`current-context-value-${nameKey}`}
              onMouseEnter={createShowHandler({ text: current.id })}
            >
              {current.displayName}
            </ac-text>
          </ac-flex>
        )}
      </ac-datagrid-cell>

      <ac-datagrid-cell>
        {store.totalCount ? (
          <ac-framed-badge
            slot="value"
            titleContent={t(
              'MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.CACHED_ITEMS'
            )}
            content={store.totalCount}
            color="#975587"
          />
        ) : null}
      </ac-datagrid-cell>

      <ac-datagrid-cell>
        {store.totalCount ? (
          <ac-icon
            onClick={(): void => setCollapsed(!isCollapsed)}
            icon={isCollapsed ? IconName.actionDown : IconName.actionUp}
            class="pointer"
          />
        ) : null}
      </ac-datagrid-cell>

      {!isCollapsed && (
        <BusinessContextGridExtendedRow
          store={store}
          entitiesLimit={entitiesLimit}
        />
      )}
    </ac-datagrid-row>
  );
};
