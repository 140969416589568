import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { loadEsmApp } from 'frontend-container/utils/externalResources/esm';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const floorPlanAppName = 'frontend-floor-plan';
export const registerFloorPlan = (): void => {
  registerApplication(
    floorPlanAppName,
    () => loadEsmApp(acConfig.frontendUrls.floorPlan, floorPlanAppName),
    (location: Location) => isFloorPlanModule(location.pathname)
  );
};
