import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { SessionService } from '@ac/library-utils/dist/services';

export type AnonymizedUserWorkstations = {
  [workstationKey: string]: {
    id: string;
  };
};

export const getAnonymizedUserWorkstations = (): AnonymizedUserWorkstations => {
  const workstations = SessionService.getSelectedWorkstations();
  const userId = getBusinessContextData().user.details.id;
  const userWorkstations = workstations?.[userId];
  const anonymizedUserWorkstations = Object.fromEntries(
    Object.entries(userWorkstations ?? {}).map(
      ([workstationKey, workstation]) => [
        workstationKey,
        {
          id: workstation?.id ?? 'undefined',
        },
      ]
    )
  );

  return anonymizedUserWorkstations;
};
