const controller = new AbortController();

const MAX_WARNING_COUNT = 20;

let warnedCount = 0;

let timer: NodeJS.Timeout | null = null;

const warnIncorrectZoomLevel = (): void => {
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  if (isNaN(browserZoomLevel)) {
    return;
  }

  if (browserZoomLevel !== 100) {
    warnedCount++;

    const message = `%cThe browser is currently zoomed at ${browserZoomLevel}%. This can break the UI resulting in some visual glitching. Please reset the zoom via browser menus or use "ctrl/cmd + 0" to reset the zoom.`;
    const style = 'border:2px solid; padding:12px;';
    // eslint-disable-next-line no-console
    console.warn(message, style);
  }
};

const warnIncorrectZoomLevelDebounced = (): void => {
  if (timer) {
    clearTimeout(timer);
  }

  if (warnedCount < MAX_WARNING_COUNT) {
    timer = setTimeout(warnIncorrectZoomLevel, 350);
  } else {
    controller.abort();
  }
};

export const initBrowserZoomWarning = (): void => {
  warnIncorrectZoomLevelDebounced();
  window.addEventListener('resize', warnIncorrectZoomLevelDebounced, {
    signal: controller.signal,
  });
};
