import { memo } from 'react';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { CommunicationDetail } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/CommunicationDetail';
import { getConfigurationEntityDescription } from 'frontend-container/utils/getConfigurationEntityDescription';

import { CommunicationMode, getInitials } from '@ac/library-api';
import { AlignItems, IconName, TextSize } from '@ac/web-components';

export const UserContact = memo((): JSX.Element => {
  const fullUser = userService.getFullCurrentUser();

  const initials = getInitials(
    fullUser.userDetails.personalData?.firstName ?? '',
    fullUser.userDetails.personalData?.lastName ?? ''
  );

  const primaryChannels = fullUser.userDetails.communicationChannels.filter(
    (channel) => channel.isPrimary
  );
  const phone = primaryChannels.find(
    (channel) => channel.modeCode === CommunicationMode.Phone
  )?.details;
  const mobile = primaryChannels.find(
    (channel) => channel.modeCode === CommunicationMode.Mobile
  )?.details;
  const email = primaryChannels.find(
    (channel) => channel.modeCode === CommunicationMode.Email
  )?.details;
  const fax = primaryChannels.find(
    (channel) => channel.modeCode === CommunicationMode.Fax
  )?.details;
  const website = primaryChannels.find(
    (channel) => channel.modeCode === CommunicationMode.Website
  )?.details;

  const descriptionParts: string[] = [];
  if (fullUser.department?.descriptionMap) {
    descriptionParts.push(
      getConfigurationEntityDescription(fullUser.department?.descriptionMap)
    );
  }
  if (fullUser.jobTitle?.descriptionMap) {
    descriptionParts.push(
      getConfigurationEntityDescription(fullUser.jobTitle?.descriptionMap)
    );
  }
  const departmentJobTitleLabel = descriptionParts.join(', ');

  return (
    <ac-flex>
      <ac-box sizeSm="auto" class="ac-spacing-right-lg">
        <ac-system-user>{initials}</ac-system-user>
      </ac-box>
      <ac-box
        class={departmentJobTitleLabel ? 'ac-spacing-bottom-lg' : undefined}
      >
        <ac-text size={TextSize.h2}>{fullUser.userDetails.userName}</ac-text>
        <ac-flex
          form={true}
          wrapped={true}
          alignItems={AlignItems.center}
          class="ac-spacing-vertical-md"
        >
          <CommunicationDetail detail={phone} iconName={IconName.telephone} />
          <CommunicationDetail detail={mobile} iconName={IconName.mobile} />
          <CommunicationDetail detail={fax} iconName={IconName.fax} />
          <CommunicationDetail detail={email} iconName={IconName.email} />
          <CommunicationDetail detail={website} iconName={IconName.www} />
        </ac-flex>
        <ac-text>{departmentJobTitleLabel}</ac-text>
      </ac-box>
    </ac-flex>
  );
});

UserContact.displayName = 'UserContact';
