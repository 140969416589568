import { useTranslation } from 'react-i18next';
import { BusinessContextFeaturesConfiguration } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/BusinessContextFeaturesConfiguration';
import { useClearBusinessContextCache } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/commands/clearCache';
import { BusinessContextGrid } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/BusinessContextGrid';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';

import { FlexDirection, IconName, JustifyContent } from '@ac/web-components';

export const BusinessContextDetails = (): JSX.Element | null => {
  const {
    state: { contextReport },
  } = useMaintenancePanelContext();
  const clearCache = useClearBusinessContextCache();
  const { t } = useTranslation();

  if (!contextReport) {
    return null;
  }

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-box class="ac-spacing-bottom-md">
        <BusinessContextFeaturesConfiguration />
      </ac-box>

      <ac-box>
        <BusinessContextGrid contextReport={contextReport} />
      </ac-box>

      <ac-box class="ac-spacing-md">
        <ac-flex
          direction={FlexDirection.row}
          justifyContent={JustifyContent.flexEnd}
        >
          <ac-button>
            <ac-button-content
              text={t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.CLEAR_CONTEXT')}
              icon={IconName.delete}
              onClick={clearCache}
            />
          </ac-button>
        </ac-flex>
      </ac-box>
    </ac-flex>
  );
};
