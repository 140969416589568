import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';

export const notifyAboutBusinessContextChange = (): void => {
  const container = window.ACP?.businessContext;
  const context = container?.context;

  if (!context || !container) {
    return;
  }

  businessContextProvider.clearCache();
  getCurrentGlobalEventBus().dispatch(
    globalBusinessContextUpdatedEventCreator(context)
  );
};
