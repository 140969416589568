import { FeatureTogglesSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/presenter/context';

import {
  PageSectionPresenterInitialAppearance,
  PropsWithChildren,
  usePageSectionPresenterStore,
} from '@ac/react-infrastructure';

interface Params {
  initialAppearance: PageSectionPresenterInitialAppearance;
}

export const FeatureTogglesSectionPresenterContextProvider = (
  props: PropsWithChildren<Params>
): JSX.Element => {
  const store = usePageSectionPresenterStore(props.initialAppearance);

  return (
    <FeatureTogglesSectionPresenterContext.Provider value={store}>
      {props.children}
    </FeatureTogglesSectionPresenterContext.Provider>
  );
};
