import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const inquiryMenu: MenuElement = {
  translation: 'MENU.INQUIRY.TITLE',
  icon: IconName.calendarPeriod,
  id: 'menu-inquiry',
  items: [
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/inquiry`,
      code: 'InquiryDashboard',
      translation: 'MENU.INQUIRY.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          propertyPermissionKeys.reservations.dashboard.viewGeneralInquiry,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+i',
      keyboardShortcutMac: '⌥+⌘+i',
      keyCode: 73,
    },
  ],
};
