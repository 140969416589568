import {
  CashierData,
  CashierInfo,
  CashierOpeningOptions,
  CashierPermissions,
} from 'frontend-container/components/Menu/components/Cashier/types';
import { getCurrentPropertyRegionCode } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { fetchAllWorkstations } from 'frontend-container/components/Menu/components/Workstation/service';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  buildFIQLFilter,
  CashierCurrentState,
  CashierDashboardItem,
  CashierListResponse,
  CashierNumber,
  CashierSecretState,
  CashierState,
  combineFilters,
  Config,
  FIQLOperators,
  GenericEntity,
  getCustomConfigWithPropertyRegionSpecificHost,
  LibraryApiResponse,
  PageResponse,
  propertyPermissionKeys,
  RawCashierDashboardItem,
  RawCashierNumber,
} from '@ac/library-api';
import { CashierApi } from '@ac/library-api/dist/api/v0/cashiering';
import { CashierApi as CashierViewsApi } from '@ac/library-api/dist/api/v0/cashiering/views';

const getCustomConfigForCurrentRegion = (): Config | undefined => {
  const currentPropertyRegionCode = getCurrentPropertyRegionCode();

  return {
    ...(currentPropertyRegionCode
      ? getCustomConfigWithPropertyRegionSpecificHost(currentPropertyRegionCode)
      : {}),
  };
};

export const fetchIsCashierOpenState = async (
  cashierNumber: number
): Promise<boolean> => {
  const cashierState = (await CashierApi.getCashierCurrentState({
    queryParams: { cashierNumber },
    customConfig: {
      ...getCustomConfigForCurrentRegion(),
      skipCache: true,
    },
  })) as CashierCurrentState;

  return cashierState.currentState === CashierState.Opened;
};

export const fetchCashierData = async (): Promise<CashierData> => {
  let isCashierOpen = false;

  const cashierNumber =
    (
      (await CashierViewsApi.getCashierNumberForLoggedUser({
        customConfig: {
          ...getCustomConfigForCurrentRegion(),
          skipCache: true,
        },
      })) as CashierNumber
    ).cashierNumber || undefined;

  if (cashierNumber) {
    isCashierOpen = await fetchIsCashierOpenState(cashierNumber);
  }

  return {
    cashierNumber,
    isCashierOpen,
  };
};

export const fetchCashierInfo = async (
  cashierNumber: number
): Promise<CashierInfo> => {
  const { number, description, version } = (
    (await CashierViewsApi.getCashierDashboard({
      queryParams: {
        filter: buildFIQLFilter('number', FIQLOperators.equal, cashierNumber),
      },
      customConfig: {
        ...getCustomConfigForCurrentRegion(),
        skipCache: true,
      },
    })) as PageResponse<RawCashierDashboardItem, CashierDashboardItem>
  ).results[0];

  return {
    cashierId: number,
    description: description ?? '',
    version,
  };
};

export const fetchIsSecretDefinedState = async (): Promise<boolean> => {
  const cashierSecretState = (await CashierApi.getCashierSecretState({
    customConfig: {
      ...getCustomConfigForCurrentRegion(),
      skipCache: true,
    },
  })) as CashierSecretState;

  return cashierSecretState.isSecretDefined;
};

export const fetchCashierOpeningOptions =
  async (): Promise<CashierOpeningOptions> => {
    const cashierResponse = (await CashierApi.getList({
      customConfig: getCustomConfigForCurrentRegion(),
    })) as CashierListResponse;
    const workstationResponse = await fetchWorkstations();

    return {
      cashiers: cashierResponse?.results || [],
      workstations: workstationResponse || [],
    };
  };

export const fetchWorkstations = async (
  value?: string
): Promise<GenericEntity[]> => {
  const workstationResponse = await fetchAllWorkstations({
    queryParams: {
      filter: value
        ? combineFilters(
            [
              buildFIQLFilter('code', FIQLOperators.stringContains, value),
              buildFIQLFilter(
                'description.content',
                FIQLOperators.contains,
                `*${value}*`
              ),
            ],
            FIQLOperators.or
          )
        : undefined,
    },
    customConfig: getCustomConfigForCurrentRegion(),
  });

  return workstationResponse.results || [];
};

export const setCashierSecret = async (secret: string): Promise<void> => {
  await CashierApi.postSetCashierSecret({
    data: { secret },
    customConfig: getCustomConfigForCurrentRegion(),
  });
};

export const openCashier = async (
  cashierSecret: string,
  cashierNumber: number
): Promise<void> => {
  await CashierApi.postRegisterToCashier({
    data: { cashierSecret, cashierNumber },
  });

  const isCashierOpen = await fetchIsCashierOpenState(cashierNumber);

  if (!isCashierOpen) {
    await CashierApi.postOpenCashier({
      data: { cashierNumber },
      customConfig: getCustomConfigForCurrentRegion(),
    });
  }
};

export const closeCashier = async (
  cashierNumber: number,
  version?: number
): Promise<string> => {
  const closeCashierOperation = (await CashierApi.postCloseCashier({
    data: { cashierNumber },
    customConfig: {
      ...getCustomConfigForCurrentRegion(),
      headers: { 'If-Match': version },
    },
    fullResponse: true,
  })) as LibraryApiResponse<RawCashierNumber>;

  return closeCashierOperation.headers.location ?? '';
};

export const getProcessedCashierPermissions = (
  permissionIds?: string[]
): CashierPermissions => {
  const cashierPermissions =
    permissionIds ?? getPropertyContextData()?.permissions.permissionIds ?? [];
  const cashierPermissionKeys = propertyPermissionKeys.cashier;

  return {
    isOpenCashierAllowed: cashierPermissions.includes(
      cashierPermissionKeys.cashierNumberOpen
    ),
    isCloseCashierAllowed: cashierPermissions.includes(
      cashierPermissionKeys.cashierNumberClose
    ),
    isManageExclusiveCashierAllowed: cashierPermissions.includes(
      cashierPermissionKeys.manageExclusiveCashierNumbers
    ),
    isViewCashierStatusDashboard: cashierPermissions.includes(
      cashierPermissionKeys.viewCashierStatusDashboard
    ),
  };
};
