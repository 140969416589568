import { useTranslation } from 'react-i18next';
import { ReadKeyModalTestSelectors } from 'frontend-container/components/Menu/components/Workstation/components/ReadKey/testSelectors';

import { AcSelectOption, TargetValueObject } from '@ac/web-components';

interface WorkstationSelectionStepProps {
  onSelectWorkstation: (workstation: AcSelectOption | undefined) => void;
  selectedWorkstation?: AcSelectOption;
  workstations: AcSelectOption[];
}
export const WorkstationSelectionStep = ({
  onSelectWorkstation,
  workstations,
  selectedWorkstation,
}: WorkstationSelectionStepProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <form>
      <ac-box>
        <ac-select
          id="read-key-modal"
          label={t('MENU.WORKSTATION.WORKSTATION')}
          value={selectedWorkstation?.value}
          optionsArray={workstations}
          onChangeCallback={onSelectWorkstation}
          optionNameField="name"
          optionValueField="value"
          translations={{ placeholder: t('MENU.MODAL.COMMON.SELECT') }}
          targetValue={TargetValueObject.modal}
          data-test-selector={ReadKeyModalTestSelectors.WorkstationSelect}
          hideMessageContainer
          required
        />
      </ac-box>
    </form>
  );
};
