import { MaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';

import {
  PropsWithChildren,
  usePanelPresenterStore,
} from '@ac/react-infrastructure';

export const MaintenancePanelPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = usePanelPresenterStore<void, void>();

  return (
    <MaintenancePanelPresenterContext.Provider value={store}>
      {props.children}
    </MaintenancePanelPresenterContext.Provider>
  );
};
