import { isActivityConfigurationModule } from 'frontend-container/components/Menu/utils/modules/activityConfiguration';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const activityConfigurationAppName = 'activity-configuration';
export const registerActivityConfiguration = (): void => {
  registerApplication(
    activityConfigurationAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.activityConfiguration,
        activityConfigurationAppName
      ),
    (location: Location) => isActivityConfigurationModule(location.pathname)
  );
};
