import { ReloadCurrentBusinessContextDataParameters } from 'frontend-container/publicApi/windowObject';
import { getBusinessContextContainer } from 'frontend-container/shared/businessContext/getBusinessContext';
import { BusinessContextDependencyConfig } from 'frontend-container/shared/businessContext/provider/dependencyConfig';
import { BusinessContextProviderLogger } from 'frontend-container/shared/businessContext/provider/logger/logger';
import { IBusinessContextDataProvider } from 'frontend-container/shared/businessContext/provider/providerWithCache';
import { reloadCurrentBusinessContextData } from 'frontend-container/shared/businessContext/provider/reloadCurrentBusinessContextData';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';

export class BusinessContextDataProviderWithoutCache
  implements IBusinessContextDataProvider
{
  private readonly loggers: BusinessContextProviderLogger[];

  constructor(dependencies: BusinessContextDependencyConfig) {
    this.loggers = dependencies.loggers;
  }

  async getBusinessContext(
    requestedUnit: BusinessContextUnitIdentifier
  ): Promise<SepModuleBusinessContextData> {
    const currentContextData = getBusinessContextContainer()?.context;

    const requiredContext =
      currentContextData &&
      tryAdjustContextToUnitIdentifier(currentContextData, requestedUnit);

    if (requiredContext) {
      this.loggers.forEach((logger) =>
        logger.logKnownContext({ requestedUnit })
      );

      return requiredContext;
    }

    const dataFetchedFromApi = await this.loadMissingData(
      requestedUnit,
      currentContextData
    );

    return dataFetchedFromApi;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearCache(): void {}

  async reloadCurrentBusinessContextData(
    parameters: ReloadCurrentBusinessContextDataParameters
  ): Promise<void> {
    this.clearCache();

    return await reloadCurrentBusinessContextData(parameters);
  }

  private async loadMissingData(
    unit: BusinessContextUnitIdentifier,
    alreadyLoadedBusinessContext?: SepModuleBusinessContextData
  ): Promise<SepModuleBusinessContextData> {
    const loader = new SepModuleBusinessContextLoader();

    return alreadyLoadedBusinessContext
      ? await loader.loadForDifferentScope(alreadyLoadedBusinessContext, unit)
      : await loader.load(unit);
  }
}
