import { acConfig } from '@ac/library-utils/dist/declarations';

export const isReservationsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    pathname?.startsWith(acConfig.newFrontendUrls.reservations ?? '') &&
    !pathname?.startsWith(acConfig.newFrontendUrls.reservationsGroups ?? '')
  );
};

export default {
  isReservationsModule,
};
