import { t } from 'frontend-container/i18n';

import { UserPreferencesDetails } from '@ac/library-api';
import { FormValidationStatuses } from '@ac/react-infrastructure';
import { ErrorType, ValidationStatus } from '@ac/web-components';

export const createFormValidator =
  (notAllowedValues: UserPreferencesDetails) =>
  (
    formValues: UserPreferencesDetails
  ): FormValidationStatuses<UserPreferencesDetails> => ({
    defaultLandingScreen: startingPageValidator(
      notAllowedValues.defaultLandingScreen,
      formValues.defaultLandingScreen
    ),
    defaultCentralReservationOfficeLandingScreen: startingPageValidator(
      notAllowedValues.defaultCentralReservationOfficeLandingScreen,
      formValues.defaultCentralReservationOfficeLandingScreen
    ),
  });

const startingPageValidator = (
  notAllowedStartingPage?: string,
  value?: string
): ValidationStatus | undefined => {
  if (!!notAllowedStartingPage && notAllowedStartingPage === value) {
    return [
      {
        severity: ErrorType.error,
        description: t(
          'MENU.LOGGED_USER.USER_PREFERENCES.STARTING_PAGE.ERRORS.NO_PERMISSION'
        ),
      },
    ];
  }

  return undefined;
};
