import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const groupsPermissions = propertyPermissionKeys.groups;

export const groupsMenu: MenuElement = {
  translation: 'MENU.GROUPS.TITLE',
  icon: IconName.group,
  id: 'menu-groups',
  items: [
    {
      link: `${acConfig.newFrontendUrls.reservationsGroups}/dashboard`,
      code: 'GroupsDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations-groups`,
        `${acConfig.newFrontendUrls.itinerary}/integrated-booking/group`,
      ],
      translation: 'MENU.GROUPS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [groupsPermissions.viewGroup],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+g',
      keyboardShortcutMac: '⌥+⌘+g',
      keyCode: 71,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/details`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations-groups/new/room-grid`,
      ],
      translation: 'MENU.GROUPS.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [groupsPermissions.createGroup],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/details`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations-groups/quick/room-grid`,
      ],
      translation: 'MENU.GROUPS.ITEMS.QUICK',
      permissionsConfiguration: {
        permissions: [groupsPermissions.createQuickBookingGroup],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.reservationsGroups}/availability-dashboard`,
      translation: 'MENU.GROUPS.ITEMS.AVAILABILITY_DASHBOARD',
      permissionsConfiguration: {
        permissions: [groupsPermissions.viewGroup],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
