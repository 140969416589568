import { VersionInfo } from '@ac/library-api';

export interface BackendVersion {
  serviceName: string;
  version: string;
  commitHash: string;
}

export interface AcLibrariesGroup {
  name: string;
  libraries: AcLibrary[];
}

export interface AcLibrary {
  name: string;
  version: string;
}

export enum Services {
  container = 'localhost',
}

export interface FeLibraryVersionObject {
  [frontendName: string]: AcLibrariesGroup[];
}

export interface LibraryVersionList {
  moduleName: string;
  response: AcLibrariesGroup[];
}

export interface FeModuleVersionResponse {
  moduleName?: string;
  response?: VersionInfo;
}

export interface FiscalVersionResponse {
  propertyName?: string;
  error?: unknown;
  version?: string;
}

export interface FeModule {
  name: string;
  version: string;
  buildDate: string;
}

export type OldApiResponse = AcLibrariesGroup[][];

export type LibraryResponse = AcLibrariesGroup[] | OldApiResponse;

export interface FormattedLibraryVersion {
  moduleName?: string;
  response?: VersionInfo[] | LibraryResponse;
}

export interface MicroservicesVersions {
  backendVersions: Array<[string, string]>;
  fiscalVersion?: FiscalVersionResponse;
  frontendVersions: FeModule[];
  frontendLibraryVersions: FeLibraryVersionObject;
}
