import { getBrowserDetails } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/browserDetails';
import { getBrowserGenericVersion } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/browserGenericVersion';
import { fetchIpAddress } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/ipAddress';
import { getAnonymizedUserWorkstations } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/userWorkstations';
import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';

const globalUserAgent = window.navigator.userAgent;

export const fetchUserEnvironmentDetails = async (
  userAgent: string = globalUserAgent
): Promise<EnvironmentDetails> => {
  const getWindowDimensions = (): string => {
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : '';
    const height = hasWindow ? window.innerHeight : '';

    return width.toString().concat('*', height.toString());
  };

  const browserCookieStatus = (): boolean => {
    return navigator.cookieEnabled;
  };

  const getPlatformDetails = (): string => {
    return getPlatformFromUserAgent();
  };

  const getPlatformFromUserAgent = (): string => {
    const platform = userAgent.match(/(Windows NT|CrOS|Mac OS|Linux)/) || [];
    const osName = platform.length ? platform[0] : 'unknown';
    const version = osName !== 'unknown' ? getPlatformVersion(platform[0]) : '';

    return osName.concat(' ', version);
  };

  const getPlatformVersion = (osName: string): string => {
    switch (osName) {
      case 'Windows NT':
        return getBrowserGenericVersion(`${osName} `, userAgent);
      case 'CrOS':
      case 'Linux':
        return getBrowserGenericVersion(`${osName} x`, userAgent);
      case 'Mac OS':
        return iOSVersion(true);
      default:
        return '';
    }
  };

  const isMobile = (): boolean => {
    return userAgent.toLowerCase().includes('mobi');
  };

  const getDeviceName = (): string => {
    const deviceDetails =
      userAgent.match(
        /Android|BlackBerry|Bluebird|Chrome OS|Datalogic|Honeywell|iPhone|iPad|iPod|macOS|Windows Phone|Zebra/
      ) || [];
    const device = deviceDetails.length ? deviceDetails[0] : 'unknown';

    return device.concat(' ', getDeviceVersion(device.toLowerCase()));
  };

  const getDeviceVersion = (device: string): string => {
    switch (device) {
      case 'android':
      case 'blackberry':
      case 'windows':
        return getBrowserGenericVersion(`${device} `, userAgent);
      case 'ipad':
      case 'ipod':
      case 'iphone':
        return iOSVersion();
      default:
        return '';
    }
  };

  const iOSVersion = (isMacOS: boolean = false): string => {
    const deviceVersion = !isMacOS
      ? userAgent.match(/OS\s([0-9_]*)/i)
      : userAgent.match(/OS X\s([0-9_]*)/i);

    return deviceVersion ? deviceVersion[1].replace(/_/g, '.') : '';
  };

  const webSocketSupport = (): boolean => {
    return !!window.WebSocket;
  };

  const isMobileDevice = isMobile();

  const ipAddress = await fetchIpAddress();

  return {
    browserDetails: getBrowserDetails(userAgent),
    screenSize: getWindowDimensions(),
    isCookieEnabled: browserCookieStatus(),
    isWebSocketSupported: webSocketSupport(),
    OSDetails: isMobileDevice ? undefined : getPlatformDetails(),
    mobileDeviceName: isMobileDevice ? getDeviceName() : undefined,
    ipAddress,
    userWorkstations: getAnonymizedUserWorkstations(),
  };
};
