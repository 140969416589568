import { createCommunicationEventCreator } from '@ac/library-utils/dist/communication/event-bus';

export interface PropertyLocalDateTimeDetails {
  localPropertyDateTime: string;
}

export const propertyLocalDateTimeUpdatedEventCreator =
  createCommunicationEventCreator<PropertyLocalDateTimeDetails>(
    'container_propertyLocalDateTimeUpdated'
  );
