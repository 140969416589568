import { acConfig } from '@ac/library-utils/dist/declarations';

export const isTaskManagementModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.taskManagement ?? '');
};

export default {
  isTaskManagementModule,
};
