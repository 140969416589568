import { Color, IconName } from '@ac/web-components';

interface Props {
  isAvailable: boolean;
}

export const ToggleAvailabilityIcon = ({
  isAvailable,
}: Props): JSX.Element | null => {
  return isAvailable ? (
    <ac-icon icon={IconName.check} color={Color.success} />
  ) : null;
};
