import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { Setting } from '@ac/library-api';

export type LanguageSettings = {
  value: {
    languageCode: string;
    supportedLanguages: string[];
  };
};

export const getLanguageSettings = (): LanguageSettings | undefined => {
  const customerSettings = getCustomerContextData()?.settings;

  const settings = customerSettings?.effectiveSettings.find(
    (setting) => setting.code === Setting.SupportedLanguages
  );

  if (settings?.value) {
    return settings.value as LanguageSettings;
  }

  return;
};
