import { MODULE_CLASS } from 'frontend-container/shared/constants';

import { addModuleClass } from '@ac/library-utils/dist/utils/micro-frontends';
import { defineCustomElements } from '@ac/web-components/dist/loader';

import '@ac/web-components/dist/web-components/web-components.css';

export const initWebComponents = (): void => {
  addModuleClass(MODULE_CLASS);

  return defineCustomElements(window);
};
