import React from 'react';
import { Error } from 'frontend-container/components/Errors/Error';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { registerApplication } from 'single-spa';

import { Errors } from '@ac/web-components';

export const pathname403 = '/403';
export const isError403 = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(pathname403);
};
export const error403AppName = 'error-403';
export const registerError403 = (): void => {
  registerApplication(
    error403AppName,
    {
      bootstrap: () => Promise.resolve(),
      mount: async () => {
        const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
        root.render(<Error403 />);
      },
      unmount: async () => {
        removeReactRoot(ROUTER_OUTLET_SELECTOR);
      },
    },
    (location: Location) => isError403(location.pathname)
  );
};

export const Error403 = (): JSX.Element => (
  <Error errorType={Errors.error403} />
);
