import {
  ApiError as ApiErrorData,
  isApiError as isApiErrorData,
} from '@ac/library-api';

interface ApiError {
  data: ApiErrorData;
}

export const formatError = (
  error: unknown,
  additionalMessage?: string
): string => {
  const errorMessage = [
    additionalMessage,
    isApiError(error)
      ? formatApiErrorToMessage(error.data)
      : formatGeneralError(error),
  ]
    .filter(Boolean)
    .join(' || ');

  return errorMessage;
};

const isApiError = (error: unknown): error is ApiError => {
  const possibleErrorData = (error as { data: unknown } | undefined)?.data;

  return isApiErrorData(possibleErrorData);
};

const formatApiErrorToMessage = (error: ApiErrorData): string => {
  const errorDetails = error.details
    .map((errorDetail) => `${errorDetail.code}: ${errorDetail.message}`)
    .join(' | ');
  const apiErrorMessage = `${error.code}: ${error.message} || ${errorDetails}`;

  return apiErrorMessage;
};

interface SerializableError {
  toString: () => string;
}

const formatGeneralError = (error: unknown): string | undefined => {
  const errorMessage = (
    error as Partial<SerializableError> | undefined
  )?.toString?.();

  return errorMessage;
};
