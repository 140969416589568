export const ContextHelpId = 'context-help-view';
export const ContextHelpSidePanelId = 'context-help';
export const ContextHelpSidePanelBodyId = 'context-help-body';
export const MENU_PINNED_KEY = 'abovecloud_menuPinned';
export const MENU_OPENED_KEY = 'abovecloud_menuOpened';
export const PinnedHelpContainerClass = 'pinned-context-help';
export const AcMediaSm = 768;
export const SpContentWidthSmName = '--sp-content-width-sm';
export const SpContextHelpWidthName = '--sp-context-help-width';
export enum OpenCloseValues {
  Open = 'Open',
  Closed = 'Closed',
}
