import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { DEFAULT_LANGUAGE } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import {
  AllowedPosition,
  MenuPosition,
  RawMenuPosition,
} from 'frontend-container/components/Menu/menuPosition';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import i18n from 'frontend-container/i18n';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';

export const getReportingMenuItems = (): MenuElementItem[] => {
  const propertyContextData = getPropertyContextData();
  const customerContextData = getCustomerContextData();

  const settings =
    propertyContextData?.settings.effectiveSettingsDetails ??
    customerContextData?.settings.effectiveSettings ??
    [];

  const setting = settings.find(
    ({ code }) => code === GeneralCustomerSettingsKeys.ReportingMenuLinks
  );

  if (!setting) {
    return [];
  }

  const rawMenuPositions = ((setting.value as { value: unknown }).value ||
    []) as RawMenuPosition[];
  const menuPositions = rawMenuPositions.map((item) => new MenuPosition(item));

  return menuPositions.map((item) => mapMenuPositionToMenuElementItem(item));
};

const reportExternalMenuLinkPermissions =
  propertyPermissionKeys.report.externalMenuLinks;

const extendWithHttp = (url: string): string =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `http://${url}`;

const mapMenuPositionToMenuElementItem = (
  menuPosition: MenuPosition
): MenuElementItem => ({
  link: extendWithHttp(menuPosition.url),
  translation:
    menuPosition.name.find(
      (item) => item.languageCode.toLowerCase() === i18n.language.toLowerCase()
    )?.content ??
    menuPosition.name.find(
      (item) => item.languageCode.toLowerCase() === DEFAULT_LANGUAGE
    )?.content ??
    '',
  isExternal: true,
  permissionsConfiguration: {
    permissions: [getPermission(menuPosition.position)],
    permissionsConjunction: PermissionsConjunction.Or,
  },
});

const getPermission = (position: AllowedPosition): string => {
  const positionMap = {
    '1': reportExternalMenuLinkPermissions.externalLinkOnPosition1,
    '2': reportExternalMenuLinkPermissions.externalLinkOnPosition2,
    '3': reportExternalMenuLinkPermissions.externalLinkOnPosition3,
  };

  return positionMap[position];
};
