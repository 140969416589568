import { useTranslation } from 'react-i18next';

import { AlignItems, Color } from '@ac/web-components';

import { Banner } from '../../../Banner/Banner';

export const TrainingBanner = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Banner>
      <ac-validation-message
        iconColor={Color.warning}
        text={t('BANNER.BANNER_MESSAGES.TRAINING_USER_IS_USED')}
        messageAlignement={AlignItems.center}
      />
    </Banner>
  );
};
