import { getCroItems } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCentersItems } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';

import { UserPreferencesDetails } from '@ac/library-api';

export const getInitialValues = (): UserPreferencesDetails => {
  const { preferences } = userService.getCurrentUserPreferences() || {};

  const properties = getPropertyUnits();
  const propertyId =
    preferences?.defaultPropertyId ||
    (properties.length === 1 ? properties[0].unitId : undefined);

  const profileCenters = getProfileCentersItems();
  const profileCenterId =
    preferences?.defaultProfileCenterId ||
    (profileCenters.length === 1 ? profileCenters[0].id : undefined);

  const croItems = getCroItems();
  const croId =
    preferences?.defaultCentralReservationOfficeId ||
    (croItems.length === 1 ? croItems[0].id : undefined);

  return {
    defaultWorkspace: preferences?.defaultWorkspace,
    defaultLanguage: preferences?.defaultLanguage,
    defaultPropertyId: propertyId,
    defaultProfileCenterId: profileCenterId,
    defaultLandingScreen: preferences?.defaultLandingScreen,
    defaultCentralReservationOfficeId: croId,
    defaultCentralReservationOfficeLandingScreen:
      preferences?.defaultCentralReservationOfficeLandingScreen,
    defaultProfileCenterLandingScreen:
      preferences?.defaultProfileCenterLandingScreen,
    displayNewMenu: preferences?.displayNewMenu,
  };
};
