import {
  FeatureToggleInfo,
  FeatureTogglesFilters,
} from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

export const filterItems = (
  allItems: FeatureToggleInfo[],
  filters: FeatureTogglesFilters
): FeatureToggleInfo[] => {
  const search = filters.search?.trim().toLowerCase();

  const filteredByText = search
    ? allItems.filter((item) => item.featureName.toLowerCase().includes(search))
    : allItems;

  return filters.enabledOnly
    ? filteredByText.filter((item) => item.isEnabled)
    : filteredByText;
};
