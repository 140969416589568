import { createCommunicationEventCreator } from '@ac/library-utils/dist/communication/event-bus';

export type ContextHelpPanelVisibilityState =
  | {
      isVisible: true;
      helpUrl: string;
    }
  | {
      isVisible: false;
    };

export const contextHelpSidePanelVisibilityChanged =
  createCommunicationEventCreator<ContextHelpPanelVisibilityState>(
    'container_contextHelpSidePanelVisibilityChanged'
  );
