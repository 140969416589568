import { createContext } from 'react';
import { InternetSpeedTestStore } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const InternetSpeedTestContext = createContext<
  InternetSpeedTestStore | undefined
>(undefined);

export const useInternetSpeedTestContext = (): InternetSpeedTestStore =>
  useDefinedContext(InternetSpeedTestContext);
