import { useEffect } from 'react';

import { SessionDataHost } from '@ac/library-utils/dist/services';

export const SessionDataSnapshotUpdater = (): null => {
  useEffect(() => {
    return SessionDataHost.startSessionDataUpdater();
  }, []);

  return null;
};
