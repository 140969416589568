import { UnsavedChangesModalPresenter } from 'frontend-container/publicApi';
import {
  useModalPresenter,
  UsePresenterResult,
} from 'frontend-container/utils/presenter';

export interface UseUnsavedChangesModalPresenterResult
  extends UsePresenterResult {
  isOpen: boolean;
  modal: UnsavedChangesModalPresenter | undefined;
}

export const useUnsavedChangesModalPresenter = (
  initialState: boolean
): UseUnsavedChangesModalPresenterResult => {
  const modal = window.ACP?.container?.modals ?? { unsavedChanges: undefined };
  const { isOpen } = useModalPresenter(modal, 'unsavedChanges', initialState);

  return { isOpen, modal: modal.unsavedChanges };
};
