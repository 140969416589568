import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

export const isConfigurationModule = (
  pathname: string = window.location.pathname
): boolean => {
  const isOldConfigurationPath =
    pathname?.startsWith(acConfig.newFrontendUrls.configuration ?? '') &&
    !pathname?.startsWith(
      (acConfig.newFrontendUrls.configurationv2 || '/configurationv2') ?? ''
    );

  return isOldConfigurationPath;
};

export const isConfigurationModuleForSuperAdmin = (): boolean => {
  return (
    isConfigurationModule(window.location.pathname ?? '') &&
    Boolean(LoginService.isSuperUser())
  );
};

export default {
  isConfigurationModule,
  isConfigurationModuleForSuperAdmin,
};
