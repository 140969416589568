import {
  ERROR_VARIABLE_NAME,
  SUCCESS_VARIABLE_NAME,
  WARNING_VARIABLE_NAME,
} from 'frontend-container/utils/setupToastNotificationsTimeouts';

import { ToastSeverity } from '@ac/web-components';

const dict = new Map([
  [ToastSeverity.success, SUCCESS_VARIABLE_NAME],
  [ToastSeverity.warning, WARNING_VARIABLE_NAME],
  [ToastSeverity.error, ERROR_VARIABLE_NAME],
]);

export const getTimeoutInMs = (severity: ToastSeverity): number => {
  const propertyName = dict.get(severity) || '';

  const timeout = document.body.style
    .getPropertyValue(propertyName)
    .replace(/[^0-9.]+/, '');

  return Number(timeout) * 1000;
};
