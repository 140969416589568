import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const profileCenterMenu: MenuElement = {
  translation: 'MENU.PROFILE_CENTERS.TITLE',
  icon: IconName.profileDetails,
  id: 'menu-profile-centers',
  settings: [GeneralCustomerSettingsKeys.ProfileCenters],
  items: [
    {
      link: `${acConfig.newFrontendUrls.profiles}/global-view/dashboard`,
      code: 'ProfilesDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/travel-agent`,
      ],
      translation: 'MENU.PROFILE_CENTERS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          customerPermissionKeys.profiles.individual.viewDetails,
          customerPermissionKeys.profiles.company.viewDetails,
          customerPermissionKeys.profiles.travelAgent.viewDetails,
          customerPermissionKeys.profiles.shared.dataProtectionOfficer
            .seeProfilesDashboard,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
  ],
};

export const configurationProfileCentersMenu: MenuElement = {
  translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
  icon: IconName.consent,
  id: 'menu-profile-centers',
  settings: [GeneralCustomerSettingsKeys.ProfileCenters],
  permissionsConfiguration: {
    permissions: [customerPermissionKeys.configuration.profileCenters.view],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/profile-center-structure`,
      translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
    },
  ],
};
