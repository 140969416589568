import { useState } from 'react';
import {
  FeatureToggleInfo,
  FeatureTogglesFilters,
  FeatureTogglesSortValue,
  getDefaultMaintenancePanelFeatureTogglesState,
  MaintenancePanelFeatureTogglesState,
} from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';
import { filterItems } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/utils/filterItems';
import { getPage } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/utils/getPage';
import { sortItems } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/utils/sortItems';

import { PagingInfoDto } from '@ac/library-api';
import { DatagridSortDirection, PaginationParams } from '@ac/web-components';

export const DEFAULT_PAGE_SIZE = 20;

export interface MaintenancePanelFeatureTogglesStore {
  state: MaintenancePanelFeatureTogglesState;

  setAllItems: (allLoadedItems: FeatureToggleInfo[]) => void;
  setFilters: (filters: FeatureTogglesFilters) => void;
  setSort: (sort: FeatureTogglesSortValue) => void;
  setPagination: (paging: PaginationParams) => void;
  setLoading: (isLoading: boolean) => void;
}

export const useCreateMaintenancePanelFeatureTogglesStore =
  (): MaintenancePanelFeatureTogglesStore => {
    const [state, setState] = useState(
      getDefaultMaintenancePanelFeatureTogglesState()
    );

    const setAllItems = (allLoadedItems: FeatureToggleInfo[]): void => {
      const filters: FeatureTogglesFilters = {
        search: undefined,
        enabledOnly: false,
      };

      const sort: FeatureTogglesSortValue = {
        name: 'featureName',
        direction: DatagridSortDirection.ascending,
      };

      const filteredItems = filterItems(allLoadedItems, filters);

      const paging: PagingInfoDto = {
        totalCount: filteredItems.length,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 1,
      };

      const visibleItems = getPage(filteredItems, paging);

      setState({
        allLoadedItems,
        filteredItems,
        visibleItems,
        paging,
        filters,
        sort,
        isLoading: false,
      });
    };

    const setFilters = (filters: FeatureTogglesFilters): void => {
      setState((prevState) => {
        const filteredItems = filterItems(prevState.allLoadedItems, filters);

        const paging: PagingInfoDto = {
          totalCount: filteredItems.length,
          pageSize: prevState.paging.pageSize,
          pageNumber: 1,
        };

        const visibleItems = getPage(filteredItems, paging);

        return {
          ...prevState,
          filteredItems,
          visibleItems,
          paging,
          filters,
        };
      });
    };

    const setSort = (sort: FeatureTogglesSortValue): void => {
      setState((prevState) => {
        const filteredItems = sortItems(prevState.filteredItems, sort);
        const visibleItems = getPage(filteredItems, prevState.paging);

        return {
          ...prevState,
          filteredItems,
          visibleItems,
          sort,
        };
      });
    };

    const setPagination = (newPageParams: PaginationParams): void => {
      setState((prevState) => {
        const paging: PagingInfoDto = {
          totalCount: prevState.paging.totalCount,
          pageSize: newPageParams.perPage,
          pageNumber: newPageParams.page,
        };

        const visibleItems = getPage(prevState.filteredItems, paging);

        return {
          ...prevState,
          visibleItems,
          paging,
        };
      });
    };

    const setLoading = (isLoading: boolean): void => {
      setState((prevState) => ({
        ...prevState,
        isLoading,
      }));
    };

    return {
      state,
      setAllItems,
      setSort,
      setFilters,
      setPagination,
      setLoading,
    };
  };
