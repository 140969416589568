import {
  SidePanelLayer,
  SidePanelSide,
  SidePanelSize,
  SidePanelTheme,
} from '@ac/web-components';

export const maintenancePanelConfiguration = {
  id: 'sep-maintenance-side-panel',
  overlay: true,
  layer: SidePanelLayer.first,
  side: SidePanelSide.left,
  size: SidePanelSize.md,
  theme: SidePanelTheme.gray,
};
