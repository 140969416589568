import { useTranslation } from 'react-i18next';

import { TextColor, TextSize } from '@ac/web-components';

interface BusinessContextGridExtendedRowTextProps {
  translationKey: string;
  value: string | undefined;
  className: string;
  additionalInfo?: string;
}

export const BusinessContextGridExtendedRowText = ({
  translationKey,
  value,
  additionalInfo,
  className,
}: BusinessContextGridExtendedRowTextProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ac-text-group
      label={t(`MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.${translationKey}`)}
      class={className}
    >
      <ac-text>{value}</ac-text>
      {additionalInfo && (
        <ac-text
          uppercase
          class="ac-spacing-left-sm"
          size={TextSize.sm}
          color={TextColor.alert}
        >{`(${additionalInfo})`}</ac-text>
      )}
    </ac-text-group>
  );
};
