import {
  deleteSharedCookie,
  getSharedCookie,
  setSharedCookie,
} from 'frontend-container/utils/setSharedCookie';

export const idleTimeoutCookieKey = 'a_lodtim';

export const getIdleTimeout = (): number | typeof NaN => {
  const idleTimeoutCookie = getSharedCookie(idleTimeoutCookieKey);

  return Number(idleTimeoutCookie);
};

export const setIdleTimeout = (idleTimeout: number | null): void => {
  if (idleTimeout === null) {
    deleteSharedCookie(idleTimeoutCookieKey);
  } else {
    setSharedCookie(idleTimeoutCookieKey, idleTimeout.toString());
  }
};

export const getSecondsLeftBeforeIdleTimeout = (): number => {
  const idleTimeout = getIdleTimeout();
  const seconds = Math.max(0, Math.round((idleTimeout - Date.now()) / 1_000));

  return seconds;
};

export const getNextIdleTimeout = (idleTimeoutInSeconds: number): number => {
  const idleTimeoutInMilliseconds = idleTimeoutInSeconds * 1_000;

  return Date.now() + idleTimeoutInMilliseconds;
};
