import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const reportsMenu: MenuElement = {
  translation: 'MENU.REPORTS.TITLE',
  icon: IconName.report,
  id: 'menu-reports',
  items: [
    {
      link: `${acConfig.newFrontendUrls.reports}/folders`,
      aliases: [acConfig.newFrontendUrls.reports],
      translation: 'MENU.REPORTS.ITEMS.DASHBOARD',
      keyboardShortcutWindows: 'alt+ctrl+r',
      keyboardShortcutMac: '⌥+⌘+r',
      keyCode: 82,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/batch-reports`,
      translation: 'MENU.REPORTS.ITEMS.BATCH_REPORT_DASHBOARD',
      permissionsConfiguration: {
        permissions: [propertyPermissionKeys.report.executeBatchReport],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+,',
      keyboardShortcutMac: '⌥+⌘+,',
      keyCode: 188,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/scheduler`,
      translation: 'MENU.SCHEDULER.ITEMS.DASHBOARD',
      keyboardShortcutWindows: 'alt+ctrl+`',
      keyboardShortcutMac: '⌥+⌘+`',
      keyCode: 192,
    },
    {
      link: `${acConfig.newFrontendUrls.reports}/run-log`,
      translation: 'MENU.REPORTS.ITEMS.REPORT_RUN_LOG_DASHBOARD',
      aliases: [
        `${acConfig.newFrontendUrls.reports}/run-log/report`,
        `${acConfig.newFrontendUrls.reports}/run-log/batch`,
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.integrations}/exports/dashboard`,
      translation: 'MENU.INTEGRATIONS.ITEMS.EXPORTS_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          propertyPermissionKeys.integrations.fileExportViewHistory,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/property/entity-manager`,
      translation: 'MENU.REPORTS.ITEMS.DATA_SET_TRACKING',
      settings: [GeneralCustomerSettingsKeys.DataSetTracking],
    },
  ],
};
