import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import { isChangelogModule } from 'frontend-container/components/Menu/utils/modules/changelog';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2Module } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { isIntegrationsModule } from 'frontend-container/components/Menu/utils/modules/integrations';

import { LoginService } from '@ac/library-utils/dist/services';

export const isConfigurationContextVisible = (): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const pathUtilFunctions = [
    isConfigurationModule,
    isConfigurationv2Module,
    isIntegrationsModule,
    isChangelogModule,
    isFloorPlanModule,
  ];

  const isVisible = pathUtilFunctions.some((func) => func());

  return (
    isVisible &&
    !isPropertyContextVisible() &&
    !isCroContextVisible() &&
    !isProfileCentersContextVisible()
  );
};
