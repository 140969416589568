import { ReloadCurrentBusinessContextDataParameters } from 'frontend-container/publicApi/windowObject';
import { getBusinessContextContainer } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  CacheReadResult,
  DataFindingResult,
} from 'frontend-container/shared/businessContext/provider/cache/reader';
import { BusinessContextCacheWriteRequest } from 'frontend-container/shared/businessContext/provider/cache/writer';
import { businessContextCache } from 'frontend-container/shared/businessContext/provider/cacheInstance';
import { BusinessContextDependencyConfig } from 'frontend-container/shared/businessContext/provider/dependencyConfig';
import {
  setToClearContextBeforeNextRead,
  shouldContextBeCleared,
} from 'frontend-container/shared/businessContext/provider/eraser';
import { BusinessContextProviderLogger } from 'frontend-container/shared/businessContext/provider/logger/logger';
import { reloadCurrentBusinessContextData } from 'frontend-container/shared/businessContext/provider/reloadCurrentBusinessContextData';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';
import { IDatabaseManager } from '@ac/library-utils/dist/storage/database';

export interface IBusinessContextDataProvider {
  getBusinessContext(
    id: BusinessContextUnitIdentifier
  ): Promise<SepModuleBusinessContextData>;

  clearCache(): void;
  reloadCurrentBusinessContextData(
    parameters: ReloadCurrentBusinessContextDataParameters
  ): Promise<void>;
}

export class BusinessContextDataProviderWithCache
  implements IBusinessContextDataProvider
{
  private readonly allStoreNames: string[];
  private readonly databaseManager: IDatabaseManager;
  private readonly loggers: BusinessContextProviderLogger[];

  constructor(dependencies: BusinessContextDependencyConfig) {
    if (!dependencies.databaseManager) {
      throw new Error(
        'Cannot use BusinessContextDataProviderWithCache without known database manager'
      );
    }

    this.databaseManager = dependencies.databaseManager;
    this.loggers = dependencies.loggers;
    this.allStoreNames = this.databaseManager.schema.stores.map(
      (store) => store.name
    );
  }

  async getBusinessContext(
    requestedUnit: BusinessContextUnitIdentifier
  ): Promise<SepModuleBusinessContextData> {
    const clearContext = shouldContextBeCleared();

    if (clearContext) {
      await this.executeCacheClearProcedure();
      setToClearContextBeforeNextRead(false);
    }

    if (!clearContext) {
      const currentContextData = getBusinessContextContainer()?.context;

      const requiredContext =
        currentContextData &&
        tryAdjustContextToUnitIdentifier(currentContextData, requestedUnit);

      if (requiredContext) {
        this.loggers.forEach((logger) =>
          logger.logKnownContext({ requestedUnit })
        );

        return requiredContext;
      }
    }

    const readTransaction = await this.databaseManager.createTransaction({
      mode: 'readonly',
      storeNames: this.allStoreNames,
    });

    const cacheReadResponse =
      await businessContextCache.reader.readDataFromCache(
        readTransaction,
        requestedUnit
      );

    const currentlyLoadedContext =
      this.collectAlreadyFetchedData(cacheReadResponse);

    const requiredContext =
      currentlyLoadedContext &&
      tryAdjustContextToUnitIdentifier(currentlyLoadedContext, requestedUnit);

    if (requiredContext) {
      this.loggers.forEach((logger) =>
        logger.logKnownContext({ requestedUnit, cacheReadResponse })
      );

      return requiredContext;
    }

    const dataFetchedFromApi = await this.loadMissingData(
      requestedUnit,
      currentlyLoadedContext
    );

    const cacheWriteRequest = this.selectDataToSaveInCache(
      cacheReadResponse,
      dataFetchedFromApi
    );

    const writeTransaction = await this.databaseManager.createTransaction({
      mode: 'readwrite',
      storeNames: this.allStoreNames,
    });

    await businessContextCache.writer.clearOtherUsersData(
      writeTransaction,
      dataFetchedFromApi.user.details.id
    );

    await businessContextCache.writer.saveMissingDataInCache(
      writeTransaction,
      cacheWriteRequest
    );

    this.databaseManager.closeConnection();

    this.loggers.forEach((logger) =>
      logger.logNewContextFetchRequirement({
        requestedUnit,
        cacheReadResponse,
        dataFetchedFromApi,
        cacheWriteRequest,
      })
    );

    return dataFetchedFromApi;
  }

  clearCache(): void {
    setToClearContextBeforeNextRead(true);
  }

  async reloadCurrentBusinessContextData(
    parameters: ReloadCurrentBusinessContextDataParameters
  ): Promise<void> {
    this.clearCache();

    return await reloadCurrentBusinessContextData(parameters);
  }

  private async executeCacheClearProcedure(): Promise<void> {
    await this.databaseManager.runIsolatedTransaction(
      {
        mode: 'readwrite',
        storeNames: this.allStoreNames,
      },
      async (transaction) => {
        await businessContextCache.writer.clearAllCache(transaction);
      }
    );

    this.loggers.forEach((logger) => logger.logCacheClear());
  }

  private collectAlreadyFetchedData(
    cacheReadResult: CacheReadResult
  ): SepModuleBusinessContextData | undefined {
    const user = this.getCacheValueIfDataIsUpToDate(cacheReadResult.user);

    if (!user) {
      return;
    }

    return {
      user,
      system: this.getCacheValueIfDataIsUpToDate(cacheReadResult.system),
      customer: this.getCacheValueIfDataIsUpToDate(cacheReadResult.customer),
      centralReservationOffice: this.getCacheValueIfDataIsUpToDate(
        cacheReadResult.centralReservationOffice
      ),
      property: this.getCacheValueIfDataIsUpToDate(cacheReadResult.property),
      profileCenters: this.getCacheValueIfDataIsUpToDate(
        cacheReadResult.profileCenters
      ),
    };
  }

  private async loadMissingData(
    unit: BusinessContextUnitIdentifier,
    alreadyLoadedBusinessContext?: SepModuleBusinessContextData
  ): Promise<SepModuleBusinessContextData> {
    const loader = new SepModuleBusinessContextLoader();

    return alreadyLoadedBusinessContext
      ? await loader.loadForDifferentScope(alreadyLoadedBusinessContext, unit)
      : await loader.load(unit);
  }

  private selectDataToSaveInCache(
    cacheReadResult: CacheReadResult,
    loadedBusinessContext: SepModuleBusinessContextData
  ): BusinessContextCacheWriteRequest {
    const request: BusinessContextCacheWriteRequest = {};

    if (!this.isDataUpToDateInCache(cacheReadResult.user)) {
      request.user = loadedBusinessContext.user;
    }

    if (
      loadedBusinessContext.system &&
      !this.isDataUpToDateInCache(cacheReadResult.system)
    ) {
      request.system = loadedBusinessContext.system;
    }

    if (
      loadedBusinessContext.customer &&
      !this.isDataUpToDateInCache(cacheReadResult.customer)
    ) {
      request.customer = loadedBusinessContext.customer;
    }

    if (
      loadedBusinessContext.centralReservationOffice &&
      !this.isDataUpToDateInCache(cacheReadResult.centralReservationOffice)
    ) {
      request.centralReservationOffice =
        loadedBusinessContext.centralReservationOffice;
    }

    if (
      loadedBusinessContext.property &&
      !this.isDataUpToDateInCache(cacheReadResult.property)
    ) {
      request.property = loadedBusinessContext.property;
    }

    if (
      loadedBusinessContext.profileCenters &&
      !this.isDataUpToDateInCache(cacheReadResult.profileCenters)
    ) {
      request.profileCenters = loadedBusinessContext.profileCenters;
    }

    return request;
  }

  private getCacheValueIfDataIsUpToDate<TData>(
    loadingResult: DataFindingResult<TData> | undefined
  ): TData | undefined {
    const cacheResult = loadingResult?.cacheResult;

    if (!cacheResult) {
      return;
    }

    return cacheResult?.isUpToDate ? cacheResult.entity.value : undefined;
  }

  private isDataUpToDateInCache<TData>(
    loadingResult: DataFindingResult<TData> | undefined
  ): boolean {
    return !!this.getCacheValueIfDataIsUpToDate(loadingResult);
  }
}
