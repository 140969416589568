import { isError403 } from 'frontend-container/components/Errors/Error403';

import { ApiError, ApiErrorDetail } from '@ac/library-api';

declare global {
  interface XMLHttpRequest {
    realSend: (body?: Document | BodyInit | null) => void;
  }
}

export const ERROR_NOT_WHITELISTED_CODE = 'NotWhitelisted';
export const ERROR_NOT_WHITELISTED = `?error=${ERROR_NOT_WHITELISTED_CODE}`;

export const isError403IpWhitlelistPage = (): boolean =>
  window.location.search.startsWith(ERROR_NOT_WHITELISTED);

export const setupWhitelistingCatcher = (): void => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const realSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (value): void {
    this.addEventListener(
      'readystatechange',
      function () {
        // eslint-disable-next-line no-invalid-this, no-unused-expressions
        this.readyState === XMLHttpRequest.DONE && this.responseType !== 'blob'
          ? // eslint-disable-next-line no-invalid-this
            handleNotWhitelisted(this.status, this.responseText)
          : null;
      },
      false
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    realSend.bind(this)(value);
  };

  const realFetch = window.fetch;
  window.fetch = (...args): Promise<Response> => {
    return realFetch(...args).then(async (response) => {
      const responseClone = response.clone();
      const responseText = await responseClone.text();
      handleNotWhitelisted(response.status, responseText);

      return response;
    });
  };
};

const jsonParseErrorMessage = 'Unexpected token a in JSON at position 0';
const handleNotWhitelisted = (status: number, responseText: string): void => {
  if (status !== 403) {
    return;
  }

  try {
    const responseObject: ApiError | undefined = JSON.parse(responseText) as
      | ApiError
      | undefined;
    const notWhitlelistedErrorExist = !!(responseObject?.details ?? []).find(
      (item: ApiErrorDetail) => item.code === ERROR_NOT_WHITELISTED_CODE
    );
    if (notWhitlelistedErrorExist && !isError403()) {
      window.location.href = `${window.location.origin}/403${ERROR_NOT_WHITELISTED}`;
    }
  } catch (error) {
    if (error instanceof Error && error.message === jsonParseErrorMessage) {
      return;
    }
    throw error;
  }
};
