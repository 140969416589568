import { ContextHelpId } from 'frontend-container/components/ContextHelp/constants';
import { getHelpUrl } from 'frontend-container/components/ContextHelp/utils/openHelpTopic';
import { ExternalPageIFrame } from 'frontend-container/components/ExternalPageIFrame/ExternalPageIFrame';

import { deserializeUrlSearchString } from '@ac/library-utils/dist/utils';

export const ContextHelpView = (): JSX.Element => {
  const urlParams = deserializeUrlSearchString<{ cshid?: string }>(
    window.location.search
  );

  const contextHelpId = urlParams.cshid ? Number(urlParams.cshid) : undefined;
  const contextHelpUrl = getHelpUrl(contextHelpId);

  return (
    <ExternalPageIFrame name={ContextHelpId} src={contextHelpUrl} fullScreen />
  );
};
