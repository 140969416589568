import {
  getDataForAllPages,
  LibraryApiResponse,
  PageResponse,
  RawGenericEntity,
  ReadKeyDto,
  ReadKeyRequest,
  Workstation,
} from '@ac/library-api';
import { WorkstationApi } from '@ac/library-api/dist/api/v0/configuration/genericEntities';
import { ReadKeyApi } from '@ac/library-api/dist/api/v0/integrationPropertyInterface';

type Params = Parameters<typeof WorkstationApi.getList>[0];

export const fetchAllWorkstations = (
  params: Params
): Promise<PageResponse<RawGenericEntity, Workstation>> => {
  return getDataForAllPages(({ pageNumber, pageSize }) => {
    return WorkstationApi.getList({
      queryParams: {
        ...params?.queryParams,
        pageNumber,
        pageSize,
      },
      customConfig: params?.customConfig,
    }) as Promise<PageResponse<RawGenericEntity, Workstation>>;
  });
};

export const readKeyData = async ({
  workstation,
}: ReadKeyRequest): Promise<ReadKeyDto> => {
  const response = (await ReadKeyApi.readKey({
    pathParams: undefined,
    queryParams: undefined,
    data: {
      workstation,
    },
  })) as LibraryApiResponse<ReadKeyDto>;

  return response.data;
};
