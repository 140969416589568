import { isResourceManagementModule } from 'frontend-container/components/Menu/utils/modules/resourceManagement';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const resourceManagementAppName = 'resource-management';

export const registerResourceManagement = (): void => {
  registerApplication(
    resourceManagementAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.resourceManagement,
        resourceManagementAppName
      ),
    (location: Location) => isResourceManagementModule(location.pathname)
  );
};
