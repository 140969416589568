import { useState } from 'react';
import { THEME_DARK_KEY } from 'frontend-container/components/Menu/service';
import { layoutDirectionUpdatedEventCreator } from 'frontend-container/publicApi/events';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

import {
  LayoutDirection,
  setDirectionLtr,
  setDirectionRtl,
} from '@ac/web-components';

interface CommandResult {
  isDarkMode: boolean;
  isAnyChange: boolean;
  setContentDirection(direction: LayoutDirection): void;
  setThemeMode(useDarkMode: boolean): void;
}

export const useDesignSystemFeatureConfiguration = (): CommandResult => {
  const modeStorageValue =
    LocalStorage.getParsed<boolean>(THEME_DARK_KEY) || false;

  const [isAnyChange, setChange] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(modeStorageValue);
  const eventBus = getCurrentGlobalEventBus();

  const setContentDirection = (direction: LayoutDirection): void => {
    if (direction === LayoutDirection.rtl) {
      setDirectionRtl();
    } else {
      setDirectionLtr();
    }

    eventBus.dispatch(layoutDirectionUpdatedEventCreator());
  };

  const setThemeMode = (useDarkMode: boolean): void => {
    setIsDarkMode(useDarkMode);
    setChange(true);
    LocalStorage.set(THEME_DARK_KEY, useDarkMode);
  };

  return {
    isDarkMode,
    isAnyChange,
    setContentDirection,
    setThemeMode,
  };
};
