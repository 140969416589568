import { IconName } from '@ac/web-components';

interface Props {
  detail?: string;
  iconName: IconName;
}

export const CommunicationDetail = ({
  detail,
  iconName,
}: Props): JSX.Element => (
  <>
    {detail && (
      <ac-box form={true} sizeSm="auto">
        <ac-icon icon={iconName} class="ac-spacing-right-xs" />
        {detail}
      </ac-box>
    )}
  </>
);
