import { ContextHelpExternalPageConfig } from 'frontend-container/externalPages/pages/ContextHelp/config';
import { getDefaultPathGuard } from 'frontend-container/utils/getDefaultPathGuard';

export interface ExternalPageConfig {
  component: React.ReactNode;
  pagePathname: string;
  pathGuard?: () => boolean;
}

// Register external pages displayed by iframe here
export const externalPagesConfig: ExternalPageConfig[] = [
  ContextHelpExternalPageConfig,
];

export const isExternalPage = (): boolean => {
  return externalPagesConfig.some(({ pagePathname }) =>
    getDefaultPathGuard(pagePathname)
  );
};
