import { configurationAppName } from 'frontend-container/config/configuration';
import { reservationsAppName } from 'frontend-container/config/reservations';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const emberPathNameAppNameMap = {
  [acConfig.newFrontendUrls.configuration ?? '/configuration']:
    configurationAppName,
  [acConfig.newFrontendUrls.reservations ?? '/reservations']:
    reservationsAppName,
};
