import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchCashierOpeningOptions } from 'frontend-container/components/Menu/components/Cashier/service';
import { WorkstationTestSelector } from 'frontend-container/components/Menu/components/Workstation/testSelectors';
import {
  getDefaultWorkstation,
  setDefaultWorkstation,
} from 'frontend-container/utils/defaultWorkstation';
import { getUILanguage } from 'frontend-container/utils/UILanguage';

import { AcSelectOption, TargetValueObject } from '@ac/web-components';

interface Option {
  value?: string | number;
  name?: string;
}

interface Props {
  onClose: () => Promise<void>;
  onConfirm: (workstationId: string) => Promise<void>;
}

export const WorkstationSelectionModal = (props: Props): JSX.Element => {
  const [workstations, setWorkstations] = useState<Option[]>([]);
  const [workstation, setWorkstation] = useState<Option | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { onClose, onConfirm } = props;
  const { t } = useTranslation();

  const language = getUILanguage();

  const initialize = async (): Promise<void> => {
    const { workstations: cashierWorkstations } =
      await fetchCashierOpeningOptions();

    const rememberedWorkstation = getDefaultWorkstation();

    const parsedWorkstations = Array.isArray(cashierWorkstations)
      ? cashierWorkstations.map(({ descriptionMap, id }) => ({
          name: descriptionMap[language],
          value: id,
        }))
      : [];

    const initialWorkstation = rememberedWorkstation
      ? {
          name: rememberedWorkstation.descriptionMap?.[language],
          value: rememberedWorkstation.id,
        }
      : parsedWorkstations[0] ?? null;

    setWorkstations(parsedWorkstations);
    setWorkstation(initialWorkstation);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const { workstations: originalWorkstations } =
        await fetchCashierOpeningOptions();
      setDefaultWorkstation(
        originalWorkstations.find((item) => item.id === workstation?.value)
      );
      window.ACP?.container?.updateWorkstation?.();
      await onConfirm(workstation?.value as string);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const isConfirmButtonDisabled = !workstation;

  const handleSetWorkstation = (option: AcSelectOption | undefined): void => {
    const selectedWorkstation =
      workstations.find(({ value }) => value === option?.value) || null;

    setWorkstation(selectedWorkstation);
  };

  return (
    <ac-modal
      confirmButton={{
        disabled: isConfirmButtonDisabled,
        ['data-test-selector']: WorkstationTestSelector.SelectionModalSelectBtn,
        content: {
          text: t('MENU.MODAL.COMMON.SELECT'),
        },
        onClick: handleSubmit,
      }}
      cancelButton={{
        content: {
          text: t('MENU.MODAL.COMMON.CANCEL'),
        },
        onClick: onClose,
      }}
      modalTitle={t('MENU.WORKSTATION.WORKSTATION_SELECTION')}
      loading={isLoading}
      data-test-selector={WorkstationTestSelector.SelectionModal}
    >
      <form>
        <div>
          <ac-box>
            <ac-select
              id="cashier-select-workstation"
              label={t('MENU.WORKSTATION.WORKSTATION')}
              value={workstation?.value}
              optionsArray={workstations}
              onChangeCallback={handleSetWorkstation}
              optionNameField="name"
              translations={{ placeholder: t('MENU.MODAL.COMMON.SELECT') }}
              hideMessageContainer={true}
              targetValue={TargetValueObject.modal}
              data-test-selector={WorkstationTestSelector.SelectionModalSelect}
              required
            />
          </ac-box>
        </div>
      </form>
    </ac-modal>
  );
};
