import { useEffect, useMemo } from 'react';

export const useOverrideMenuHeight = (
  isNonProduction: boolean,
  isMenuV2Enabled: boolean
): void => {
  const root = useMemo(() => document.documentElement, []);

  const newMenuHeightWithoutBanner = useMemo(
    () =>
      // After dropping old menu we should replace the following var with `--menu-height` and set 56px for it
      parseInt(
        getComputedStyle(root).getPropertyValue('--menu-bar-height'),
        10
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const oldMenuHeightWithoutBanner = useMemo(
    () =>
      parseInt(getComputedStyle(root).getPropertyValue('--menu-height'), 10),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const bannerHeight = 40;

    if (isMenuV2Enabled && isNonProduction) {
      document.documentElement.style.setProperty(
        '--menu-height',
        `${newMenuHeightWithoutBanner + bannerHeight}px`
      );
    } else if (isMenuV2Enabled && !isNonProduction) {
      document.documentElement.style.setProperty(
        '--menu-height',
        `${newMenuHeightWithoutBanner}px`
      );
    } else {
      document.documentElement.style.removeProperty('--menu-height');
    }
  }, [
    isNonProduction,
    isMenuV2Enabled,
    root,
    newMenuHeightWithoutBanner,
    oldMenuHeightWithoutBanner,
  ]);
};
