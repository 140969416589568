import { acConfig } from '@ac/library-utils/dist/declarations';

export const isIntegrationsModule = (
  pathname: string = window.location.pathname
): boolean => pathname?.startsWith(acConfig.newFrontendUrls.integrations ?? '');

const migrationAuthorizationPathForHotelUserRegExp =
  /.*\/migration-authorization\/[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89a-f\d]{4}-[a-f\d]{12}\/dashboard/;

const isMigrationAuthorizationDashboardForPropertyUser = (
  pathname: string = window.location.pathname
) => migrationAuthorizationPathForHotelUserRegExp.test(pathname);

const isExportDashboard = (pathname: string = window.location.pathname) =>
  pathname?.includes('exports/dashboard');

const isDataMigrationDashboard = (
  pathname: string = window.location.pathname
) => pathname?.includes('data-migration');

export const isIntegrationsModuleWithoutProperty = (
  pathname: string = window.location.pathname
): boolean =>
  isIntegrationsModule(pathname) && !isIntegrationsModuleWithProperty();

export const isIntegrationsModuleWithProperty = (
  pathname: string = window.location.pathname
): boolean =>
  isIntegrationsModule(pathname) &&
  (isExportDashboard(pathname) ||
    isDataMigrationDashboard(pathname) ||
    isMigrationAuthorizationDashboardForPropertyUser(pathname));

export default {
  isIntegrationsModule,
  isIntegrationsModuleWithoutProperty,
};
