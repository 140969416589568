import { updateLoginBrandingStorage } from 'frontend-container/components/Menu/components/Branding/brandingLocalStorage';
import i18n from 'frontend-container/i18n';
import {
  getBusinessContextData,
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  buildUniqueHashForBusinessContext,
  getCustomConfigWithoutPropertyHeader,
  SepModuleBusinessContextData,
  Setting,
  SettingValue,
} from '@ac/library-api';
import {
  SettingsCustomerApi,
  SettingsPropertiesApi,
} from '@ac/library-api/dist/api/v0/configuration/settings';
import { isDefined } from '@ac/library-utils/dist/utils';
import { Translator } from '@ac/library-utils/dist/utils/translations';
import { Translation } from '@ac/library-utils/dist/utils/translations/interfaces';

export type Branding = {
  mainMenuLogo?: Blob;
  favicon?: Blob;
  documentTitle?: string;
  loginLogoUrl?: string;
  loginBackgroundUrl?: string;
  loginBackgroundId?: string;
  loginLogoId?: string;
  loginFaviconData?: string | ArrayBuffer | null;
};

type BrandingTabTitleSetting = {
  value?: Translation[];
};

type BrandingSettingValue = {
  contentType: string;
  fileId: string;
  fileName: string;
};

interface BrandingSetting extends SettingValue {
  value?: BrandingSettingValue;
}

const cachedBranding = new Map<string, Promise<Branding | undefined>>();

const isResolved = <T>(
  promiseResult: PromiseSettledResult<T>
): promiseResult is PromiseFulfilledResult<T> => {
  return (promiseResult as PromiseFulfilledResult<T>).value !== undefined;
};

const getSetting = (
  settingKey: Setting,
  businessContext: SepModuleBusinessContextData
): BrandingSetting | undefined => {
  if (!businessContext) {
    return;
  }
  const propertySettings =
    getPropertyContextData()?.settings.effectiveSettingsDetails;
  const customerSettings = getCustomerContextData()?.settings.effectiveSettings;
  const settings = propertySettings || customerSettings;

  return (
    settings &&
    (settings.find((setting) => setting.code === settingKey) as
      | BrandingSetting
      | undefined)
  );
};

const getBrandingSettingPublicContentUrl = (
  settingKey: Setting.BrandingLoginBackground | Setting.BrandingLoginLogo,
  businessContext: SepModuleBusinessContextData
): string | undefined => {
  const customerId = getCustomerContextData()?.details.id;

  if (!customerId) {
    return undefined;
  }

  const isSettingEnabled = isDefined(getSetting(settingKey, businessContext));

  if (!isSettingEnabled) {
    return undefined;
  }

  const tenantImageUrl = SettingsCustomerApi.getPublicContentUrl({
    pathParams: {
      code: settingKey,
      customerId,
    },
    customConfig: getCustomConfigWithoutPropertyHeader(),
  }) as string | undefined;

  return tenantImageUrl;
};

const loadBrandingSettingContent = async (
  settingKey: Setting.BrandingFavicon | Setting.BrandingMainMenuLogo,
  businessContext: SepModuleBusinessContextData
): Promise<Blob | undefined> => {
  const isSettingEnabled = isDefined(getSetting(settingKey, businessContext));

  if (!isSettingEnabled) {
    return undefined;
  }

  const { property } = businessContext;

  if (property) {
    const propertyImageBlob = (await SettingsPropertiesApi.getContent({
      pathParams: {
        propertyId: property.details.id,
        code: settingKey,
      },
    })) as Blob | undefined;

    if (propertyImageBlob) {
      return propertyImageBlob;
    }
  }

  const tenantImageBlob = (await SettingsCustomerApi.getContent({
    pathParams: {
      code: settingKey,
    },
    customConfig: getCustomConfigWithoutPropertyHeader(),
  })) as Blob | undefined;

  return tenantImageBlob;
};

const loadMainMenuBrandingLogo = async (
  businessContext: SepModuleBusinessContextData
): Promise<Blob | undefined> => {
  return await loadBrandingSettingContent(
    Setting.BrandingMainMenuLogo,
    businessContext
  );
};

const loadBrandingFavicon = async (
  businessContext: SepModuleBusinessContextData
): Promise<Blob | undefined> => {
  return await loadBrandingSettingContent(
    Setting.BrandingFavicon,
    businessContext
  );
};

const getLoginBackgroundUrl = (
  businessContext: SepModuleBusinessContextData
): string | undefined => {
  return getBrandingSettingPublicContentUrl(
    Setting.BrandingLoginBackground,
    businessContext
  );
};

const getLoginLogoUrl = (
  businessContext: SepModuleBusinessContextData
): string | undefined => {
  return getBrandingSettingPublicContentUrl(
    Setting.BrandingLoginLogo,
    businessContext
  );
};

export const loadLoginBrandingFilesIds = async (): Promise<void> => {
  try {
    const loginBackgroundValue = (await SettingsCustomerApi.getValue({
      pathParams: { code: Setting.BrandingLoginBackground },
      customConfig: getCustomConfigWithoutPropertyHeader(),
    })) as BrandingSetting;

    updateLoginBrandingStorage({
      loginBackgroundId: loginBackgroundValue.value?.fileId,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  try {
    const loginLogoValue = (await SettingsCustomerApi.getValue({
      pathParams: { code: Setting.BrandingLoginLogo },
      customConfig: getCustomConfigWithoutPropertyHeader(),
    })) as BrandingSetting;

    updateLoginBrandingStorage({ loginLogoId: loginLogoValue.value?.fileId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const loadBranding = (): Promise<Branding | undefined> => {
  const businessContext = getBusinessContextData();
  const hash = buildUniqueHashForBusinessContext(businessContext);

  const cachedRequest = cachedBranding.get(hash);

  if (cachedRequest) {
    return cachedRequest;
  }

  const tabTitleSetting = getSetting(Setting.BrandingTabTitle, businessContext);

  const tabTitleSettingValue = isDefined(tabTitleSetting)
    ? (tabTitleSetting.value as BrandingTabTitleSetting).value
    : undefined;

  const translatedDocumentTitle =
    tabTitleSettingValue &&
    Translator.getTranslation(tabTitleSettingValue, {
      userLanguageCode: i18n.language,
    })?.content;

  const loginLogoUrl = getLoginLogoUrl(businessContext);
  const loginBackgroundUrl = getLoginBackgroundUrl(businessContext);
  const loginLogoId = getSetting(Setting.BrandingLoginLogo, businessContext)
    ?.value?.fileId;
  const loginBackgroundId = getSetting(
    Setting.BrandingLoginBackground,
    businessContext
  )?.value?.fileId;

  const request = Promise.allSettled([
    loadMainMenuBrandingLogo(businessContext),
    loadBrandingFavicon(businessContext),
  ]).then(([mainMenuLogo, favicon]) => ({
    loginLogoUrl,
    loginBackgroundUrl,
    loginLogoId,
    loginBackgroundId,
    mainMenuLogo: isResolved(mainMenuLogo) ? mainMenuLogo.value : undefined,
    favicon: isResolved(favicon) ? favicon.value : undefined,
    documentTitle: translatedDocumentTitle,
  }));

  cachedBranding.set(hash, request);

  return request;
};
