const normalizeScrollLeft = (element: Element, value: number): number => {
  const isRtl = getComputedStyle(element).direction === 'rtl';
  if (isRtl) {
    return Math.max(
      element.scrollWidth - element.clientWidth - Math.min(value, 0) * -1,
      0
    );
  }

  return Math.max(value, 0);
};

type ScrollReport = {
  isScrollVisible: boolean;
  isArrowUpDisabled: boolean;
  isArrowDownDisabled: boolean;
};

export const getScrollReport = (options: ScrollToOptions): ScrollReport => {
  const container = document.querySelector('.items-container');
  let isScrollVisible = true;
  let isArrowUpDisabled = false;
  let isArrowDownDisabled = false;

  if (container) {
    const scrollLeft = normalizeScrollLeft(
      container,
      options.left ?? container.scrollLeft
    );
    isScrollVisible = container.scrollWidth > container.clientWidth;
    isArrowUpDisabled = scrollLeft < 1;
    isArrowDownDisabled =
      scrollLeft + container.clientWidth >= container.scrollWidth;
  }

  return {
    isScrollVisible,
    isArrowUpDisabled,
    isArrowDownDisabled,
  };
};

export const isScrollNeeded = (): boolean => {
  const menuContainer = document.querySelector('.items-container');
  if (!menuContainer || !menuContainer.scrollWidth) {
    return true;
  }

  return menuContainer.scrollWidth > menuContainer.clientWidth;
};
