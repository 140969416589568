import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureTogglesGridSchema } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/gridSchema';
import { FeatureTogglesGridSchemaBuilder } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/gridSchema.builder';

export const useDefaultFeatureTogglesGridSchema =
  (): FeatureTogglesGridSchema => {
    const { t } = useTranslation();

    const schema = useMemo(
      () =>
        new FeatureTogglesGridSchemaBuilder(t)
          .withFeatureName()
          .withSystem()
          .withTenant()
          .withProperty()
          .build(),
      [t]
    );

    return schema;
  };
