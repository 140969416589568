import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { MultiRegionErrorModal } from 'frontend-container/components/MultiRegionError/Modal';
import { OperationNotAllowedInRegionErrorHandler } from 'frontend-container/publicApi/multiRegion';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { getUrlForNewRegion } from 'frontend-container/utils/region/getUrlForNewRegion';

export interface GlobalErrorHandlers {
  repeatRequestAndRedirect: () => Promise<void>;
}

interface ErrorModal {
  showErrorModal: () => void;
  hideErrorModal: () => void;
  isErrorModalVisible: boolean;
}

export const MultiRegionErrorHandler: FunctionComponent = () => {
  const { hideErrorModal, showErrorModal, isErrorModalVisible } =
    useErrorModal();
  const [globalRegionCode, setGlobalRegionCode] = useState('');
  const { repeatRequestAndRedirect } = useGlobalErrorHandlers(
    showErrorModal,
    setGlobalRegionCode
  );

  return isErrorModalVisible ? (
    <MultiRegionErrorModal
      onClose={hideErrorModal}
      onConfirm={repeatRequestAndRedirect}
      globalRegionCode={globalRegionCode}
    />
  ) : null;
};

const useGlobalErrorHandlers = (
  showErrorModal: () => void,
  setGlobalRegionCode: (globalRegionCode: string) => void
): GlobalErrorHandlers => {
  const [repeatRequestAndRedirect, setRepeatRequestAndRedirectFunction] =
    useState(() => (): Promise<void> => Promise.resolve());

  useEffect(() => {
    const containerNamespace = window.ACP?.container || {
      handleOperationNotAllowedInRegionError: undefined,
    };

    containerNamespace.handleOperationNotAllowedInRegionError =
      getOperationAllowedInGlobalRegionErrorHandler(
        setRepeatRequestAndRedirectFunction,
        showErrorModal,
        setGlobalRegionCode
      );

    return (): void => {
      const containerNamespaceValue = window.ACP?.container || {
        handleOperationNotAllowedInRegionError: undefined,
      };

      containerNamespaceValue.handleOperationNotAllowedInRegionError =
        undefined;
    };
  }, [showErrorModal, setGlobalRegionCode]);

  return {
    repeatRequestAndRedirect,
  };
};

const getOperationAllowedInGlobalRegionErrorHandler =
  (
    setRepeatRequestAndRedirectFunction: (
      stateSetter: () => () => Promise<void>
    ) => void,
    showErrorModal: () => void,
    setGlobalRegionCode: (globalRegionCode: string) => void
  ): OperationNotAllowedInRegionErrorHandler =>
  ({ correctRegionCode, repeatRequest }): void => {
    if (!correctRegionCode) {
      throw new Error('Missing "correctRegionCode"');
    }

    setRepeatRequestAndRedirectFunction(() =>
      getRepeatRequestAndRedirect(correctRegionCode, repeatRequest)
    );

    // order of call `setGlobalRegionCode` and `showErrorModal` is important
    // If you display modal and set region code later, then interpolation in Modal in react-i18next will break
    // (if e.g. region code was changed)
    setGlobalRegionCode(correctRegionCode);
    showErrorModal();
  };

const getRepeatRequestAndRedirect =
  (globalRegionCode: string, repeatModuleRequest: () => Promise<void>) =>
  async (): Promise<void> => {
    try {
      await repeatModuleRequest();
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      redirectToRegion(globalRegionCode);
    }
  };

const redirectToRegion = (regionCode: string): void => {
  const region = findRegionData(regionCode);
  if (!region) {
    throw new Error(`region is not defined; regionCode: ${regionCode}`);
  }
  const newUrl = getUrlForNewRegion(region.code, region.domain);
  window.location.href = newUrl;
};

const useErrorModal = (): ErrorModal => {
  const [isErrorModalVisible, setErrorModalVisibility] = useState(false);
  const hideErrorModal = useCallback(() => setErrorModalVisibility(false), []);
  const showErrorModal = useCallback(() => setErrorModalVisibility(true), []);

  return {
    showErrorModal,
    hideErrorModal,
    isErrorModalVisible,
  };
};
