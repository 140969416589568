import { SessionService } from '@ac/library-utils/dist/services';

export const getPageIdentifier = (): string => {
  const pathnameLength = window.location.pathname.length;

  return createLocationContext(
    window.location.pathname.substring(1, pathnameLength)
  );
};

export const createLocationContext = (basePageIdentifier: string): string => {
  const userLocationKey = basePageIdentifier.replace(/\//g, '_');

  const propertyId = SessionService.getPropertyId() ?? '';

  return `${userLocationKey}_${propertyId}`;
};
