import ReactDOM from 'react-dom/client';
import { ExternalPageConfig } from 'frontend-container/externalPages/externalPagesConfig';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { createReactRoot } from 'frontend-container/utils/createReactRoot';
import { getDefaultPathGuard } from 'frontend-container/utils/getDefaultPathGuard';
import { registerApplication } from 'single-spa';

export const getRegisterExternalPageFunction = ({
  component,
  pagePathname,
  pathGuard,
}: ExternalPageConfig): (() => void) => {
  let root: ReactDOM.Root | undefined;

  return () =>
    registerApplication(
      pagePathname,
      {
        bootstrap: () => Promise.resolve(),
        mount: async () => {
          root = createReactRoot(ROUTER_OUTLET_SELECTOR);
          root.render(component);
        },
        unmount: async () => {
          root?.unmount();
        },
      },
      () => pathGuard?.() ?? getDefaultPathGuard(pagePathname)
    );
};
