import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegExpUtils } from '@ac/library-utils/dist/utils';

const inventoryRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.inventory}.*$`;
const inventoryPropertyRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.inventory}(?!/dashboard).*$`;
const inventorySuperAdminRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.inventory}/dashboard.*$`;

export const isInventoryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventoryRegExp.test(pathname);
};

export const isPropertyDependentInventoryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventoryPropertyRegExp.test(pathname);
};

export const isSuperAdminDependentInventoryModule = (
  pathname: string = window.location.pathname
): boolean => {
  return inventorySuperAdminRegExp.test(pathname);
};

export default {
  isInventoryModule,
  isPropertyDependentInventoryModule,
  isSuperAdminDependentInventoryModule,
};
