import {
  BUSINESS_CONTEXT_DB_KEYS_PROVIDERS,
  BUSINESS_CONTEXT_DB_SCHEMA,
  BusinessContextDbStoreKey,
} from 'frontend-container/shared/businessContext/provider/dbConfig';

import {
  CentralReservationOfficeContextData,
  CustomerContextData,
  PropertyContextData,
  SystemContextData,
  UserContextData,
} from '@ac/library-api';
import { ProfileCentersContextData } from '@ac/library-api/dist/businessContext/profileCenters/types';
import {
  IndexedDBOpenedStoreManager,
  ITransaction,
} from '@ac/library-utils/dist/storage/database';

interface AddToStoreParams<TEntity> {
  transaction: ITransaction;
  storeName: BusinessContextDbStoreKey;
  entity: TEntity;
  entityKeyProvider: (entity: TEntity) => string;
}

export interface BusinessContextCacheWriteRequest {
  user?: UserContextData;
  system?: SystemContextData;
  customer?: CustomerContextData;
  centralReservationOffice?: CentralReservationOfficeContextData;
  property?: PropertyContextData;
  profileCenters?: ProfileCentersContextData;
}

export interface BusinessContextCacheWriter {
  saveMissingDataInCache(
    transaction: ITransaction,
    dataToSave: BusinessContextCacheWriteRequest
  ): Promise<void>;
  clearAllCache(transaction: ITransaction): Promise<void>;
  clearOtherUsersData(
    transaction: ITransaction,
    currentUserId: string
  ): Promise<void>;
}

export class BusinessContextIndexedDBCacheWriter
  implements BusinessContextCacheWriter
{
  async saveMissingDataInCache(
    transaction: ITransaction,
    dataToSave: BusinessContextCacheWriteRequest
  ): Promise<void> {
    const {
      user,
      system,
      customer,
      centralReservationOffice,
      property,
      profileCenters,
    } = dataToSave;

    if (user) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.User,
        entity: user,
        entityKeyProvider: BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.user,
      });
    }

    if (system) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.System,
        entity: system,
        entityKeyProvider: BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.system,
      });
    }

    if (customer) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.Customer,
        entity: customer,
        entityKeyProvider: BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.customer,
      });
    }

    if (centralReservationOffice) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.CentralReservationOffice,
        entity: centralReservationOffice,
        entityKeyProvider:
          BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.centralReservationOffice,
      });
    }

    if (profileCenters) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.ProfileCenters,
        entity: profileCenters,
        entityKeyProvider: BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.profileCenters,
      });
    }

    if (property) {
      await this.addEntityToStore({
        transaction,
        storeName: BusinessContextDbStoreKey.Property,
        entity: property,
        entityKeyProvider: BUSINESS_CONTEXT_DB_KEYS_PROVIDERS.property,
      });
    }
  }

  async clearAllCache(transaction: ITransaction): Promise<void> {
    await Promise.all(
      BUSINESS_CONTEXT_DB_SCHEMA.stores.map((store) =>
        new IndexedDBOpenedStoreManager({
          transaction,
          storeName: store.name,
        }).deleteAllEntities()
      )
    );
  }

  async clearOtherUsersData(
    transaction: ITransaction,
    currentUserId: string
  ): Promise<void> {
    const storeManager = new IndexedDBOpenedStoreManager({
      transaction,
      storeName: BusinessContextDbStoreKey.User,
    });

    const allUser = await storeManager.getAllEntities();
    const usersToRemove = allUser.filter((item) => item.id !== currentUserId);

    if (!usersToRemove.length) {
      return;
    }

    await Promise.all(
      usersToRemove.map((user) => storeManager.deleteEntityById(user.id))
    );
  }

  private async addEntityToStore<TEntity>({
    transaction,
    storeName,
    entity,
    entityKeyProvider,
  }: AddToStoreParams<TEntity>): Promise<void> {
    await new IndexedDBOpenedStoreManager<TEntity>({
      transaction,
      storeName,
    }).updateEntity({
      id: entityKeyProvider(entity),
      value: entity,
    });
  }
}
