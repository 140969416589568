import { createContext } from 'react';

import {
  PageSectionPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export const FeatureTogglesSectionPresenterContext = createContext<
  PageSectionPresenterStore | undefined
>(undefined);

export const useFeatureTogglesSectionPresenterContext =
  (): PageSectionPresenterStore =>
    useDefinedContext(FeatureTogglesSectionPresenterContext);
