import { FieldPattern, ValidationStatus } from '@ac/web-components';

interface Props {
  id: string;
  maxLength?: number;
  required?: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  hideMessageContainer?: boolean;
  validationStatus?: ValidationStatus;
  dataTestSelector?: string;
}

export const PasswordField = (props: Props): JSX.Element => {
  const {
    id,
    maxLength = 128,
    required = true,
    value,
    label,
    onChange,
    className = '',
    hideMessageContainer = false,
    validationStatus,
    dataTestSelector,
  } = props;

  const validation =
    validationStatus && validationStatus.length ? validationStatus : undefined;

  return (
    <ac-encrypted-field
      id={id}
      maxLength={maxLength}
      label={label}
      class={className}
      required={required}
      value={value}
      pattern={FieldPattern.default}
      onKeyUpCallback={(callbackValue: string): void => onChange(callbackValue)}
      hideMessageContainer={hideMessageContainer}
      validationStatus={validation}
      data-test-selector={dataTestSelector}
    />
  );
};
