import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  FlexDirection,
  IconName,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  cashierName?: string;
}

export const CashierInfotipHeader: FC<Props> = ({ cashierName }) => {
  const { t } = useTranslation();

  return (
    <ac-flex alignItems={AlignItems.center} class="ac-gap-sm">
      <ac-icon icon={IconName.cashier} />
      <ac-flex direction={FlexDirection.column}>
        {cashierName ? (
          <ac-text weight={TextWeight.semibold}>{cashierName}</ac-text>
        ) : (
          t('MENU.CASHIER.NOT_OPENED')
        )}
        <ac-text size={TextSize.sm}>{t('MENU.CASHIER.TITLE')}</ac-text>
      </ac-flex>
    </ac-flex>
  );
};
