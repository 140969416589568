import { isChangelogModule } from 'frontend-container/components/Menu/utils/modules/changelog';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const changelogAppName = 'frontend-changelog';
export const registerChangelog = (): void => {
  registerApplication(
    changelogAppName,
    () => loadReactApp(acConfig.frontendUrls.changelog, changelogAppName),
    (location: Location) => isChangelogModule(location.pathname)
  );
};
