import { BusinessContextContainer } from 'frontend-container/publicApi';

import {
  CentralReservationOfficeContextData,
  CustomerContextData,
  isSystemBusinessContextData,
  PropertyContextData,
  SepModuleBusinessContextData,
} from '@ac/library-api';

export const getBusinessContextContainer = ():
  | BusinessContextContainer
  | undefined => window.ACP?.businessContext;

export const getBusinessContextData = (): SepModuleBusinessContextData => {
  const businessContextData = getBusinessContextContainer()?.context;

  if (!businessContextData) {
    throw new Error('BusinessContext is undefined!');
  }

  return businessContextData;
};

export const getCustomerContextData = (): CustomerContextData | undefined => {
  const context = getBusinessContextData();

  return isSystemBusinessContextData(context) ? undefined : context.customer;
};

export const getPropertyContextData = (): PropertyContextData | undefined => {
  const context = getBusinessContextData();

  return context?.property;
};

export const getCroContextData = ():
  | CentralReservationOfficeContextData
  | undefined => {
  const context = getBusinessContextData();

  return context?.centralReservationOffice;
};
