import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnsavedChangesModalPresenter } from 'frontend-container/components/Menu/components/UnsavedChangesModal/presenter';

import { ModalTheme } from '@ac/web-components';

const TRANSLATIONS = {
  TITLE: 'MENU.MODAL.UNSAVED_CHANGES.TITLE',
  DESCRIPTION: 'MENU.MODAL.UNSAVED_CHANGES.DESCRIPTION',
  CANCEL_BUTTON: 'MENU.MODAL.COMMON.CANCEL',
  CONFIRM_BUTTON: 'MENU.MODAL.UNSAVED_CHANGES.CONFIRM_BUTTON_TEXT',
};

export const UnsavedChangesModal: FC = () => {
  const { t } = useTranslation();
  const { isOpen, modal } = useUnsavedChangesModalPresenter(false);

  const [isLoading, setIsLoading] = useState(false);

  const input = modal?.input || {};
  const title = input.title ?? t(TRANSLATIONS.TITLE);
  const description = input.description ?? t(TRANSLATIONS.DESCRIPTION);
  const cancelButtonText =
    input.cancelButtonText ?? t(TRANSLATIONS.CANCEL_BUTTON);
  const confirmButtonText =
    input.confirmButtonText ?? t(TRANSLATIONS.CONFIRM_BUTTON);

  const onConfirm = async (): Promise<void> => {
    if (input?.onConfirm) {
      setIsLoading(true);
      await input.onConfirm();
      setIsLoading(false);
    }
    modal?.confirm();
  };

  return (
    <div>
      {isOpen ? (
        <ac-modal
          modalTitle={title}
          loading={isLoading}
          confirmButton={{
            content: {
              text: confirmButtonText,
            },
            onClick: onConfirm,
            'data-test-selector': 'unsaved-changes-modal-confirm-button',
          }}
          cancelButton={{
            content: {
              text: cancelButtonText,
            },
            onClick: (): void => modal?.close(),
            'data-test-selector': 'unsaved-changes-modal-cancel-button',
          }}
          theme={ModalTheme.alertTheme}
          data-test-selector="unsaved-changes-modal"
        >
          <ac-box>
            <ac-text>{description}</ac-text>
          </ac-box>
        </ac-modal>
      ) : null}
    </div>
  );
};
