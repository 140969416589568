import { logout } from 'frontend-container/components/Menu/components/User/logout';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { getApiConfigForConfigurationRequests } from 'frontend-container/utils/api/getApiConfigForConfigurationRequests';

import {
  GenericOverrideEntity,
  UserContextDetails,
  UserPreferences,
} from '@ac/library-api';
import { UsersApi } from '@ac/library-api/dist/api/v0/identity';

export interface FullCurrentUser {
  userDetails: UserContextDetails;
  department?: GenericOverrideEntity;
  jobTitle?: GenericOverrideEntity;
  userPreferences?: UserPreferences;
}

const getFullCurrentUser = (): FullCurrentUser => {
  const { user } = getBusinessContextData();

  return {
    userDetails: user.details,
    department: user.department,
    jobTitle: user.jobTitle,
    userPreferences: user.preferences,
  };
};

const getCurrentUserDetails = (): UserContextDetails => {
  const { user } = getBusinessContextData();

  return user.details;
};

const getCurrentUserPreferences = (): UserPreferences | undefined => {
  const { user } = getBusinessContextData();

  return user.preferences;
};

const changeMyPassword = async (
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  const { id } = getCurrentUserDetails();

  await UsersApi.postChangePassword({
    data: { oldPassword, newPassword },
    pathParams: { id },
    customConfig: getApiConfigForConfigurationRequests(),
  });
};

export const userService = {
  logout,
  changeMyPassword,
  getCurrentUserDetails,
  getCurrentUserPreferences,
  getFullCurrentUser,
};
