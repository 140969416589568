import { useSendUserDeviceLogCommand } from 'frontend-container/components/panels/maintenancePanel/commands/sendUserDeviceLog';
import { UndefinedFileSizeError } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/commands/errors';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { formatError } from 'frontend-container/utils/errors/formatError';
import { logError } from 'frontend-container/utils/errors/logError';
import { KeysForOverriddenLogLevel } from 'frontend-container/utils/logger/configurationLogKeys';

type MeasureInternetSpeedCommand = () => Promise<void>;

export const useMeasureInternetSpeedCommand =
  (): MeasureInternetSpeedCommand => {
    const store = useInternetSpeedTestContext();
    const sendLogCommand = useSendUserDeviceLogCommand();

    const measureCommand: MeasureInternetSpeedCommand = async () => {
      store.startMeasurement();
      try {
        const measuredInternetSpeedInMbps = await measureInternetSpeed();
        store.saveMeasurement(measuredInternetSpeedInMbps);
        sendLogCommand({ measuredInternetSpeedInMbps });
      } catch (error: unknown) {
        const detailedMessage = formatError(error);
        store.finishMeasurementWithError({
          detailedMessage,
        });

        logError({
          error,
          mainMessage: 'error during Internet Speed Test',
          keyForOverriddenLogLevel:
            KeysForOverriddenLogLevel.InternetSpeedTestError,
        });
      }
    };

    return measureCommand;
  };

interface FileConfig {
  path: string;
}

const smallFile: FileConfig = {
  path: '/assets/internetSpeedTest/image2MB.jpg',
};

const regularFiles: FileConfig[] = [
  {
    path: '/assets/internetSpeedTest/image5MB.jpg',
  },
  {
    path: '/assets/internetSpeedTest/image10MB.jpg',
  },
  {
    path: '/assets/internetSpeedTest/image20MB.jpg',
  },
];

const singleMeasurementSufficientTimeInSeconds = 10;
const speedTestSufficientTotalTimeInSeconds = 20;
const maxFilesCountToFetch = 8;

const getFileConfiguration = (index: number): FileConfig =>
  regularFiles[index] ?? regularFiles.at(-1);

interface ShouldContinueMeasurementsParams {
  measurement: SpeedTestResult;
  totalTimeOfAllMeasurementsInSeconds: number;
  filesCountToFetch: number;
  fileConfigurationIndex: number;
}

const shouldContinueMeasurements = (
  params: ShouldContinueMeasurementsParams
): boolean =>
  params.measurement.timeInSeconds < singleMeasurementSufficientTimeInSeconds &&
  params.totalTimeOfAllMeasurementsInSeconds <
    speedTestSufficientTotalTimeInSeconds &&
  (params.filesCountToFetch < maxFilesCountToFetch ||
    params.fileConfigurationIndex < regularFiles.length);

const measureInternetSpeed = async (): Promise<number> => {
  const firstResult = await loadFile(smallFile, 1);

  // Repeating file download is needed for more precise result - especially for faster internet speeds (above 50Mbps)
  let lastMeasurement = firstResult;
  let totalTimeOfAllMeasurementsInSeconds = firstResult.timeInSeconds;
  let filesCountToFetch = 1;
  let fileConfigurationIndex = 0;
  while (
    shouldContinueMeasurements({
      measurement: lastMeasurement,
      fileConfigurationIndex,
      filesCountToFetch,
      totalTimeOfAllMeasurementsInSeconds,
    })
  ) {
    lastMeasurement = await loadFile(
      getFileConfiguration(fileConfigurationIndex),
      filesCountToFetch
    );

    if (filesCountToFetch >= maxFilesCountToFetch) {
      fileConfigurationIndex++;
    } else {
      filesCountToFetch *= 2;
    }
    totalTimeOfAllMeasurementsInSeconds += lastMeasurement.timeInSeconds;
  }

  return getRoundedInternetSpeed(lastMeasurement.internetSpeedInMbps);
};

const getRoundedInternetSpeed = (internetSpeedInMbps: number): number =>
  Math.round(internetSpeedInMbps * 100) / 100;

const OneMegaBytesInBytes = 1_000_000; // the same value is used in dev tools and online speed tests

interface SpeedTestResult {
  internetSpeedInMbps: number;
  timeInSeconds: number;
}

const loadFile = async (
  file: FileConfig,
  measurementsCount: number
): Promise<SpeedTestResult> => {
  const startTime = Math.round(performance.now());

  const loadingFiles = await Promise.all(
    Array.from({ length: measurementsCount }).map(() =>
      fetchFileWithMeasurement(file)
    )
  );

  const endTime = Math.round(performance.now());

  const singleFileSizeInBytes = loadingFiles[0].fileSizeInBytes;
  const totalTransferredSizeInBytes = singleFileSizeInBytes * measurementsCount;
  const transferredInMB: number =
    totalTransferredSizeInBytes / OneMegaBytesInBytes;
  const transferredInMb = transferredInMB * 8;
  const timeInSeconds = (endTime - startTime) / 1_000;

  const internetSpeedInMbps = transferredInMb / timeInSeconds;

  return {
    internetSpeedInMbps,
    timeInSeconds,
  };
};

const fetchFileWithMeasurement = async (
  fileToFetch: FileConfig
): Promise<FetchingFileResult> => {
  const file = await fetch(`${fileToFetch.path}?v=${Math.random()}`);

  const fileSizeInBytes = file.headers.get('Content-Length');
  if (!fileSizeInBytes) {
    throw new UndefinedFileSizeError();
  }

  return {
    fileSizeInBytes: parseInt(fileSizeInBytes, 10),
  };
};

interface FetchingFileResult {
  fileSizeInBytes: number;
}
