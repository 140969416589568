import { getBusinessContextContainer } from 'frontend-container/shared/businessContext/getBusinessContext';
import { BusinessContextCacheReport } from 'frontend-container/shared/businessContext/provider/cache/report';
import { BUSINESS_CONTEXT_DEFAULT_MAX_ENTITIES_COUNT } from 'frontend-container/shared/businessContext/provider/dbConfig';
import { BusinessContextDependencyConfig } from 'frontend-container/shared/businessContext/provider/dependencyConfig';
import { BusinessContextIndexedDBCacheReportReader } from 'frontend-container/shared/businessContext/provider/report/reader';

import { SepModuleBusinessContextData } from '@ac/library-api';
import { IDatabaseManager } from '@ac/library-utils/dist/storage/database';

export interface BusinessContextDataReport {
  currentGlobalContext?: SepModuleBusinessContextData;
  cache: BusinessContextCacheReport;
}

interface GetReportOptions {
  maxEntitiesToFetch: number;
}

export interface IBusinessContextDataReportCreator {
  getContextStoreReport(
    options: GetReportOptions
  ): Promise<BusinessContextDataReport>;
}

export class BusinessContextDataReportCreator
  implements IBusinessContextDataReportCreator
{
  private readonly allStoreNames: string[];
  private readonly databaseManager?: IDatabaseManager;

  constructor(dependencies: BusinessContextDependencyConfig) {
    this.databaseManager = dependencies.databaseManager;
    this.allStoreNames = (this.databaseManager?.schema.stores ?? []).map(
      (store) => store.name
    );
  }
  async getContextStoreReport(
    options: GetReportOptions
  ): Promise<BusinessContextDataReport> {
    const currentContextData = getBusinessContextContainer()?.context;

    if (!this.databaseManager) {
      return {
        currentGlobalContext: currentContextData,
        cache: this.createEmptyCacheReport(),
      };
    }

    const cacheReport =
      await this.databaseManager.runIsolatedTransaction<BusinessContextCacheReport>(
        {
          mode: 'readonly',
          storeNames: this.allStoreNames,
        },
        async (transaction) => {
          const report =
            await new BusinessContextIndexedDBCacheReportReader().getCacheReport(
              {
                transaction,
                maxEntitiesToFetch:
                  options.maxEntitiesToFetch ??
                  BUSINESS_CONTEXT_DEFAULT_MAX_ENTITIES_COUNT,
              }
            );

          return report;
        }
      );

    return {
      currentGlobalContext: currentContextData,
      cache: cacheReport,
    };
  }

  private createEmptyCacheReport(): BusinessContextCacheReport {
    return {
      user: { items: [], totalCount: 0 },
      system: { items: [], totalCount: 0 },
      customer: { items: [], totalCount: 0 },
      centralReservationOffice: { items: [], totalCount: 0 },
      property: { items: [], totalCount: 0 },
      profileCenters: { items: [], totalCount: 0 },
      entitiesLimitPerStore: 0,
    };
  }
}
