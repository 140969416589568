import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  resourceManagementPropertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const resourceManagementMenu: MenuElement = {
  translation: 'MENU.RESOURCE_MANAGEMENT.TITLE',
  icon: IconName.concierge,
  id: 'menu-resource-management',
  items: [
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/resources`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          resourceManagementPropertyPermissionKeys.viewStaff,
          resourceManagementPropertyPermissionKeys.viewMyDepartmentStaff,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/leave-calendar`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.LEAVE_CALENDAR',
      permissionsConfiguration: {
        permissions: [
          resourceManagementPropertyPermissionKeys.viewLeave,
          resourceManagementPropertyPermissionKeys.viewMyDepartmentLeave,
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
  featureToggles: [FeatureToggleName.ResourceManagementModule],
};
