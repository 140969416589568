import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

const configurationCodes = {
  warningTime: 'WARNINGTIMEBEFORESESSIONIDLETIMEOUT',
  idleTimeout: 'SESSIONIDLETIMEOUT',
};

interface TimeResponse {
  value: number;
}

export interface ConfigurationResponse {
  idleTimeoutInMinutes?: number;
  warningTimeInSecondsBeforeIdleTimeout?: number;
}

export const loadConfiguration = (): ConfigurationResponse => {
  const customerContextData = getCustomerContextData();
  const customerSettings =
    customerContextData?.settings.effectiveSettings ?? [];

  const idleTimeoutInMinutesResponse = customerSettings.find(
    (setting) => setting.code === configurationCodes.idleTimeout
  )?.value as TimeResponse | undefined;
  const warningTimeInSecondsBeforeIdleTimeout = customerSettings.find(
    (setting) => setting.code === configurationCodes.warningTime
  )?.value as TimeResponse | undefined;

  return {
    idleTimeoutInMinutes: idleTimeoutInMinutesResponse?.value,
    warningTimeInSecondsBeforeIdleTimeout:
      warningTimeInSecondsBeforeIdleTimeout?.value,
  };
};
