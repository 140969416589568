import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const housekeepingAppName = 'frontend-housekeeping';
export const registerHousekeeping = (): void => {
  registerApplication(
    housekeepingAppName,
    () => loadReactApp(acConfig.frontendUrls.housekeeping, housekeepingAppName),
    (location: Location) => isHousekeepingModule(location.pathname)
  );
};
