import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { croLandingPagePathname } from 'frontend-container/components/LandingPage';
import {
  profileCenterLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage/location';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
  redirectToPropertyOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getNewUrlForCashieringModule } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForCashieringModule';
import { getNewUrlForHousekeepingModule } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForHousekeepingModule';
import { getNewUrlForReservationsModuleIfNeeded } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForReservationsModuleIfNeeded';
import {
  getCurrentPropertyIdentifierColor,
  getPropertyContexts,
  redirectToCroOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getActiveContextId } from 'frontend-container/components/Menu/utils/getActiveContextId';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isCashieringBillingModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { profileCentersPath } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { PresenterResultType } from 'frontend-container/publicApi';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  getOriginForNewRegion,
  getUrlForNewRegion,
  getUrlWithContextParam,
} from 'frontend-container/utils/region/getUrlForNewRegion';
import { redirectIfContextCroRegionChanged } from 'frontend-container/utils/region/redirectIfContextCroRegionChanged';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';
import { isEmpty } from 'lodash';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';
import { AcSelectOption, AcSelectValue } from '@ac/web-components';

import { useBusinessDateTime } from './components/ContextDropdown/BusinessDate';
import { Context, ContextComponent, ContextType } from '.';

interface Props {
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  color?: string;
}

export const ContextManager = ({
  onLoadingStart,
  onLoadingEnd,
  color,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<Context | undefined>(undefined);

  const activeContextId = getActiveContextId();

  const { propertyId } = useSessionContextTypeIds();

  const { t } = useTranslation();

  const enableLoading = (): void => {
    setIsLoading(true);
    onLoadingStart?.();
  };

  const disableLoading = (): void => {
    setIsLoading(false);
    onLoadingEnd?.();
  };

  const initialize = async (): Promise<void> => {
    const tenantId = LoginService.authData()?.tenantId;

    enableLoading();

    let selectedContext;

    switch (activeContextId) {
      case ContextOption.cro: {
        const croContexts = getCroContexts();
        const propertyContexts = getPropertyContexts();

        if (!tenantId) {
          throw new Error('Tenant ID is undefined');
        }

        if (isEmpty(croContexts) || !isCroEnabled()) {
          return redirectToPropertyOrNoPermissionsPage(propertyContexts.length);
        }

        selectedContext = selectInitialContext(
          croContexts,
          SessionService.getCentralReservationOfficeId()
        );

        if (selectedContext) {
          await selectUnitInBusinessContext({
            tenantId,
            centralReservationOfficeId: selectedContext.id,
            propertyId,
          });
        }

        if (selectedContext) {
          redirectToProperRegionIfNecessary(selectedContext);
        }

        break;
      }
      case ContextOption.property: {
        const croContexts = getCroContexts();
        const propertyContexts = getPropertyContexts();

        if (isEmpty(propertyContexts)) {
          return redirectToCroOrNoPermissionsPage(croContexts);
        }

        selectedContext = selectInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        break;
      }
      case ContextOption.profileCenter: {
        if (!tenantId) {
          throw new Error('Tenant ID is undefined');
        }

        const profileCentersContexts = getProfileCentersContexts();

        if (isEmpty(profileCentersContexts)) {
          return redirectToCroOrNoPermissionsPage(profileCentersContexts);
        }

        selectedContext = selectInitialContext(
          profileCentersContexts,
          SessionService.getProfileCenterId()
        );

        if (selectedContext) {
          await selectUnitInBusinessContext({
            tenantId,
            profileCenterId: selectedContext.id,
          });
        }

        if (selectedContext) {
          redirectToProperRegionIfNecessary(selectedContext);
        }

        break;
      }
      case ContextOption.configuration: {
        const propertyContexts = getPropertyContexts();

        selectedContext = selectInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        break;
      }
    }

    disableLoading();

    if (selectedContext) {
      setSessionContext(selectedContext);
      setSelected(selectedContext);
      redirectToProperRegionIfNecessary(selectedContext);
    }
  };

  const handleSelectProperty = async (
    option: Context,
    discardUnsavedChanges = false
  ): Promise<void> => {
    if (selected?.id === option.id) {
      return;
    }

    setSessionContext(option);
    setSelected(option);

    navigateToPage(propertyLandingPagePathname);

    if (
      !discardUnsavedChanges &&
      window.ACP?.container?.hasUnsavedChanges?.()
    ) {
      const modal = window.ACP?.container?.modals?.unsavedChanges;
      const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

      if (!isUnsavedChangesModalVisible) {
        const result = await modal?.show();

        if (result?.type === PresenterResultType.Confirmed) {
          await handleSelectProperty(option, true);
        } else {
          return;
        }
      }
    }

    const currentPropertyId = SessionService.getPropertyId();
    SessionService.setCashierNumber(undefined);
    SessionService.setPropertyCashierNumber(currentPropertyId, undefined);

    if (option?.regionCode !== selected?.regionCode) {
      const region = findRegionData(
        option?.regionCode ?? getGlobalRegionCode()
      );

      const originForNewRegion = getOriginForNewRegion(
        region?.code ?? '',
        region?.domain ?? ''
      );

      if (isHousekeepingModule() && option.id) {
        return window.location.replace(
          getUrlWithContextParam(
            getNewUrlForHousekeepingModule(
              option.id,
              window.location.pathname,
              originForNewRegion
            )
          )
        );
      }

      const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
        window.location,
        originForNewRegion
      );
      if (newReservationsUrl) {
        return window.location.replace(
          getUrlWithContextParam(newReservationsUrl)
        );
      }

      return window.location.replace(
        getUrlForNewRegion(region?.code ?? '', region?.domain ?? '', option)
      );
    }

    if (isCashieringBillingModule()) {
      return window.location.replace(getNewUrlForCashieringModule());
    }

    if (isHousekeepingModule() && option.id) {
      return window.location.replace(
        getNewUrlForHousekeepingModule(
          option.id,
          window.location.pathname,
          window.location.origin
        )
      );
    }

    const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
      window.location
    );
    if (newReservationsUrl) {
      return window.location.replace(newReservationsUrl);
    }

    if (window.location.search) {
      window.location.replace(window.location.pathname);
    }
  };

  const handleSelectCro = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    const isRedirectedToNewCroRegion =
      redirectIfContextCroRegionChanged(context);

    if (!isRedirectedToNewCroRegion && tenantId) {
      history.replaceState({}, '', croLandingPagePathname);

      void selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: context.id,
        propertyId,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', croLandingPagePathname);
      }
    }

    navigateToPage(croLandingPagePathname);
  };

  const handleSelectConfiguration = (
    selectedOption: Array<AcSelectOption<AcSelectValue>>
  ): void => {
    const selectedOptionValue = selectedOption[0]?.value ?? '';
    const pathToLoad = `${window.location.origin}${selectedOptionValue}`;

    if (selectedOptionValue === profileCenterLandingPagePathname) {
      handleUnsavedChangesRedirect(
        false,
        `${window.location.origin}${profileCenterLandingPagePathname}`,
        async () =>
          await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
      );
      window.location.replace(
        `${window.location.origin}${profileCenterLandingPagePathname}`
      );
      navigateToPage(
        `${window.location.origin}${profileCenterLandingPagePathname}`
      );
    }

    if (selectedOptionValue !== profileCenterLandingPagePathname) {
      handleUnsavedChangesRedirect(
        false,
        pathToLoad,
        async () =>
          await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
      );
      handleMenuItemRedirect(pathToLoad);
    }
  };

  const handleSelectProfileCenter = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    if (tenantId) {
      history.replaceState({}, '', profileCentersPath);

      void selectUnitInBusinessContext({
        tenantId,
        profileCenterId: context.id,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', profileCentersPath);
      }
    }

    navigateToPage(profileCenterLandingPagePathname);
  };

  const handleSelect = (
    option: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges = false,
    tabId = ''
  ): Promise<void> | void => {
    switch (tabId) {
      case ContextOption.cro: {
        return handleSelectCro(option as Context);
        break;
      }
      case ContextOption.property: {
        return handleSelectProperty(option as Context, discardUnsavedChanges);
        break;
      }
      case ContextOption.configuration: {
        return handleSelectConfiguration(
          option as Array<AcSelectOption<AcSelectValue>>
        );
        break;
      }
      case ContextOption.profileCenter: {
        return handleSelectProfileCenter(option as Context);
        break;
      }
      default: {
        throw new Error('Context not defined');
      }
    }
  };

  useEffect(() => {
    void initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColor = (): string | undefined => {
    if (selected?.type === ContextType.PROPERTY) {
      return color ? color : getCurrentPropertyIdentifierColor();
    }

    return;
  };

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selected}
      useDateTime={useBusinessDateTime}
      onSelect={handleSelect}
      color={getColor()}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
    />
  );
};
