import { useEffect } from 'react';
import {
  NotificationsNames,
  PropertyBusinessDateChangedNotification,
} from 'frontend-container/shared/webSockets/types';
import { logger } from 'frontend-container/utils/logger/logger';

import { SessionService } from '@ac/library-utils/dist/services';
import { repeatableCall } from '@ac/library-utils/dist/utils';

export const usePropertyBusinessDateChangedNotification = (
  callback: (newBusinessDate?: string) => void | Promise<void>,
  fallbackIntervalMs: number
): void => {
  useEffect(() => {
    let unsubscribe: () => void;

    const messageHandler = (
      message: PropertyBusinessDateChangedNotification
    ): void => {
      const { propertyId, businessDate } = parseMessage(message);
      const isParsingFailed = !propertyId || !businessDate;
      const isCurrentProperty = SessionService.getPropertyId() === propertyId;

      if (isParsingFailed || isCurrentProperty) {
        callback(businessDate);
      }
    };

    const subscribe = async (): Promise<void> => {
      const notificationType = NotificationsNames.PropertyBusinessDateChanged;

      // TODO: repeatableCall might not be needed after ACFC-2692
      const subscribed = await repeatableCall<boolean>(
        async (): Promise<boolean> => {
          const webSockets = window.ACP?.api?.webSockets;
          if (!webSockets) {
            return false;
          }

          try {
            const notificationChannel = await webSockets.user.subscribe(
              { notificationType },
              messageHandler
            );
            unsubscribe = notificationChannel.unsubscribe;

            return true;
          } catch (error: unknown) {
            const message = error instanceof Error ? error.message : '';
            logger?.error(
              `Websocket subscription failed ${message || notificationType}`
            );

            return false;
          }
        },
        Boolean,
        {
          intervalTime: 1_000,
          repeatCount: 3,
        }
      );

      if (!subscribed) {
        const intervalId = setInterval(callback, fallbackIntervalMs);
        unsubscribe = (): void => {
          clearInterval(intervalId);
        };
      }
    };

    subscribe();

    return () => {
      unsubscribe?.();
    };
  }, [callback, fallbackIntervalMs]);
};

const parseMessage = (
  message: PropertyBusinessDateChangedNotification
): {
  businessDate?: string;
  propertyId?: string;
} => {
  try {
    let businessDate = message.notification.newBusinessDate;
    const suffix = 'T00:00:00';
    // the endpoint business-day/v0/current-date returns date with suffix (2022-02-02:T00:00:00),
    // whereas notification does not (2022-02-02)
    // if we want to compare & store both results they need to have the same format
    if (!businessDate?.includes('T')) {
      businessDate += suffix;
    }

    return {
      propertyId: message.notification.aggregateId,
      businessDate,
    };
  } catch {
    logger?.error('Failed to parse PropertyBusinessDateChangedNotification');

    return {
      propertyId: undefined,
      businessDate: undefined,
    };
  }
};
