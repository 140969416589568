import {
  AllowedAccessConfigurationUnitId,
  loadAllAccessConfiguration,
} from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { mapDictionaryEntityToSelectOption } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/mapToSelectOption';
import { Resource } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/resource';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedMainApplicationMenu,
  getAllowedProfileCenterMenu,
} from 'frontend-container/components/Menu/configuration';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  BusinessContextUnitIdentifier,
  DictionaryEntity,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';
import { AcSelectOption } from '@ac/web-components';

const getCurrentTenantId = (): string => {
  if (!LoginService.isLoggedIn()) {
    throw new Error('Please login to get current tenant ID');
  }

  const tenantId = LoginService.authData?.()?.tenantId;

  if (!tenantId) {
    throw new Error('Tenant ID in undefined');
  }

  return tenantId;
};

export const fetchAllowedPropertyLandingPages = async (
  propertyId: string,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  return fetchAllowedLandingPages(
    getSupportedAllowedAccessConfigurationUnitId({ propertyId }),
    allLandingPages,
    defaultLandingPage,
    getAllowedMainApplicationMenu
  );
};

export const fetchAllowedCroLandingPages = async (
  centralReservationOfficeId: string,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  return fetchAllowedLandingPages(
    getSupportedAllowedAccessConfigurationUnitId({
      centralReservationOfficeId,
    }),
    allLandingPages,
    defaultLandingPage,
    getAllowedCentralReservationOfficeMenu
  );
};
export const fetchAllowedProfileCenterLandingPages = async (
  profileCenterId: string,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  return fetchAllowedLandingPages(
    getSupportedAllowedAccessConfigurationUnitId({
      profileCenterId,
    }),
    allLandingPages,
    defaultLandingPage,
    getAllowedProfileCenterMenu
  );
};

const getSupportedAllowedAccessConfigurationUnitId = (
  identifier: BusinessContextUnitIdentifier
): AllowedAccessConfigurationUnitId => {
  const tenantId = getCurrentTenantId();
  if (identifier.propertyId) {
    return {
      type: 'property',
      propertyId: identifier.propertyId,
      tenantId,
    };
  }

  if (identifier.centralReservationOfficeId) {
    return {
      type: 'centralReservationOffice',
      centralReservationOfficeId: identifier.centralReservationOfficeId,
      tenantId,
    };
  }

  if (identifier.profileCenterId) {
    return {
      type: 'profileCenter',
      profileCenterId: identifier.profileCenterId,
      tenantId,
    };
  }

  throw new Error(
    'At least one business context unit ID for landing page must be defined'
  );
};

const fetchAllowedLandingPages = async (
  landingPagesIdentifier: AllowedAccessConfigurationUnitId,
  allLandingPages: DictionaryEntity[],
  defaultLandingPage: string | undefined,
  getMenuItems: (accessConfiguration: AllAccessConfiguration) => MenuElement[]
): Promise<Resource<Array<AcSelectOption<string>>>> => {
  try {
    const allAccessConfiguration = await loadAllAccessConfiguration(
      landingPagesIdentifier
    );
    const menuElements = getMenuItems(allAccessConfiguration);

    const allowedLandingPages = allLandingPages.filter((page) =>
      menuElements.some((element) =>
        element.items.some((item) => item.code === page.code)
      )
    );

    const isDefaultLandingScreenNotAllowed = !allowedLandingPages.some(
      (page) => page.code === defaultLandingPage
    );
    const landingPagesOptions = allowedLandingPages.map((page) =>
      mapDictionaryEntityToSelectOption(page)
    );

    if (isDefaultLandingScreenNotAllowed) {
      const missingLandingPage = allLandingPages.find(
        (page) => page.code === defaultLandingPage
      );

      if (missingLandingPage) {
        landingPagesOptions.unshift(
          mapDictionaryEntityToSelectOption(missingLandingPage, true)
        );
      }
    }

    return {
      status: 'fulfilled',
      value: landingPagesOptions.filter(isDefined),
    };
  } catch (reason: unknown) {
    return { status: 'rejected', reason };
  }
};
