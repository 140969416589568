import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';

import { ConfigBuilder, UnreadNotificationCount } from '@ac/library-api';
import { UserNotificationsApi } from '@ac/library-api/dist/api/v0/userNotifications';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

export const fetchNotificationCount =
  async (): Promise<UnreadNotificationCount> => {
    const isPropertyContext = !isPathnameWithoutProperty();
    const tenantId = LoginService.authData()?.tenantId;
    const propertyId = SessionService.getPropertyId();

    return (await UserNotificationsApi.getUnreadCount({
      customConfig: new ConfigBuilder()
        .setHeaders({
          'AC-Tenant-ID': isPropertyContext ? null : tenantId,
          'AC-Property-ID': isPropertyContext ? propertyId : null,
        })
        .setSkipCache(true)
        .getConfig(),
    })) as UnreadNotificationCount;
  };
