import { MaintenancePanelMode } from 'frontend-container/components/panels/maintenancePanel/store/state';

import { Config } from '@ac/library-utils/dist/declarations/config/ac.config';

const fullModeEnvironments: Array<Config['environmentType']> = [
  'cm',
  'local',
  'dev',
  'test',
  'uat',
  'pre',
];

// It will be extended by the [ACFA-280] Improve hiding sections in the SEP maintenance panel
// input params: tenantContext: TenantBusinessContextState,
// improvement: check feature toggle or permission (BE decision)
export const determineMaintenancePanelMode = (
  acConfig: Config
): MaintenancePanelMode => {
  if (fullModeEnvironments.includes(acConfig.environmentType)) {
    return 'full';
  }

  // [ACFA-280]: check permissions for 'fullReadonly' mode

  return 'basic';
};
