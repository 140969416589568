const landingPagePathName = '/landing-page';

export const propertyLandingPagePathname = `${landingPagePathName}/property`;

export const croLandingPagePathname = `${landingPagePathName}/cro`;

export const profileCenterLandingPagePathname = `${landingPagePathName}/profile-center`;

export const isLandingPage = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(landingPagePathName);
};

export const isPropertyLandingPage = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(propertyLandingPagePathname);
};

export const isCroLandingPage = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(croLandingPagePathname);
};

export const isProfileCenterLandingPage = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(profileCenterLandingPagePathname);
};
