import { RefreshModalPresenterProviders } from 'frontend-container/components/modals/refreshModal/Providers';
import { RefreshModalContent } from 'frontend-container/components/modals/refreshModal/RefreshModalContent';

export const RefreshModal = (): JSX.Element | null => {
  return (
    <RefreshModalPresenterProviders>
      <RefreshModalContent />
    </RefreshModalPresenterProviders>
  );
};
