import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { loadEmberApp } from 'frontend-container/utils/externalResources/ember';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const configurationAppName = 'frontend-configuration';
export const registerConfiguration = (): void => {
  registerApplication(
    configurationAppName,
    () =>
      loadEmberApp(acConfig.frontendUrls.configuration, configurationAppName),
    (location: Location) => isConfigurationModule(location.pathname)
  );
};
