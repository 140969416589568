import { FC } from 'react';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';

import { acConfig } from '@ac/library-utils/dist/declarations';

interface Props {
  isCashierOpen: boolean;
  toggleOpenCashierModal: () => void;
  toggleChangeSecretModal: () => void;
}

export const CashierInfotipBody: FC<Props> = ({
  isCashierOpen,
  toggleOpenCashierModal,
  toggleChangeSecretModal,
}) => {
  const closeCashierUrl = `${acConfig.newFrontendUrls.cashiering}/cashier-closure`;

  const openCashierTranslationKey = isCashierOpen
    ? 'MENU.CASHIER.SWITCH.TITLE'
    : 'MENU.CASHIER.OPEN.TITLE';

  return (
    <>
      <SubItem
        key={'open-cashier'}
        active={false}
        item={{
          link: '',
          translation: openCashierTranslationKey,
        }}
        onClick={toggleOpenCashierModal}
      />
      <SubItem
        key={'cashier-change-secret'}
        active={false}
        item={{
          link: '',
          translation: 'MENU.CASHIER.CHANGE_SECRET.TITLE',
        }}
        onClick={toggleChangeSecretModal}
      />
      {isCashierOpen && (
        <SubItem
          key={'close-cashier'}
          active={false}
          item={{
            link: '',
            translation: 'MENU.CASHIER.CLOSE.TITLE',
          }}
          onClick={(): void => navigateToPage(closeCashierUrl)}
        />
      )}
    </>
  );
};
