import { InternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { useInternetSpeedTestStore } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/store';

import { PropsWithChildren } from '@ac/react-infrastructure';

export const InternetSpeedTestContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useInternetSpeedTestStore();

  return (
    <InternetSpeedTestContext.Provider value={store}>
      {props.children}
    </InternetSpeedTestContext.Provider>
  );
};
