import { useTranslation } from 'react-i18next';
import { ENGLISH_LANGUAGE } from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';

export const useTranslationWithEnglishInParentheses = (): ((
  translationKey: string
) => string) => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const translate = (translationKey: string): string => {
    if (currentLanguage === ENGLISH_LANGUAGE) {
      return t(translationKey);
    }

    const englishTranslation = t(translationKey, {
      lng: ENGLISH_LANGUAGE,
    });

    if (t(translationKey) === englishTranslation) {
      return t(translationKey);
    }

    const translationWithParentheses = `${t(
      translationKey
    )} (${englishTranslation})`;

    return translationWithParentheses;
  };

  return translate;
};
