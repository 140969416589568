import { FC } from 'react';
import { ButtonTrigger } from 'frontend-container/components/Menu/components/MainMenuInfotip/ButtonTrigger';

import { Color, IconName } from '@ac/web-components';

interface Props {
  isCashierOpen: boolean;
}

export const CashierInfotipTrigger: FC<Props> = ({ isCashierOpen }) => {
  return (
    <ButtonTrigger
      buttonIcon={IconName.cashier}
      statusInactiveIcon={IconName.lock}
      inactiveColor={Color.alert}
      active={isCashierOpen}
    />
  );
};
