import { isTaskManagementModule } from 'frontend-container/components/Menu/utils/modules/taskManagement';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const taskManagementAppName = 'frontend-task-management';
export const registerTaskManagement = (): void => {
  registerApplication(
    taskManagementAppName,
    () =>
      loadReactApp(acConfig.frontendUrls.taskManagement, taskManagementAppName),
    (location: Location) => isTaskManagementModule(location.pathname)
  );
};
