import { isItineraryModule } from 'frontend-container/components/Menu/utils/modules/itinerary';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const itineraryAppName = 'frontend-itinerary';
export const registerItinerary = (): void => {
  registerApplication(
    itineraryAppName,
    () => loadReactApp(acConfig.frontendUrls.itinerary, itineraryAppName),
    (location: Location) => isItineraryModule(location.pathname)
  );
};
