import { useTranslation } from 'react-i18next';
import { useBusinessContextFeatureConfiguration } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/commands/contextConfiguration';

import {
  AlertSeverity,
  AlertSize,
  CheckableInputTheme,
  FlexDirection,
  IconName,
  JustifyContent,
} from '@ac/web-components';

export const BusinessContextFeaturesConfiguration = (): JSX.Element | null => {
  const config = useBusinessContextFeatureConfiguration();
  const { t } = useTranslation();

  return (
    <ac-flex
      direction={FlexDirection.column}
      class="ac-gap-md"
      justifyContent={JustifyContent.center}
    >
      {!config.isCacheEnabledInStorage && (
        <ac-alert
          message={t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.CONFIG.CACHE_WARNING')}
          severity={AlertSeverity.warning}
          size={AlertSize.md}
          class="ac-spacing-top-sm"
        />
      )}

      <ac-flex direction={FlexDirection.row} class="ac-gap-lg">
        <ac-switch
          theme={CheckableInputTheme.dark}
          label={t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.CONFIG.CACHE')}
          checked={config.isCacheEnabled}
          class="ac-spacing-top-sm"
          onChangeCallback={(): void =>
            config.setIsCacheEnabled(!config.isCacheEnabled)
          }
        />
        <ac-switch
          theme={CheckableInputTheme.dark}
          label={t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.CONFIG.CONSOLE_LOGGER')}
          checked={config.isConsoleLoggerEnabled}
          class="ac-spacing-top-sm"
          onChangeCallback={(): void =>
            config.setIsConsoleLoggerEnabled(!config.isConsoleLoggerEnabled)
          }
        />
        <ac-box>
          <ac-button>
            <ac-button-content
              text={t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.CONFIG.SAVE')}
              icon={IconName.save}
              onClick={(): Promise<void> => config.saveChanges()}
            />
          </ac-button>
        </ac-box>
      </ac-flex>
    </ac-flex>
  );
};
