import { isActivityReservationsModule } from 'frontend-container/components/Menu/utils/modules/activityReservations';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const activityReservationsAppName = 'activity-reservations';

export const registerActivityReservations = (): void => {
  registerApplication(
    activityReservationsAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.activityReservations,
        activityReservationsAppName
      ),
    (location: Location) => isActivityReservationsModule(location.pathname)
  );
};
