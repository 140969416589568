import { createContext } from 'react';
import { ConfirmationModalPresenterStore } from 'frontend-container/components/modals/confirmationModal/presenter/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const ConfirmationModalPresenterContext = createContext<
  ConfirmationModalPresenterStore | undefined
>(undefined);

export const useConfirmationModalPresenterContext =
  (): ConfirmationModalPresenterStore =>
    useDefinedContext(ConfirmationModalPresenterContext);
