export enum PresenterResultType {
  Confirmed = 'Confirmed',
  Canceled = 'Canceled',
}

export interface PresenterResult<Output> {
  readonly type: PresenterResultType;
  output?: Output;
}

export interface ContainerPresenter<Input, Output> {
  isVisible: boolean;
  input: Input;
  confirm(output?: Output): void;
  close(output?: Output): void;
  show(input: Input): Promise<PresenterResult<Output>>;
}
