import { acConfig } from '@ac/library-utils/dist/declarations';

export const isFloorPlanModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(
    acConfig.newFrontendUrls.floorPlan ?? '/floor-plan'
  );
};

export default {
  isFloorPlanModule,
};
