import { PrestepPermissionsInProgress } from 'frontend-container/components/Prestep/AccessDenied/PermissionsInProgress/PermissionsInProgress';

import { TenantWebSocketClientsProvider } from '@ac/react-infrastructure';

export const PrestepPermissionsInProgressContainer = (): JSX.Element => {
  return (
    <TenantWebSocketClientsProvider>
      <PrestepPermissionsInProgress />
    </TenantWebSocketClientsProvider>
  );
};
