import { isElementVisible } from 'frontend-container/components/Menu/utils/menuScroll/isElementVisible';
import { isSafariBrowser } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/commands/safariBrowser';

const isSafari = isSafariBrowser();

type ScrollToMenuItemOptions = {
  id: string;
  callback: (options: ScrollToOptions) => void;
  enforce: boolean;
  center: boolean;
};

export const scrollToMenuItem = ({
  id,
  callback,
  enforce,
  center,
}: ScrollToMenuItemOptions): boolean => {
  const element = document.getElementById(id);
  const parent = document.querySelector('.items-container');
  if (!parent || !element) {
    return false;
  }
  // bugs in safari - JS scroll does not work when 'smooth' scroll is set
  // https://bugs.webkit.org/show_bug.cgi?id=242224
  // https://bugs.webkit.org/show_bug.cgi?id=238497
  const scrollBehavior: ScrollBehavior = isSafari ? 'auto' : 'smooth';

  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  if (!enforce) {
    if (isElementVisible(parentRect, elementRect)) {
      callback({ top: 0, left: parent.scrollLeft });

      return false;
    }
  }

  const parentHalfWidth = parentRect.width >> 1; // eslint-disable-line no-bitwise
  const elementHalfWidth = elementRect.width >> 1; // eslint-disable-line no-bitwise
  const offset = elementRect.left - parentRect.left + parent.scrollLeft;
  const left = center ? offset + elementHalfWidth - parentHalfWidth : offset;
  const scrollOptions = { top: 0, left, behavior: scrollBehavior };

  parent.scrollTo(scrollOptions);
  callback(scrollOptions);

  return true;
};
