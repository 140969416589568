import { pathnamesToHelpIdMap } from './pathnamesToHelpIdMap';

let list: Map<string, number>;

let lastCSHID: number | undefined;
export const getCSHID = async (
  topicToOpen?: string,
  useLastCSHId?: boolean
): Promise<number | undefined> => {
  if (useLastCSHId) {
    return lastCSHID;
  }

  if (!list) {
    await downloadAndParseHeaderFile();
  }

  if (topicToOpen === 'CommunicationType') {
    topicToOpen += 'System';
  }

  const keyInTheListFromTopic =
    topicToOpen && underscorize(topicToOpen).toUpperCase();

  let cshid: number | undefined = keyInTheListFromTopic
    ? list.get(keyInTheListFromTopic)
    : undefined;

  const { pathname, search } = window.location;
  if (!cshid) {
    const searchElements = search.split('&');
    const selectedEntityCode =
      searchElements
        .find(
          (item) =>
            item.includes('selectedEntityCode') ||
            item.includes('settingType') ||
            item.includes('dictionaryTypeCode')
        )
        ?.split('=')[1] ?? '';

    const keyInTheListFromLocation =
      selectedEntityCode && underscorize(selectedEntityCode).toUpperCase();

    cshid = keyInTheListFromLocation
      ? list.get(keyInTheListFromLocation)
      : undefined;
  }

  if (!cshid) {
    const pathWithoutGuids = pathname.replace(
      /[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}/g,
      ':id'
    );
    cshid =
      pathnamesToHelpIdMap[`${pathWithoutGuids}${search}`] ||
      pathnamesToHelpIdMap[pathWithoutGuids];
  }

  lastCSHID = cshid;

  return cshid;
};

const underscorize = (name: string): string => {
  return name
    .replace(
      /[A-Z]/g,
      (char, index) => (index !== 0 ? '_' : '') + char.toUpperCase()
    )
    .replace(/-/g, '_');
};

const downloadAndParseHeaderFile = async (): Promise<void> => {
  list = new Map<string, number>();
  const configurationFile = (await import('./ACProject.h')).default;
  const lines = configurationFile
    .split(/\n/)
    .filter((line) => line.startsWith('#define'));
  lines.forEach((line) => {
    const [_, name, id] = line.split(/ /);
    list.set(name, parseInt(id, 10));
  });
};
