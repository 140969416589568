export const mapPermissionArrayToObject = (
  permissionIds: string[]
): Record<string, boolean> => {
  const permissionObject: Record<string, boolean> = {};

  for (const permissionId of permissionIds) {
    permissionObject[permissionId] = true;
  }

  return permissionObject;
};
