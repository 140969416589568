import { useTranslation } from 'react-i18next';
import { TranslationFunction } from 'frontend-container/i18n/translation';

export const getInternetSpeedTestTranslationKey = (key: string): string =>
  `MAINTENANCE_PANEL.INTERNET_SPEED_TEST.${key}`;

export const useInternetSpeedTranslation = (): { t: TranslationFunction } => {
  const { t: originalTranslation } = useTranslation();

  return {
    t: (key: string): string =>
      originalTranslation(getInternetSpeedTestTranslationKey(key)),
  };
};
