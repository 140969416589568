import {
  isAuditChangelog,
  isChangelogModule,
  isDeviceManager,
} from 'frontend-container/components/Menu/utils/modules/changelog';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2TenantContextModule } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isIntegrationsModuleWithoutProperty } from 'frontend-container/components/Menu/utils/modules/integrations';
import { isSuperAdminDependentInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isNotificationSubscriptionsRoute } from 'frontend-container/components/Menu/utils/modules/notifications';

import { LoginService } from '@ac/library-utils/dist/services';

export const isPathnameWithoutProperty = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    isConfigurationModule(pathname) ||
    isConfigurationv2TenantContextModule(pathname) ||
    (isChangelogModule(pathname) &&
      !isAuditChangelog(pathname) &&
      !isDeviceManager(pathname) &&
      !LoginService.isSuperUser()) ||
    isNotificationSubscriptionsRoute(pathname) ||
    isIntegrationsModuleWithoutProperty(pathname) ||
    isSuperAdminDependentInventoryModule(pathname)
  );
};
