import * as actions from 'frontend-container/components/Menu/components/ChangePasswordModal/domain/actions';
import {
  ErrorPayload,
  Store,
} from 'frontend-container/components/Menu/components/ChangePasswordModal/domain/interfaces';
import { Reducer, ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

export const initialState: Store = {
  newPassword: [],
  newPasswordConfirmation: [],
  oldPassword: [],
};

const reducerMap: ReducerMap<Store, unknown> = {
  [actions.addError]: (state, action: Action<ErrorPayload>): Store => ({
    ...state,
    [action.payload.field]: [
      ...state[action.payload.field],
      action.payload.error,
    ],
  }),

  [actions.clearErrors]: () => initialState,
};

export default handleActions(reducerMap, initialState) as Reducer<
  Store,
  unknown
>;
