import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  ButtonPattern,
  CountdownStartupType,
  GenericButton,
  IconName,
  ModalTheme,
} from '@ac/web-components';

interface WarningModalProps {
  initSecondsCount: number;
  onStayOnPage: () => void;
  onLogout: () => void;
}

export const CountdownWarningModal: FC<WarningModalProps> = ({
  initSecondsCount,
  onStayOnPage,
  onLogout,
}) => {
  const { t } = useTranslation();
  const submitButtons = useSubmitButtons(onStayOnPage, onLogout);

  return initSecondsCount !== undefined ? (
    <ac-modal
      modalTitle={t('IDLE_TIMEOUT.WARNING_MODAL.TITLE')}
      theme={ModalTheme.alertTheme}
      customButtons={submitButtons}
    >
      <ac-flex alignItems={AlignItems.center}>
        <ac-timer
          class="ac-spacing-right-md"
          time={initSecondsCount}
          countdownStartup={CountdownStartupType.onInitialize}
        />
        <ac-box>
          {t('IDLE_TIMEOUT.WARNING_MODAL.CONTENT', {
            secondsCount: initSecondsCount,
          })}
        </ac-box>
      </ac-flex>
    </ac-modal>
  ) : null;
};

const useSubmitButtons = (
  onStayOnPage: () => void,
  onLogout: () => void
): GenericButton[] => {
  const { t } = useTranslation();

  const submitButtons = useMemo<GenericButton[]>(
    () => [
      {
        content: {
          text: t('IDLE_TIMEOUT.WARNING_MODAL.FOOTER_BUTTONS.LOG_OUT'),
          icon: IconName.cancel,
        },
        onClick: onLogout,
        pattern: ButtonPattern.tertiary,
      },
      {
        content: {
          text: t('IDLE_TIMEOUT.WARNING_MODAL.FOOTER_BUTTONS.STAY_ON_PAGE'),
        },
        onClick: onStayOnPage,
        pattern: ButtonPattern.primary,
      },
    ],
    [t, onStayOnPage, onLogout]
  );

  return submitButtons;
};
