import {
  onLogoutDoItOnAllTabs,
  popStoredURL,
} from 'frontend-container/utils/loginService/logoutService';

import { LoggerService } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

const getAuthorizationParamsFromPageUrl = (): string =>
  window.location.search.substring(1);

export const redirectHost = (): string => {
  return `${
    window.location.hostname === 'localhost'
      ? acConfig.frontendUrls.localhost
      : acConfig.containerFrontendUrl
  }/login`;
};

const setupRedirectHost = (): void => {
  LoginService.setRedirectUri(redirectHost());
};

let refreshTokenListeners: Array<() => void> = [];

export const addRefreshTokenListener = (
  newListener: () => void
): (() => void) => {
  refreshTokenListeners.push(newListener);

  return () => {
    refreshTokenListeners = refreshTokenListeners.filter(
      (listener) => listener !== newListener
    );
  };
};

const onRefreshSuccessHandler = (): void => {
  refreshTokenListeners.forEach((listener) => listener());
};

type AuthorizationCallback = (newRoute: string) => void;

const onInitSuccessHandler = async (
  newRoute: string,
  callback: AuthorizationCallback
): Promise<void> => {
  LoginService.setOnSuccessHandler(onRefreshSuccessHandler);
  onLogoutDoItOnAllTabs();

  if (!window.frameElement) {
    // FYI LoginService.init need to be done before setting logger
    // because LoginService.init overwrites logger - it needs to be
    // fixed in library-utils
    LoginService.init();
    callback(newRoute);
  }
};

const initLoginService = (callback: AuthorizationCallback): Promise<void> => {
  setupRedirectHost();
  LoginService.setOnSuccessHandler((routeName) =>
    onInitSuccessHandler(routeName, callback)
  );

  if (LoginService.hasAvailableAuthorisationDetails()) {
    return initLoginServiceIfHashReceived();
  }

  return initLoginServiceIfNotLoggedIn();
};

const initLoginServiceIfNotLoggedIn = (): Promise<void> => {
  const { pathname, search } = window.location;
  const rememberedURL = popStoredURL();
  const redirectTo =
    rememberedURL || (pathname === '/login' && '/') || `${pathname}${search}`;

  return LoginService.authorize(redirectTo, true);
};

const initLoginServiceIfHashReceived = (): Promise<void> => {
  return LoginService.authorize(
    undefined,
    false,
    getAuthorizationParamsFromPageUrl()
  );
};

const postHashToParentWindow = (): void => {
  window.parent.postMessage(
    `${LoginService.ACIFRAME_MESSAGE}&${getAuthorizationParamsFromPageUrl()}`,
    redirectHost()
  );
};

const initLoginServiceLogger = (logger: LoggerService): void => {
  LoginService.setLogger(logger.debug);
  LoginService.setOnBeforeRedirect(logger.forceSend);
};

export { initLoginService, initLoginServiceLogger, postHashToParentWindow };
