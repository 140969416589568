import { useEffect, useState } from 'react';

export interface BrowserDateTime {
  date: Date;
}

export const useBrowserDateTime = (
  interval: number = 60_000
): BrowserDateTime => {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const tick = (): void => {
      setDate(new Date());
      const deltaTime = Date.now() % interval;
      const nextTick = interval - deltaTime;
      timeout = setTimeout(tick, nextTick);
    };

    tick();

    return (): void => {
      clearTimeout(timeout);
    };
  }, [interval]);

  return { date };
};
