import { isReservationsGroupsModule } from 'frontend-container/components/Menu/utils/modules/reservations-groups';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const reservationsGroupsAppName = 'frontend-reservations-groups';
export const registerReservationsGroups = (): void => {
  registerApplication(
    reservationsGroupsAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.reservationsGroups,
        reservationsGroupsAppName
      ),
    (location: Location) => isReservationsGroupsModule(location.pathname)
  );
};
