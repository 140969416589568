import {
  Context,
  ContextMapper,
} from 'frontend-container/components/Menu/components/Context';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  EffectiveSettingDetails,
  GeneralCustomerSettingsKeys,
  ProfileCenterDto,
} from '@ac/library-api';

export const getProfileCentersItems = (): ProfileCenterDto[] => {
  const customerContext = getCustomerContextData();

  return customerContext?.customContexts.profileCenters ?? [];
};

export const getProfileCenterItemById = (
  profileCenterId: string
): ProfileCenterDto | undefined => {
  return getProfileCentersItems().find(({ id }) => id === profileCenterId);
};

export const hasAnyProfileCentersContext = (): boolean => {
  return Boolean(getProfileCentersItems().length);
};

export const getProfileCentersContexts = (): Context[] => {
  const profileCenters = getProfileCentersItems();

  return profileCenters.map(ContextMapper.profileCenterToContext);
};

export const getProfileCenterContextById = (
  profileCenterId: string
): Context | undefined => {
  return getProfileCentersContexts().find(({ id }) => id === profileCenterId);
};

export const isProfileCentersEnabled = (): boolean => {
  const customerContext = getCustomerContextData();
  const settings = customerContext?.settings.effectiveSettings;

  if (!settings) {
    return false;
  }

  const profileCentersSetting = settings.find(
    (item) => item.code === GeneralCustomerSettingsKeys.ProfileCenters
  ) as EffectiveSettingDetails<{ value: boolean }> | undefined;

  return profileCentersSetting?.value?.value === true;
};
