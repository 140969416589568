import {
  auditChangelogUrl,
  deviceManagerUrl,
} from 'frontend-container/components/Menu/configuration/changelog';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const isChangelogModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.changelog ?? '');
};

export const isAuditChangelog = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(auditChangelogUrl);
};

export const isDeviceManager = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(deviceManagerUrl);
};

export default {
  isChangelogModule,
  isAuditChangelog,
};
