import {
  AcLibrariesGroup,
  AcLibrary,
  BackendVersion,
  FeLibraryVersionObject,
  FeModule,
  FeModuleVersionResponse,
  FiscalVersionResponse,
  FormattedLibraryVersion,
  LibraryResponse,
  LibraryVersionList,
  MicroservicesVersions,
  Services,
} from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { stripTrailingSlash } from 'frontend-container/utils/stripTrailingSlash';

import {
  AxiosResponse,
  BaseApi,
  ConfigBuilder,
  VersionInfo,
} from '@ac/library-api';
import { FiscalizationApi } from '@ac/library-api/dist/api/v0/integrationFiscal';
import { acConfig } from '@ac/library-utils/dist/declarations';

const feApplicationsWithoutUrl = ['localhost'];
const feApplicationsWithoutLibraryVersions = [
  'userDocumentation',
  'systemMaintenance',
];

type Data<T> = { data: T };
type LibraryVersionResponse = Record<string, string>;
type LibraryVersionsGroupResponse = Record<string, LibraryVersionResponse>;

export const getMicroservicesVersions =
  async (): Promise<MicroservicesVersions> => {
    const frontendEntries = addDefaultUrlForMicroFrontendApps();

    const formatLibrariesVersions = (
      librariesVersionData:
        | LibraryVersionResponse
        | LibraryVersionsGroupResponse
    ): LibraryResponse => {
      const versionsWithOldApiResponse = [
        {
          name: 'dependencies',
          libraries: Object.entries(librariesVersionData)
            .filter(([, value]) => typeof value === 'string')
            .map(([key, value]) => {
              return { name: key, version: value as string };
            }),
        },
      ];
      const versionsWithNewApiResponse = Object.entries(librariesVersionData)
        .filter(([, value]) => typeof value === 'object' && value !== null)
        .map(([key, value]) => {
          return formatVersionsObjects(key, value as AcLibrary);
        });

      return versionsWithNewApiResponse.length > 0
        ? versionsWithNewApiResponse
        : versionsWithOldApiResponse;
    };

    const formatVersionsObjects = (
      key: string,
      versions: AcLibrary
    ): AcLibrariesGroup => {
      const versionTypes = Object.entries(versions).map(([objKey, value]) => {
        return { name: objKey, version: value as string };
      });

      return { name: key, libraries: versionTypes };
    };

    const getFrontendLibraryVersions = async (
      name: string
    ): Promise<Data<FormattedLibraryVersion>> => {
      try {
        const baseUrl = stripTrailingSlash(acConfig.frontendUrls[name]);
        const responseData = await BaseApi.get<undefined, VersionInfo>(
          new ConfigBuilder()
            .setUrl(`${baseUrl}/libraries-versions.json`)
            .setSkipCache(true)
            .getConfig()
        );

        const response = responseData.data;
        const formattedResponse = Array.isArray(response)
          ? response
          : typeof response === 'object' && response !== null
          ? formatLibrariesVersions(
              response as unknown as Record<string, string>
            )
          : [];

        return { data: { moduleName: name, response: formattedResponse } };
      } catch {
        return { data: {} };
      }
    };

    const fetchFrontendAppVersionInfo = async (
      name: string
    ): Promise<Data<Partial<FeModuleVersionResponse>>> => {
      try {
        const baseUrl = stripTrailingSlash(acConfig.frontendUrls[name]);
        const versionResponse = await BaseApi.get<undefined, VersionInfo>(
          new ConfigBuilder()
            .setUrl(`${baseUrl}/version.json`)
            .setSkipCache(true)
            .getConfig()
        );

        return {
          data: { moduleName: name, response: versionResponse.data },
        };
      } catch {
        return { data: {} };
      }
    };

    const fetchBackendVersions = async (): Promise<Data<BackendVersion[]>> => {
      try {
        return await BaseApi.get<undefined, BackendVersion[]>(
          new ConfigBuilder()
            .setUrl(`${acConfig.apiUrl}/deployment/v0/system-version`)
            .getConfig()
        );
      } catch {
        return { data: [] as BackendVersion[] };
      }
    };

    const frontendPromises = frontendEntries
      .map(([name, url]) =>
        url
          ? (async (): Promise<
              Data<Partial<FeModuleVersionResponse>> | undefined
            > => {
              return fetchFrontendAppVersionInfo(name);
            })()
          : undefined
      )
      .filter(Boolean) as Array<
      Promise<AxiosResponse<FeModuleVersionResponse>>
    >;

    const fetchFiscalServiceVersion = async (): Promise<
      FiscalVersionResponse | undefined
    > => {
      const propertyData = getPropertyContextData();
      const propertyId = propertyData?.details.id;
      const propertyName =
        propertyData?.details.code ||
        propertyData?.details.displayName ||
        propertyId;

      if (!propertyId) {
        return undefined;
      }

      try {
        const response = await FiscalizationApi.getVersion({
          customConfig: {
            cacheKey: `fiscal-version-${propertyId}`,
          },
        });

        return {
          propertyName,
          version: response.data.version,
        };
      } catch (error: unknown) {
        return {
          propertyName,
          error,
        };
      }
    };

    const frontendLibraryVersionsPromises = frontendEntries
      .map(([name, url]) =>
        url && !feApplicationsWithoutLibraryVersions.includes(name)
          ? (async (): Promise<Data<FormattedLibraryVersion>> => {
              return getFrontendLibraryVersions(name);
            })()
          : undefined
      )
      .filter(Boolean) as Array<Promise<AxiosResponse<LibraryVersionList>>>;

    const [
      backendResponses,
      frontendResponses,
      frontendLibraryVersionsResponses,
      fiscalServiceVersion,
    ] = await Promise.all([
      fetchBackendVersions(),
      Promise.all(frontendPromises),
      Promise.all(frontendLibraryVersionsPromises),
      fetchFiscalServiceVersion(),
    ]);

    const backendVersions = backendResponses.data.reduce<
      Array<[string, string]>
    >((aggregate, nextBackend) => {
      aggregate.push([
        nextBackend.serviceName,
        `${(nextBackend.version || nextBackend.commitHash) ?? ''}`,
      ]);

      return aggregate;
    }, []);

    const frontendVersions = frontendEntries.reduce<FeModule[]>(
      (versions, currentEntry) => {
        const feModuleResponse = frontendResponses.find(
          (feResponse) => feResponse.data.moduleName === currentEntry[0]
        );
        const feModuleData = feModuleResponse?.data.response;
        const versionInfo =
          feModuleResponse?.data?.response?.buildType === 'develop'
            ? feModuleData?.commitHash
            : feModuleData?.version;
        const moduleName =
          currentEntry[0] === Services.container
            ? 'container'
            : currentEntry[0];
        const buildDate = feModuleData?.buildDate || '';

        versions.push({
          name: `${moduleName ?? '-'}`,
          version: `${versionInfo ?? '-'}`,
          buildDate: `${buildDate ?? '-'}`,
        });

        return versions;
      },
      []
    );

    const frontendLibraryVersions = frontendLibraryVersionsResponses
      .map((item) => item.data)
      .reduce<FeLibraryVersionObject>((versions, nextFrontend) => {
        if (nextFrontend.moduleName) {
          const keyParam =
            nextFrontend.moduleName === Services.container
              ? 'container'
              : nextFrontend.moduleName;
          const sortedLibraryVersions = sortLibraryVersions(
            nextFrontend.response
          );
          versions[keyParam] = sortedLibraryVersions;
        }

        return versions;
      }, {});

    return {
      backendVersions,
      frontendVersions: sortFeModule(frontendVersions),
      frontendLibraryVersions,
      fiscalVersion: fiscalServiceVersion,
    };
  };

export const addDefaultUrlForMicroFrontendApps = (): Array<
  [string, string]
> => {
  const frontendEntries = Object.entries({ ...acConfig.frontendUrls });

  feApplicationsWithoutUrl.forEach((feModuleName: string) => {
    const objIndex = frontendEntries.findIndex(
      (obj) => obj[0] === feModuleName
    );
    if (objIndex > -1 && !frontendEntries[objIndex][1]) {
      frontendEntries[objIndex][1] = '/';
    }
  });

  return frontendEntries;
};

const sortFeModule = (versions: FeModule[]): FeModule[] => {
  return versions.sort((firstVersionItem, secondVersionItem) => {
    return compareStrings(firstVersionItem.name, secondVersionItem.name);
  });
};

const sortLibraryVersions = (
  versionData: AcLibrariesGroup[]
): AcLibrariesGroup[] => {
  const feLibraryVersions = versionData.map((moduleVersions) => {
    const sortedLibraries = moduleVersions.libraries.sort(
      (firstVersionItem, secondVersionItem) => {
        return compareStrings(firstVersionItem.name, secondVersionItem.name);
      }
    );

    return { ...moduleVersions, libraries: sortedLibraries };
  });

  return feLibraryVersions;
};

const compareStrings = (
  firstElement: string,
  secondElement: string
): number => {
  const formattedFirstElement = firstElement.toUpperCase();
  const formattedSecondElement = secondElement.toUpperCase();

  return formattedFirstElement.localeCompare(formattedSecondElement);
};
