import { acConfig } from '@ac/library-utils/dist/declarations';

export const isWorkflowsModule = (
  pathname: string = window.location.pathname
): boolean =>
  pathname?.startsWith(acConfig.newFrontendUrls.workflows || '/workflows');

export default {
  isWorkflowsModule,
};
