import { useEffect } from 'react';
import { getIsNonProduction } from 'frontend-container/components/Menu/service';

const useTrainingColor = (isTraining: boolean): void => {
  useEffect(() => {
    if (isTraining) {
      document.documentElement.style.setProperty(
        '--color-breadcrumbs-in-training',
        'var(--color-pink-in-training)'
      );
    } else {
      document.documentElement.style.removeProperty(
        '--color-breadcrumbs-in-training'
      );
    }
  }, [isTraining]);
};

export const useTrainingFeature = (): boolean => {
  const isTraining = getIsNonProduction();
  useTrainingColor(isTraining);

  return isTraining;
};
