import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const accountReceivablePermissions =
  propertyPermissionKeys.cashiering.accountReceivable;

export const accountsReceivableMenu: MenuElement = {
  translation: 'MENU.ACCOUNTS_RECEIVABLE.TITLE',
  icon: IconName.accountsReceivable,
  id: 'menu-ar-accounts',
  settings: [GeneralCustomerSettingsKeys.AccountsReceivable],
  items: [
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/dashboard`,
      code: 'AccountReceivableDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.accountsReceivable}`,
        `${acConfig.newFrontendUrls.accountsReceivable}/selected-transactions`,
        `${acConfig.newFrontendUrls.accountsReceivable}/:id/selected-transactions`,
        `${acConfig.newFrontendUrls.accountsReceivable}/selected-accounts`,
      ],
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.AR_DASHBOARD',
      permissionsConfiguration: {
        permissions: [accountReceivablePermissions.view],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+a',
      keyboardShortcutMac: '⌥+⌘+a',
      keyCode: 65,
    },
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/transactions`,
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.TR_DASHBOARD',
      permissionsConfiguration: {
        permissions: [accountReceivablePermissions.view],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: 'alt+ctrl+=',
      keyboardShortcutMac: '⌥+⌘+=',
      keyCode: 187,
    },
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/create`,
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.NEW',
      permissionsConfiguration: {
        permissions: [
          accountReceivablePermissions.manage,
          accountReceivablePermissions.manageCreditCardARAccount,
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
  ],
};
