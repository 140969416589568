import { MaintenancePanelPresenterContextProvider } from 'frontend-container/components/panels/maintenancePanel/presenter/Provider';

import {
  createProvider,
  PropsWithChildren,
  ProviderComposer,
} from '@ac/react-infrastructure';

const providers = [
  createProvider({ component: MaintenancePanelPresenterContextProvider }),
];

export const PanelPresenterProviders = (
  props: PropsWithChildren
): JSX.Element => (
  <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
);
