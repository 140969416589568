import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  removeReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const handleMenuItemRedirect = async (
  link: string,
  menuElementItems?: MenuElementItem[],
  onBeforeRedirectCallback?: () => void,
  skipInMenuCheck?: boolean
): Promise<void> => {
  const propertyId = SessionService.getPropertyId() ?? '';
  const tenantId = LoginService.authData()?.tenantId ?? '';
  const fullLink = link
    .replace(/:id/g, propertyId)
    .replace(/:customerId/g, tenantId);

  const isLinkInMenu = menuElementItems?.find(
    (element) => element.link === link
  );
  if (!isLinkInMenu && !skipInMenuCheck) {
    return;
  }
  onBeforeRedirectCallback?.();

  const currentRegionCode = getCurrentRegionCode();
  // Only modules which are editable in global region care for readOnly mode.
  // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
  // That's why we active readOnly mode for modules other then the ones editable in global region.
  if (
    isModuleEditableInGlobalRegionOnly() &&
    !isModuleEditableInGlobalRegionOnly(fullLink)
  ) {
    if (isReadOnlyRequired()) {
      setReadOnlyMode(true);
      removeReadOnlyByUser();
    }

    const units = getPropertyUnits();
    const property = units.find((unit) => unit.unitId === propertyId);
    if (property?.regionCode !== currentRegionCode) {
      window.location.href = fullLink;

      return;
    }
  }

  if (link.indexOf('?foreignCurrencyExchange=true') >= 0) {
    window.location.href = fullLink;
  } else {
    navigateToPage(fullLink);
  }

  return;
};
