import { useTranslation } from 'react-i18next';
import { BusinessContextGridExtendedRowText } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/BusinessContextGridExtendedRowText';
import { BusinessContextStoreReport } from 'frontend-container/shared/businessContext/provider/cache/report';

import { FlexDirection, TextColor, TextWeight } from '@ac/web-components';

import './BusinessContextGridExtendedRow';

interface BusinessContextGridExtendedRowProps {
  store: BusinessContextStoreReport;
  entitiesLimit: number;
}

export const BusinessContextGridExtendedRow = ({
  store,
  entitiesLimit,
}: BusinessContextGridExtendedRowProps): JSX.Element | null => {
  const { t } = useTranslation();
  const outdatedText = t('MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.OUTDATED');

  return (
    <ac-datagrid-cell extendedCell={true}>
      <ac-flex direction={FlexDirection.column} form>
        {store.items.map((item, index) => (
          <ac-box key={index} class="ac-spacing-horizontal-md">
            <ac-divider />

            <ac-flex direction={FlexDirection.row} wrapped>
              <ac-box sizeSm={8}>
                <BusinessContextGridExtendedRowText
                  translationKey="NAME"
                  value={item.name}
                  className="ac-spacing-xs"
                />
              </ac-box>

              <ac-box sizeSm={8}>
                <BusinessContextGridExtendedRowText
                  translationKey="DISPLAY_NAME"
                  value={item.displayName}
                  className="ac-spacing-xs"
                />
              </ac-box>

              <ac-box sizeSm={8}>
                <BusinessContextGridExtendedRowText
                  translationKey="ID"
                  value={item.id}
                  className="ac-spacing-xs"
                />
              </ac-box>

              <ac-box sizeSm={8}>
                <BusinessContextGridExtendedRowText
                  translationKey="UPDATED_ON"
                  value={item.updatedAt}
                  additionalInfo={item.isUpToDate ? undefined : outdatedText}
                  className="ac-spacing-xs"
                />
              </ac-box>
            </ac-flex>
          </ac-box>
        ))}

        {store.totalCount > entitiesLimit ? (
          <ac-text
            color={TextColor.alert}
            weight={TextWeight.semibold}
            class="ac-spacing-md"
          >
            {t(
              'MAINTENANCE_PANEL.BUSINESS_CONTEXT.GRID.NOT_ALL_ENTITIES_LOADED',
              { max: entitiesLimit }
            )}
          </ac-text>
        ) : null}
      </ac-flex>
    </ac-datagrid-cell>
  );
};
