import { LocalizedString } from '@ac/library-api';
import { getUserUILanguageCode } from '@ac/react-infrastructure';

const DEFAULT_DATA_LANGUAGE = 'EN';

export const getLocalizedDescriptionFromLocalizedStrings = (
  descriptions: LocalizedString[]
): string => {
  const uiLanguage = getUserUILanguageCode();

  const description =
    descriptions.find((desc) => desc.languageCode === uiLanguage) ??
    descriptions.find((desc) => desc.languageCode === DEFAULT_DATA_LANGUAGE);

  return description?.content ?? '';
};
