import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import { openUserDocumentation } from '@ac/react-infrastructure';
import {
  ButtonPattern,
  ButtonTheme,
  IconName,
  MenuButtonSize,
  Size,
} from '@ac/web-components';

interface Props {
  className?: string;
}

export const UserDocumentationButton = ({ className }: Props): JSX.Element => {
  const isMenuV2Enabled = getIsMenuV2Enabled();

  return isMenuV2Enabled ? (
    <ac-menu-button
      size={MenuButtonSize.trigger}
      transparent
      onClick={openUserDocumentation}
      class={className}
    >
      <ac-icon icon={IconName.help} size={Size.lg} />
    </ac-menu-button>
  ) : (
    <ac-button
      pattern={ButtonPattern.tertiary}
      theme={!getIsMenuV2Enabled() ? ButtonTheme.light : ButtonTheme.dark}
      onClick={openUserDocumentation}
      class={className}
    >
      <ac-button-content icon={IconName.help} />
    </ac-button>
  );
};
