import { FeatureTogglesData } from '@ac/library-api';

export const mapFeatureTogglesArrayToObject = (
  featureToggles: FeatureTogglesData
): Record<string, boolean> => {
  const featureTogglesObject: Record<string, boolean> = {};

  for (const featureToggle of featureToggles) {
    if (featureToggle.enabled) {
      featureTogglesObject[featureToggle.featureName] = true;
    }
  }

  return featureTogglesObject;
};
