import { isProfilesModule } from 'frontend-container/components/Menu/utils/modules/profiles';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const profilesAppName = 'frontend-profiles';
export const registerProfiles = (): void => {
  registerApplication(
    profilesAppName,
    () => loadReactApp(acConfig.frontendUrls.profiles, profilesAppName),
    (location: Location) => isProfilesModule(location.pathname)
  );
};
