import { BUSINESS_CONTEXT_DB_SCHEMA } from 'frontend-container/shared/businessContext/provider/dbConfig';
import { BusinessContextDependencyConfig } from 'frontend-container/shared/businessContext/provider/dependencyConfig';
import { isBusinessContextCacheEnabled } from 'frontend-container/shared/businessContext/provider/isBusinessContextCacheEnabled';
import { BusinessContextProviderConsoleLogger } from 'frontend-container/shared/businessContext/provider/logger/consoleLogger';
import { BusinessContextProviderKibanaLogger } from 'frontend-container/shared/businessContext/provider/logger/kibanaLogger';
import {
  BusinessContextDataProviderWithCache,
  IBusinessContextDataProvider,
} from 'frontend-container/shared/businessContext/provider/providerWithCache';
import { BusinessContextDataProviderWithoutCache } from 'frontend-container/shared/businessContext/provider/providerWithoutCache';
import {
  BusinessContextDataReportCreator,
  IBusinessContextDataReportCreator,
} from 'frontend-container/shared/businessContext/provider/report/creator';

import { IndexedDBManager } from '@ac/library-utils/dist/storage/database';

const isSettingEnabled = isBusinessContextCacheEnabled();
const isIndexedDbSupported = typeof indexedDB !== 'undefined';

export const shouldCacheBeUsed = isSettingEnabled && isIndexedDbSupported;

const dependencies: BusinessContextDependencyConfig = {
  databaseManager: shouldCacheBeUsed
    ? new IndexedDBManager(BUSINESS_CONTEXT_DB_SCHEMA)
    : undefined,
  loggers: [
    new BusinessContextProviderConsoleLogger(),
    new BusinessContextProviderKibanaLogger(),
  ],
};

export const businessContextProvider: IBusinessContextDataProvider =
  shouldCacheBeUsed
    ? new BusinessContextDataProviderWithCache(dependencies)
    : new BusinessContextDataProviderWithoutCache(dependencies);

export const businessContextReportCreator: IBusinessContextDataReportCreator =
  new BusinessContextDataReportCreator(dependencies);
