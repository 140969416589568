import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { UserDetails } from 'frontend-container/components/Prestep/UserDetails/UserDetails';

import {
  AlignItems,
  ButtonPattern,
  Color,
  IconName,
  JustifyContent,
  Size,
  TransparentContainerTheme,
} from '@ac/web-components';

import './AccessDenied.scss';

interface Props {
  message: string;
}

export const prestepAccessDeniedClass = 'prestep-access-denied';

export const PrestepAccessDenied: FC<Props> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <ac-flex
      class="prestep-view"
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
    >
      <ac-modal
        modalTitle={t('LOGIN_LOGOUT.LOGIN_NO_PERMISSIONS.TITLE')}
        customButtons={[
          {
            content: { text: t('LOGIN_PRESTEP.BUTTONS.LOG_OUT') },
            onClick: () => userService.logout(false, true),
            pattern: ButtonPattern.tertiary,
          },
        ]}
        containerTheme={TransparentContainerTheme.transparent}
        modalTitleIcon={{
          icon: IconName.cancel,
          color: Color.white,
          size: Size.sm,
          dynamicClass: 'prestep-no-permissions-icon ac-spacing-right-sm',
        }}
      >
        <span>
          <ac-flex>
            <ac-box sizeSm={10}>
              <ac-text>{message}</ac-text>
            </ac-box>
            <ac-box sizeSm="auto" grow>
              <UserDetails />
            </ac-box>
          </ac-flex>
        </span>
      </ac-modal>
    </ac-flex>
  );
};
