import { FC } from 'react';
import { CountdownWarningModal } from 'frontend-container/components/AutomaticLogout/CountdownWarningModal/CountdownWarningModal';
import { useConfigurationFromApi } from 'frontend-container/components/AutomaticLogout/data/configuration';
import { useIdleTimeout } from 'frontend-container/components/AutomaticLogout/useIdleTimeout';

import { isDefined } from '@ac/library-utils/dist/utils';

export const AutomaticLogout: FC = () => {
  const configurationFromApi = useConfigurationFromApi();
  const { shouldWarnUser, remainingSeconds, resetIdleTimeout, logoutIdleUser } =
    useIdleTimeout(configurationFromApi);

  return shouldWarnUser && isDefined(remainingSeconds) ? (
    <CountdownWarningModal
      initSecondsCount={remainingSeconds}
      onStayOnPage={resetIdleTimeout}
      onLogout={logoutIdleUser}
    />
  ) : null;
};
