import { useTranslation } from 'react-i18next';

import { AlignItems, Color, IconName } from '@ac/web-components';

import { ReadKeyModalTestSelectors } from '../testSelectors';

export const ReadingFailedStep = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ac-box
      class="ac-padding-botton-xl"
      data-test-selector={ReadKeyModalTestSelectors.ErrorStep}
    >
      <ac-flex class="ac-spacing-bottom-md" alignItems={AlignItems.flexEnd}>
        <ac-icon icon={IconName.error} color={Color.alert} />
        <ac-text class="ac-spacing-left-xs">
          {t('MENU.WORKSTATION.READ_KEY.READING_FAILED')}
        </ac-text>
      </ac-flex>

      <ac-text class="full-width">
        {t('MENU.WORKSTATION.READ_KEY.TRY_AGAIN_MESSAGE')}
      </ac-text>
    </ac-box>
  );
};
