import { BusinessContextSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/presenter/context';

import {
  PageSectionPresenterInitialAppearance,
  PropsWithChildren,
  usePageSectionPresenterStore,
} from '@ac/react-infrastructure';

interface Params {
  initialAppearance: PageSectionPresenterInitialAppearance;
}

export const BusinessContextSectionPresenterContextProvider = (
  props: PropsWithChildren<Params>
): JSX.Element => {
  const store = usePageSectionPresenterStore(props.initialAppearance);

  return (
    <BusinessContextSectionPresenterContext.Provider value={store}>
      {props.children}
    </BusinessContextSectionPresenterContext.Provider>
  );
};
