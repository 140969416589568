import { createContext } from 'react';

import {
  PageSectionPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export const BusinessContextSectionPresenterContext = createContext<
  PageSectionPresenterStore | undefined
>(undefined);

export const useBusinessContextSectionPresenterContext =
  (): PageSectionPresenterStore =>
    useDefinedContext(BusinessContextSectionPresenterContext);
