import { useEffect } from 'react';

export const useInterval = (
  operation: () => void | Promise<void>,
  interval: number
): void => {
  useEffect(() => {
    operation();

    const intervalId = setInterval(operation, interval);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);
};
