import { initializeBaseApiErrorLogger } from 'frontend-container/utils/api/initialization';
import { initLoginServiceLogger } from 'frontend-container/utils/loginService/loginServiceInitializers';
import { initializeMetricsOnce } from 'frontend-container/utils/metrics/initialize';

import { LoggerService } from '@ac/library-api';

export const initServicesWithLogger = async (
  logger: Promise<LoggerService>
): Promise<void> => {
  initializeMetricsOnce(logger);
  const initializedLogger = await logger;
  initializeBaseApiErrorLogger(initializedLogger);
  initLoginServiceLogger(initializedLogger);
};
