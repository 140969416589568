import { isReportsModule } from 'frontend-container/components/Menu/utils/modules/reports';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const reportsAppName = 'frontend-reports';
export const registerReports = (): void => {
  if (acConfig.frontendUrls.reports) {
    registerApplication(
      reportsAppName,
      () => loadReactApp(acConfig.frontendUrls.reports, reportsAppName),
      (location: Location) => isReportsModule(location.pathname)
    );
  }
};
