import { initReactI18next } from 'react-i18next';
import i18n, { TFunction } from 'i18next';
import backend from 'i18next-http-backend';
import moment from 'moment';

import { setUpTranslationsForStandaloneApplication } from '@ac/library-utils/dist/translations/react/standalone';

export const initI18nTranslations = (): void => {
  setUpTranslationsForStandaloneApplication({
    dateUtilsLibraryInstance: moment,
    i18n,
    config: {
      plugins: { backend, initReactI18next },
      repositoryName: 'frontend-container',
      useRemoteTranslations: true,
      initOptions: {
        returnNull: false,
        lowerCaseLng: true,
      },
    },
  });
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const t: TFunction = i18n.t.bind(i18n);

export default i18n;
