import { createContext } from 'react';

import {
  PageSectionPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export const DesignSystemSectionPresenterContext = createContext<
  PageSectionPresenterStore | undefined
>(undefined);

export const useDesignSystemSectionPresenterContext =
  (): PageSectionPresenterStore =>
    useDefinedContext(DesignSystemSectionPresenterContext);
