import { useMemo } from 'react';
import { FeatureTogglesSectionContent } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/FeatureTogglesSectionContent';
import { featureTogglesSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/manifest';
import { useFeatureTogglesSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/presenter/context';
import { useMaintenancePanelFeatureTogglesContext } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/context';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import {
  CollapsiblePanelStatus,
  CollapsiblePanelTitleSize,
} from '@ac/web-components';

export const FeatureTogglesSection = (): JSX.Element | null => {
  const presenter = useFeatureTogglesSectionPresenterContext();
  const {
    state: { isLoading },
  } = useMaintenancePanelFeatureTogglesContext();

  const config = useMemo<PageSectionConfiguration>(
    () => ({
      header: {
        toggleContent: {
          allowToggle: true,
        },
        textSize: CollapsiblePanelTitleSize.auto,
      },
      status: isLoading
        ? {
            status: CollapsiblePanelStatus.loading,
          }
        : undefined,
    }),
    [isLoading]
  );

  return (
    <StandardPageSection
      manifest={featureTogglesSectionManifest}
      presenter={presenter}
      config={config}
    >
      <FeatureTogglesSectionContent />
    </StandardPageSection>
  );
};
