import { BusinessContextDetails } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/BusinessContextDetails';
import { businessContextSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/manifest';
import { useBusinessContextSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/presenter/context';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import { CollapsiblePanelTitleSize } from '@ac/web-components';

const sectionConfig: PageSectionConfiguration = {
  header: {
    toggleContent: {
      allowToggle: true,
    },
    textSize: CollapsiblePanelTitleSize.auto,
  },
};

export const BusinessContextSection = (): JSX.Element | null => {
  const presenter = useBusinessContextSectionPresenterContext();

  return (
    <StandardPageSection
      manifest={businessContextSectionManifest}
      presenter={presenter}
      config={sectionConfig}
    >
      <BusinessContextDetails />
    </StandardPageSection>
  );
};
