import { useEffect, useState } from 'react';
import { useBranding } from 'frontend-container/shared/branding/useBranding';

export const useBrandingLogo = () => {
  const [mainMenuLogo, setMainMenuLogo] = useState<string>();
  const branding = useBranding();

  useEffect(() => {
    const mainMenuLogoBlob = branding?.mainMenuLogo;
    const mainMenuLogoObjUrl =
      mainMenuLogoBlob && URL.createObjectURL(mainMenuLogoBlob);

    setMainMenuLogo(mainMenuLogoObjUrl);

    return (): void => {
      if (mainMenuLogoObjUrl) {
        URL.revokeObjectURL(mainMenuLogoObjUrl);
      }
    };
  }, [branding?.mainMenuLogo]);

  return mainMenuLogo;
};
