import { isTravelAgentCommisionsModule } from 'frontend-container/components/Menu/utils/modules/travelAgentCommissions';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const travelAgentCommissionsAppName =
  'frontend-travel-agent-commissions';
export const registerTravelAgentCommissions = (): void => {
  registerApplication(
    travelAgentCommissionsAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.travelAgentCommissions,
        travelAgentCommissionsAppName
      ),
    (location: Location) => isTravelAgentCommisionsModule(location.pathname)
  );
};
