const CLEAR_CACHE_BEFORE_READ_KEY =
  'aboveCloud.businessContextClearCacheBeforeNextRead';

export const setToClearContextBeforeNextRead = (shouldBeClear: boolean): void =>
  shouldBeClear
    ? localStorage.setItem(CLEAR_CACHE_BEFORE_READ_KEY, 'true')
    : localStorage.removeItem(CLEAR_CACHE_BEFORE_READ_KEY);

export const shouldContextBeCleared = (): boolean =>
  localStorage.getItem(CLEAR_CACHE_BEFORE_READ_KEY) === 'true';
