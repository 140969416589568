import { FeatureTogglesGridSchema } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/gridSchema';
import {
  FeatureTogglesGridHeaderDefinition,
  getFeatureTogglesGridHeaderCells,
} from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/headers';
import { TFunction } from 'i18next';

import { DatagridConfiguration } from '@ac/web-components';

export class FeatureTogglesGridSchemaBuilder {
  private readonly headerDefinition: FeatureTogglesGridHeaderDefinition;

  private headers: DatagridConfiguration[] = [];

  constructor(t: TFunction) {
    this.headerDefinition = getFeatureTogglesGridHeaderCells(t);
  }

  withFeatureName(): this {
    return this.withSingleColumn(this.headerDefinition.featureName);
  }

  withSystem(): this {
    return this.withSingleColumn(this.headerDefinition.system);
  }

  withTenant(): this {
    return this.withSingleColumn(this.headerDefinition.tenant);
  }

  withProperty(): this {
    return this.withSingleColumn(this.headerDefinition.property);
  }

  build(): FeatureTogglesGridSchema {
    return {
      headers: this.headers,
    };
  }

  private withSingleColumn(header: DatagridConfiguration): this {
    this.headers.push(header);

    return this;
  }
}
