import { acConfig } from '@ac/library-utils/dist/declarations';

export const isProfilesModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.profiles ?? '');
};

export default {
  isProfilesModule,
};
