import React from 'react';
import { Error } from 'frontend-container/components/Errors/Error';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import {
  createReactRoot,
  removeReactRoot,
} from 'frontend-container/utils/createReactRoot';
import { registerApplication } from 'single-spa';

import { isUUIDV4 } from '@ac/library-utils/dist/utils';
import { Errors } from '@ac/web-components';

export const pathname503 = '/503';
export const isError503 = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(pathname503);
};
export const error503AppName = 'error-503';
export const registerError503 = (): void => {
  registerApplication(
    error503AppName,
    {
      bootstrap: () => Promise.resolve(),
      mount: async () => {
        const root = createReactRoot(ROUTER_OUTLET_SELECTOR);
        root.render(<Error503 />);
      },
      unmount: async () => {
        removeReactRoot(ROUTER_OUTLET_SELECTOR);
      },
    },
    (location: Location) => isError503(location.pathname)
  );
};

export const Error503 = (): JSX.Element => {
  const id = new URLSearchParams(location.search).get('id') ?? undefined;
  const isIdValid = id && isUUIDV4(id);

  return (
    <Error errorType={Errors.error503} errorId={isIdValid ? id : undefined} />
  );
};
