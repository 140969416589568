import { acConfig } from '@ac/library-utils/dist/declarations';

export const isIndividualReservationsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(
    acConfig.newFrontendUrls.individualReservations ?? ''
  );
};

export default {
  isIndividualReservationsModule,
};
