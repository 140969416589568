import ReactDOM from 'react-dom/client';

const map = new Map<string, ReactDOM.Root>();

const isReactRootExists = (selector: string): boolean => {
  return map.has(selector);
};

export const getReactRoot = (selector: string): ReactDOM.Root | undefined => {
  return map.get(selector);
};

export const removeReactRoot = (selector: string): void => {
  getReactRoot(selector)?.unmount();
  map.delete(selector);
};

export const createReactRoot = (selector: string): ReactDOM.Root => {
  if (isReactRootExists(selector)) {
    return getReactRoot(selector) as ReactDOM.Root;
  }

  const element = document.querySelector(selector);
  if (!element) {
    throw new Error(`Element ${selector} does not exists`);
  }

  const root = ReactDOM.createRoot(element);
  map.set(selector, root);

  return root;
};
