import { useEffect, useState } from 'react';
import { ContainerModals } from 'frontend-container/publicApi';
import {
  ContainerPresenter,
  PresenterResult,
  PresenterResultType,
} from 'frontend-container/publicApi/containerPresenter';

class Result<Output> implements PresenterResult<Output> {
  constructor(
    public readonly type: PresenterResultType = PresenterResultType.Confirmed,
    public readonly output?: Output
  ) {}
}

class Presenter<Input, Output> implements ContainerPresenter<Input, Output> {
  private resolver: (result: PresenterResult<Output>) => void;
  public input: Input;

  constructor(
    public isVisible: boolean = false,
    private setIsVisible: (value: boolean) => void
  ) {}

  confirm(output?: Output): void {
    this.hide();
    this.resolver(new Result(PresenterResultType.Confirmed, output));
  }

  close(output?: Output): void {
    this.hide();
    this.resolver(new Result(PresenterResultType.Canceled, output));
  }

  show(input: Input): Promise<PresenterResult<Output>> {
    if (input) {
      this.input = input;
    }

    this.setIsVisible(true);
    this.isVisible = true;

    return new Promise<PresenterResult<Output>>((resolve) => {
      this.resolver = resolve;
    });
  }

  private hide(): void {
    this.setIsVisible(false);
    this.isVisible = false;
  }
}

export interface UsePresenterResult {
  isOpen: boolean;
}

export const useModalPresenter = (
  container: ContainerModals,
  key: keyof ContainerModals,
  initialState: boolean = false
): UsePresenterResult => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    container[key] = new Presenter(initialState, setIsOpen);

    return (): void => {
      container[key] = undefined;
    };
  }, [container, key, initialState]);

  return { isOpen };
};
