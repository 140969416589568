import { getScriptUrl } from 'frontend-container/utils/externalResources/blazor/urlExtraction';

import {
  importExternalModule,
  loadPage,
} from '@ac/library-utils/dist/utils/micro-frontends/loading/page';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

type BlazorModule = {
  default: {
    getIndexInContainer: () => Promise<AppToRender>;
  };
};

export const loadBlazorApp = async (
  appUrl: string
): Promise<AppToRender<unknown>> =>
  loadPage(appUrl)
    .then((page) => getScriptUrl(page))
    .then((scriptUrl) =>
      importExternalModule<BlazorModule>(`${appUrl}/${scriptUrl}`)
    )
    .then((acModule) => acModule.default.getIndexInContainer());
