import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { navigateToPageEventCreator } from 'frontend-container/shared/navigation/events';
import { navigateToUrl } from 'single-spa';

export const navigateToPage = (url: string): void => {
  navigateToUrl(url);

  const eventBus = getCurrentGlobalEventBus();
  eventBus.dispatch(navigateToPageEventCreator());
};
