import {
  getBusinessContextData,
  getCustomerContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

export const THEME_DARK_KEY = 'aboveCloud.themeDark';

export const shouldUseDarkTheme = (): boolean => {
  return LocalStorage.getParsed<boolean>(THEME_DARK_KEY) || false;
};

export const getIsNonProduction = (): boolean => {
  const customerContext = getCustomerContextData();

  return customerContext?.details?.nonProduction ?? false;
};

export const isBusinessContextDataGoingToChange = (
  pathname: string = window.location.pathname
): boolean => {
  if (isError403IpWhitlelistPage()) {
    return false;
  }

  const { centralReservationOfficeId, propertyId, profileCenterId } =
    getUnitForCurrentLocation(pathname);
  const { centralReservationOffice, property, profileCenters } =
    getBusinessContextData();

  if (centralReservationOfficeId) {
    return centralReservationOfficeId !== centralReservationOffice?.office.id;
  }

  if (profileCenterId) {
    return profileCenterId !== profileCenters?.details.id;
  }

  if (propertyId) {
    return propertyId !== property?.details.id;
  }

  return false;
};
