import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const workflowsMenu: MenuElement = {
  translation: 'MENU.WORKFLOWS.TITLE',
  icon: IconName.rulesGeneric,
  id: 'menu-workflows',
  permissionsConfiguration: {
    permissions: [propertyPermissionKeys.workflows.view],
    permissionsConjunction: PermissionsConjunction.Or,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.workflows}`,
      translation: 'MENU.WORKFLOWS.ITEMS.DASHBOARD',
    },
    {
      link: `${acConfig.newFrontendUrls.workflows}/programs`,
      translation: 'MENU.WORKFLOWS.ITEMS.PROGRAMS',
    },
    {
      link: `${acConfig.newFrontendUrls.workflows}/processes`,
      translation: 'MENU.WORKFLOWS.ITEMS.PROCESSES',
    },
  ],
};
