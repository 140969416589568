import { isGolfModule } from 'frontend-container/components/Menu/utils/modules/golf';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const golfAppName = 'golf';

export const registerGolf = (): void => {
  registerApplication(
    golfAppName,
    () => loadReactApp(acConfig.frontendUrls.golf, golfAppName),
    (location: Location) => isGolfModule(location.pathname)
  );
};
