import { useTranslation } from 'react-i18next';

import { ReadKeyDto } from '@ac/library-api';
import { Color, FlexDirection, IconName } from '@ac/web-components';

import { ReadKeyModalTestSelectors } from '../testSelectors';

interface DataLoadedStepProps {
  data: ReadKeyDto;
}

export const DataLoadedStep = ({
  data: {
    doorKey: {
      guestName = '',
      roomNumber,
      arrivalDate,
      departureDate,
      reservationNumber,
      serialNumber,
    },
  },
}: DataLoadedStepProps): JSX.Element => {
  const [firstName, lastName] = guestName.split(' ');
  const { t } = useTranslation();

  return (
    <ac-box data-test-selector={ReadKeyModalTestSelectors.LoadedStep}>
      <ac-box class="ac-spacing-bottom-md">
        <ac-icon icon={IconName.done} color={Color.success} />
        <ac-text class="ac-spacing-left-xs">
          {t('MENU.WORKSTATION.READ_KEY.KEY_IS_READ')}
        </ac-text>
      </ac-box>
      <ac-flex direction={FlexDirection.row} wrapped class="ac-row-gap-sm">
        {serialNumber && (
          <ac-box sizeSm={8}>
            <ac-box>
              <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.KEY_ID')}>
                {serialNumber}
              </ac-text-group>
            </ac-box>
          </ac-box>
        )}

        {roomNumber && (
          <ac-box sizeSm={8}>
            <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.ROOM_NUMBER')}>
              {roomNumber}
            </ac-text-group>
          </ac-box>
        )}

        {arrivalDate && (
          <ac-box sizeSm={8}>
            <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.ARRIVAL')}>
              {arrivalDate}
            </ac-text-group>
          </ac-box>
        )}

        {departureDate && (
          <ac-box sizeSm={8}>
            <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.DEPARTURE')}>
              {departureDate}
            </ac-text-group>
          </ac-box>
        )}

        {reservationNumber && (
          <ac-box sizeSm={8}>
            <ac-text-group
              label={t('MENU.WORKSTATION.READ_KEY.CONFIRMATION_NUMBER')}
            >
              {reservationNumber}
            </ac-text-group>
          </ac-box>
        )}

        {firstName && (
          <ac-box sizeSm={8}>
            <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.FIRST_NAME')}>
              {firstName}
            </ac-text-group>
          </ac-box>
        )}

        {lastName && (
          <ac-box sizeSm={8}>
            <ac-text-group label={t('MENU.WORKSTATION.READ_KEY.FIRST_NAME')}>
              {lastName}
            </ac-text-group>
          </ac-box>
        )}
      </ac-flex>
    </ac-box>
  );
};
