import { mapBusinessContextDataReportToJsonContent } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/mapToJsonContent/businessContext';
import { mapMicroservicesVersionsToFrontendJsonContent } from 'frontend-container/components/panels/maintenancePanel/features/generateReport/mapToJsonContent/frontendVersions';
import { InternetSpeedTestState } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';
import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';
import { MicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

interface Params {
  environment: EnvironmentDetails;
  microservicesVersions: MicroservicesVersions;
  contextReport?: BusinessContextDataReport;
  internetSpeedTest: InternetSpeedTestState;
  dateTime: string;
}

export const mapMaintenanceDataToJsonContentString = (
  params: Params
): string => {
  const {
    dateTime,
    environment,
    contextReport,
    microservicesVersions,
    internetSpeedTest,
  } = params;

  return JSON.stringify(
    {
      Date: dateTime,
      EnvironmentDetails: environment,
      BackendMicroserviceVersions: {
        ...microservicesVersions.backendVersions.reduce(
          (aggregated, [name, version]) => {
            return {
              ...aggregated,
              [name]: version,
            };
          },
          {}
        ),
      },
      FrontendMicroservicesVersions:
        mapMicroservicesVersionsToFrontendJsonContent(microservicesVersions),
      BusinessContext: contextReport
        ? mapBusinessContextDataReportToJsonContent(contextReport)
        : {},
      InternetSpeedTest: internetSpeedTest,
    },
    undefined,
    '\t'
  );
};
