export const getAppScopeFromPathname = (customPathname?: string): string => {
  const pathname = customPathname || window.location.pathname;
  const pathnameSubstring = pathname.substring(1, pathname.length);
  const indexOfSlash = pathnameSubstring.indexOf('/');

  return pathname.substring(
    0,
    (indexOfSlash >= 0 ? indexOfSlash : pathnameSubstring.length) + 1
  );
};
