import { ConfirmationModalType } from 'frontend-container/components/modals/confirmationModal/presenter/input';

import {
  Color,
  GenericIcon,
  IconName,
  ModalTheme,
  Size,
} from '@ac/web-components';

export interface ConfirmationDefinition {
  translationObjectKey: string;
  theme: ModalTheme;
  titleIcon?: GenericIcon;
}

export const confirmationModalDefinitions = new Map<
  ConfirmationModalType,
  ConfirmationDefinition
>([
  [
    ConfirmationModalType.PageReloadAfterContextClean,
    {
      theme: ModalTheme.alertTheme,
      translationObjectKey:
        'CONFIRMATION_MODAL.PAGE_RELOAD_AFTER_CONTEXT_CLEAR',
    },
  ],
  [
    ConfirmationModalType.PageReloadAfterBusinessContextConfigChange,
    {
      theme: ModalTheme.alertTheme,
      translationObjectKey:
        'CONFIRMATION_MODAL.PAGE_RELOAD_AFTER_BUSINESS_CONTEXT_CONFIG_CHANGE',
    },
  ],
  [
    ConfirmationModalType.PageReloadAfterAfterNetworkTimeout,
    {
      theme: ModalTheme.primaryTheme,
      titleIcon: {
        icon: IconName.error,
        color: Color.alert,
        size: Size.lg,
      },
      translationObjectKey:
        'CONFIRMATION_MODAL.PAGE_RELOAD_AFTER_NETWORK_TIMEOUT',
    },
  ],
]);
