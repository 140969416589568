import { createFormValidator } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/validation/validator';

import { UserPreferencesDetails } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';
import { FormValidationStatuses } from '@ac/react-infrastructure';

type UseFormValidationParams = {
  notAllowedValues: UserPreferencesDetails;
  formValues: UserPreferencesDetails;
};

type UseFormValidationResult = {
  formValidationStatuses: FormValidationStatuses<UserPreferencesDetails>;
  hasAnyError: boolean;
};

export const useFormValidation = ({
  notAllowedValues,
  formValues,
}: UseFormValidationParams): UseFormValidationResult => {
  const formValidationStatuses =
    createFormValidator(notAllowedValues)(formValues);

  return {
    formValidationStatuses,
    hasAnyError: Object.values(formValidationStatuses).some(isDefined),
  };
};
