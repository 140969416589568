import {
  MENU_OPENED_KEY,
  OpenCloseValues,
} from 'frontend-container/components/ContextHelp/constants';
import { unpinContextHelp } from 'frontend-container/components/ContextHelp/utils/pinningHelpers';
import { contextHelpSidePanelVisibilityChanged } from 'frontend-container/publicApi/events';

import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';

export const closeContextHelp = (eventBus: IEventBus): void => {
  sessionStorage.setItem(MENU_OPENED_KEY, OpenCloseValues.Closed);
  unpinContextHelp(eventBus);
  eventBus.dispatch(
    contextHelpSidePanelVisibilityChanged({ isVisible: false })
  );
  (
    window.ACP?.container ?? { isContextHelpOpened: undefined }
  ).isContextHelpOpened = false;
};

export const isOpenedByUser = (): boolean =>
  sessionStorage.getItem(MENU_OPENED_KEY) === OpenCloseValues.Open;
