import { useMemo } from 'react';
import { useConfirmationModalPresenterContext } from 'frontend-container/components/modals/confirmationModal/presenter/context';
import { ConfirmationModalType } from 'frontend-container/components/modals/confirmationModal/presenter/input';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import {
  AuthorizationProcessStatus,
  authorizationProcessStatusChangeEventCreator,
} from '@ac/library-utils/dist/services/login';
import { ModalResultType } from '@ac/react-infrastructure';

interface SetupResult {
  cleanup: () => void;
}

type CommandResult = () => SetupResult;

export const useSetupRefreshModal = (): CommandResult => {
  const presenter = useConfirmationModalPresenterContext();

  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  const setup = (): SetupResult => {
    const removeListener = eventBus.subscribe(
      authorizationProcessStatusChangeEventCreator,
      async (payload) => {
        if (
          payload.authorizationProcessStatus ===
          AuthorizationProcessStatus.NetworkTimeout
        ) {
          const { resultType } = await presenter.show({
            type: ConfirmationModalType.PageReloadAfterAfterNetworkTimeout,
          });

          if (resultType === ModalResultType.Confirmed) {
            window.location.reload();
          }
        }
      }
    );

    return {
      cleanup: (): void => {
        removeListener();
      },
    };
  };

  return setup;
};
