import { InternetSpeedTestSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/presenter/context';

import {
  PageSectionPresenterInitialAppearance,
  PropsWithChildren,
  usePageSectionPresenterStore,
} from '@ac/react-infrastructure';

interface Params {
  initialAppearance: PageSectionPresenterInitialAppearance;
}

export const InternetSpeedTestSectionPresenterContextProvider = (
  props: PropsWithChildren<Params>
): JSX.Element => {
  const store = usePageSectionPresenterStore(props.initialAppearance);

  return (
    <InternetSpeedTestSectionPresenterContext.Provider value={store}>
      {props.children}
    </InternetSpeedTestSectionPresenterContext.Provider>
  );
};
