import { isCacheDataUpToDate } from 'frontend-container/shared/businessContext/provider/cache/invalidation';
import {
  BusinessContextCacheReport,
  BusinessContextStoreReport,
} from 'frontend-container/shared/businessContext/provider/cache/report';
import {
  BUSINESS_CONTEXT_DB_NAMES_PROVIDERS,
  BusinessContextDbStoreKey,
  BusinessContextEntityNames,
} from 'frontend-container/shared/businessContext/provider/dbConfig';

import {
  IndexedDBOpenedStoreManager,
  ITransaction,
} from '@ac/library-utils/dist/storage/database';
import { DateUtils } from '@ac/library-utils/dist/utils';

export interface GetCacheReportParams {
  transaction: ITransaction;
  maxEntitiesToFetch: number;
}

export interface BusinessContextCacheReportReader {
  getCacheReport(
    params: GetCacheReportParams
  ): Promise<BusinessContextCacheReport>;
}

export class BusinessContextIndexedDBCacheReportReader
  implements BusinessContextCacheReportReader
{
  async getCacheReport(
    params: GetCacheReportParams
  ): Promise<BusinessContextCacheReport> {
    const [
      user,
      system,
      customer,
      centralReservationOffice,
      property,
      profileCenters,
    ] = await Promise.all([
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.User,
        namesProvider: BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.user,
      }),
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.System,
        namesProvider: BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.system,
      }),
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.Customer,
        namesProvider: BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.customer,
      }),
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.CentralReservationOffice,
        namesProvider:
          BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.centralReservationOffice,
      }),
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.Property,
        namesProvider: BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.property,
      }),
      this.getStoreReport({
        params,
        storeName: BusinessContextDbStoreKey.ProfileCenters,
        namesProvider: BUSINESS_CONTEXT_DB_NAMES_PROVIDERS.profileCenters,
      }),
    ]);

    return {
      user,
      system,
      customer,
      centralReservationOffice,
      property,
      profileCenters,

      entitiesLimitPerStore: params.maxEntitiesToFetch,
    };
  }

  private async getStoreReport<TData>({
    params: { transaction, maxEntitiesToFetch },
    storeName,
    namesProvider,
  }: GetStoreReportParams<TData>): Promise<BusinessContextStoreReport> {
    const store = new IndexedDBOpenedStoreManager<TData>({
      transaction,
      storeName,
    });

    const totalCount = await store.getEntitiesCount();
    const entitiesToFetch =
      totalCount > maxEntitiesToFetch ? maxEntitiesToFetch : totalCount;
    const entities = await store.getManyEntities(entitiesToFetch);

    return {
      totalCount,
      items: entities.map((entity) => {
        const { name, displayName } = namesProvider(entity.value);

        return {
          id: entity.id,
          name,
          displayName,
          updatedAt: DateUtils.serialization.formatDateTimeForDisplay(
            new Date(entity.updatedAt)
          ),
          isUpToDate: isCacheDataUpToDate(entity.updatedAt),
        };
      }),
    };
  }
}

interface GetStoreReportParams<TEntity> {
  params: GetCacheReportParams;
  storeName: BusinessContextDbStoreKey;
  namesProvider: (entity: TEntity) => BusinessContextEntityNames;
}
