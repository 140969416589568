import { ReloadCurrentBusinessContextDataParameters } from 'frontend-container/publicApi/windowObject';
import { notifyAboutBusinessContextChange } from 'frontend-container/shared/businessContext/events/notifyAboutChange';
import {
  getBusinessContextContainer,
  getBusinessContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { getUnitForCurrentLocation } from 'frontend-container/shared/businessContext/getUnitForCurrentLocation';

import {
  AllBusinessContextElements,
  SepModuleBusinessContextLoader,
} from '@ac/library-api';

export const defaultElementsToReload: AllBusinessContextElements = {
  customer: {
    customContext: true,
    permissions: true,
    settings: true,
  },
  system: {
    permissions: true,
  },
  user: {
    units: true,
    details: false,
    preferences: false,
  },
  property: {
    permissions: true,
    settings: true,
    details: false,
    businessDate: false,
  },
  centralReservationOffice: {
    permissions: true,
    office: false,
  },
  profileCenters: {
    details: false,
  },
};

const defaultParameters: ReloadCurrentBusinessContextDataParameters = {
  elementsToReload: defaultElementsToReload,
};

export const reloadCurrentBusinessContextData = async ({
  elementsToReload = defaultElementsToReload,
}: ReloadCurrentBusinessContextDataParameters = defaultParameters): Promise<void> => {
  const container = getBusinessContextContainer();
  const context = getBusinessContextData();

  if (!context || !container) {
    throw new Error(
      'Cannot reload business context without context defined in the container'
    );
  }

  const contextLoader = new SepModuleBusinessContextLoader();

  contextLoader.clearApiCache(elementsToReload);

  const requiredUnitId = getUnitForCurrentLocation();

  container.clearCache();

  const updatedContext = await new SepModuleBusinessContextLoader().load(
    requiredUnitId
  );

  container.context = updatedContext;

  notifyAboutBusinessContextChange();
};
