import { LocalizedString, LocalizedStringDto } from '@ac/library-api';

export type AllowedPosition = '1' | '2' | '3';

export interface RawMenuPosition {
  name: LocalizedStringDto[];
  position: AllowedPosition;
  url: string;
}

export class MenuPosition {
  name: LocalizedString[];
  position: AllowedPosition;
  url: string;
  constructor(raw: RawMenuPosition) {
    this.name = raw.name.map((item) => new LocalizedString(item));
    this.position = raw.position;
    this.url = raw.url;
  }
}
