import { formatError } from 'frontend-container/utils/errors/formatError';
import { KeysForOverriddenLogLevel } from 'frontend-container/utils/logger/configurationLogKeys';
import { logger } from 'frontend-container/utils/logger/logger';

interface ErrorToLog {
  error: unknown;
  mainMessage: string;
  keyForOverriddenLogLevel: KeysForOverriddenLogLevel;
}

export const logError = (log: ErrorToLog): void => {
  const errorDetails = formatError(log.error);

  // eslint-disable-next-line no-console
  console.error(log.mainMessage, { errorDetails });

  logger?.error(
    {
      message: log.mainMessage,
      errorDetails,
    },
    {
      keyForOverriddenLogLevel: log.keyForOverriddenLogLevel,
    }
  );
};
