import { DataFindingResult } from 'frontend-container/shared/businessContext/provider/cache/reader';
import { NewContextFetchingLogParams } from 'frontend-container/shared/businessContext/provider/logger/logger';

type GetReportForElementParams = {
  cacheReadResponse: DataFindingResult<unknown> | undefined;
  apiResponse: boolean;
  cacheWriteRequest: boolean;
};

const YES = 'Yes';
const NO = 'No';

type ReportRow = {
  ['Requested unit']: string;
  ['In cache']?: string;
  ['Up to date']?: string;
  ['Fetched from API']?: string;
  ['Saved in Cache']?: string;
};

type Report = {
  [key: string]: ReportRow;
};

const getReportForElement = ({
  cacheReadResponse,
  apiResponse,
  cacheWriteRequest,
}: GetReportForElementParams): ReportRow => {
  const row: ReportRow = {
    ['Requested unit']: cacheReadResponse ? YES : NO,
  };

  if (!cacheReadResponse) {
    return row;
  }

  const isUpToDateInCache = cacheReadResponse.cacheResult?.isUpToDate;

  return {
    ...row,
    ['In cache']: cacheReadResponse.cacheResult?.entity ? YES : NO,
    ['Up to date']: isUpToDateInCache ? YES : NO,
    ['Fetched from API']: !isUpToDateInCache && apiResponse ? YES : NO,
    ['Saved in Cache']: cacheWriteRequest ? YES : NO,
  };
};

export const generateFetchReportTable = (
  params: NewContextFetchingLogParams
): Report => {
  return {
    user: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.user,
      apiResponse: !!params.dataFetchedFromApi.user,
      cacheWriteRequest: !!params.cacheWriteRequest.user,
    }),
    system: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.system,
      apiResponse: !!params.dataFetchedFromApi.system,
      cacheWriteRequest: !!params.cacheWriteRequest.system,
    }),
    customer: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.customer,
      apiResponse: !!params.dataFetchedFromApi.customer,
      cacheWriteRequest: !!params.cacheWriteRequest.customer,
    }),
    centralReservationOffice: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.centralReservationOffice,
      apiResponse: !!params.dataFetchedFromApi.centralReservationOffice,
      cacheWriteRequest: !!params.cacheWriteRequest.centralReservationOffice,
    }),
    property: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.property,
      apiResponse: !!params.dataFetchedFromApi.property,
      cacheWriteRequest: !!params.cacheWriteRequest.property,
    }),
    profileCenters: getReportForElement({
      cacheReadResponse: params.cacheReadResponse.profileCenters,
      apiResponse: !!params.dataFetchedFromApi.profileCenters,
      cacheWriteRequest: !!params.cacheWriteRequest.profileCenters,
    }),
  };
};
