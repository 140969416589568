import { mapFeatureTogglesArrayToObject } from 'frontend-container/components/Menu/authorization/mapFeatureTogglesArrayToObject';
import { mapPermissionArrayToObject } from 'frontend-container/components/Menu/authorization/mapPermissionArrayToObject';
import { mapSettingsArrayToSettingsObject } from 'frontend-container/components/Menu/authorization/mapSettingsArrayToSettingsObject';
import {
  AccessConfiguration,
  AccessSource,
  AllAccessConfiguration,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuPosition } from 'frontend-container/components/Menu/menuPosition';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  BusinessContextUnitIdentifier,
  CentralReservationOfficeContextDataLoader,
  EffectiveSettingDetails,
  FeatureTogglesData,
  isCentralReservationOfficeBusinessContextData,
  isPropertyBusinessContextData,
  isSystemBusinessContextData,
  isTenantBusinessContextData,
  PropertyContextDataLoader,
  SepModuleBusinessContextData,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';

export const getAllAccessConfiguration = (): AllAccessConfiguration => {
  const businessContextData = getBusinessContextData();
  const cache = {} as AllAccessConfiguration;

  return {
    currentSource: resolveCurrentAccessSource({
      tenantId: businessContextData.customer?.details.id,
      centralReservationOfficeId:
        businessContextData.centralReservationOffice?.office.id,
      propertyId: businessContextData.property?.details.id,
    }),
    get [AccessSource.Property](): AccessConfiguration {
      return (
        cache[AccessSource.Property] ||
        (cache[AccessSource.Property] = getAccessConfiguration(
          AccessSource.Property,
          businessContextData
        ))
      );
    },
    get [AccessSource.CentralReservationOffice](): AccessConfiguration {
      return (
        cache[AccessSource.CentralReservationOffice] ||
        (cache[AccessSource.CentralReservationOffice] = getAccessConfiguration(
          AccessSource.CentralReservationOffice,
          businessContextData
        ))
      );
    },
    get [AccessSource.Tenant](): AccessConfiguration {
      return (
        cache[AccessSource.Tenant] ||
        (cache[AccessSource.Tenant] = getAccessConfiguration(
          AccessSource.Tenant,
          businessContextData
        ))
      );
    },
    get [AccessSource.System](): AccessConfiguration {
      return (
        cache[AccessSource.System] ||
        (cache[AccessSource.System] = getAccessConfiguration(
          AccessSource.System,
          businessContextData
        ))
      );
    },
  };
};

export const resolveCurrentAccessSource = (
  identifier: BusinessContextUnitIdentifier
): AccessSource => {
  if (identifier.centralReservationOfficeId !== undefined) {
    return AccessSource.CentralReservationOffice;
  }

  if (identifier.propertyId !== undefined) {
    return AccessSource.Property;
  }

  return identifier.tenantId !== undefined
    ? AccessSource.Tenant
    : AccessSource.System;
};

const getAccessConfiguration = (
  type: AccessSource,
  businessContextData: SepModuleBusinessContextData
): AccessConfiguration => {
  let featureToggles: FeatureTogglesData;
  let permissionIds: string[];
  let settings: EffectiveSettingDetails[];

  if (
    type === AccessSource.System &&
    isSystemBusinessContextData(businessContextData)
  ) {
    featureToggles = businessContextData.system.featureToggles;
    permissionIds = businessContextData.system.permissions.permissionIds;
    settings = [];
  } else if (
    type === AccessSource.Property &&
    isPropertyBusinessContextData(businessContextData)
  ) {
    featureToggles = businessContextData.property?.featureToggles || [];
    permissionIds =
      businessContextData.property?.permissions.permissionIds || [];
    settings =
      businessContextData.property?.settings.effectiveSettingsDetails || [];
  } else if (
    type === AccessSource.CentralReservationOffice &&
    isCentralReservationOfficeBusinessContextData(businessContextData)
  ) {
    featureToggles = businessContextData.customer.featureToggles;
    permissionIds =
      businessContextData.centralReservationOffice.permissions.permissionIds;
    settings = businessContextData.customer.settings.effectiveSettings;
  } else if (
    type === AccessSource.Tenant &&
    isTenantBusinessContextData(businessContextData)
  ) {
    featureToggles = businessContextData.customer.featureToggles;
    permissionIds = businessContextData.customer.permissions.permissionIds;
    settings = businessContextData.customer.settings.effectiveSettings;
  } else {
    featureToggles = [];
    permissionIds = [];
    settings = [];
  }

  return {
    settings: mapSettingsArrayToSettingsObject(settings),
    permissions: mapPermissionArrayToObject(permissionIds),
    featureToggles: mapFeatureTogglesArrayToObject(featureToggles),
  };
};

export type AllowedAccessConfigurationUnitId =
  | {
      type: 'centralReservationOffice';
      centralReservationOfficeId: string;
      tenantId: string;
    }
  | {
      type: 'profileCenter';
      profileCenterId: string;
      tenantId: string;
    }
  | {
      type: 'property';
      propertyId: string;
      tenantId: string;
    };

export const loadAllAccessConfiguration = async (
  identifier: AllowedAccessConfigurationUnitId
): Promise<AllAccessConfiguration> => {
  const businessContextData = getBusinessContextData();
  const requiredContext = tryAdjustContextToUnitIdentifier(
    businessContextData,
    identifier
  );

  const currentSource = resolveCurrentAccessSource(identifier);
  const accessConfiguration = requiredContext
    ? getAccessConfiguration(currentSource, requiredContext)
    : await fetchAccessConfiguration(identifier);

  return {
    ...getAllAccessConfiguration(),
    currentSource,
    [currentSource]: accessConfiguration,
  };
};

const fetchAccessConfiguration = async (
  identifier: AllowedAccessConfigurationUnitId
): Promise<AccessConfiguration> => {
  const allAccessConfiguration = getAllAccessConfiguration();

  const [featureTogglesResult, settingsResult, permissionsResult] =
    await Promise.allSettled([
      identifier.type === 'property'
        ? fetchPropertyFeatureToggles(identifier.propertyId)
        : allAccessConfiguration.Tenant.featureToggles,
      identifier.type === 'property'
        ? fetchPropertySettings(identifier.propertyId)
        : allAccessConfiguration.Tenant.settings,
      fetchPermissionIds(identifier),
    ]);

  return {
    featureToggles:
      featureTogglesResult.status === 'fulfilled'
        ? featureTogglesResult.value
        : {},
    settings: settingsResult.status === 'fulfilled' ? settingsResult.value : {},
    permissions:
      permissionsResult.status === 'fulfilled' ? permissionsResult.value : {},
  };
};

const fetchPropertyFeatureToggles = (
  propertyId: string
): Promise<Record<string, boolean>> => {
  return new PropertyContextDataLoader(propertyId)
    .loadFeatureToggles()
    .then((featureTogglesData) =>
      mapFeatureTogglesArrayToObject(featureTogglesData)
    );
};

const fetchPropertySettings = async (
  propertyId: string
): Promise<Record<string, boolean | MenuPosition[] | unknown>> => {
  const businessContext = getBusinessContextData();
  if (isSystemBusinessContextData(businessContext)) {
    return {};
  }

  const settings = await new PropertyContextDataLoader(
    propertyId
  ).loadSettings();

  return mapSettingsArrayToSettingsObject(settings.effectiveSettingsDetails);
};

const fetchPermissionIds = async (
  identifier: AllowedAccessConfigurationUnitId
): Promise<Record<string, boolean>> => {
  if (identifier.type === 'centralReservationOffice') {
    return new CentralReservationOfficeContextDataLoader(
      identifier.centralReservationOfficeId
    )
      .loadPermissions()
      .then((contextData) =>
        mapPermissionArrayToObject(contextData.permissionIds)
      );
  }

  if (identifier.type === 'profileCenter') {
    return {};
  }

  if (!identifier.propertyId) {
    throw new Error(
      'Cannot fetch permissionIds when propertyId/croId is not defined'
    );
  }

  return new PropertyContextDataLoader(identifier.propertyId)
    .loadPermissions()
    .then((contextData) =>
      mapPermissionArrayToObject(contextData.permissionIds)
    );
};

export const getCurrentAccessSource = (): AccessSource => {
  const businessContextData = getBusinessContextData();

  const accessSource = resolveCurrentAccessSource({
    tenantId: businessContextData.customer?.details.id,
    centralReservationOfficeId:
      businessContextData.centralReservationOffice?.office.id,
    propertyId: businessContextData.property?.details.id,
  });

  return accessSource;
};
