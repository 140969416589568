import { acConfig } from '@ac/library-utils/dist/declarations';

export const isResourceManagementModule = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    !!acConfig.newFrontendUrls.resourceManagement &&
    pathname?.startsWith(acConfig.newFrontendUrls.resourceManagement)
  );
};

export default {
  isResourceManagementModule,
};
