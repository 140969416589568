import { MicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';

export const mapMicroservicesVersionsToFrontendJsonContent = ({
  frontendLibraryVersions,
  frontendVersions,
}: MicroservicesVersions): object => {
  return {
    ...frontendVersions.reduce((aggregated, feModule) => {
      const feLibraryVersions =
        frontendLibraryVersions[feModule.name] &&
        frontendLibraryVersions[feModule.name].length > 0 &&
        frontendLibraryVersions[feModule.name].reduce(
          (aggregatedValue, next) => {
            return {
              ...aggregatedValue,
              ['Build date']: feModule.buildDate,
              [next.name]: next.libraries,
            };
          },
          {}
        );

      return {
        ...aggregated,
        [feModule.name]: {
          version: feModule.version,
          libraryVersions: {
            feLibraryVersions,
          },
        },
      };
    }, {}),
  };
};
