import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  FeatureToggleName,
  GolfCustomerSettingsKeys,
  GolfPropertySettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const getGolfMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();
  const customerContextData = getCustomerContextData();

  const propertySettings = propertyContextData
    ? propertyContextData?.settings.effectiveSettingsDetails
    : [];
  const customerSettings = customerContextData
    ? customerContextData?.settings.effectiveSettings
    : [];

  const settings = [...propertySettings, ...customerSettings];

  const hasPrestep = (
    settings.find(
      ({ code }) => code === GolfPropertySettingsKeys.GolfUsePrestep
    )?.value as { value: boolean }
  )?.value;

  return {
    translation: 'MENU.GOLF.TITLE',
    icon: IconName.golf,
    id: 'menu-golf',
    settings: [GolfCustomerSettingsKeys.GolfReservations],
    items: [
      {
        link: `${acConfig.newFrontendUrls.golf}/reservations/dashboard`,
        translation: 'MENU.GOLF.ITEMS.DASHBOARD',
        featureToggles: [FeatureToggleName.GolfDashboard],
        aliases: [`${acConfig.newFrontendUrls.golf}/reservations/`],
      },
      {
        link: `${acConfig.newFrontendUrls.golf}/reservations/planner`,
        translation: 'MENU.GOLF.ITEMS.PLANNER',
        featureToggles: [FeatureToggleName.GolfPlanner],
      },
      {
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/golf`
          : `${acConfig.newFrontendUrls.golf}/reservations/new`,
        translation: 'MENU.GOLF.ITEMS.CREATE_RESERVATION',
        featureToggles: [
          FeatureToggleName.GolfReservationsModule,
          FeatureToggleName.GolfCreateReservation,
        ],
        aliases: [
          `${acConfig.newFrontendUrls.golf}/reservation/new`,
          `${acConfig.newFrontendUrls.golf}/reservations/new`,
        ],
      },
      {
        link: `${acConfig.newFrontendUrls.golf}/configuration/course/dashboard`,
        translation: 'MENU.GOLF.ITEMS.COURSE_MANAGER',
        aliases: [`${acConfig.newFrontendUrls.golf}/configuration/`],
      },
    ],
    featureToggles: [FeatureToggleName.GolfReservationsModule],
  };
};
