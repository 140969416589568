import { PagingInfoDto } from '@ac/library-api';
import { DatagridSortDirection } from '@ac/web-components';

export interface FeatureToggleInfo {
  featureName: string;
  availability: {
    system: boolean;
    tenant: boolean;
    property: boolean;
  };
  isEnabled: boolean;
}

export interface FeatureTogglesFilters {
  search?: string;
  enabledOnly: boolean;
}

export interface FeatureTogglesSortValue {
  name: keyof FeatureToggleInfo;
  direction: DatagridSortDirection;
}

export interface MaintenancePanelFeatureTogglesState {
  allLoadedItems: FeatureToggleInfo[];
  filteredItems: FeatureToggleInfo[];
  visibleItems: FeatureToggleInfo[];
  paging: PagingInfoDto;
  filters: FeatureTogglesFilters;
  sort: FeatureTogglesSortValue;
  isLoading: boolean;
}

export const getDefaultMaintenancePanelFeatureTogglesState =
  (): MaintenancePanelFeatureTogglesState => ({
    allLoadedItems: [],
    filteredItems: [],
    visibleItems: [],
    paging: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
    },
    filters: {
      enabledOnly: false,
    },
    sort: {
      name: 'featureName',
      direction: DatagridSortDirection.ascending,
    },
    isLoading: false,
  });
