import throttle from 'lodash.throttle';

interface RegistrationCallbackResult {
  cleanUpAfterUserEventsRegistration: () => void;
}

export const registerCallbackToUserEvents = (
  callbackToTriggerOnUserInteraction: () => void
): RegistrationCallbackResult => {
  const userEvents = getUserEventsToReact();

  const delayedCallback = throttle(callbackToTriggerOnUserInteraction, 1000);

  userEvents.forEach((eventName) =>
    window.addEventListener(eventName, delayedCallback)
  );

  const cleanUp = (): void => {
    userEvents.forEach((eventName) =>
      window.removeEventListener(eventName, delayedCallback)
    );
    delayedCallback.cancel();
  };

  return {
    cleanUpAfterUserEventsRegistration: cleanUp,
  };
};

const getUserEventsToReact = (): Array<keyof WindowEventMap> => [
  'keydown',
  'click',
  'mousedown',
  'mousemove',
  'wheel',
  'focus',
  'blur',
  // event below ('resize') is removed because ember application dispatch this event (source code
  // of frontend-reservations)
  // 'resize',
];
