import { SessionService } from '@ac/library-utils/dist/services';
/**
 * Key for testing purposes only,
 * allows to override readOnlyMode
 */
const CUSTOM_READ_ONLY_MODE_KEY = 'aboveCloud.test.readOnlyMode';
const customReadOnly = sessionStorage.getItem(CUSTOM_READ_ONLY_MODE_KEY);

export const removeCustomReadOnlyMode = (): void => {
  sessionStorage.removeItem(CUSTOM_READ_ONLY_MODE_KEY);
};

const READ_ONLY_MODE_SET_BY_USER_KEY = 'aboveCloud.readOnlyModeSetByUser';

export const setReadOnlyByUser = (readOnly: boolean): void => {
  sessionStorage.setItem(READ_ONLY_MODE_SET_BY_USER_KEY, String(readOnly));
};

export const removeReadOnlyByUser = (): void => {
  sessionStorage.removeItem(READ_ONLY_MODE_SET_BY_USER_KEY);
};

export const getReadOnlyByUser = (): boolean | undefined => {
  const storedValue = sessionStorage.getItem(READ_ONLY_MODE_SET_BY_USER_KEY);

  return storedValue ? storedValue === 'true' : undefined;
};

export const setReadOnlyMode = (isActive: boolean): void => {
  const value = customReadOnly ? Boolean(Number(customReadOnly)) : isActive;

  SessionService.setReadOnlyMode(value);
};
