import { isGlobalRegion } from 'frontend-container/components/Menu/utils/isGlobalRegion';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';

import { SessionService } from '@ac/library-utils/dist/services';

const isEditModeEnabled = SessionService.getReadOnlyMode();

export const isReadOnlyRequired = (): boolean =>
  !isGlobalRegion() &&
  isModuleEditableInGlobalRegionOnly() &&
  !isEditModeEnabled;
