import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

const INTERVAL = 2000;

let timer: ReturnType<typeof setInterval> | undefined;
let iframe: HTMLIFrameElement;
let sessionState: string | undefined;
let onSessionEndCallback: () => void;
let frameWindow: Window | null;
const frameOrigin = `${acConfig.identityOauthUrl.replace(
  '/identity-server/auth',
  ''
)}`;
const iframeId = 'checkSession';

const createChecksessionIFrameElement = (): void => {
  iframe = window.document.createElement('iframe');
  iframe.id = iframeId;
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'absolute';
  iframe.style.display = 'none';
  iframe.style.width = '0';
  iframe.style.height = '0';

  iframe.src = `${frameOrigin}/connect/checksession`;
};

export const loadChecksessionIFrame = (): Promise<void> => {
  createChecksessionIFrameElement();

  return new Promise<void>((resolve) => {
    iframe.onload = (): void => {
      resolve();
    };
    window.document.body.appendChild(iframe);
    frameWindow = (document.getElementById(iframeId) as HTMLIFrameElement)
      ?.contentWindow;
    window.addEventListener('message', message, false);
  });
};

const message = (e: MessageEvent): void => {
  if (e.origin === frameOrigin && e.source === frameWindow) {
    if (e.data === 'error') {
      stopIntervals();

      return;
    }

    if (e.data === 'changed') {
      stopIntervals();
      onSessionEndCallback();
    }
  }
};

export const startListeningOnSessionEnd = async (
  newSessionState: string,
  newOnSessionEndCallback: () => void
): Promise<void> => {
  onSessionEndCallback = newOnSessionEndCallback;

  await loadChecksessionIFrame();

  if (sessionState !== newSessionState) {
    stopIntervals();
    sessionState = newSessionState;
    const identityClientId = LoginService.getIdentityClientId();
    const send = (): void =>
      frameWindow?.postMessage(
        `${identityClientId} ${newSessionState}`,
        frameOrigin
      );
    timer = setInterval(send, INTERVAL);
  }
};

const stopIntervals = (): void => {
  sessionState = undefined;

  if (timer) {
    clearInterval(timer);
    timer = undefined;
  }
};
