import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegExpUtils } from '@ac/library-utils/dist/utils';

const distributionChannelManagerRegExp = RegExpUtils.interpolate`^${acConfig.newFrontendUrls.distributionChannelManager}.*$`;

export const isDistributionChannelManagerModule = (
  pathname: string = window.location.pathname
): boolean => {
  return distributionChannelManagerRegExp.test(pathname);
};

export default {
  isDistributionChannelManagerModule,
};
