import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessContextGridRow } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/BusinessContextGridRow';
import { getContextGridConfig } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/config';
import { mapReportToBusinessContextGridRowProps } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/grid/mapReportToBusinessContextGridRowProps';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

interface Props {
  contextReport: BusinessContextDataReport;
}

export const BusinessContextGrid = ({
  contextReport,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const gridConfig = useMemo(() => getContextGridConfig(t), [t]);
  const gridData = useMemo(
    () => mapReportToBusinessContextGridRowProps(contextReport),
    [contextReport]
  );

  return (
    <ac-datagrid configuration={gridConfig}>
      {gridData.map((rowProps) => (
        <BusinessContextGridRow key={rowProps.nameKey} {...rowProps} />
      ))}
    </ac-datagrid>
  );
};
