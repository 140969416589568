import { acConfig } from '@ac/library-utils/dist/declarations';

export const defaultActivityConfigurationPathName = '/activity-configuration';

export const isActivityConfigurationModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(
    acConfig.newFrontendUrls.activityConfiguration ?? ''
  );
};

export default {
  isActivityConfigurationModule,
};
