import { FC, memo, useEffect, useRef } from 'react';
import { WithoutSeverityToast } from 'frontend-container/publicApi/windowObject';
import { toastDefaults } from 'frontend-container/utils/definedCustomObjectOnWindow';

import { ToastInterface, ToastSeverity, Translation } from '@ac/web-components';

import { getTimeoutInMs } from './getTimeoutInMs';

const FE_CONTAINER_AC_TOAST_ID = 'fe-container-ac-toast-id';

export type ToastElement = HTMLAcToastElement | undefined;

const generateHas = <
  T extends ToastInterface | WithoutSeverityToast | Translation
>(
  key: string
) => {
  return (
    toast: ToastInterface | WithoutSeverityToast | Translation
  ): toast is T => {
    if (typeof toast === 'string') {
      return false;
    }

    return key in toast;
  };
};
const checkHasMessage = generateHas<ToastInterface | WithoutSeverityToast>(
  'message'
);
const checkHasSeverity = generateHas<ToastInterface>('severity');
const checkHasActions = generateHas<ToastInterface>('actions');

const ToastComponent: FC = () => {
  const ref = useRef<ToastElement>();

  const makeHandleShowToast = (severityArg?: ToastSeverity) => {
    return (
      toast: ToastInterface | WithoutSeverityToast | Translation
    ): void => {
      const message = checkHasMessage(toast) ? toast.message : toast;

      const severity = checkHasSeverity(toast)
        ? severityArg ?? toast.severity
        : severityArg ?? ToastSeverity.success;

      const timeout = checkHasMessage(toast)
        ? toast.timeout ?? getTimeoutInMs(severity)
        : getTimeoutInMs(severity);

      const actions = checkHasActions(toast) ? toast.actions : [];

      ref.current?.showToast?.({
        message,
        severity,
        timeout,
        actions,
      });
    };
  };

  useEffect(() => {
    const toastElement = document.getElementById(
      FE_CONTAINER_AC_TOAST_ID
    ) as ToastElement;

    ref.current = toastElement;
  }, []);

  useEffect(() => {
    if (window?.ACP?.container) {
      window.ACP.container.toast.show = makeHandleShowToast();
      window.ACP.container.toast.showSuccess = makeHandleShowToast(
        ToastSeverity.success
      );
      window.ACP.container.toast.showError = makeHandleShowToast(
        ToastSeverity.error
      );
      window.ACP.container.toast.showWarning = makeHandleShowToast(
        ToastSeverity.warning
      );
    }

    return (): void => {
      if (window?.ACP?.container) {
        window.ACP.container.toast = { ...toastDefaults };
      }
    };
  }, []);

  return <ac-toast id={FE_CONTAINER_AC_TOAST_ID} />;
};

export const Toast = memo(ToastComponent);
