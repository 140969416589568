import { NotificationEnvelope } from '@ac/library-api';

export enum NotificationsNames {
  UserPermissionsChanged = 'PermissionManagement.UserPermissionsChanged',
  UserNotificationCreated = 'UserNotifications.UserNotificationCreated',
  UserNotificationMarkedAsRead = 'UserNotifications.NotificationMarkedAsRead',
  UserNotificationMarkedAsUnread = 'UserNotifications.NotificationMarkedAsUnread',
  UserNotificationsMarkedAsRead = 'UserNotifications.AllNotificationsMarkedAsRead',
  PropertyBusinessDateChanged = 'PropertyManagement.PropertyBusinessDateChangedNotification',
}

export type PropertyBusinessDateChangedNotification = NotificationEnvelope<{
  aggregateId: string;
  newBusinessDate: string;
}>;
