import { useTranslation } from 'react-i18next';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { InternetSpeedTestStatus } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';
import { getInternetSpeedTestTranslationKey } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/translation';

import { Color, IconName } from '@ac/web-components';

const minimumInternetSpeedInMbps = 20;

export const InternetQualityIndicator = (): JSX.Element | null => {
  const { state } = useInternetSpeedTestContext();
  const { t } = useTranslation();

  if (state.status !== InternetSpeedTestStatus.Finished) {
    return null;
  }

  const isFastInternetSpeed = state.valueInMbps > minimumInternetSpeedInMbps;

  if (isFastInternetSpeed) {
    return (
      <>
        <ac-icon
          icon={IconName.done}
          color={Color.success}
          class="ac-spacing-right-sm"
        />
        <ac-text>
          {t(getInternetSpeedTestTranslationKey('RESULT.FAST'))}
        </ac-text>
      </>
    );
  }

  return (
    <>
      <ac-icon
        icon={IconName.alert}
        color={Color.warning}
        class="ac-spacing-right-sm"
      />
      <ac-text>
        {t(getInternetSpeedTestTranslationKey('RESULT.SLOW'), {
          requiredSpeedValueInMbps: minimumInternetSpeedInMbps,
        })}
      </ac-text>
    </>
  );
};
