import { registerDocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import {
  ACP,
  Api,
  Container,
  ContainerToast,
} from 'frontend-container/publicApi/windowObject';
import {
  startNewPageActivityTracker,
  stopPageActivityTracker,
} from 'frontend-container/utils/activityTracker/activityTrackerInitializer';
import { createLocationContext } from 'frontend-container/utils/activityTracker/getPageIdentifier';
import { getUILanguage } from 'frontend-container/utils/UILanguage';
import { mountRootParcel, registerApplication, start } from 'single-spa';

import { EmptyMetrics } from '@ac/library-api';
import { EventBus } from '@ac/library-utils/dist/communication/event-bus';
import { LoginService } from '@ac/library-utils/dist/services';
import { getGlobalACPNamespace } from '@ac/library-utils/dist/utils';
import { SingleSPA } from '@ac/library-utils/dist/utils/micro-frontends/singleSPA';
import {
  CommunicationRequiredByReactInfrastructure,
  DeveloperToolsRequiredByReactInfrastructure,
} from '@ac/react-infrastructure';

import { createRouter } from './router';

const singleSpa: SingleSPA = {
  registerApplication,
  start,
  mountRootParcel,
};

export const toastDefaults: ContainerToast = Object.seal({
  show: () => undefined,
  showSuccess: () => undefined,
  showError: () => undefined,
  showWarning: () => undefined,
});

export const defineObjectOnWindow = (): void => {
  defineGlobalsForConfigurationModule();

  const acp = getGlobalACPNamespace();
  const acpProperties = getGlobalACPProperties();
  Object.keys(acpProperties).forEach((key) => {
    acp[key] = acpProperties[key];
  });

  acp.singleSpa = singleSpa;
};

const getGlobalACPProperties = (): ACP => {
  return {
    container: getContainerObject(),
    businessContext: undefined,
    getUserUILanguage: (): string => getUILanguage().toUpperCase(),
    api: getApiObject(),
    communication: getCommunicationObject(),
    developerTools: getDeveloperTools(),
  };
};

const getApiObject = (): Api => {
  const acp = getGlobalACPNamespace();
  const api = (acp.api ?? {}) as Api;

  return {
    ...api,
    webSockets: undefined,
  };
};

const getContainerObject = (): Container => {
  return {
    handleOperationNotAllowedInRegionError: undefined,
    openContextHelpTopic: undefined,
    updateContextHelpTopic: undefined,
    isContextHelpOpened: undefined,
    hasUnsavedChanges: undefined,
    refreshBusinessDate: undefined,
    openCashierModal: undefined,
    closeCashierModal: undefined,
    workstationSelectionModal: undefined,
    refreshCashier: undefined,
    registerDocumentTitle,
    getAccessToken: LoginService.accessToken,
    getIdToken: LoginService.idToken,
    logTokenInfo: (): void => {
      const data = LoginService.authData();
      // eslint-disable-next-line no-console
      console.log(
        `Token exists: ${(!!data?.token).toString()}, expires at: ${
          data?.expiresAt ? new Date(data?.expiresAt * 1000).toUTCString() : '-'
        }`
      );
    },
    modals: {
      unsavedChanges: undefined,
    },
    usersActivity: {
      startActivityTracker: startNewPageActivityTracker,
      stopActivityTracker: stopPageActivityTracker,
      createLocationContext,
    },
    workstation: {},
    toast: { ...toastDefaults },
    router: createRouter(),
  };
};

const getDeveloperTools = (): DeveloperToolsRequiredByReactInfrastructure => ({
  metrics: Promise.resolve(new EmptyMetrics()),
});

const defineGlobalsForConfigurationModule = (): void => {
  // fix for configuration
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).global = window;
};

const getCommunicationObject =
  (): CommunicationRequiredByReactInfrastructure => ({
    eventBus: new EventBus(),
  });
