import { useState } from 'react';
import {
  getDefaultMaintenancePanelState,
  MaintenancePanelMode,
  MaintenancePanelState,
} from 'frontend-container/components/panels/maintenancePanel/store/state';
import { EnvironmentDetails } from 'frontend-container/components/panels/maintenancePanel/types/environmentDetails';
import { MicroservicesVersions } from 'frontend-container/components/panels/maintenancePanel/types/microserviceVersion';
import { BusinessContextDataReport } from 'frontend-container/shared/businessContext/provider/report/creator';

export interface MaintenancePanelStore {
  state: MaintenancePanelState;

  setEnvironmentDetails: (environmentDetails: EnvironmentDetails) => void;
  setMicroservicesVersions: (
    microservicesVersions: MicroservicesVersions
  ) => void;
  setBusinessContextReport: (report: BusinessContextDataReport) => void;
  setLoading: (isLoadingInProgress: boolean) => void;
  setMode: (mode: MaintenancePanelMode) => void;
}

type Props = Partial<MaintenancePanelState>;

export const useCreateMaintenancePanelStore = (
  props: Props
): MaintenancePanelStore => {
  const [state, setState] = useState<MaintenancePanelState>(
    getDefaultMaintenancePanelState(props)
  );

  const setEnvironmentDetails = (environment: EnvironmentDetails): void =>
    setPartialState({ environment });

  const setMicroservicesVersions = (
    microservicesVersions: MicroservicesVersions
  ): void => setPartialState({ microservicesVersions });

  const setBusinessContextReport = (
    contextReport: BusinessContextDataReport
  ): void => setPartialState({ contextReport });

  const setMode = (mode: MaintenancePanelMode): void =>
    setPartialState({ mode });

  const setLoading = (isLoadingInProgress: boolean): void =>
    setPartialState({ isLoadingInProgress });

  const setPartialState = (newState: Partial<MaintenancePanelState>): void =>
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

  return {
    state,
    setEnvironmentDetails,
    setMicroservicesVersions,
    setBusinessContextReport,
    setLoading,
    setMode,
  };
};
