import {
  isCROCreateReservationPathname,
  isCROEditReservationPathname,
} from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

export const isPropertySelectorReadOnlyVisible = (): boolean => {
  return [
    isCROCreateReservationPathname(),
    isCROEditReservationPathname(),
  ].some(Boolean);
};
