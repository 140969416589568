import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import './Header.scss';

interface Props {
  titleTranslation?: string;
  customDisplay?: ReactNode;
}

export const Header = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="container-menu-item-header">
      {props.titleTranslation && t(props.titleTranslation)}
      {props.customDisplay && props.customDisplay}
    </div>
  );
};
