import { createCommunicationEventCreator } from '@ac/library-utils/dist/communication/event-bus';

export interface PropertyBusinessDateDetails {
  businessDate: string;
}

export const propertyBusinessDateUpdatedEventCreator =
  createCommunicationEventCreator<PropertyBusinessDateDetails>(
    'container_propertyBusinessDateUpdated'
  );
