import { useConfirmationModalPresenterContext } from 'frontend-container/components/modals/confirmationModal/presenter/context';
import { ConfirmationModalType } from 'frontend-container/components/modals/confirmationModal/presenter/input';
import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';

import { ModalResultType } from '@ac/react-infrastructure';

type CommandResult = () => Promise<void>;

export const useClearBusinessContextCache = (): CommandResult => {
  const presenter = useConfirmationModalPresenterContext();

  return async () => {
    const { resultType } = await presenter.show({
      type: ConfirmationModalType.PageReloadAfterContextClean,
    });

    if (resultType === ModalResultType.Confirmed) {
      businessContextProvider.clearCache();
      window.location.reload();
    }
  };
};
