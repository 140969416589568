import { useTranslation } from 'react-i18next';
import { useMaintenancePanelFeatureTogglesContext } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/context';

import { debounce } from '@ac/library-utils/dist/utils';
import {
  AlignItems,
  CheckableInputTheme,
  FlexDirection,
  Type,
} from '@ac/web-components';

export const FeatureTogglesFilters = (): JSX.Element | null => {
  const { state, setFilters } = useMaintenancePanelFeatureTogglesContext();
  const { t } = useTranslation();

  const debouncedUpdate = debounce(
    (value: string) =>
      setFilters({
        ...state.filters,
        search: value,
      }),
    500
  );

  return (
    <ac-flex
      direction={FlexDirection.row}
      alignItems={AlignItems.center}
      class="ac-gap-md"
    >
      <ac-field
        value={state.filters.search}
        type={Type.search}
        onInputCallback={debouncedUpdate}
        onClearInputCallback={(): void =>
          setFilters({
            ...state.filters,
            search: '',
          })
        }
        allowClear
        translations={{
          placeholder: t('MAINTENANCE_PANEL.FEATURE_TOGGLES.SEARCH'),
        }}
        hideMessageContainer
        class="ac-spacing-vertical-md"
      />
      <ac-switch
        theme={CheckableInputTheme.dark}
        label={t('MAINTENANCE_PANEL.FEATURE_TOGGLES.ENABLED_ONLY')}
        checked={state.filters.enabledOnly}
        onChangeCallback={(): void =>
          setFilters({
            ...state.filters,
            enabledOnly: !state.filters.enabledOnly,
          })
        }
      />
    </ac-flex>
  );
};
