import { Context } from 'frontend-container/components/Menu/components/Context';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const selectInitialContext = (
  contexts: Context[],
  id?: string
): Context | undefined => {
  return (
    findById(contexts, id) ??
    findFirstInCurrentRegion(contexts) ??
    findFirst(contexts)
  );
};

const findById = (contexts: Context[], id?: string): Context | undefined => {
  if (!id) {
    return undefined;
  }

  return contexts.find((context) => context.id === id);
};

const findFirstInCurrentRegion = (contexts: Context[]): Context | undefined => {
  const currentRegionCode = getCurrentRegionCode();

  return contexts.find(({ regionCode }) => regionCode === currentRegionCode);
};

const findFirst = (contexts: Context[]): Context | undefined => contexts[0];
