import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapEnvironmentDetailsToDisplayValues } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/mapDetailsToDisplayValues';
import { useMaintenancePanelContext } from 'frontend-container/components/panels/maintenancePanel/store/context';

import { FlexDirection } from '@ac/web-components';

export const EnvironmentDetailsList = (): JSX.Element | null => {
  const {
    state: { environment },
  } = useMaintenancePanelContext();
  const { t } = useTranslation();
  const details = useMemo(
    () =>
      environment
        ? mapEnvironmentDetailsToDisplayValues(environment)
        : undefined,
    [environment]
  );

  return (
    <ac-flex
      direction={FlexDirection.row}
      data-test-selector="user-environment-information"
      wrapped
    >
      {details?.map((item, index) => (
        <ac-box key={index} sizeSm={8} class="ac-spacing-bottom-md">
          <ac-text-group label={t(item.translationPath)}>
            {item.value}
          </ac-text-group>
        </ac-box>
      ))}
    </ac-flex>
  );
};
