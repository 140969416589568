import { TFunction } from 'i18next';

import { DatagridConfiguration, IconName, Justify } from '@ac/web-components';

export type FeatureTogglesGridHeader =
  | 'featureName'
  | 'system'
  | 'tenant'
  | 'property';

export type FeatureTogglesGridHeaderDefinition = {
  [column in FeatureTogglesGridHeader]: DatagridConfiguration;
};

export const getFeatureTogglesGridHeaderCells = (
  t: TFunction
): FeatureTogglesGridHeaderDefinition => ({
  featureName: {
    text: t('MAINTENANCE_PANEL.FEATURE_TOGGLES.GRID.FEATURE_NAME'),
    size: '1fr',
    sortField: 'featureName',
  },
  system: {
    text: t('MAINTENANCE_PANEL.FEATURE_TOGGLES.GRID.SYSTEM'),
    icon: IconName.systemUsers,
    justify: Justify.center,
  },
  tenant: {
    text: t('MAINTENANCE_PANEL.FEATURE_TOGGLES.GRID.TENANT'),
    icon: IconName.tenantZone,
    justify: Justify.center,
  },
  property: {
    text: t('MAINTENANCE_PANEL.FEATURE_TOGGLES.GRID.PROPERTY'),
    icon: IconName.propertyZone,
    justify: Justify.center,
  },
});
