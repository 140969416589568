import { acConfig } from '@ac/library-utils/dist/declarations';

export const isAccountsReceivableModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(
    acConfig.newFrontendUrls.accountsReceivable ?? ''
  );
};

export default {
  isAccountsReceivableModule,
};
