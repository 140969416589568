import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';

import { isDefined } from '@ac/library-utils/dist/utils';
import { AcSelectOption, AcSelectValue } from '@ac/web-components';

import { Context } from '../components/Context/context';

export const handleOnChange =
  <T extends { id: string }>(
    array: T[],
    handler: (item: T | undefined) => void
  ) =>
  (value: Array<AcSelectOption<AcSelectValue>>): void =>
    handler(array.find((item) => item.id === value[0].value));

export const isContextList = (
  toBeDetermined: Context[] | ButtonContextOption | undefined
): toBeDetermined is Context[] => {
  if (
    Array.isArray(toBeDetermined) &&
    isDefined(toBeDetermined[0]) &&
    'code' in toBeDetermined[0]
  ) {
    return true;
  }

  return false;
};
