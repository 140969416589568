import { useEffect, useState } from 'react';
import { getCurrentPropertyDateTime } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate/service';
import { useCroDateTime } from 'frontend-container/components/Menu/components/CroContext/useCroDateTime';
import { formatDate, TimeFormats } from 'frontend-container/utils/dateUtils';

import { CurrentDate } from '@ac/library-api';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';

type PropertyDateDetails = {
  date?: string;
  dateFormat?: string;
  time?: string;
};

export const usePropertyReadOnlyDate = (): PropertyDateDetails | undefined => {
  const [propertyDate, setPropertyDate] = useState<PropertyDateDetails>();
  const [businessDate, setBusinessDate] = useState<string>();
  const { time: timeTick, dateFormat, timeFormat } = useCroDateTime();

  useEffect(() => {
    const loadBusinessDate = async (): Promise<void> => {
      try {
        const currentDate = await CurrentDateApi.getCurrentDate();
        const currentBusinessDate = (currentDate as CurrentDate)?.businessDate;
        if (!currentBusinessDate) {
          return;
        }
        setBusinessDate((currentDate as CurrentDate)?.businessDate);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    loadBusinessDate();
  }, []);

  useEffect(() => {
    const updateTime = async (): Promise<void> => {
      try {
        if (!businessDate) {
          return;
        }
        const { localPropertyDateTime } = await getCurrentPropertyDateTime();

        const timeOutputFormat = timeFormat || TimeFormats.H24;

        setPropertyDate({
          date: formatDate(businessDate, dateFormat.toUpperCase()),
          time: formatDate(localPropertyDateTime, timeOutputFormat),
          dateFormat,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        return;
      }
    };

    updateTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTick, businessDate]);

  return propertyDate;
};
