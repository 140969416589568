import { FC } from 'react';
import { userService } from 'frontend-container/components/Menu/components/User/service';

import { isDefined } from '@ac/library-utils/dist/utils';
import { FlexDirection, TextColor, TextSize } from '@ac/web-components';

import './UserDetails.scss';

export const UserDetails: FC = () => {
  const { userName, email, personalData } = userService.getCurrentUserDetails();
  const formattedPersonalData = [
    personalData?.firstName,
    personalData?.lastName,
  ]
    .filter(isDefined)
    .join(' ');

  return (
    <ac-flex class="prestep-user-details" direction={FlexDirection.column}>
      <ac-text>{formattedPersonalData}</ac-text>
      <ac-text size={TextSize.sm} color={TextColor.lightGray}>
        {email}
      </ac-text>
      <ac-text size={TextSize.sm}>{userName}</ac-text>
    </ac-flex>
  );
};
