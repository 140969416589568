import { acConfig } from '@ac/library-utils/dist/declarations';

export const isReportsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.reports ?? '');
};

export default {
  isReportsModule,
};
