import { useMemo } from 'react';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import {
  getCroContextData,
  getCustomerContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  formatDate,
  getShortDateFormat,
  getTimeFormat,
  ShortDateFormats,
  TimeFormats,
} from 'frontend-container/utils/dateUtils';

import { useBrowserDateTime } from './useBrowserDateTime';

export const useCroDateTime: DateTimeHook = () => {
  const { date } = useBrowserDateTime();

  const croContextData = getCroContextData();
  const customerContextData = getCustomerContextData();

  const shortDateFormat =
    getShortDateFormat(croContextData?.office) ||
    getShortDateFormat(customerContextData?.details, ShortDateFormats.YYYYMMDD);

  // TODO: Include time format from CRO Entity when It's implemented
  const timeFormat = getTimeFormat(
    customerContextData?.details,
    TimeFormats.H24
  );

  const stringDate = useMemo(
    () => formatDate(date, shortDateFormat.toUpperCase()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stringTime = useMemo(() => formatDate(date, timeFormat), [date]);

  return {
    date: stringDate,
    time: stringTime,
    dateFormat: shortDateFormat,
    timeFormat,
  };
};
