export const getNewUrlForHousekeepingModule = (
  newPropertyId: string,
  pathname: string,
  origin: string
): string => {
  const newPathname = pathname.replace(
    /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i,
    newPropertyId
  );

  return `${origin}${newPathname}`;
};
