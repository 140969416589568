import ReactDOM from 'react-dom/client';
import { PrestepAccessDenied } from 'frontend-container/components/Prestep/AccessDenied/AccessDenied';
import { pathnamePrestepNoPermissions } from 'frontend-container/components/Prestep/AccessDenied/NoPermissions/config';
import i18n from 'frontend-container/i18n';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { createReactRoot } from 'frontend-container/utils/createReactRoot';
import { registerApplication } from 'single-spa';

import { withTranslationsReady } from '@ac/react-infrastructure';

export const isPrestepNoPermissions = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(pathnamePrestepNoPermissions);
};
export const prestepNoPermissionsAppName = 'prestep-no-permissions';
export const registerPrestepNoPermissions = (): void => {
  let root: ReactDOM.Root | undefined;

  registerApplication(
    prestepNoPermissionsAppName,
    {
      bootstrap: () => Promise.resolve(),
      mount: async () => {
        root = createReactRoot(ROUTER_OUTLET_SELECTOR);
        root.render(<PrestepNoPermissionsWithTranslationsReady />);
      },
      unmount: async () => {
        root?.unmount();
      },
    },
    (location: Location) => isPrestepNoPermissions(location.pathname)
  );
};

const PrestepNoPermissions = (): JSX.Element => (
  <PrestepAccessDenied
    message={i18n.t('LOGIN_LOGOUT.LOGIN_NO_PERMISSIONS.MESSAGE')}
  />
);

const PrestepNoPermissionsWithTranslationsReady =
  withTranslationsReady(PrestepNoPermissions);

export { PrestepNoPermissionsWithTranslationsReady as PrestepNoPermissions };
