import { FeatureToggleInfo } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

import { PagingInfoDto } from '@ac/library-api';

export const getPage = (
  filteredItems: FeatureToggleInfo[],
  paging: PagingInfoDto
): FeatureToggleInfo[] => {
  const startIndex = (paging.pageNumber - 1) * paging.pageSize;
  const endIndex = startIndex + paging.pageSize;

  return filteredItems.slice(startIndex, endIndex);
};
