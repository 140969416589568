import { ToggleAvailabilityIcon } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/AvailabilityIcon';
import { useDefaultFeatureTogglesGridSchema } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/defaultSchema';
import { revertSortValue } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/sort';
import { useMaintenancePanelFeatureTogglesContext } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/context';
import { FeatureToggleInfo } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/state';

import { FlexDirection, Justify, TargetValueObject } from '@ac/web-components';

export const FeatureTogglesGrid = (): JSX.Element | null => {
  const { state, setPagination, setSort } =
    useMaintenancePanelFeatureTogglesContext();
  const schema = useDefaultFeatureTogglesGridSchema();

  if (!state.visibleItems.length) {
    return null;
  }

  return (
    <ac-flex direction={FlexDirection.column} class="ac-gap-sm">
      <ac-datagrid
        configuration={schema.headers}
        sortValue={state.sort}
        onSortCallback={(field: keyof FeatureToggleInfo): void =>
          setSort(revertSortValue(state.sort, field))
        }
      >
        <ac-datagrid-body>
          {state.visibleItems.map((item) => (
            <ac-datagrid-row key={item.featureName}>
              <ac-datagrid-cell text={item.featureName} />
              <ac-datagrid-cell justify={Justify.center}>
                <ToggleAvailabilityIcon
                  isAvailable={item.availability.system}
                />
              </ac-datagrid-cell>
              <ac-datagrid-cell justify={Justify.center}>
                <ToggleAvailabilityIcon
                  isAvailable={item.availability.tenant}
                />
              </ac-datagrid-cell>
              <ac-datagrid-cell justify={Justify.center}>
                <ToggleAvailabilityIcon
                  isAvailable={item.availability.property}
                />
              </ac-datagrid-cell>
            </ac-datagrid-row>
          ))}
        </ac-datagrid-body>
      </ac-datagrid>

      <ac-pagination
        page={state.paging.pageNumber}
        perPage={state.paging.pageSize}
        total={state.paging.totalCount}
        onChangeCallback={setPagination}
        targetValue={TargetValueObject.sidePanel}
      />
    </ac-flex>
  );
};
