import { isMeetingsAndEventsModule } from 'frontend-container/components/Menu/utils/modules/meetingsAndEvents';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const meetingsAndEventsAppName = 'frontend-meetings-and-events';
export const registerMeetingsAndEvents = (): void => {
  registerApplication(
    meetingsAndEventsAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.meetingsAndEvents,
        meetingsAndEventsAppName
      ),
    (location: Location) => isMeetingsAndEventsModule(location.pathname)
  );
};
