import { acConfig } from '@ac/library-utils/dist/declarations';

export const isRateManagerModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(acConfig.newFrontendUrls.rateManager ?? '');
};

export default {
  isRateManagerModule,
};
