import { Context } from 'frontend-container/components/Menu/components/Context';
import { getCroUrlForNewRegion } from 'frontend-container/utils/region/getUrlForNewRegion';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const redirectToCroRegion = (
  regionCode: string,
  domain: string,
  context: Context
): boolean => {
  const currentRegionCode = getCurrentRegionCode();

  if (currentRegionCode && context?.regionCode !== currentRegionCode) {
    window.location.replace(getCroUrlForNewRegion(regionCode, domain, context));

    return true;
  }

  return false;
};
