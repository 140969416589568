import { acConfig } from '@ac/library-utils/dist/declarations';

export const getCurrentUrl = (): string =>
  `${window.location.pathname}${window.location.search}`;

export const isMainUrl = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname === '/' || pathname === '';
};

export const isLoginUrl = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname.startsWith('/login');
};

export const isAnyConfiguration = (
  pathname: string = window.location.pathname
): boolean => {
  return (
    pathname.startsWith(
      acConfig.newFrontendUrls.configuration || '/configuration'
    ) ||
    pathname.startsWith(
      acConfig.newFrontendUrls.configurationv2 || '/configurationv2'
    )
  );
};
