import { useTranslation } from 'react-i18next';
import { useDesignSystemFeatureConfiguration } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/commands/designSystemConfiguration';
import { designSystemSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/manifest';
import { useDesignSystemSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/presenter/context';
import { useLayoutDirection } from 'frontend-container/utils/hooks/useLayoutDirection';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import {
  ButtonPattern,
  CollapsiblePanelTitleSize,
  FlexDirection,
  IconName,
  LayoutDirection,
} from '@ac/web-components';

const sectionConfig: PageSectionConfiguration = {
  header: {
    toggleContent: {
      allowToggle: true,
    },
    textSize: CollapsiblePanelTitleSize.auto,
  },
};

export const DesignSystemSection = (): JSX.Element | null => {
  const presenter = useDesignSystemSectionPresenterContext();
  const config = useDesignSystemFeatureConfiguration();
  const { t } = useTranslation();
  const { isLtr, isRtl } = useLayoutDirection();

  return (
    <StandardPageSection
      manifest={designSystemSectionManifest}
      presenter={presenter}
      config={sectionConfig}
    >
      <ac-flex
        direction={FlexDirection.row}
        class="ac-gap-md ac-spacing-bottom-md ac-spacing-top-sm"
      >
        <ac-box sizeSm={8}>
          <ac-text>
            {t('MAINTENANCE_PANEL.DESIGN_SYSTEM.LAYOUT_DIRECTION')}
          </ac-text>

          <ac-flex class="ac-gap-sm ac-spacing-top-sm">
            <ac-button-group
              buttons={[
                {
                  selected: isLtr,
                  pattern: ButtonPattern.secondary,
                  content: {
                    text: t('MAINTENANCE_PANEL.DESIGN_SYSTEM.DIRECTION.LTR'),
                  },
                  onClick: (): void => {
                    config.setContentDirection(LayoutDirection.ltr);
                  },
                },
                {
                  selected: isRtl,
                  pattern: ButtonPattern.secondary,
                  content: {
                    text: t('MAINTENANCE_PANEL.DESIGN_SYSTEM.DIRECTION.RTL'),
                  },
                  onClick: (): void => {
                    config.setContentDirection(LayoutDirection.rtl);
                  },
                },
              ]}
            />
          </ac-flex>
        </ac-box>

        <ac-box sizeSm={8}>
          <ac-flex direction={FlexDirection.column} class="ac-gap-sm">
            <ac-text>{t('MAINTENANCE_PANEL.DESIGN_SYSTEM.THEME')}</ac-text>
            <ac-switch
              label={t('MAINTENANCE_PANEL.DESIGN_SYSTEM.DARK_MODE')}
              checked={config.isDarkMode}
              onChangeCallback={(): void =>
                config.setThemeMode(!config.isDarkMode)
              }
            />
            {config.isAnyChange && (
              <ac-link
                onClick={(): void => window.location.reload()}
                icon={IconName.restart}
              >
                {t('MAINTENANCE_PANEL.DESIGN_SYSTEM.RELOAD_NEEDED')}
              </ac-link>
            )}
          </ac-flex>
        </ac-box>
      </ac-flex>
    </StandardPageSection>
  );
};
