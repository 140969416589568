import { ExceptionReport } from '@ac/library-api';

const WRONG_CASHIER_SECRET_CODE = 'WrongCashierSecretException';

export interface ApiError {
  config: Record<string, unknown>;
  data: ExceptionReport;
}

export const isCurrentErrorWrongSecretError =
  ({ reverse }: { reverse: boolean } = { reverse: false }) =>
  (e: ApiError): boolean => {
    const details = e?.data?.details || [];
    const isCurrentWrongSecret = details.some(
      (d) => d.code === WRONG_CASHIER_SECRET_CODE
    );

    return reverse ? !isCurrentWrongSecret : isCurrentWrongSecret;
  };
