import { ContextsContext } from 'frontend-container/components/Menu/store/context';
import { useCreateContextsContextStore } from 'frontend-container/components/Menu/store/store';

import { PropsWithChildren } from '@ac/react-infrastructure';

export const ContextsContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateContextsContextStore();

  return (
    <ContextsContext.Provider value={store}>
      {props.children}
    </ContextsContext.Provider>
  );
};
