import {
  CentralReservationOfficeUserListItemDto,
  ProfileCenterDto,
  UnitAssignmentListItem,
  UserPreferencesDetails,
} from '@ac/library-api';
import { Form } from '@ac/react-infrastructure';

export interface PrestepDictionaries {
  croUnits: CentralReservationOfficeUserListItemDto[];
  units: UnitAssignmentListItem[];
  profileCenterUnits: ProfileCenterDto[];
}

export interface PrestepUserPreferencesForm extends UserPreferencesDetails {
  keepAsDefault?: boolean;
}

export const PrestepForm = Form<PrestepUserPreferencesForm>;
