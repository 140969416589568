import { isIndividualReservationsModule } from 'frontend-container/components/Menu/utils/modules/individualReservations';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const individualReservationsAppName = 'frontend-individual-reservations';
export const registerIndividualReservations = (): void => {
  registerApplication(
    individualReservationsAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.individualReservations,
        individualReservationsAppName
      ),
    (location: Location) => isIndividualReservationsModule(location.pathname)
  );
};
