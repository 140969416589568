import { formatError } from 'frontend-container/utils/errors/formatError';

interface IpResponse {
  ip: string;
}

export enum IpAddressState {
  loaded = 'loaded',
  error = 'error',
}

export type IpAddress =
  | {
      value: string;
      state: IpAddressState.loaded;
    }
  | {
      state: IpAddressState.error;
      error: string;
    };

export const fetchIpAddress = async (): Promise<IpAddress> => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const jsonResponse = (await response.json()) as IpResponse;

    return {
      state: IpAddressState.loaded,
      value: jsonResponse.ip,
    };
  } catch (e) {
    return {
      error: formatError(e),
      state: IpAddressState.error,
    };
  }
};
