import { useTranslation } from 'react-i18next';
import { useInternetSpeedTestContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/context';
import { InternetSpeedTestStatus } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/store/state';
import { getInternetSpeedTestTranslationKey } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/translation';

const maximumDisplayedValueInMbps = 50;

export const InternetSpeedValue = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { state } = useInternetSpeedTestContext();

  if (state.status !== InternetSpeedTestStatus.Finished) {
    return null;
  }

  const valueMessage =
    state.valueInMbps > maximumDisplayedValueInMbps
      ? t(getInternetSpeedTestTranslationKey('RESULT.SPEED_ABOVE_THRESHOLD'), {
          thresholdInMbps: maximumDisplayedValueInMbps,
        })
      : t(getInternetSpeedTestTranslationKey('RESULT.SPEED_VALUE'), {
          valueInMbps: state.valueInMbps,
        });

  return <>{valueMessage}</>;
};
