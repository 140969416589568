import {
  CentralReservationOfficeContextData,
  CustomerContextData,
  PropertyContextData,
  UserContextData,
} from '@ac/library-api';
import { ProfileCentersContextData } from '@ac/library-api/dist/businessContext/profileCenters/types';
import { DatabaseSchema } from '@ac/library-utils/dist/storage/database';

export const BUSINESS_CONTEXT_DEFAULT_MAX_ENTITIES_COUNT = 10;

export enum BusinessContextDbStoreKey {
  User = 'user',
  System = 'system',
  Customer = 'customer',
  CentralReservationOffice = 'centralReservationOffice',
  Property = 'property',
  ProfileCenters = 'profileCenters',
}

export const BUSINESS_CONTEXT_DB_SCHEMA: DatabaseSchema = {
  name: 'SEPBusinessContextDatabase',
  stores: [
    { name: BusinessContextDbStoreKey.User },
    { name: BusinessContextDbStoreKey.System },
    { name: BusinessContextDbStoreKey.Customer },
    { name: BusinessContextDbStoreKey.CentralReservationOffice },
    { name: BusinessContextDbStoreKey.Property },
    { name: BusinessContextDbStoreKey.ProfileCenters },
  ],
};

export const BUSINESS_CONTEXT_DB_KEYS_PROVIDERS = {
  user: (entity: UserContextData): string => entity.details.id,
  system: (): string => 'SEP_SystemAdmin',
  customer: (entity: CustomerContextData): string => entity.details.id,
  centralReservationOffice: (
    entity: CentralReservationOfficeContextData
  ): string => entity.office.id,
  property: (entity: PropertyContextData): string => entity.details.id,
  profileCenters: (entity: ProfileCentersContextData): string =>
    entity.details.id,
};

export interface BusinessContextEntityNames {
  name?: string;
  displayName?: string;
}

export const BUSINESS_CONTEXT_DB_NAMES_PROVIDERS = {
  user: (entity: UserContextData): BusinessContextEntityNames => ({
    name: entity.details.userName,
    displayName: entity.details.email,
  }),
  system: (): BusinessContextEntityNames => ({
    name: 'SEP_SystemAdmin',
    displayName: 'System Administrator',
  }),
  customer: (entity: CustomerContextData): BusinessContextEntityNames =>
    entity.details,
  centralReservationOffice: (
    entity: CentralReservationOfficeContextData
  ): BusinessContextEntityNames => entity.office,
  property: (entity: PropertyContextData): BusinessContextEntityNames =>
    entity.details,
  profileCenters: (
    entity: ProfileCentersContextData
  ): BusinessContextEntityNames => ({
    name: entity.details.code,
    displayName: entity.details.code,
  }),
};
