export interface ActivityTrackerRequestData {
  apiUrl: string;
  workstationId: string;
  propertyId: string;
  tenantId: string;
  accessToken: string;
}

export interface ActivityTrackerPageIdentifier
  extends ActivityTrackerRequestData {
  pageIdentifier: string;
  url: string;
}

export enum ActivityTrackerCommand {
  NewPage = 'NewPage',
  RequestInfo = 'RequestInfo',
  Stop = 'Stop',
}

export interface ActivityTrackerData extends ActivityTrackerPageIdentifier {
  command: ActivityTrackerCommand;
}
