import {
  handleEmptyModuleUrl,
  handleErrorLoadingModule,
  injectGlobalMount,
} from 'frontend-container/utils/externalResources/shared';

import { loadEmberApp as loadEmberModule } from '@ac/library-utils/dist/utils/micro-frontends/loading/page/ember';
import { AppToRender } from '@ac/library-utils/dist/utils/micro-frontends/rendering/shared';

let loadingEmberApp: Promise<AppToRender | void> = Promise.resolve();

export const loadEmberApp = (
  appUrl: string,
  appName: string
): Promise<AppToRender> => {
  if (!appUrl) {
    handleEmptyModuleUrl(appName);

    return Promise.resolve({
      bootstrap: [],
      mount: [],
      unmount: [],
    }) as ReturnType<typeof loadEmberModule>;
  }
  const loadingApp = loadEmberModule(appUrl, appName).then((emberApp) =>
    injectGlobalMount(emberApp)
  );

  loadingEmberApp = loadingApp;
  loadingApp.catch((error) => {
    handleErrorLoadingModule(error, appName);
  });

  return loadingApp;
};

export const getEmberAppLoadingStatus = (): Promise<AppToRender | void> =>
  loadingEmberApp;
