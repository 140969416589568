import { getLanguageSettings } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/getLanguageSettings';
import { mapDictionaryEntityToSelectOption } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/data/mapToSelectOption';

import { DictionaryEntity, SupportedUiLanguageDto } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';
import { AcSelectOption } from '@ac/web-components';

const getLanguageNameWithFallback = (
  supportedUiLanguages: SupportedUiLanguageDto[],
  code?: string,
  name?: string
): string | undefined => {
  const nativeName = supportedUiLanguages.find(
    (supportedLanguage) => supportedLanguage.code === code
  )?.nativeLanguageName;

  if (!nativeName) {
    return name;
  }

  return nativeName;
};

export const getAllowedLanguages = (
  languages: Array<DictionaryEntity<string>>,
  supportedUiLanguages: SupportedUiLanguageDto[]
): Array<AcSelectOption<string>> => {
  const allowedLanguages = languages.filter(
    (item) =>
      item.code && supportedUiLanguages.some(({ code }) => code === item.code)
  );

  const languageSettings = getLanguageSettings();

  const configurationAllowedLanguages = languageSettings?.value
    ? allowedLanguages.filter(
        (language) =>
          language.code &&
          languageSettings.value.supportedLanguages.find(
            (code) => code === language.code
          )
      )
    : allowedLanguages;

  return configurationAllowedLanguages
    .map((language) =>
      mapDictionaryEntityToSelectOption({
        ...language,
        name: getLanguageNameWithFallback(
          supportedUiLanguages,
          language.code,
          language.name
        ),
      })
    )
    ?.filter(isDefined);
};
