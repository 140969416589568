import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const centralReservationOfficeAppName =
  'frontend-central-reservation-office';
export const registerCentralReservationOffice = (): void => {
  registerApplication(
    centralReservationOfficeAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.centralReservationOffice,
        centralReservationOfficeAppName
      ),
    (location: Location) => isCentralReservationOfficeModule(location.pathname)
  );
};
