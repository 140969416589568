import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { isCentralReservationOfficeBusinessContextData } from '@ac/library-api';

const isCroBusinessContextOnErrorPage = (): boolean => {
  return (
    isErrorPage() &&
    isCentralReservationOfficeBusinessContextData(getBusinessContextData())
  );
};

const pathUtilFunctions = [
  isCentralReservationOfficeModule,
  isCroBusinessContextOnErrorPage,
];

export const isCroContextVisible = (): boolean =>
  pathUtilFunctions.some((func) => func());
