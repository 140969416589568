import { isDistributionChannelManagerModule } from 'frontend-container/components/Menu/utils/modules/distributionChannelManager';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const distributionChannelManagerAppName =
  'frontend-distribution-channel-manager';
export const registerDistributionChannelManager = (): void => {
  registerApplication(
    distributionChannelManagerAppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.distributionChannelManager,
        distributionChannelManagerAppName
      ),
    (location: Location) =>
      isDistributionChannelManagerModule(location.pathname)
  );
};
