import { isConfigurationv2Module } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { loadReactApp } from 'frontend-container/utils/externalResources/react';
import { registerApplication } from 'single-spa';

import { acConfig } from '@ac/library-utils/dist/declarations';

export const configurationv2AppName = 'frontend-configurationv2';
export const registerConfigurationv2 = (): void => {
  registerApplication(
    configurationv2AppName,
    () =>
      loadReactApp(
        acConfig.frontendUrls.configurationv2,
        configurationv2AppName
      ),
    (location: Location) => isConfigurationv2Module(location.pathname)
  );
};
