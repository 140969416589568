import { Context } from 'frontend-container/components/Menu/components/Context';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToCroRegion } from 'frontend-container/utils/region/redirectToCroRegion';

import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const redirectIfContextCroRegionChanged = (
  context: Context
): boolean => {
  const region = findRegionData(context.regionCode ?? getGlobalRegionCode());

  return redirectToCroRegion(region?.code ?? '', region?.domain ?? '', context);
};
