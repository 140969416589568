import { fetchFeatureTogglesDefinitions } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/commands/fetchFeatureTogglesDefinitions';
import { useMaintenancePanelFeatureTogglesContext } from 'frontend-container/components/panels/maintenancePanel/store/featureToggles/context';

type CommandResult = () => Promise<void>;

export const useLoadFeatureTogglesOnce = (): CommandResult => {
  const { state, setLoading, setAllItems } =
    useMaintenancePanelFeatureTogglesContext();

  return async () => {
    try {
      if (state.allLoadedItems.length) {
        return;
      }

      setLoading(true);

      const allLoaded = await fetchFeatureTogglesDefinitions();

      setAllItems(allLoaded);
    } finally {
      setLoading(false);
    }
  };
};
