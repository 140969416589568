import { FC, MouseEvent } from 'react';
import { useBrandingLogo } from 'frontend-container/components/Menu/components/Logo/useBrandingLogo';
import { useMaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';

import shijiLogo from './assets/shiji-logo-white-small_v2.png';

import './Logo.scss';

type TapHandler = (event: MouseEvent<HTMLElement>) => boolean;

export const Logo: FC = () => {
  const maintenancePanel = useMaintenancePanelPresenterContext();
  const mainMenuLogo = useBrandingLogo();

  const openMaintenancePanel = async (): Promise<void> => {
    if (!maintenancePanel.state.isVisible) {
      await maintenancePanel.show();
    }
  };

  return (
    <>
      <div
        className="menu-logo-container"
        onClick={tapHandler(openMaintenancePanel)}
        onDoubleClick={openMaintenancePanel}
      >
        <div className="menu-logo">
          <img src={mainMenuLogo || shijiLogo} />
        </div>
      </div>
    </>
  );
};

const tapHandler = (action: () => void): TapHandler => {
  let tapedTwice = false;

  return (event: MouseEvent<HTMLElement>): boolean => {
    if (!tapedTwice) {
      tapedTwice = true;
      setTimeout(() => {
        tapedTwice = false;
      }, 300);

      return false;
    }
    event.preventDefault();
    action();

    return false;
  };
};
