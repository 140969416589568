import { useEffect } from 'react';
import { useLoadFeatureTogglesOnce } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/commands/loadFeatureToggles';
import { FeatureTogglesFilters } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/FeatureTogglesFilters';
import { FeatureTogglesGrid } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/grid/FeatureTogglesGrid';

import { FlexDirection } from '@ac/web-components';

export const FeatureTogglesSectionContent = (): JSX.Element | null => {
  const load = useLoadFeatureTogglesOnce();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void load(), []);

  return (
    <ac-flex direction={FlexDirection.column}>
      <FeatureTogglesFilters />
      <FeatureTogglesGrid />
    </ac-flex>
  );
};
