import { useCallback } from 'react';
import { CONTACT_US_URL } from 'frontend-container/components/LoginLogout/linksConsts';
import { ROUTER_OUTLET_SELECTOR } from 'frontend-container/shared/constants';
import { removeReactRoot } from 'frontend-container/utils/createReactRoot';

import { ErrorPageObject, Errors } from '@ac/web-components';

export const ERROR_PAGE_QUERY_SELECTOR = '.ac-error-page';

interface Props {
  errorType: Errors;
  error?: ErrorPageObject;
  errorId?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

export const Error = ({
  errorType,
  error,
  onPrimaryClick,
  onSecondaryClick,
  errorId,
}: Props): JSX.Element => {
  const backToPreviousPage = useCallback(() => {
    removeReactRoot(ROUTER_OUTLET_SELECTOR);

    return history.back();
  }, []);

  const openContactPage = useCallback(() => {
    return window.open(CONTACT_US_URL, '_blank');
  }, []);

  const reloadPage = useCallback(() => {
    return window.location.reload();
  }, []);

  const handlePrimaryCallback = (): void => {
    if (onPrimaryClick) {
      onPrimaryClick();
    }

    switch (errorType) {
      case Errors.error403:
        openContactPage();

        return;
      case Errors.error404:
        return backToPreviousPage();
      case Errors.error503:
        return backToPreviousPage();
      default:
        return reloadPage();
    }
  };

  return (
    <ac-error-page
      errorType={errorType}
      error={error}
      errorId={errorId}
      onPrimaryClickCallback={handlePrimaryCallback}
      onSecondaryClickCallback={
        errorType === Errors.custom ? onSecondaryClick : backToPreviousPage
      }
    />
  );
};
