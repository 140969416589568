import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationDefinition,
  confirmationModalDefinitions,
} from 'frontend-container/components/modals/confirmationModal/definitions';
import { ConfirmationModalPresenterStore } from 'frontend-container/components/modals/confirmationModal/presenter/store';

import { DialogModal, ModalPresenterStore } from '@ac/react-infrastructure';

interface Props {
  presenter: ConfirmationModalPresenterStore;
}

export const ConfirmationModalContent = ({
  presenter,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const inputData = presenter.state.inputData;

  const definition = useMemo<ConfirmationDefinition | undefined>(() => {
    const confirmation = inputData?.type;

    return confirmation && confirmationModalDefinitions.get(confirmation);
  }, [inputData]);

  if (!inputData) {
    return null;
  }

  if (!definition) {
    throw new Error(
      `Confirmation for type ${inputData.type} is not implemented.`
    );
  }

  return (
    <DialogModal
      presenter={presenter as never as ModalPresenterStore<void, void>}
      config={{
        theme: definition.theme,
        title: t(`${definition.translationObjectKey}.TITLE`),
        titleIcon: definition.titleIcon,
        content: {
          text: t(`${definition.translationObjectKey}.DESCRIPTION`),
        },
        buttons: {
          cancelButtonText: t(`${definition.translationObjectKey}.CANCEL`),
          confirmButtonText: t(`${definition.translationObjectKey}.CONFIRM`),
        },
      }}
    />
  );
};
